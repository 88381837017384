import { Injectable, TemplateRef } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { CustomerHeaderFilter, MinimizerEnum, ProductTypes, CategoryTypeEnum } from 'src/app/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class CreateSaleService {

  sectionTitles = {
    leftSectionTitle: 'ORDER SUMMARY',
    rightSectionTitle1: 'PRODUCT LIST',
    rightSectionTitle2: 'CUSTOMER LIST',
    rightSectionTitle3: 'NOTES',
  }
  activeSection = {
    rightSection1: false,
    rightSection2: false,
    rightSection3: false,
  }
  limit: number = 10000
  skip: number = 0
  isGetOrderById = false
  categoryTypeEnum = CategoryTypeEnum
  categoryType = this.categoryTypeEnum.Parts

  modalRef: BsModalRef;


  // Observable string sources
  private showToasterSource = new Subject<any>();
  // Observable string streams
  onShowToaster$ = this.showToasterSource.asObservable();

  // Observable string sources
  private deviceProductListSource = new Subject<any>();
  // Observable string streams
  $deviceProductListSource = this.deviceProductListSource.asObservable();

  private sectionTitleTextSource = new BehaviorSubject(this.sectionTitles)
  sectionTitleText$ = this.sectionTitleTextSource.asObservable()

  private deviceListingRefresh = new BehaviorSubject(this.deviceFilter)
  deviceList$ = this.deviceListingRefresh.asObservable()


  private productListingRefresh = new BehaviorSubject(this.productFilter)
  productList$ = this.productListingRefresh.asObservable()

  private activeSectionSource = new BehaviorSubject(this.activeSection)
  $activeSection = this.activeSectionSource.asObservable()

  private _deviceFilter = {
    customer_id: '',
    search: '',
    is_active: true,
    location_id: localStorage.getItem('location_id')
  };

  private _productFilter = {
    businessLocation: localStorage.getItem('location_id'),
    productType: ProductTypes.Product,
    is_product: true,
    device_id: '',
    search: '',
    limit: this.limit,
    skip: this.skip,
    categoryType: this.categoryType
  };

  private _customerFilter = {
    search: '',
    location_id: localStorage.getItem('location_id'),
    limit: this.limit,
    skip: this.skip,
    is_deleted: false,
    fromDate: "",
    toDate: "",
    is_cloud: false,
    headerFliter: CustomerHeaderFilter.All,
    footerFliter: [CustomerHeaderFilter.All]
  };

  public get deviceFilter() {
    return this._deviceFilter;
  }
  public set deviceFilter(value) {
    this._deviceFilter = value;
  }

  public get customerFilter() {
    return this._customerFilter;
  }

  public set customerFilter(value) {
    this._customerFilter = value;
  }

  public get productFilter() {
    return this._productFilter;
  }

  public set productFilter(value) {
    this._productFilter = value;
  }


  constructor(private modalService: BsModalService) { }

  setSectionTiles(leftSectionTitle, rightSectionTitle1, rightSectionTitle2, rightSectionTitle3) {

    leftSectionTitle != '' ? this.sectionTitles.leftSectionTitle = leftSectionTitle : ''
    rightSectionTitle1 != '' ? this.sectionTitles.rightSectionTitle1 = rightSectionTitle1 : ''
    rightSectionTitle2 != '' ? this.sectionTitles.rightSectionTitle2 = rightSectionTitle2 : ''
    rightSectionTitle3 != '' ? this.sectionTitles.rightSectionTitle3 = rightSectionTitle3 : ''

    this.onChangeSection(this.sectionTitles)
  }

  setActiveSection(rightSection1: boolean, rightSection2: boolean, rightSection3: boolean) {
    this.activeSection.rightSection1 = rightSection1
    this.activeSection.rightSection2 = rightSection2
    this.activeSection.rightSection3 = rightSection3

    this.onChangeActiceSection(this.activeSection)
  }


  getSectionTitles() {
    return this.sectionTitles
  }

  // Service message commands
  showToaster(valueArray: any) {
    this.showToasterSource.next(valueArray);
  }

  onChangeActiceSection(activeSection = this.activeSection) {
    this.activeSectionSource.next(activeSection)
  }

  onChangeSection(sectionTitles = this.sectionTitles) {
    this.sectionTitleTextSource.next(sectionTitles)
  }

  getDeviceListWithFilter(searchDeviceInput) {
    this.deviceListingRefresh.next(searchDeviceInput)
  }

  getProductListWithFilter(productListingSearchFilter) {
    this.productListingRefresh.next(productListingSearchFilter)
  }

  openModal(template: TemplateRef<any>, cls) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm ' + cls, backdrop: 'static', keyboard: false });
  }
  
  closeModel() {
    this.modalRef.hide();
  }

  isObjectEmpty(Obj) {
    for (var key in Obj) {
      if (Obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  percentage(percent, total) {
    return ((percent / 100) * total).toFixed(2)
  }

  reloadProductList() {
    this.deviceProductListSource.next()
  }

  getDeviceProductAndService(deviceId, is_product) {
    this.deviceProductListSource.next([deviceId, is_product])
  }

  isAutoFocus: boolean = true
  isAutoFocusInput(elementId) {
    let myInput = document.getElementById(elementId);
    setTimeout(() => {
      this.isAutoFocus && myInput ? myInput.focus() : ''
    }, 100);
  }

  toggleIsAtuoFoucs(isAutoFocus: boolean) {
    this.isAutoFocus = isAutoFocus
    isAutoFocus ? this.isAutoFocusInput('searchProduct') : ''
  }

}
