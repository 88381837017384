import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { RolesPermissionsGQLService } from "src/app/services/role-permission/roles-permissions-gql.service"

@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.css']
})
export class AddEditComponent implements OnInit {

  @Input() _id;
  @Input() role_record;
  @Input() allowedModule;
  @Output() event: EventEmitter<any> = new EventEmitter();
  location_id = localStorage.getItem('location_id')

  showToast: boolean = false
  hideToaster: boolean = true
  toasterMsg: String = ''
  toasterType: String = ''
  edit: boolean = false
  submitted: boolean = false

  roleForm: any;

  constructor(public bsModalRef: BsModalRef,
    private formbulider: FormBuilder,
    private _rolePermissionsGQL: RolesPermissionsGQLService) { }

  ngOnInit() {
    this.initRoleForm(this.role_record)
    this.edit == !!this._id
  }

  initRoleForm(role) {
    this.roleForm = this.formbulider.group({
      name: [role ? role.role.roleName : '', Validators.required],
      modules: [role ? role.modules : '', Validators.required]
    })
  }

  get frole() {
    return this.roleForm.controls
  }

  saveRole() {
    this.submitted = true
    if (this.roleForm.invalid) {
      return
    }
    if (!this._id) {
      this.createRole(this.frole.name.value, this.frole.modules.value)
    } else {
      var remove = this.role_record.modules.filter(x => this.frole.modules.value.indexOf(x) === -1);
      var add = this.frole.modules.value.filter(x => this.role_record.modules.indexOf(x) === -1);

      this.updateRole(this._id, this.role_record.role.roleName, add, remove)
    }

  }

  createRole(roleName, modules) {
    this._rolePermissionsGQL.createRole(roleName, modules, this.location_id)
      .subscribe((res) => {
        this.onRefreshRoleList()
        this.closeModal()
      }, (err) => {
        this.showToaster(err.message, 'error')
      })
  }

  updateRole(roleID, roleName, addModule, removeModule, location_id = this.location_id) {
    this._rolePermissionsGQL.updateRole(roleID, roleName, addModule, removeModule, location_id)
      .subscribe((res) => {
        this.onRefreshRoleList()
        this.closeModal()
      }, (err) => {
        this.showToaster(err.message, 'error')
      })
  }

  onRefreshRoleList() {
    this.event.emit()
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  showToaster(message, status) {
    this.showToast = true
    this.hideToaster = false
    this.toasterMsg = message
    this.toasterType = status
  }

  closeToaster() {
    this.showToast = false
    this.hideToaster = true
  }


}
