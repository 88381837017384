import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { QuestionBase } from 'src/app/utilties/dynamic-form/core/question-base';
import { QuestionService } from 'src/app/utilties/dynamic-form/core/question.service';
import { QuestionGqlService } from 'src/app/utilties/dynamic-form/core/question-gql.service';
import { BsModalRef } from 'ngx-bootstrap';

enum customFieldTypeEnum {
  Customer = 'customer'
}

@Component({
  selector: 'app-form-creation',
  templateUrl: './form-creation.component.html',
  styleUrls: ['./form-creation.component.css'],
})
export class FormCreationComponent implements OnInit {

  questions$: Observable<QuestionBase<any>[]>;
  @Output() event: EventEmitter<any> = new EventEmitter();
  bsModalRef: BsModalRef

  form: FormGroup
  customFieldType = 'customer'

  loading = false;
  errorMessage: string | null;
  successMessage: string | null;
  error: boolean = false;
  success: boolean = false;
  id = null;
  edit = false;

  formOptions = {
    location_id: localStorage.getItem('location_id'),
    customFieldType: customFieldTypeEnum.Customer,
    systemRequiredFileds: [{
      value: '',
      key: Math.floor(Math.random() * 100).toString(),
      label: 'First Name',
      required: true,
      controlType: 'textbox',
      type: '',
      options: [],
      order: 0,
      systemRequired: true
    }, {
      value: '',
      key: Math.floor(Math.random() * 100).toString(),
      label: 'Last Name',
      required: true,
      controlType: 'textbox',
      type: '',
      options: [],
      order: 0,
      systemRequired: true
    }, {
      value: '',
      key: Math.floor(Math.random() * 100).toString(),
      label: 'Phone Number',
      required: true,
      controlType: 'textbox',
      type: '',
      options: [],
      order: 0,
      systemRequired: true
    }, {
      value: '',
      key: Math.floor(Math.random() * 100).toString(),
      label: 'Email',
      required: true,
      controlType: 'email',
      type: '',
      options: [],
      order: 0,
      systemRequired: true
    }]
  }

  private unsubscribe: Subject<any>;
  submitted: boolean = false;

  constructor(private questionService: QuestionService,
    private fb: FormBuilder) { }

  ngOnInit() {
    
  }

  initForm() {
    const validation = {
      formElement: this.fb.array([]),
      location_id: [localStorage.getItem('location_id'), Validators.compose([Validators.required])],
      customFieldType: [this.customFieldType, Validators.compose([Validators.required])],
    };
    this.form = this.fb.group(validation)
  }



  onSave() {
    this.event.emit()
    this.closeModal()
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
