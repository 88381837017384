// POS Module Permissions Set
export enum MODULE_PERMISSIONS_NAME {
  create_sale_menu = "create_sale_menu",
  dashboard_menu = "dashboard_menu",
  repair_room_menu = "repair_room_menu",
  tickets_menu = "tickets_menu",
  customers_menu = "customers_menu",
  stock_menu = "stock_menu",
  reports_menu = "reports_menu",
  system_menu = "system_menu",
  discount_menu = "discount_menu",
  franchise_menu = "franchise_menu",
  techbar_ai_menu = "techbar_ai_menu",
  directory_menu = "directory_menu",
  acounting_menu = "accounting_menu"
}


// POS Rourting Permissions Set
export enum ROUTING {
  // Create a sale
  create_sale_menu = "CreateSale",
  orders_summary_tab = "OrderSummary",
  product_list_tab = "ProductList",
  customer_list_tab = "CustomerList",
  notes_tab = "Notes",
  device_list_tab = "DeviceList",
  order_check_out_tab = "Checkout",

  // Tickets
  tickets_menu = "Tickets",
  tickets_listing_tab = "Listing",
  tickets_details_tab = "Details",

  // Repair Room
  repair_room_menu = "RepairRoom",
  task_list_tab = "TaskList",
  task_detail_tab = "TaskDetail",
  extra_item_tab = "ExtraItem",
  device_checkin_tab = "DeviceCheckin",
  device_comment_tab = "DeviceComment",


  // Customers
  customers_menu = "Customers",
  customer_detail_tab = "CustomerDetails",
  purchase_history_tab = "PurchaseHistory",
  tickets_tab = "Tickets",
  net_terms_tab = "NetTerms",
  store_credit_tab = "StoreCredit",
  document_tab = "Document",

  //Discount 
  discount_menu = "Discounts",
  

  //stock
  stock_menu = "Stock",
  supplier_list_tab = "SupplierList",
  supplier_detail_tab = "SupplierDetail",
  suplier_po_tab = "SuplierPO",
  supplier_RMA_tab = "SupplierRMA",
  supplier_buyback_tab = "SupplierBuyback",
  supplier_net_terms_tab = "SupplierNetTerms",
  supplier_store_credits_tab = "SupplierStoreCredits",
  supplier_shipping_tab = "SupplierShipping",
  supplier_payments_type_tab = "SupplierPaymentType",

  manage_order_type_tab = "OrderType",

  buyback_supplier_tab = "BuybackSupplier",
  add_item_Buyback_tab = "BuybackProductList",
  receive_Buyback_submitted_tab = "BuybackSubmitted",
  receive_Buyback_on_transit_tab = "BuybackOnTransit",

  receive_rma_submitted_tab = "RMASubmitted",
  receive_rma_on_transit_tab = "RMAOnTransit",

  manage_service_tab = "ServiceList",

  po_record_payment_tab = "PORecordPayment",
  receive_po_paid_tab = "POPaid",
  receive_po_on_transit_tab = "POonTransit",
  create_po_tab = "CreatePO",

  // system setiing
  system_menu = "setting",
  business_management_tab = 'business',
  store_management_tab = 'store',
  cash_register_tab = 'cash-register',
  user_tab = 'user',
  role_tab = 'role',
  department_tab = 'department',
  chart_of_account_tab = 'chart-of-account',
  configuration_tab = 'configuration',
  manage_lookup_tab = 'manage-lookup',
  discount_listing_tab = "discounts",
  commission_listing_tab = 'commission'
}

// 1 - Features Permissions Set
export enum CREATE_SALE_FEATURE_NAME {
  orders_summary_tab = "orders_summary_tab",
  product_list_tab = "product_list_tab",
  customer_list_tab = "customer_list_tab",
  notes_tab = "notes_tab",
  device_list_tab = "device_list_tab",
  order_check_out_tab = "order_check_out_tab"
}

export enum CREATE_SALE_ORDER_CHECKOUT_ACTION_NAME {
  apply_discout_action = "apply_discount_action",
  credit_card_payment_action = "credit_card_payment_action",
  cash_payment_action = "cash_payment_action",
  check_payment_action = "check_payment_action",
  paypal_payment_action = "paypal_payment_action",
  net_terms_payment_action = "net_terms_payment_action",
  store_credit_payment_action = "store_credit_payment_action",
  giftcard_payment_action = "giftcard_payment_action",
  paypal_invoice_payment_action = "paypal_invoice_payment_action",
  pay_later_action = "pay_later_action"
}

// 1.2 - Product List Permissions Set
export enum CREATE_SALE_PRODUCT_LIST_ACTION_NAME {
  filter_selected_device_action = "filter_selected_device_action",
  filter_searchbar_action = "filter_searchbar_action",
  add_custom_product_action = "add_custom_product_action",
  filter_products_action = "filter_products_action",
}

// 1.3 - Device List Permissions Set
export enum CREATE_SALE_DEVICE_LIST_ACTION_NAME {
  filter_active_action = "filter_active_action",//remove tobe
  filter_archived_action = "filter_archived_action",
  filter_searchbar_action = "filter_searchbar_action",
  filter_clear_action = "filter_clear_action",//remove tobe
  detail_device_action = "detail_device_action",
  edit_device_action = "edit_device_action",
  add_device_action = "add_device_action",
  device_setting_action = "device_setting_action",
  print_label_device_action = "print_label_device_action",
  archive_device_action = "archive_device_action",
  import_device_action = "import_device_action",
  export_device_action = "export_device_action",
  merge_device_action = "merge_device_action",
  transfer_device_action = "transfer_device_action",
  active_device_action = "active_device_action",
  permanent_delete_device_action = "permanent_delete_device_action"

}

// 1.4 - Order Summary Permissions Actions
export enum CREATE_SALE_ORDER_SUMMARY_ACITON_NAME {
  quote_sell_action = "quote_sell_action",
  close_sell_action = "close_sell_action",
  checkout_sell_action = "checkout_sell_action",

  detail_customer_action = "detail_customer_action",
  edit_customer_contact_number_action = "edit_customer_contact_number_action",
  print_quote_action = "print_quote_action",
  send_quote_email_action = "send_quote_email_action",
  send_private_note_action = "send_private_note_action",
  discount_sale_action = "discount_sale_action",
  charge_taxes_action = 'charge_taxes_action',
  discount_product_action = "discount_product_action",
  change_product_price_action = "change_product_price_action",
}

// 2 - Tickets Features Permissions Set
export enum TICKETS_FEATURE_NAME {
  tickets_listing_tab = "tickets_listing_tab",
  tickets_details_tab = "tickets_details_tab",
}

// 2.1 - Tickets Lisiting Permissions Actions
export enum TICKETS_LISTING_ACTION_NAME {
  filter_active_complete_action = "filter_active_complete_action",
  view_invoice_detail_action = "view_invoice_detail_action",
  view_quote_detail_action = "view_quote_detail_action",
  launch_repair_room_action = "launch_repair_room_action",
  launch_checkout_action = "launch_checkout_action",
  launch_reopen_action = "launch_reopen_action",
  download_invoice_action = "download_invoice_action"
}

// 2.2 - Tickets Detail Permissions Actions
export enum TICKETS_DETAIL_ACTION_NAME {
  goto_customer_action = "goto_customer_action",
  download_invoice_action = "download_invoice_action",
  print_invoice_action = "print_invoice_action",
  make_payment_action = "make_payment_action",
  make_refund_action = "make_refund_action"
}

// 3 - Repair Room Feature Permissions Set
export enum REPAIR_ROOM_FEATURE_NAME {
  task_list_tab = "task_list_tab",
  task_detail_tab = "task_detail_tab",
  extra_item_tab = "extra_item_tab",
  device_checkin_tab = "device_checkin_tab",
  device_comment_tab = "device_comment_tab",
}

// 3.1 - Task Listing Permissions Actions
export enum TASK_LIST_ACTION_NAME {
  view_all_tasks_action = "view_all_tasks_action",
  view_task_setting_action = "view_task_setting_action",
  mark_tasks_done_action = "mark_tasks_done_action",
  mark_all_tasks_done_action = "mark_all_tasks_done_action",
}

// 3.2 - Task Detail Permissions Actions
export enum TASK_DETAIL_ACTION_NAME {
  mark_task_done_action = "mark_task_done_action",
  assign_user_action = "assign_user_action",
  purchase_alert_action = "purchase_alert_action",
  change_status_action = "change_status_action",
  start_timer_action = "start_timer_action",
  remove_service_action = "remove_service_action",
  order_part_action = "order_part_action",
  item_setting_action = "item_setting_action",
  item_damaged_action = "item_damaged_action",
  item_exchange_action = "item_exchange_action",
  item_defective_action = "item_defective_action",

}

// 3.3 - Extra Permissions Actions
export enum EXTRA_ITEM_ACTION_NAME {
  set_reminder_action = "set_reminder_action",
  device_checkin_action = "device_checkin_action"
}

// 3.4 - Device Checkin Permissions Actions
export enum DEVICE_CHECKIN_ACTION_NAME {

}

// 3.5 -  Detail Permissions SActionset
export enum DEVICE_COMMENT_ACTION_NAME {

}

// 4 - Customerd Features Permissions Set
export enum CUSTOMER_FEATURE_NAME {
  customer_list_tab = "customer_list_tab",
  customer_detail_tab = "customer_detail_tab",
  device_list_tab = "device_list_tab",
  purchase_history_tab = "purchase_history_tab",
  tickets_tab = "tickets_tab",
  net_terms_tab = "net_terms_tab",
  store_credit_tab = "store_credit_tab",
  document_tab = "document_tab",
}

// 4.1 - Customer Listing Permissions Actions
export enum CUSTOMER_LIST_ACTION_NAME {
  filter_cloud_action = "filter_cloud_action",
  filter_store_action = "filter_store_action",//remove tobe
  filter_active_action = "filter_active_action",
  filter_archived_action = "filter_archived_action",
  filter_daterange_action = "filter_daterange_action",
  filter_monthdate_action = "filter_monthdate_action",
  filter_search_action = "filter_search_action",
  filter_clear_action = "filter_clear_action",//remove tobe
  detail_customer_action = "detail_customer_action",
  edit_customer_action = "edit_customer_action",
  add_customer_action = "add_customer_action",
  merge_customer_action = "merge_customer_action",
  export_customer_action = "export_customer_action",
  import_customer_action = "import_customer_action",
  archive_customer_action = "archive_customer_action",
  active_customer_action = "active_customer_action",
  setting_customer_action = "setting_customer_action",
  filter_overdue_action = "filter_overdue_action",
  filter_with_device_action = "filter_with_device_action",
  filter_with_out_device_action = "filter_with_out_device_action",
  filter_store_credit_action = "filter_store_credit_action",
  filter_net_term_action = "filter_net_term_action",
  filter_most_recent_action = "filter_most_recent_action",
  permanent_delete_customer_action = "permanent_delete_customer_action",
}

// 4.2 - Customer Detail Permissions Actions
export enum CUSTOMER_DETAIL_ACTION_NAME {

  // Customer details Top Bar Actions
  archive_customer_action = "archive_customer_action",
  edit_customer_action = "edit_customer_action",

  // Customer Detail tabs Actions
  device_list_tab = "device_list_tab",
  purchase_history_tab = "purchase_history_tab",
  tickets_tab = "tickets_tab",
  net_terms_tab = "net_terms_tab",
  store_credit_tab = "store_credit_tab",
  document_tab = "document_tab",

  // Device Tab List Actions
  filter_archived_action = "filter_archived_action",
  detail_device_action = "detail_device_action",
  edit_device_action = "edit_device_action",
  add_device_action = "add_device_action",
  device_setting_action = "device_setting_action",
  print_label_device_action = "print_label_device_action",
  archive_device_action = "archive_device_action",
  import_device_action = "import_device_action",
  export_device_action = "export_device_action",
  merge_device_action = "merge_device_action",
  transfer_device_action = "transfer_device_action",
  active_device_action = "active_device_action",
  permanent_delete_device_action = "permanent_delete_device_action",

  // Tickets Tab List Actions
  filter_active_complete_action = "filter_active_complete_action",
  view_invoice_detail_action = "view_invoice_detail_action",
  view_quote_detail_action = "view_quote_detail_action",
  launch_repair_room_action = "launch_repair_room_action",
  launch_checkout_action = "launch_checkout_action",
  launch_reopen_action = "launch_reopen_action",
  download_invoice_action = "download_invoice_action",

  // Net Terms Tab List Actions
  manage_net_terms_action = "manage_net_terms_action",
  record_payment_action = "record_payment_action",

  // Store Credit Tab List Actions
  manage_credit_action = "manage_credit_action",
  transfer_credit_action = "transfer_credit_action",

  // Document Tab List Actions
  add_document_action = "add_document_action",
  setting_document_action = "setting_document_action",
  delete_document_action = "delete_document_action",

  // Record Payment Detail Actions
  credit_card_payment_method_action = "credit_card_payment_method_action",
  cash_payment_method_action = "cash_payment_method_action",
  check_payment_method_action = "check_payment_method_action",
  paypal_payment_method_action = "paypal_payment_method_action",
  bank_transfer_payment_method_action = "bank_transfer_payment_method_action",
  store_credit_payment_method_action = "store_credit_payment_method_action"
}

export enum DISCOUNT_FEATURE_NAME {
  discount_listing_tab = "discount_listing_tab",
}


export enum STOCK_FEATURE_NAME {
  product_list_tab = "product_list_tab",

  supplier_list_tab = "supplier_list_tab",
  supplier_detail_tab = "supplier_detail_tab",
  suplier_po_tab = "suplier_po_tab",
  supplier_RMA_tab = "supplier_RMA_tab",
  supplier_buyback_tab = "supplier_buyback_tab",
  supplier_net_terms_tab = "supplier_net_terms_tab",
  supplier_store_credits_tab = "supplier_store_credits_tab",
  supplier_shipping_tab = "supplier_shipping_tab",
  supplier_payments_type_tab = "supplier_payments_type_tab",

  manage_order_type_tab = "manage_order_type_tab",

  buyback_supplier_tab = "buyback_supplier_tab",
  add_item_Buyback_tab = "add_item_Buyback_tab",
  receive_Buyback_submitted_tab = "receive_Buyback_submitted_tab",
  receive_Buyback_on_transit_tab = "receive_Buyback_on_transit_tab",


  receive_rma_submitted_tab = "receive_rma_submitted_tab",
  receive_rma_on_transit_tab = "receive_rma_on_transit_tab",

  manage_service_tab = "manage_service_tab",
  create_po_tab = "create_po_tab",
  po_record_payment_tab = "po_record_payment_tab",
  receive_po_paid_tab = "receive_po_paid_tab",
  receive_po_on_transit_tab = "receive_po_on_transit_tab"
}

export enum STOCK_PRODUCT_LIST_ACTION_NAME {
  filter_products_action = "filter_products_action",
  filter_services_action = "filter_services_action",
  filter_bundle_services_action = "filter_bundle_services_action",
  filter_bundle_product_action = "filter_bundle_product_action",
  filter_manufacture_bundle_product_action = "filter_manufacture_bundle_product_action",
  filter_incomplete_action = "filter_incomplete_action",
  filter_archived_action = "filter_archived_action",
  add_product_action = "add_product_action",
  product_settings_action = "product_settings_action",
  import_product_action = "import_product_action",
  export_product_action = "export_product_action",
  print_label_action = "print_label_action",
  archive_product_action = "archive_product_action",
  active_product_action = "active_product_action",
  edit_product_action = "edit_product_action",
}

export enum STOCK_SUPPLIER_LIST_ACTION_NAME {
  filter_archived_action = "filter_archived_action",
  add_supplier_action = "add_supplier_action",
  import_supplier_action = "import_supplier_action",
  export_supplier_action = "export_supplier_action",
  archive_supplier_action = "archive_supplier_action",
  filter_verified_supplier_action = "filter_verified_supplier_action",
  filter_unlink_products_action = "filter_unlink_products_action",
  show_supplier_setting_action = "show_supplier_setting_action"
}

export enum STOCK_SUPPLIER_DETAIL_ACTION_NAME {

  //detail
  archive_supplier_action = "archive_supplier_action",
  edit_supplier_action = "edit_supplier_action",

  //Net terms
  manage_net_terms_action = "manage_net_terms_action",
  record_payment_action = "record_payment_action",

  //store credits
  manage_credit_action = "manage_credit_action",
  transfer_credit_action = "transfer_credit_action",

  //shiping
  add_shipping_type_action = "add_shipping_type_action",
  edit_shipping_type_action = "edit_shipping_type_action",
  archive_shipping_type_action = "archive_shipping_type_action",

  //buyback
  can_activate_buyback_action = "can_activate_buyback_action"

}

export enum MANAGE_ORDER_ACTION_NAME {
  //feature manage_order_type_tab
  create_po_action = "create_po_action",
  create_RMA_action = "create_RMA_action",
  create_Buyback_action = "create_Buyback_action"
}

export enum BUYBACK_ACTION_NAME {
  //feature buyback_supplier_tab
  active_supplier_buyback_action = "active_supplier_buyback_action",
  inactive_supplier_buyback_action = "inactive_supplier_buyback_action",

  // feature add_item_Buyback_tab
  add_new_device_action = "add_new_device_action",

  //feature receive_Buyback_submitted_tab
  add_tracking_number_action = "add_tracking_number_action",
  add_supplier_buyback_number = "add_supplier_buyback_number",
  download_buyback_action = "download_buyback_action",
  cancel_buyback_action = "cancel_buyback_action",

  //fetaure receive_Buyback_on_transit_tab
  mark_buyback_as_delivered_action = "mark_buyback_as_delivered_action"
}

export enum RMA_ACTION_NAME {
  // receive_rma_submitted_tab
  download_rma_action = 'download_rma_action',
  cancel_rma_action = 'cancel_rma_action',
  add_tracking_number_action = "add_tracking_number_action",
  add_supplier_rma_number = "add_supplier_rma_number",

  // receive_rma_on_transit_tab
  mark_rma_as_delivered_action = "mark_rma_as_delivered_action"
}

export enum STOCK_SERVICE_ACTION_NAME {
  //manage_service_tab
  add_service_action = "add_service_action",
  edit_service_action = "edit_service_action",
  delete_service_action = "delete_service_action",

  add_brand_action = "add_brand_action",
  delete_brand_action = "delete_brand_action",

  add_model_action = "add_model_action",
  edit_model_action = "edit_model_action",
  delete_model_action = "delete_model_action",

}

export enum PO_ACTION_NAME {

  //create_po_tab
  change_unit_cost_action = "change_unit_cost_action",
  discount_po_action = "discount_po_action",
  record_payment_action = "record_payment_action",

  //po_record_payment_tab
  credit_card_payment_action = "credit_card_payment_action",
  cash_payment_action = "cash_payment_action",
  check_payment_action = "check_payment_action",
  paypal_paymnet_action = "paypal_paymnet_action",
  net_terms_payment_action = "net_terms_payment_action",
  store_credit_payment_action = "store_credit_payment_action",


  //recived_po_paid_tab
  download_po_action = "download_po_action",
  cancel_po_action = "cancel_po_action",
  add_tracking_number_action = "add_tracking_number_action",
  add_supplier_order_number_action = "add_supplier_order_number_action",

  //receive_po_on_transit_tab
  mark_order_as_delivered_action = "mark_order_as_delivered_action"
}

export enum SYSTEM_FEATURE_NAME {
  business_management_tab = 'business_management_tab',
  store_management_tab = 'store_management_tab',
  cash_register_tab = 'cash_register_tab',
  user_tab = 'user_tab',
  role_tab = 'role_tab',
  department_tab = 'department_tab',
  chart_of_account_tab = 'chart_of_account_tab',
  configuration_tab = 'configuration_tab',
  manage_lookup_tab = 'manage_lookup_tab',
  discount_listing_tab = "discount_listing_tab",
  commission_listing_tab = "commission_listing_tab"
}

export enum SYSTEM_BUSINESS_ACTION_NAME {
  edit_business_action = 'edit_business_action',
  change_password_action = 'change_password_action'
}

export enum SYSTEM_STORE_ACTION_NAME {
  add_store_action = 'add_store_action',
  edit_store_action = 'edit_store_action',
  view_store_action = 'view_store_action',
  change_store_status_action = 'change_store_status_action'
}

export enum  SYSTEM_CASH_REGISTER_ACTION_NAME{
  add_cash_register_action = 'add_cash_register_action',
  view_cash_register_action = 'view_cash_register_action',
  edit_cash_register_action = 'edit_cash_register_action',
  delete_cash_register_action = 'delete_cash_register_action',
  change_cash_register_status_action = 'change_cash_register_status_action'
}

export enum SYSTEM_USER_ACTION_NAME{
  add_user_action = 'add_user_action',
  edit_user_action = 'edit_user_action',
  view_user_action = 'view_user_action',
  delete_user_action = 'delete_user_action',
  set_permission_action = 'set_permission_action',
  import_user_action = 'import_user_action'
}

export enum SYSTEM_ROLE_ACTION_NAME{
  add_role_action = 'add_role_action',
  edit_role_action = 'edit_role_action',
  delete_role_action = 'delete_role_action',
  set_permission_action = 'set_permission_action',
}

export enum SYSTEM_DEPARTMENT_ACTION_NAME{
  add_department_action = 'add_department_action',
  edit_department_action = 'edit_department_action',
  delete_department_action = 'delete_department_action'
}

export enum SYSTEM_CHART_OF_ACCOUNT_ACTION_NAME{
  add_account_action='add_account_action',
  edit_account_action = 'edit_account_action',
  delete_account_action = 'delete_account_action'
}

export enum SYSTEM_CONFIGURATION_ACTION_NAME{
  configure_feature_action = 'configure_feature_action',
  configure_finance_action = 'configure_finance_action',
  configure_inventory_action = 'configure_inventory_action',
  configure_customer_action = 'configure_customer_action',
  configure_HR_action = 'configure_HR_action'
}

export enum MANAGE_LOOKUP_ACTION_NAME{
  add_brand_action = 'add_brand_action',
  
  add_model_action = 'add_model_action',
  link_model_action = 'link_model_action',
  edit_model_action = 'edit_model_action',
  delete_model_action = 'delete_model_action',
  
  add_carrier_action = 'add_carrier_action',
  link_carrier_action = 'link_carrier_action',
  edit_carrier_action = 'edit_carrier_action',
  delete_carrier_action = 'delete_carrier_action',

  add_lock_status_action = 'add_lock_status_action',
  link_lock_status_action = 'link_lock_status_action',
  edit_lock_status_action = 'edit_lock_status_action',
  delete_lock_status_action = 'delete_lock_status_action',

  add_storage_action = 'add_storage_action',
  link_storage_action = 'link_storage_action',
  edit_storage_action = 'edit_storage_action',
  delete_storage_action = 'delete_storage_action',

  add_color_action = 'add_color_action',
  link_color_action = 'link_color_action',
  edit_color_action = 'edit_color_action',
  delete_color_action = 'delete_color_action',

  add_grade_action = 'add_grade_action',
  link_grade_action = 'link_grade_action',
  edit_grade_action = 'edit_grade_action',
  delete_grade_action = 'delete_grade_action'
}

export enum DISCOUNT_LISTING_ACTION_NAME {
  change_campaign_status_action = "change_campaign_status_action",
  change_discount_status_action = "change_discount_status_action",
  edit_disount_action = "edit_disount_action",
  create_discount_action = "create_discount_action",
  download_discount_action = "download_discount_action"
}

export enum SYSTEM_COMMISSION_ACTION_NAME{
  change_campaign_status_action = "change_campaign_status_action",
  change_commission_status_action = "change_commission_status_action",
  edit_commission_action = "edit_commission_action",
  create_commission_action = "create_commission_action",
}



