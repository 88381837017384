import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericUtility } from './generic-utility';
import { AutofocusDirective } from 'src/app/utilties/auto-focus.directive';
import { TabOrderDirective } from 'src/app/utilties/tab-order';
import { NumericDirective } from './numeric-directive.directive';
import { ListFilterPipe } from './Pipe/list-filter.pipe'
import { ListFilterByFieldNamePipe } from './Pipe/list-filter-by-field-name.pipe';
import { OrderPartSupplierPipe } from './Pipe/order-part-supplier-filer'
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { customDateFormatPipe } from './directives/custom-date-format.directive';
import { TwoDigitDecimaNumberDirective } from './directives/TwoDigitDecimaNumberDirective';
import { OnlyNumber } from './directives/only-number.directive';
import { OmitSpecialChar } from './directives/omit-special-char.directive';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { TaskDetailStatusFilterPipe } from './Pipe/task-detail-status-filter.pipe';
import { ModalWithYesNoOptionComponent } from './templates/modals/modal-with-yes-no-option/modal-with-yes-no-option.component';
import { RolesPermissionsNamePipe } from './Pipe/roles-permissions/roles-permissions-name.pipe';
import { PermissionFilterPipe } from './Pipe/roles-permissions/permission-filter.pipe';
import { SearchPermissionPipe } from './Pipe/roles-permissions/search-permission.pipe';
import { PermissionNoClickDirective } from './directives/permission-no-click/permission-no-click.directive';
import { PermissionsDisableActionsDirective } from './directives/permissions-disable-action/permissions-disable-actions.directive';
import { MousetrapKeyDirective } from './directives/mousetrap-key/mousetrap-key.directive';
import { DynamicFormQuestionComponent } from './dynamic-form/component/dynamic-form-question/dynamic-form-question.component';
import { DynamicFormComponent } from './dynamic-form/component/dynamic-form/dynamic-form.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormCreationComponent } from './dynamic-form/component/dynamic-form-creation/dynamic-form-creation.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule, BsDropdownModule, TooltipModule } from 'ngx-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators"
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
// import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    BsDropdownModule,
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    NgSelectModule,
    SortableModule.forRoot(),
    RxReactiveFormsModule,
    NgxTrimDirectiveModule,
    // NgOptionHighlightModule
  ],
  providers: [
    GenericUtility
  ],
  declarations: [
    TwoDigitDecimaNumberDirective,
    AutofocusDirective,
    TabOrderDirective,
    customDateFormatPipe,
    NumericDirective,
    ListFilterPipe,
    ListFilterByFieldNamePipe,
    OrderPartSupplierPipe,
    PhoneMaskDirective,
    OnlyNumber,
    OmitSpecialChar,
    ClickStopPropagationDirective,
    TaskDetailStatusFilterPipe,
    ModalWithYesNoOptionComponent,
    RolesPermissionsNamePipe,
    PermissionFilterPipe,
    SearchPermissionPipe,
    PermissionNoClickDirective,
    PermissionsDisableActionsDirective,
    MousetrapKeyDirective,
    DynamicFormQuestionComponent,
    DynamicFormComponent,
    DynamicFormCreationComponent,

  ],
  exports: [
    TwoDigitDecimaNumberDirective,
    AutofocusDirective,
    TabOrderDirective,
    customDateFormatPipe,
    NumericDirective,
    ListFilterPipe,
    ListFilterByFieldNamePipe,
    PhoneMaskDirective,
    OrderPartSupplierPipe,
    OnlyNumber,
    OmitSpecialChar,
    ClickStopPropagationDirective,
    TaskDetailStatusFilterPipe,
    ModalWithYesNoOptionComponent,
    RolesPermissionsNamePipe,
    PermissionFilterPipe,
    SearchPermissionPipe,
    PermissionNoClickDirective,
    PermissionsDisableActionsDirective,
    MousetrapKeyDirective,
    DynamicFormQuestionComponent,
    DynamicFormComponent,
    DynamicFormCreationComponent, 
    NgxTrimDirectiveModule,
    // NgOptionHighlightModule
  ],
})
export class UtilitiesModule { }
