import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {

  private ls = window.localStorage;
  private secretKey = 'luna'
  constructor(private router: Router) { }

  public setItem(key, value) {
    value = JSON.stringify(value);
    this.ls.setItem(key, value);
    return true;
  }

  public getItem(key) {
    const value = this.ls.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      // console.log(e)
      return null;
    }
  }

  public setEncrypt(key,value){
    this.ls.setItem(key, CryptoJS.AES.encrypt(JSON.stringify(value), this.secretKey))
  }

  public getDecrypt(key){
    const value = this.ls.getItem(key)
    try {
      return JSON.parse(CryptoJS.AES.decrypt(value, this.secretKey).toString(CryptoJS.enc.Utf8))
    } catch (e) {
      return null;
    }
  }

  public clear() {
    this.ls.clear();
  }
}
