import { Injectable } from "@angular/core";
import { type } from "os";
import {
  CreateCarrierGQL,
  CreateChartOfAccountByTypeGQL,
  CreateDeviceModelGQL,
  UpdateDeviceModelGQL,
  CreateGradeGQL,
  CreateLockStatusGQL,
  CreateProductGQL,
  CreateStorageSizeGQL,
  CreateSystemBrandGQL,
  CreateUpdateDeviceGQL,
  DeleteProductGQL,
  DeleteSystemBrandGQL,
  DeviceAddSerialStockValidateGQL,
  DeviceSerialStockDeleteGQL,
  GenerateSkuNumberGQL,
  GetAllAccountTypesGQL,
  GetAllAccountTypeWiseGQL,
  GetAllCarrierGQL,
  GetAllDeviceModelByBrandGQL,
  GetAllDeviceTypeGQL,
  GetAllGradesGQL,
  GetAllLockStatusGQL,
  GetAllProductCategoriesGQL,
  GetAllStorageSizesGQL,
  GetAllSystemBrandsGQL,
  GetCategoryTypeTaxGQL,
  GetProductbyIdGQL,
  GetProductsAndSearchGQL,
  ImportProductsDataGQL,
  ImportProductsWithFileGQL,
  IsUniqueDeviceSkuGQL,
  RestoreProductGQL,
  SearchSupplierGQL,
  TaxsGQL,
  UniqueSkuNumberForProductGQL,
  UniqueSkUNumberForSupplierGQL,
  UnlinkSupplierWithDeviceGQL,
  UpdateProductGQL,
  DeleteDeviceModelGQL,
  DeleteCarrierGQL,
  DeleteStorageSizeGQL,
  DeleteColorGQL
} from "src/app/generated/graphql";
import { CommonService } from "../common/common.service";
import { NotificationsService } from "../notification-service/notifications.service";

@Injectable({
  providedIn: "root"
})
export class ManageProductsService {
  constructor(
    private getAllProductsGQL: GetProductsAndSearchGQL,
    private restoreProductGQL: RestoreProductGQL,
    private deleteProductGQL: DeleteProductGQL,
    private uniqueSkuNumberForProductGQL: UniqueSkuNumberForProductGQL,
    private generateSkuNumberGQL: GenerateSkuNumberGQL,
    private createProductGQL: CreateProductGQL,
    private getAllSystemBrands: GetAllSystemBrandsGQL,
    private createSystemBrand: CreateSystemBrandGQL,
    private createDeviceModelGQL: CreateDeviceModelGQL,
    private updateDeviceModelGQL: UpdateDeviceModelGQL,
    private getDeviceModelGQL: GetAllDeviceModelByBrandGQL,
    private getAllAccountTypeWiseGQL: GetAllAccountTypeWiseGQL,
    private getAllAccountTypesGQL: GetAllAccountTypesGQL,
    private createChartOfAccountByTypeGQL: CreateChartOfAccountByTypeGQL,
    private updateProductGQL: UpdateProductGQL,
    private getProductbyIdGQL: GetProductbyIdGQL,
    private searcingAndListingSupplier: SearchSupplierGQL,
    private uniqueSkUNumberForSupplierGQL: UniqueSkUNumberForSupplierGQL,
    private taxsGQL: TaxsGQL,
    private deleteSystemBrandGQL: DeleteSystemBrandGQL,
    private deleteCarriers: DeleteCarrierGQL,
    private deleteColor: DeleteColorGQL,
    private deleteStorageSize: DeleteStorageSizeGQL,
    private importProductsData: ImportProductsDataGQL,
    private getAllCarrier: GetAllCarrierGQL,
    private createCarrier: CreateCarrierGQL,
    private getAllLockStatus: GetAllLockStatusGQL,
    private createLockStatus: CreateLockStatusGQL,
    private getAllStorageSizes: GetAllStorageSizesGQL,
    private createStorageSizes: CreateStorageSizeGQL,
    private getAllGrades: GetAllGradesGQL,
    private createGrade: CreateGradeGQL,
    private getAllProductCategories: GetAllProductCategoriesGQL,
    private getAllDeviceType: GetAllDeviceTypeGQL,
    private createUpdateDevice: CreateUpdateDeviceGQL,
    private IsUniqueDeviceSKU: IsUniqueDeviceSkuGQL,
    private deviceAddSerialStockValidate: DeviceAddSerialStockValidateGQL,
    private deviceSerialStockDelete: DeviceSerialStockDeleteGQL,
    private unlinkSupplierWithDevice: UnlinkSupplierWithDeviceGQL,
    private getCategoryTypeTax: GetCategoryTypeTaxGQL,
    private commonService: CommonService,
    private importProductsWithFile: ImportProductsWithFileGQL,
    private notificationsService: NotificationsService,
    private deleteDeviceModel: DeleteDeviceModelGQL
  ) { }

  getAllProducts(obj) {
    return this.getAllProductsGQL.watch(obj).valueChanges;
  }

  getAllSuppliers(search, excludeSyncSupplier) {
    return this.searcingAndListingSupplier.watch({
      search: search,
      active: "active",
      is_verify_supplier: false,
      unlink_product: false,
      location_id: window.localStorage.getItem("location_id"),
      limit: 100,
      skip: 0,
      exclude_sync_supplier: excludeSyncSupplier
    }).valueChanges;
  }

  checkUniqueSupplierSKU(obj) {
    return this.uniqueSkUNumberForSupplierGQL.watch(obj).valueChanges;
  }

  getAllBrands(search) {
    return this.getAllSystemBrands.watch({
      search: search
    }).valueChanges;
  }

  createBrand(input) {
    return this.createSystemBrand.mutate({
      input: input
    });
  }

  deleteBrand(id) {
    return this.deleteSystemBrandGQL.mutate({
      id: id
    });
  }

  deleteCarrier(id) {
    return this.deleteCarriers.mutate({
      id: id
    });
  }

  deleteCapacity(id) {
    return this.deleteStorageSize.mutate({
      id: id
    });
  }

  deleteColors(id) {
    return this.deleteColor.mutate({
      id: id
    });
  }

  getModels(id) {
    return this.getDeviceModelGQL.watch({
      id: id
    }).valueChanges;
  }

  createModels(obj) {
    return this.createDeviceModelGQL.mutate({
      input: obj
    });
  }

  updateModels(id, obj) {
    return this.updateDeviceModelGQL.mutate({
      id: id,
      input: obj
    })
  }

  deleteModel(id) {
    return this.deleteDeviceModel.mutate({
      id: id
    });
  }

  activeProducts(products) {
    return this.restoreProductGQL.mutate({
      productIds: products
    });
  }

  deleteProducts(products) {
    return this.deleteProductGQL.mutate({
      productIds: products
    });
  }

  generateSKU() {
    return this.generateSkuNumberGQL.mutate();
  }

  checkProductSKUNumber(number, id) {
    return this.uniqueSkuNumberForProductGQL.watch({
      locationId: localStorage.getItem("location_id"),
      sku: number,
      productID: id
    }).valueChanges;
  }

  createProduct(obj) {
    return this.createProductGQL.mutate(
      {
        input: obj
      },
      {
        context: {
          useMultipart: true
        }
      }
    );
  }

  getAllAccount(search, type) {
    return this.getAllAccountTypeWiseGQL.watch({
      search: search,
      type: type
    }).valueChanges;
  }

  getAllAccountTypes() {
    return this.getAllAccountTypesGQL.watch().valueChanges;
  }

  createAccount(type, obj) {
    return this.createChartOfAccountByTypeGQL.mutate({
      account_type: type,
      input: obj
    });
  }

  getProductByID(id) {
    return this.getProductbyIdGQL.watch({
      locationId: localStorage.getItem("location_id"),
      id: id
    });
  }

  updateProduct(id, obj, isSync) {
    return this.updateProductGQL.mutate(
      {
        id: id,
        input: obj,
        is_sync_product: isSync
      },
      {
        context: {
          useMultipart: true
        }
      }
    );
  }

  getAllTaxes() {
    return this.taxsGQL.watch().valueChanges;
  }

  productsInvalidData = [];
  newlyAddedProducts = [];
  alreadyExistProducts = [];
  importProducts(data) {
    this.addImportedProductsChunk(data.location_id, data.input);
    return;
    let startSet = 0;
    let endSet = 300;
    let products = data.input;
    let iteration = 1;
    while (products.length > 0) {
      let chunk = products.splice(startSet, endSet);
      console.log("set ", chunk);
      // await this.addImportedProductsChunk(data.location_id, chunk)
      this.commonService.showToaster(["Chunk " + iteration + " completed", "success"]);
      iteration = iteration + 1;
    }
    console.log("Products imported", this.newlyAddedProducts);
    console.log("Products invalid", this.productsInvalidData);
    console.log("Products already exists", this.alreadyExistProducts);
  }

  importProductWithFile(file, id) {
    this.importProductsWithFile.mutate({
      file: file,
      locationID: id
    }, {
      context: {
        useMultipart: true
      }
    }).subscribe(
      (res) => {
        this.notificationsService.isFileDownloaded = false;
        this.notificationsService.showImportRecordbar = true;
        this.notificationsService.importSubscription();
        this.notificationsService.importProductResult();
      }, (err) => {
        console.log('err while import product', err);
        this.commonService.showToaster(["Error while importing products ", "error"]);
      }
    )
  }

  addImportedProductsChunk(location_id, chunk) {
    this.importProductsData
      .mutate({
        location_id: location_id,
        input: chunk
      })
      .subscribe(
        res => {
          if (res["data"].importProductsData.productsInvalidData.length > 0) {
            this.productsInvalidData.push(
              res["data"].importProductsData.productsInvalidData
            );
          }
          if (res["data"].importProductsData.newlyAddedProducts.length > 0) {
            this.newlyAddedProducts.push(
              res["data"].importProductsData.newlyAddedProducts
            );
          }
          if (res["data"].importProductsData.alreadyExistProducts.length > 0) {
            this.alreadyExistProducts.push(
              res["data"].importProductsData.alreadyExistProducts
            );
          }
        },
        err => {
          this.productsInvalidData.push(chunk);
        }
      );
  }

  getAllProductTypes() {
    return this.getAllProductCategories.watch({
      location_id: localStorage.getItem("location_id")
    }).valueChanges;
  }

  getAllCarriers() {
    return this.getAllCarrier.watch().valueChanges;
  }

  createCarriers(carriers_name, locationId) {
    return this.createCarrier.mutate({carrierName: carriers_name, locationId: locationId});
  }

  getAllLockStatuses() {
    return this.getAllLockStatus.watch().valueChanges;
  }

  createLockStatuses(name) {
    return this.createLockStatus.mutate({
      lock_status: name
    });
  }

  getAllSizes(type) {
    return this.getAllStorageSizes.watch({
      storage_type: type
    });
  }

  createStorageSize(input) {
    return this.createStorageSizes.mutate({input: input});
  }

  getAllDeviceGrades() {
    return this.getAllGrades.watch().valueChanges;
  }

  createDeviceGrade(grade) {
    return this.createGrade.mutate({
      grade: grade
    });
  }

  getAllDeviceTypes() {
    return this.getAllDeviceType.watch({
      location_id: localStorage.getItem("location_id")
    }).valueChanges;
  }

  createUpdateDevices(id, data) {
    return this.createUpdateDevice.mutate(
      {
        _id: id,
        input: data
      },
      {
        context: {
          useMultipart: true
        }
      }
    );
  }

  checkDeviceSKU(id, sku) {
    return this.IsUniqueDeviceSKU.watch({
      location_id: localStorage.getItem("location_id"),
      sku: sku,
      _id: id
    }).valueChanges;
  }

  checkDeviceSerialNumber(serail, device = undefined) {
    return this.deviceAddSerialStockValidate.watch({
      deviceSerial: serail,
      device: device,
      location_id: localStorage.getItem("location_id")
    }).valueChanges;
  }

  deleteSerialNumber(deviceID, supplierID, serailNumber) {
    return this.deviceSerialStockDelete.mutate({
      deviceID: deviceID,
      supplier_id: supplierID,
      deviceSerial: serailNumber,
      location_id: localStorage.getItem("location_id")
    });
  }

  unlinkSupplierFromDevice(deviceID, supplierID) {
    return this.unlinkSupplierWithDevice.mutate({
      deviceID: deviceID,
      supplierID: supplierID,
      locationID: localStorage.getItem("location_id")
    });
  }

  getCategoryTax(id) {
    return this.getCategoryTypeTax.watch({
      categoryID: id,
      location_id: localStorage.getItem("location_id")
    });
  }
}
