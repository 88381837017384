import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  DoCheck
} from "@angular/core";
import { SaleCartService } from "src/app/services/create-sale/sale-cart.service";
import { CreateSaleService } from "src/app/services/create-sale/create-sale.service";
import { CreateSaleGQLService } from "src/app/services/create-sale/create-sale-gql.service";
import { CustomerGQLService } from "src/app/services/customer/customerGQL.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PrintService } from "src/app/print/print.service";
import {
  AllowedTransactionStatus,
  Customer,
  CustomerFooterFilter,
  CustomerHeaderFilter,
  MinimizerEnum
} from "src/app/generated/graphql";
import { MinimizerService } from "src/app/services/minimizer/minimizer.service";
import {
  CREATE_SALE_FEATURE_NAME,
  CREATE_SALE_ORDER_SUMMARY_ACITON_NAME
} from "src/app/services/authentication/permissions-name";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { CustomerService } from "src/app/services/customer/customer.service";
import { CommonService } from "src/app/services/common/common.service";
import { concat, Observable, of, Subject } from "rxjs";

@Component({
  selector: "app-sale-cart",
  templateUrl: "./sale-cart.component.html",
  styleUrls: ["./sale-cart.component.css"]
})
export class SaleCartComponent implements OnInit {

  Check: boolean = false;
  businessSaleTax: any = 0;
  orderId = "";
  CREATE_SALE_ORDER_SUMMARY_ACITON_NAME = CREATE_SALE_ORDER_SUMMARY_ACITON_NAME;
  CREATE_SALE_FEATURE_NAME = CREATE_SALE_FEATURE_NAME;

  @ViewChild("open_removeCustomerFromCart", { static: true })
  open_removeCustomerFromCart: any;
  @ViewChild("open_customerChange", { static: true }) open_customerChange: any;
  isRolePermissionModule: boolean = false;

  public get customers$(): Observable<any> {
    return this.saleCartService.customers$;
  }
  public set customers$(value: Observable<any>) {
    this.saleCartService.customers$ = value;
  }
 
  public get customerLoading() {
    return this.saleCartService.customerLoading;
  }
  public set customerLoading(value) {
    this.saleCartService.customerLoading = value;
  }
  
  public get customerInput$() {
    return this.saleCartService.customerInput$;
  }
  public set customerInput$(value) {
    this.saleCartService.customerInput$ = value;
  }

  get createSaleInput(){
    return this.saleCartService.createSaleInput
  }

  set createSaleInput(createSaleInput){
    this.saleCartService.createSaleInput = createSaleInput
  }

  get loading(){
    return this.saleCartService.loading
  }

  get customer() {
    return this.saleCartService.customer
  }
  previousCustomer: Customer = null;
  set customer(customer) {
    this.previousCustomer = this.saleCartService.customer;
    this.saleCartService.customer = customer;
  }

  get customer_id(){
    return this.saleCartService.customer_id
  }

  set customer_id(customer_id){
    this.saleCartService.customer_id = customer_id
  }

  constructor(
    private saleCartService: SaleCartService,
    private createSaleService: CreateSaleService,
    private customerGQLService: CustomerGQLService,
    private customerService: CustomerService,
    private router: Router,
    private createSaleGQLService: CreateSaleGQLService,
    private printService: PrintService,
    private route: ActivatedRoute,
    private _minimizer: MinimizerService,
    private _authentication: AuthenticationService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    if (this.router.url.includes("Permissions")) {
      this.isRolePermissionModule = true;
    }
    
    this.orderId = this.route.snapshot.params.orderId;
    if (this.orderId) {
      this.createSaleService.isGetOrderById = true;
      !this.isRolePermissionModule && this.getOrderById(this.orderId);
      setTimeout(() => {
        this._minimizer.getAllMinimizers(
          MinimizerEnum.CreateSale,
          localStorage.getItem("location_id"),
          false
        );
      }, 500);
    }

    !this.isRolePermissionModule &&
      this._minimizer.getAllMinimizers(
        MinimizerEnum.CreateSale,
        localStorage.getItem("location_id"),
        !this.createSaleService.isGetOrderById
      );

    if (this.isRolePermissionModule) {
      this.saleCartService.addItemToCart(
        this.saleCartService.data.orderSummery
      );
      //this.createSaleInput['saleCart'].push(this.saleCartService.data.orderSummery)
      this.saleCartService.customer = this.saleCartService.data.customer[0];
      this.createSaleInput["saleCart"] = this.saleCartService.data.orderSummery;
    }
  }

  trackByFn(item: any) {
    return item._id;
  }

  openCustomerTab() {
    this.router.navigate(["Pointofsale", "CreateSale", "Order"]);
  }

  getOrderById(id) {
    this.saleCartService.getOrderById(id);
  }

  isScrollable = false;
  downcart() {
    this.isScrollable = true;
  }

  upcart() {
    this.isScrollable = false;
  }

  changeSaveB = false;
  changeSaveButton() {
    this.changeSaveB = !this.changeSaveB;
  }

  onCustomerInfoClick(customerId) {
    this.router.navigateByUrl(
      `Pointofsale/Customers/Listing/detail/${customerId}`
    );
  }

  onDiscardOrder() {
    if (
      this.createSaleInput["transaction_status"] ==
        AllowedTransactionStatus.Minimize &&
      this.createSaleInput["_id"]
    ) {
      this._minimizer.deleteMinimizer(
        this.createSaleInput["_id"],
        MinimizerEnum.CreateSale,
        localStorage.getItem("location_id"),
        -1
      );
    }
    this.saleCartService.onDiscardOrder();
    this.router.navigateByUrl(`Pointofsale/CreateSale/Order`);
    this.createSaleInput = this.saleCartService.getCreateSaleInput();
    //this.isQuoteSave = false
    this.changeSaveB = false;
    //this.cdr.detectChanges()
  }

  onRemoveAllProduct() {
    this.saleCartService.initCreateSaleInput();
    this.createSaleInput = this.saleCartService.getCreateSaleInput();
  }

  onCalculateSubTotal() {
    this.saleCartService.onCalculateSubTotal();
  }

  onDiscountChange() {
    this.saleCartService.onCalculateDiscount();
  }

  onApplySaleTaxAmountChange() {
    this.saleCartService.onCalculateSaleTax();
    this.onCalculateTotalAmount();
  }

  onCalculateTotalAmount() {
    this.saleCartService.onCalculateTotalAmount();
  }

  onRemoveItemFromCart(product_id) {
    this.saleCartService.removeItemFromCart(product_id);
  }

  get isQuoteSave(): boolean {
    return (
      this.saleCartService.createSaleInput._id !== null &&
      this.saleCartService.createSaleInput.transaction_status ==
        AllowedTransactionStatus.Quote
    );
  }

  onCustomerChange($event) {
    this.customer = $event
    this.saleCartService.isDeviceInCart()
      ? this.createSaleService.openModal(
          this.open_customerChange,
          "custModal wd300"
        )
      : this.onChangeCustomer($event);
  }

  onChangeCustomer($event) {
    this.saleCartService.removeDeviceFromCart();
    this.customer
      ? this.router.navigateByUrl(
          `Pointofsale/CreateSale/Order/DeviceList/${this.customer._id}`
        )
      : this.router.navigateByUrl(`Pointofsale/CreateSale/Order/CustomerList`);
  }

  onCancelChangeCustomer() {
    this.customer = this.previousCustomer
  }

  onSaveOrder(transactionStatus, isQuote) {
    this.saleCartService.onSaveOrder(transactionStatus, isQuote);
  }

  OnPrintInvoice() {
    let obj = {
      orderIDs: [this.createSaleInput["_id"]],
      fileType: "pdf",
      is_detail: false
    };
    this.printService.getAndPrintDocument("api/order/download/", obj);
  }

  onSendEmail() {
    this.createSaleGQLService
      .sendEmailOnOrder(
        this.createSaleInput["_id"],
        localStorage.getItem("location_id")
      )
      .subscribe(
        res => {
          if (res.data.emailTicket)
            this.createSaleService.showToaster(["Email Send", "success"]);
          else if (res.errors)
            this.createSaleService.showToaster(["Email Send Error", "error"]);
        },
        err => {
          this.createSaleService.showToaster([err.message, "error"]);
        }
      );
  }

  editContactField = false;
  editContactNumber() {
    this.editContactField = true;
  }

  saveContactNumber() {
    this.editContactField = false;
    this.customerGQLService
      .updateCustomerPhoneNumber(
        this.customer["_id"],
        this.customer["phone"]
      )
      .subscribe(res => {
        this.customerService.onCustomerListinRefresh();
        this.createSaleService.showToaster([
          "Customer contanct number updated",
          "success"
        ]);
      });
  }

  isActionAllowed(actionName) {
    return this._authentication.isActionAllowed(
      CREATE_SALE_FEATURE_NAME.orders_summary_tab,
      actionName
    );
  }

  focusSearch(elementId) {
    this.createSaleService.isAutoFocusInput(elementId);
  }

  toggleIsAtuoFoucs(isAutoFocus: boolean) {
    this.createSaleService.toggleIsAtuoFoucs(isAutoFocus);
  }
}
