import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[permission-no-click]'
})
export class PermissionNoClickDirective {

  constructor(private router: Router) { }

  @HostListener("click", ["$event"])
  public onClick(event: any): void {
    if (this.router.url.includes('Permissions'))
      event.preventDefault();
  }
}
