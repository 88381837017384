import { url } from 'inspector';

export class EnvironmentUrl {

    // public static DownloadPO = 'https://api.dev.techbar.com/api/pdf?orderId='
    // public static Images = 'https://api.dev.techbar.com/upload/'
    // public static CloseTosterTime = 5000
    // // public static CloseTosterTime = 150000 

    // public static get getAPIUrl(): string {
    //   return 'https://api.dev.techbar.com/'
    // } 

    // public static get getGoogleKey() {
    //     return 'AIzaSyA5cnq3CEOoKAYLYW2OsPXUxBgevdEVWWs'
    // }

    // public static get getClientUrl(): string {
    //     return window.location.origin + '/';
    // }
    public static DownloadPO = 'https://api-pos-ldev.4iisolutions.com/api/pdf?orderId='
    public static Images = 'https://api-pos-ldev.4iisolutions.com/upload/'
    public static CloseTosterTime = 5000
    // public static CloseTosterTime = 150000 

    public static get getAPIUrl(): string {
        let url = window.localStorage.getItem('ServerURL');
        if(url == null || url == undefined) {
            return 'https://api-pos-ldev.4iisolutions.com/'
        }
        return window.localStorage.getItem('ServerURL');
    }

    public static get getSubscriptionSocketURL(): string {
        return 'wss://api-pos-ldev.4iisolutions.com'
    }

    public static get getSocketURL(): string {
        return 'wss://api-pos-ldev.4iisolutions.com'
    }

    public static get getGoogleKey() {
        return 'AIzaSyA5cnq3CEOoKAYLYW2OsPXUxBgevdEVWWs'
    }

    public static get getClientUrl(): string {
        return window.location.origin + '/';
    }
}

