import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BusinessStatus, UserStatus } from 'src/app/generated/graphql';
import { MustMatch } from 'src/app/modules/account/register-old/_helper/must-match.validator';
import { ProfilePhotoUploaderComponent } from 'src/app/modules/shared/profile-photo-uploader/profile-photo-uploader.component';
import { SettingGqlService } from 'src/app/services/settings/setting-gql.service'
import { SettingsService } from "src/app/services/settings/settings.service"
import { EnvironmentUrl } from 'src/environments/environment-url';


@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.css']
})
export class AddEditUserComponent implements OnInit {

  @Input() userId: any
  @Output() event: EventEmitter<any> = new EventEmitter();
  baseUrl = EnvironmentUrl.Images

  addUserSubmitted = false
  addUserLoader = false

  addUserForm: any
  userlogo = null
  user: any
  roles = []

  hideToaster = true
  toasterType = ''
  toasterMsg = ''

  statuses = ['Active', 'InActive']

  constructor(private formbulider: FormBuilder,
    public bsModalRef: BsModalRef,
    public bsModalRefIMage: BsModalRef,
    private _settingGQL: SettingGqlService,
    private modalService: BsModalService,
    private _setting: SettingsService) { }

  ngOnInit() {
    if (this.userId) {
      this.getUserById(this.userId)
    }
    this.initializeUserForm(null)
    this.getAllRoles()
    this.getAllCountries()
  }

  getUserById(id) {
    this.addUserLoader = true
    this._settingGQL.getUserById(id)
      .subscribe((res) => {
        this.addUserLoader = false
        this.user = JSON.parse(JSON.stringify(res.data.getUserByID))
        this.user.roles = []
        res.data.getUserByID.roles.forEach(element => {
          this.user.roles.push(element._id)
        });
        this.initializeUserForm(this.user)
      }, (err) => {
        this.showToaster(err.mesage, 'error')
      })
  }

  initializeUserForm(user) {

    this.addUserForm = this.formbulider.group({
      email: [user ? user.email : '', [Validators.required,
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      first_name: [user ? user.first_name : '', [Validators.required]],
      last_name: [user ? user.last_name : '', [Validators.required]],
      phone: [user ? user.phone : '', [Validators.required]],
      status: [user ? user.status : 'Active', Validators.required],
      password: [user ? user.password : '', Validators.required],
      confirm_password: [, [Validators.required]],
      address_1: [user ? user.address_1 : '', [Validators.required]],
      address_2: [user ? user.address_2 : ''],
      country: [user ? user.Country._id : '', [Validators.required]],
      city: [user ? user.city : '', [Validators.required]],
      state: [user ? user.state : '', [Validators.required]],
      zipcode: [user ? user.zipcode : '', [Validators.required]],
      salaryHour: [user ? user.salaryHour : '', [Validators.required]],
      roles: [user ? user.roles : '', [Validators.required]],
      BusinessLocation: [window.localStorage.getItem('location_id')],
      Business: [window.localStorage.getItem('BusinessId')]
    }, {
      validator: MustMatch('password', 'confirm_password')
    })
    user ? this.addUserForm.controls.status.setValue(this.faddUser.status.value == BusinessStatus.Active ? 'Active' : 'InActive') : ''
    user ? this.userlogo = user.avatar_location == null || user.avatar_location == "" ? null : this.baseUrl + user.avatar_location : this.userlogo = null
  }

  get faddUser() {
    return this.addUserForm.controls
  }

  getAllRoles() {
    this._settingGQL.getAllRoles(localStorage.getItem('location_id'))
      .subscribe((res) => {
        this.roles = []
        res.data.getRoles.forEach(element => {
          this.roles.push(element.role)
        });
      })
  }

  allCountries: any
  getAllCountries() {
    this.addUserLoader = true
    this._settingGQL.getCountries()
      .subscribe(
        (res) => {
          this.allCountries = res.data.countries
          this.addUserLoader = false
        }, (err) => {
          console.log('err in countries');
        }
      )
  }


  saveUser() {

    if (this.addUserForm.invalid) {
      this.addUserSubmitted = true
      return;
    }

    let userInput = JSON.parse(JSON.stringify(this.addUserForm.value))
    delete userInput['confirm_password']
    userInput['salaryHour'] = parseFloat(userInput['salaryHour'])

    if (this.userId) {
      this.updateUser(userInput)
    } else {
      this.addUser(userInput)
    }
  }

  addUser(userInput) {
    userInput.status = userInput.status == 'Active' ? BusinessStatus.Active : BusinessStatus.Inactive
    this.addUserLoader = true
    this._settingGQL.createBusinessUser(userInput, this.userlogo ? this.userlogo : '')
      .subscribe(
        (res) => {
          this.updateUserList()
          this.closeModal()
          this.addUserLoader = false
          this.addUserSubmitted = false
          // this.initializeAddUser()
        }, (err) => {
          this.showToaster(err.message, 'error')
          this.addUserLoader = false
        }
      )
  }

  updateUser(userInput) {
    userInput.status = userInput.status == 'Active' ? BusinessStatus.Active : BusinessStatus.Inactive
    this.addUserLoader = false
    let obj = this._setting.arrayCompareAddRemoveItem(this.user.roles, userInput.roles)

    userInput.addRoles = obj['add']
    userInput.removeRoles = obj['remove']
    delete userInput['roles']
    this._settingGQL.updateBusinessUser(this.userId, userInput)
      .subscribe(
        (res) => {
          this.addUserLoader = false
          this.updateUserList()
          this.closeModal()
          this.addUserLoader = false

          this.addUserSubmitted = false
          // this.initializeAddUser()
        }, (err) => {
          this.showToaster(err.message, 'error')
          this.addUserLoader = false
        }
      )

  }


  model_ProfilePhoto(imageURL) {
    const initialState = { imageURL: imageURL };
    this.bsModalRefIMage = this.modalService.show(ProfilePhotoUploaderComponent, { class: 'modal-sm box-saveorder profilepopUp wd600', initialState });

    this.bsModalRefIMage.content.event.subscribe(res => {
      if (res.data && res.data.isSave) {
        this.saveUserPhoto(res.data.imageURL)
      }
      else if (res.data && !res.data.isSave)
        this.deleteUserPhoto()
    });
  }

  saveUserPhotoLoader = false
  saveUserPhoto(image) {
    this.userlogo = ''
    this.userlogo = image.getAsDataURL()
    //this.userlogo = this.img.getAsDataURL()
    if (this.userId)
      this._settingGQL.userLogoUpload(this.userId, image.getAsDataURL())
        .subscribe(
          (res) => {
            this.user.avatar_location = res.data.userLogoUpload
            this.saveUserPhotoLoader = false
          }, (err) => {
            this.showToaster(err.mesage, 'error')
            this.saveUserPhotoLoader = false
          }
        )
  }

  userLogoDeleteLoader = false
  deleteUserPhoto() {
    this.userLogoDeleteLoader = true
    if (this.userId)
      this._settingGQL.userLogoDelete(this.userId)
        .subscribe(
          (res) => {
            this.userLogoDeleteLoader = false
            this.userlogo = null
          }, (err) => {
            this.userLogoDeleteLoader = false
            console.log('userLogoDelete err', err);
          }
        )
    else
      this.userlogo = null
  }

  updateUserList() {
    this.event.emit()
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  showToast = false
  showToaster(message, status) {
    this.showToast = true
    this.hideToaster = false
    this.toasterMsg = message
    this.toasterType = status
  }

  closeToaster() {
    this.showToast = false
    this.hideToaster = true
  }

}
