import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { PointofsaleComponent } from './point-of-sale.component';
import { HomeComponent } from './home/home.component';
import { TicketsComponent } from './tickets-old/tickets.component';
import { StockComponent } from './stock-old/stock.component';
import { ReportsComponent } from './reports-old/reports.component';
import { SettingsComponent } from './settings/settings.component';
import { LunaGuardService } from 'src/app/guard/luna-guard.service';
import { DiscountsComponent } from './discounts-old/discounts.component';
import { FranchiseComponent } from './franchise/franchise.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { TechbarAiComponent } from './techbar-ai/techbar-ai.component';
import { PoCheckoutComponent } from './stock/manage-orders/po/po-checkout/po-checkout.component';
import { PermissionGuardService } from 'src/app/guard/permission-guard.service';
import { CREATE_SALE_FEATURE_NAME, MANAGE_ORDER_ACTION_NAME, MODULE_PERMISSIONS_NAME, STOCK_FEATURE_NAME } from 'src/app/services/authentication/permissions-name';
import { ManageUserComponent } from './system-settings/users/manage-user/manage-user.component';
import { DongleComponent } from './dongle/dongle/dongle.component';

const routes: Routes = [
  {
    path: 'Pointofsale',
    component: PointofsaleComponent,
    canActivate: [LunaGuardService],
    canActivateChild: [PermissionGuardService],
    children: [
      {
        path: '',
        redirectTo: 'Home',
        pathMatch: 'full'
      },
      {
        path: 'dongle', component: DongleComponent,
        // data: {
        //   menu: MODULE_PERMISSIONS_NAME.techbar_ai_menu
        // }
      },
      {
        path: 'Home', component: HomeComponent,
        data: {
          menu: MODULE_PERMISSIONS_NAME.dashboard_menu
        }
      },
      {
        path: 'Tickets-OLD', component: TicketsComponent,
        data: {
          menu: MODULE_PERMISSIONS_NAME.tickets_menu
        }
      },
      {
        path: 'Tickets',
        loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule),
        data: {
          menu: MODULE_PERMISSIONS_NAME.tickets_menu
        }
      },
      {
        path: 'CreateSale',
        loadChildren: () => import('./create-sale/create-sale.module').then(m => m.CreateSaleModule),
        data: {
          menu: MODULE_PERMISSIONS_NAME.create_sale_menu
        },
      },
      {
        path: 'Customers',
        loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
        data: {
          menu: MODULE_PERMISSIONS_NAME.customers_menu
        },
      },
      {
        path: 'RepairRoom',
        loadChildren: () => import('./repair-room/repair-room.module').then(m => m.RepairRoomModule),
        data: {
          menu: MODULE_PERMISSIONS_NAME.repair_room_menu
        },
      },
      {
        path: 'Stock-OLD', component: StockComponent

      },
      {
        path: 'Stock',
        loadChildren: () => import('./stock/stock.module').then(m => m.StockModule),
        data: {
          menu: MODULE_PERMISSIONS_NAME.stock_menu
        },
      },
      {
        path: 'Reports', component: ReportsComponent,
        data: {
          menu: MODULE_PERMISSIONS_NAME.reports_menu
        },
      },
      {
        path: 'Settings',
        loadChildren: () => import('./system-settings/system-settings.module')
          .then(m => m.SystemSettingsModule),
        // component: SettingsComponent,
        data: {
          menu: MODULE_PERMISSIONS_NAME.system_menu
        },
      },
      {
        path: 'update-profile/:id',
        component: ManageUserComponent,
      },
      {
        path: 'System-Tab',
        component: SettingsComponent,
      },
      {
        path: 'Discounts',
        loadChildren: () => import('./discounts/discounts.module').then(m => m.DiscountsModule),
        data: {
          menu: MODULE_PERMISSIONS_NAME.system_menu
        },
      },
      {
        path: 'Discounts-old', component: DiscountsComponent,
        data: {
          menu: MODULE_PERMISSIONS_NAME.system_menu
        },
      },
      {
        path: 'Franchise', component: FranchiseComponent,
        data: {
          menu: MODULE_PERMISSIONS_NAME.franchise_menu
        },
      },
      {
        path: 'Directory',
        loadChildren: () => import('./directory/directory.module').then(m => m.DirectoryModule),
        data: {
          menu: MODULE_PERMISSIONS_NAME.directory_menu
        },
      }
    ]
  },
  {
    path: 'Permissions',
    loadChildren: () => import('./roles-permissions/roles-permissions.module').then(m => m.RolesPermissionsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PointofsaleRoutingModule { }
