import { Directive, Input, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
const Mousetrap = require("mousetrap");

@Directive({
  selector: '[mousetrapKey]'
})
export class MousetrapKeyDirective implements AfterViewInit, OnDestroy {

  @Input() mousetrapKey

  constructor(private elementRef: ElementRef) { }

  ngOnDestroy(): void {
    if (this.mousetrapKey)
      Mousetrap.unbind(this.mousetrapKey)
  }

  ngAfterViewInit(): void {
    Mousetrap.bind(this.mousetrapKey, (e) => {
      if (e.preventDefault) {
        e.preventDefault();
      } else {
        // internet explorer
        e.returnValue = false;
      }
      if ((this.elementRef.nativeElement as HTMLElement).tagName.toLowerCase() == 'input') {
        (this.elementRef.nativeElement as HTMLElement).focus();
      } else {
        (this.elementRef.nativeElement as HTMLElement).dispatchEvent(new Event('click'));
      }
      console.log(this.mousetrapKey + ' press')
    })
  }


}
