import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuardService } from 'src/app/guard/permission-guard.service';
import { MODULE_PERMISSIONS_NAME, TICKETS_FEATURE_NAME } from 'src/app/services/authentication/permissions-name';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { TicketListingComponent } from './ticket-listing/ticket-listing.component';

const routes: Routes = [
    {
        path: '',
        component: TicketListingComponent,
        canActivate: [PermissionGuardService],
        data: {
            menu: MODULE_PERMISSIONS_NAME.tickets_menu,
            feature: TICKETS_FEATURE_NAME.tickets_listing_tab
        }
    },
    {
        path: 'Detail/:id',
        component: TicketDetailComponent,
        canActivate: [PermissionGuardService],
        data: {
            menu: MODULE_PERMISSIONS_NAME.tickets_menu,
            feature: TICKETS_FEATURE_NAME.tickets_details_tab
        }
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TicketRoutingModule { }
