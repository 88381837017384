import { Component, OnInit } from '@angular/core';
import { ITreeOptions } from '@circlon/angular-tree-component';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import {DongleSocketService} from "../dongle-socket.service"
@Component({
  selector: 'app-dongle',
  templateUrl: './dongle.component.html',
  styleUrls: ['./dongle.component.css']
})
export class DongleComponent implements OnInit {
  deviceList = []
  deviceData = []
  fileData:any
  downloadedFile:any
  socket:any
  usbPath:string
  options: ITreeOptions = {
    actionMapping: {
        mouse: {
            dblClick: (tree, node, $event) => {
                const lineage = [];
                // add clicked node as first item
                lineage.push(node.data);

                // grab parent of clicked node
                let parent = node.parent;

                // loop through parents until the root of the tree is reached
                while(parent !== null){
                    lineage.push(parent.data);
                    parent = parent.parent;
                }
                let p = "/mnt/"
                for (let i = lineage.length - 1; i >= 0; i--) {
                  if (lineage[i].name) {
                    p += lineage[i].name + "/"
                  }
                }
                p = p.substring(0, p.length - 1);
                this.downloadFile(p)
                console.log(p, "final-path")
                // for(var i = 0; i < lineage.length; i++){
                //    var obj = lineage[i];
                //    if (obj && obj.name) {
                //     var path = 'mnt/' + obj.name + '/'  + obj.name + '/' + obj.name;
                //     console.log(path, obj.name)
 
                //    }
                // }
                // console.log('lineage', lineage)
            }
        }
    }
};

  constructor(private _dongleSocket: DongleSocketService,
    private wsService: WebsocketService) { }

  ngOnInit() {
    this.socket = this.wsService.socket

    this._dongleSocket.devices.subscribe(data => {
      this.deviceList = JSON.parse(data)
      console.log(data);
    });

    this.socket.on('devices', (data: any) => {
      this.deviceList = JSON.parse(data)
    });

    this.socket.on('file_data',(data: any)=>{
      console.log('File Data',data)
      this.fileData = JSON.parse(data)
    });

    this.socket.on('file_download_data',(data:any)=>{
      console.log('Downlaod Data',data)
      this.download('Hello IOT.txt',data)
      //this.downloadedFile = JSON.parse(data)
    })

    this.socket.on('device_data', (data: any) => {
      this.deviceList = []
      this.deviceData = []
      this.deviceData.push(JSON.parse(data))
      console.log(data);
    });
    
    this.getDevices()
  }
  
  onEvent = ($event) => console.log($event);

  downloadFile(path){
    this.socket.emit('file_download',path)
  }

  getFile(path){
    this.socket.emit('file_requested',path);
  }

  getDevices() {
    this.socket.emit('show_devices', 'devices');
  }

  getDeviceData(path){
    this.usbPath = path
    this.socket.emit('device_path',path);
  }

  download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

}
