import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AllowMethodPhoneVerify, AllSearchApplication, BusinessStatus } from 'src/app/generated/graphql';
import { SettingGqlService } from 'src/app/services/settings/setting-gql.service'
import { SettingsService } from "src/app/services/settings/settings.service"
import { EnvironmentUrl } from 'src/environments/environment-url';
import { ProfilePhotoUploaderComponent } from 'src/app/modules/shared/profile-photo-uploader/profile-photo-uploader.component'
import { AddEditUserComponent } from '../add-edit-user/add-edit-user.component';
import { Router } from '@angular/router';

@Component({
  selector: 'user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {

  @Input() userId: any
  @Output() public closeUserDetail: EventEmitter<any> = new EventEmitter();
  baseUrl = EnvironmentUrl.Images
  userDetail: any
  userTimeLogLoader = false
  userTimeLog: any
  loading = false
  userlogo: any

  generatePinCode = true

  getLogLoader = false
  logType = AllSearchApplication.All
  logActivityData = []
  searchUserActivityLog = {
    userId: '',
    limit: 100,
    skip: 0,
    type: this.logType
  }

  constructor(private _settingGQL: SettingGqlService,
    private modalService: BsModalService,
    private _setting: SettingsService,
    private router: Router) { }

  ngOnInit() {
    this.searchUserActivityLog.userId = this.userId
    this.getUserById(this.userId)
    this.getUserTimeLog(this.userId)
    this.getUsersLogsActivity()
    this.getUserPinCodeById()
  }

  getUserById(id) {
    this.loading = true
    this._settingGQL.getUserById(id)
      .subscribe((res) => {
        this.loading = false
        this.userDetail = res.data.getUserByID
        this.userlogo = this.userDetail.avatar_location == null || this.userDetail.avatar_location == "" ? null : this.baseUrl + this.userDetail.avatar_location
      })
  }

  onBackButtonClick() {
    this.closeUserDetail.emit()
  }

  getUserTimeLog(_id) {
    this.userTimeLogLoader = true
    this._settingGQL.getCLockOfUserById(_id, '', '', localStorage.getItem('location_id'))
      .subscribe(
        (res) => {
          this.userTimeLogLoader = false
          this.userTimeLog = res['data'].getUserClockInOutHistoryLogs
        }, (err) => {
          this.userTimeLogLoader = false
        }
      )
  }

  getUsersLogsActivity() {
    this.getLogLoader = true
    this._settingGQL.getUsersLogsActivity(this.searchUserActivityLog)
      .subscribe(
        (res) => {
          console.log(' res', res['data']);
          this.logActivityData = JSON.parse(JSON.stringify(res['data'].getUsersLogsActivity))
          this.getLogLoader = false
        }, (err) => {
          console.log(' err', err);
          this.getLogLoader = false

        }
      )
  }

  updateFiltersLOG(value) {
    this.searchUserActivityLog.type = value
    this.getUsersLogsActivity()
  }

  updatePinSahreMethod(type) {
    this.pinShareType = type
  }

  bsModalRef: BsModalRef;
  model_ProfilePhoto(imageURL) {
    const initialState = { imageURL: imageURL };
    this.bsModalRef = this.modalService.show(ProfilePhotoUploaderComponent, { class: 'modal-sm box-saveorder profilepopUp wd600', initialState });

    this.bsModalRef.content.event.subscribe(res => {
      if (res.data && res.data.isSave)
        this.saveUserPhoto(res.data.imageURL)
      else if (res.data && !res.data.isSave)
        this.deleteUserPhoto(this.userId)
    });
  }

  saveUserPhotoLoader = false
  saveUserPhoto(image) {
    this.userlogo = ''
    this.userlogo = image.getAsDataURL()
    //this.userlogo = this.img.getAsDataURL()
    this._settingGQL.userLogoUpload(this.userId, image.getAsDataURL())
      .subscribe(
        (res) => {
          this.userDetail.avatar_location = res.data.userLogoUpload
          this.saveUserPhotoLoader = false
        }, (err) => {
          this.saveUserPhotoLoader = false
        }
      )
  }

  userLogoDeleteLoader = false
  deleteUserPhoto(id) {
    this.userLogoDeleteLoader = true
    this._settingGQL.userLogoDelete(this.userId)
      .subscribe(
        (res) => {
          this.userLogoDeleteLoader = false
          this.userlogo = null
        }, (err) => {
          this.userLogoDeleteLoader = false
          console.log('userLogoDelete err', err);
        }
      )
  }

  userStatusUpdate(event) {
    let status;
    const isChecked = event.target.checked;
    if (isChecked === true) {
      status = BusinessStatus.Active;
    } else {
      status = BusinessStatus.Inactive;
    }
    this._settingGQL.statusBusinessUser(this.userId, status)
      .subscribe(
        (res) => {
          const result = JSON.parse(JSON.stringify(res['data'].statusBusinessUser));
        }, (err) => {
          console.log('Error ', err);
        }
      );
  }

  newPinCode = ''
  pinShareType = 'sms'
  userPincodeLoader = false
  userPincode2Loader = false
  codeGenerate = false
  genrateBtnDisable = false
  resendPinCode = false
  checkResend = false
  updatePinCode = false

  generatePinCodeFun() {
    this.userPincodeLoader = true
    this.resendPinCode = false
    this.checkResend = false
    this._settingGQL.generatePinCode()
      .subscribe(
        (res) => {
          this.codeGenerate = true
          this.userPincodeLoader = false
          this.newPinCode = String(res['data'].generatePinCode)
        }, (err) => {
          console.log('generatePinCode err', err);
        }
      )
  }

  sharePinCode = false
  openSharePinCode() {
    this.newPinCode = ''
    this.sharePinCode = true
    this.generatePinCodeFun()
  }

  togglePassword(event, span) {
    if (event.type === 'password') {
      event.type = 'text';
      span.target.className = 'fa fa-fw fa-eye-slash field-icon';
    } else {
      event.type = 'password';
      span.target.className = 'fa fa-fw fa-eye field-icon';
    }
  }

  getUserPinCodeById() {
    this._settingGQL.getUserPinCodeById(this.userId)
      .subscribe(
        (res) => {
          this.newPinCode = String(res.data.getUserPinCodeById.pincode)
          this.sharePinCode = false
          this.updatePinCode = false
          this.generatePinCode = false
          this.resendPinCode = true
        }, (err) => {
          let message = err.graphQLErrors[0].message
          console.log('getUserPinCodeById err', message);
        }
      )
  }

  regenerateCode() {
    this.updatePinCode = true
    this.sharePinCode = true
    this.resendPinCode = false
    this.generatePinCodeFun()
  }

  reSendPin() {
    this.sharePinCode = true
    this.checkResend = true
  }

  confirmPassword4Pin = ''
  confirmPassword4PinLoader = false
  confirmPassword4PinError = false
  confirmPassword4PinEmpty = false
  pinShareCheck = false
  pinLessThenSix = false
  uniquePinCodeVerifyFun(template: TemplateRef<any>, cls) {
    if (this.newPinCode.length < 6) {
      this.pinLessThenSix = true
      return
    }
    this.pinLessThenSix = false
    this.userPincode2Loader = true
    this._settingGQL.uniquePinCodeVerify(Number(this.newPinCode))
      .subscribe(
        (res) => {
          console.log('uniquePinCodeVerify res', res['data'].uniquePinCodeVerify);
          let returnVal = res['data'].uniquePinCodeVerify
          this.userPincode2Loader = false
          if (returnVal == true) {
            this.pinShareCheck = true
          } else {
            this.pinShareCheck = false
            this.confirmPassword4Pin = ''
            this.confirmPassword4PinLoader = false
            this.confirmPassword4PinError = false
            this.confirmPassword4PinEmpty = false
            this.bsModalRef = this.modalService.show(template, { class: 'modal-sm ' + cls, backdrop: 'static', keyboard: false });
          }
        }, (err) => {
          this.userPincode2Loader = false
          let message = err.graphQLErrors[0].message
          if (message = 'pin code already exist please choose alternate') {
            this.pinShareCheck = true
          }
          console.log('uniquePinCodeVerify err', message);
        }
      )
  }

  endTheError() {
    this.pinShareCheck = false
    this.pinLessThenSix = false
  }


  confirmPassword4PinErrorReset() {
    this.confirmPassword4PinError = false
    this.confirmPassword4PinEmpty = false
  }

  closeSharePinCode() {
    if (this.confirmPassword4Pin == '') {
      this.confirmPassword4PinEmpty = true
      return
    }
    if (this.checkResend == true) {
      this.userPinCodeResendFun()
    } else {
      this.createAndSharePinCodeFun()
    }
    this.sharePinCode = false
    this.generatePinCode = false
    this.resendPinCode = true
    this.updatePinCode = false
  }

  userPinCodeResendFun() {
    this.confirmPassword4PinLoader = true
    this._settingGQL.userPinCodeResend(this.userId, this.pinShareType == 'email' ? AllowMethodPhoneVerify.Email : AllowMethodPhoneVerify.Sms, this.confirmPassword4Pin)
      .subscribe(
        (res) => {
          this.pinShareCheck = false
          this.codeGenerate = true
          this.confirmPassword4PinLoader = false
          this.sharePinCode = false
          this.generatePinCode = false
          this.resendPinCode = true
          this.updatePinCode = false
          this.checkResend = false
          this.modalService.hide(1);
          console.log('generatePinCode res', res['data'].userPinCodeResend);
          this.newPinCode = String(res['data'].userPinCodeResend)
          //this.toaster.showSuccess('Your Pin Code Shared Successfully', '')
        }, (err) => {
          this.confirmPassword4PinLoader = false
          let code = err.graphQLErrors[0].code
          if (code == 'incorrect_password') {
            this.confirmPassword4PinError = true
          }
          //this.toaster.showError('Something Went Wrong.', 'Try Again')
          //let message=err.graphQLErrors[0].message
          console.log('generatePinCode err', err);
        }
      )
  }

  createAndSharePinCodeFun() {
    this.confirmPassword4PinLoader = true
    let input = {
      userID: this.userId,
      pincode: parseFloat(this.newPinCode),
      method: this.pinShareType == 'email' ? AllowMethodPhoneVerify.Email : AllowMethodPhoneVerify.Sms,
      businessLocation: window.localStorage.getItem('location_id'),
      password: this.confirmPassword4Pin
    }
    this._settingGQL.createPinCode(input)
      .subscribe(
        (res) => {
          this.pinShareCheck = false
          this.codeGenerate = false
          this.confirmPassword4PinLoader = false
          console.log(' res', res['data'].createPinCode);
          let returnVal = res['data'].createPinCode
          if (returnVal) {
            this.modalService.hide(1);
            //this.toaster.showSuccess('Your Pin Code Shared Successfully', '')
          }
        }, (err) => {
          this.confirmPassword4PinLoader = false
          let message = err.graphQLErrors[0].message
          let code = err.graphQLErrors[0].code
          if (code == 'incorrect_password') {
            this.confirmPassword4PinError = true
          }
          console.log(' err', message);
        }
      )
  }

  onEditUer(_id) {
    const initialState = { userId: _id };
    this.bsModalRef = this.modalService.show(AddEditUserComponent, { class: 'modal-sm box-add-supplier', backdrop: 'static', keyboard: false, initialState })
    this.bsModalRef.content.event.subscribe(res => {
      this.getUserById(this.userId)
    });
  }

  onSetUserPermission() {
    this.router.navigateByUrl('/Permissions', { state: { user: this.userDetail } })
  }

}
