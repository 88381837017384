import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//utilities
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { ModalModule, TabsModule, AccordionModule, TooltipModule } from 'ngx-bootstrap';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule } from '../../shared/shared.module';
import { UtilitiesModule } from 'src/app/utilties/utilities.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

//components
import { DiscountsRoutingModule } from './discounts-routing.module';
import { MainComponent } from './main/main.component';
import { DiscountListingComponent } from './main/discounts/discount-listing/discount-listing.component';
import { AddDiscountComponent } from './main/discounts/add-discount/add-discount.component';


@NgModule({
  declarations: [MainComponent, DiscountListingComponent, AddDiscountComponent],
  imports: [
    CommonModule,
    DiscountsRoutingModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    CarouselModule.forRoot(),
    SharedModule,
    InfiniteScrollModule,
    UtilitiesModule,
    ProgressbarModule
  ],
  providers:[

  ],
  exports:[
    DiscountListingComponent, 
    AddDiscountComponent
  ]
})
export class DiscountsModule { }
