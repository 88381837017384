import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component'
import { DiscountListingComponent } from './main/discounts/discount-listing/discount-listing.component'
import { AddDiscountComponent } from './main/discounts/add-discount/add-discount.component'
import { SYSTEM_FEATURE_NAME, DISCOUNT_LISTING_ACTION_NAME, MODULE_PERMISSIONS_NAME } from "src/app/services/authentication/permissions-name"
import { PermissionGuardService } from 'src/app/guard/permission-guard.service';


const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivateChild: [PermissionGuardService],
    children: [
      {
        path: '',
        component: DiscountListingComponent,
        data: {
          menu: MODULE_PERMISSIONS_NAME.system_menu,
          feature: SYSTEM_FEATURE_NAME.discount_listing_tab,
        }
      },
      {
        path: 'Add',
        component: AddDiscountComponent,
        data: {
          menu: MODULE_PERMISSIONS_NAME.system_menu,
          feature: SYSTEM_FEATURE_NAME.discount_listing_tab,
          action: DISCOUNT_LISTING_ACTION_NAME.create_discount_action
        }
      },
      {
        path: 'Update/:_id',
        component: AddDiscountComponent,
        data: {
          menu: MODULE_PERMISSIONS_NAME.system_menu,
          feature: SYSTEM_FEATURE_NAME.discount_listing_tab,
          action: DISCOUNT_LISTING_ACTION_NAME.edit_disount_action
        }
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DiscountsRoutingModule { }
