import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register-old/register.component';
import { AccountComponent } from './account.Component';
import { HomeComponent } from './home/home.component';
import { AnonymousGuardService } from 'src/app/guard/anonymousguard';
import { AuthGuardService } from 'src/app/guard/auth-guard.service';
import { RegistrationComponent } from "./registration/registration.component"
import {StoreConfigurationComponent} from "./store-configuration/store-configuration.component"

const routes: Routes = [
  {
    path: 'Home', component: HomeComponent,
    canActivate: [AnonymousGuardService]
  },
  {
    path: 'Index', component: AccountComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: 'Login', component: LoginComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'cash-register', component: LoginComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'select-store', component: LoginComponent,
    canActivate: [AuthGuardService]
  },
 
  {
    path: 'Register', component: RegisterComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'Register-old', component: RegistrationComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'Store-Configuration',
    //component: StoreConfigurationComponent
    loadChildren: () => import('../store-configurations/store-configurations.module').then(m => m.StoreConfigurationsModule),
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }