import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { TicketsGQLService } from "src/app/services/order and invoice/ticketsGQLService";
import { EnvironmentUrl } from "src/environments/environment-url";
import { CustomerGQLService } from "src/app/services/customer/customerGQL.service";
import { RepairRoomService } from "src/app/services/repair-room/repair-room.service";
import { PrintService } from "src/app/print/print.service";
import { RepairRoomGQLService } from "src/app/services/repair-room/repair-room-gql.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ToasterService } from "src/app/services/toaster/toaster.service";
import { Orders } from "src/app/modules/Globals/order";
import {
  GetOrdersAndSearchGQL,
  InputTicketsFilter
} from "src/app/generated/graphql";
import { FormControl } from "@angular/forms";
import { DownloadFileComponent } from "../../shared-templates/download-file/download-file.component";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import {
  TICKETS_FEATURE_NAME,
  TICKETS_LISTING_ACTION_NAME
} from "src/app/services/authentication/permissions-name";
import * as dummydata from "src/app/modules/shared/data-role-permission.json";
import { CommonService } from "src/app/services/common/common.service";
import { RefundByOrigianlPaymentMethodComponent } from "../refund-by-origianl-payment-method/refund-by-origianl-payment-method.component";
@Component({
  selector: "app-ticket-listing",
  templateUrl: "./ticket-listing.component.html",
  styleUrls: ["./ticket-listing.component.css"]
})
export class TicketListingComponent implements OnInit {
  modalRef: BsModalRef;
  listOfAllOrders = [];
  searchText = "";
  searchOrderData = [];
  limit: number = 100;
  skip: number = 0;
  searchOrder: FormControl = new FormControl();
  loadOrderInvoice = false;
  isCustomerSpecific = false;
  customerId = "";
  orderId = "";
  existingOrders = true;
  viewInvoice = false;
  statusFilter = "Repair Status";
  ticketFilters: InputTicketsFilter = null;
  repairStatuses = [];
  documentPath = EnvironmentUrl.Images;
  selectedTicket = {};
  selectTransactionPayment = {};
  TICKETS_LISTING_ACTION_NAME = TICKETS_LISTING_ACTION_NAME;
  TICKETS_FEATURE_NAME = TICKETS_FEATURE_NAME;

  mouseTrapKey = {
    search: "ctrl+s",
    clearSearch: "ctrl+c",
    active: "ctrl+shift+a",
    completed: "ctrl+shift+c"
  };

  @ViewChild("downloadFileModal", { static: true })
  downloadFileModal: DownloadFileComponent;

  constructor(
    private modalService: BsModalService,
    private toaster: ToasterService,
    private order: Orders,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private getOrdersAndSearchGQL: GetOrdersAndSearchGQL,
    private ticketsGQLService: TicketsGQLService,
    private customerGQLService: CustomerGQLService,
    private repairRoomService: RepairRoomService,
    private printService: PrintService,
    private repairRoomGQLService: RepairRoomGQLService,
    private _repairRoom: RepairRoomService,
    private _authenticate: AuthenticationService,
    private commonService: CommonService
  ) {}

  isRolePermissionModule = false;
  ngOnInit() {
    if (this.router.url.includes("Permissions"))
      this.isRolePermissionModule = true;
    this.getRepairStatus();
    this.ticketsGQLService.inputTicketsFilter.location_id = localStorage.getItem(
      "location_id"
    );
    this.ticketFilters = this.ticketsGQLService.getFilters();
    this.searchOrder.setValue(this.ticketFilters["search"]);

    if (!this.isRolePermissionModule) {
      this.getAllTickets(false);
    } else {
      this.allTickets = dummydata.ticketList;
    }
    this.searchOrder.valueChanges.subscribe(val => {
      this.updateFilters("search", this.searchOrder.value);
    });
  }

  allTickets = [];
  loadTickets = false;
  toDate = null;
  fromDate = null;
  ticketCount = 0;
  getAllTickets(scroll) {
    this.loadTickets = true;
    if (!scroll) {
      this.ticketsGQLService.updateFilter("limit", 100);
      this.ticketFilters = this.ticketsGQLService.updateFilter("skip", 0);
    }
    this.ticketsGQLService
      .getAllTickets(JSON.parse(JSON.stringify(this.ticketFilters)))
      .valueChanges.subscribe(
        res => {
          this.loadTickets = false;
          if (res["data"].getTicketswithfilter == null) {
            return;
          }
          this.ticketCount = res["data"].getTicketswithfilter.count;
          if (scroll) {
            this.allTickets = this.allTickets.concat(
              res["data"].getTicketswithfilter.transaction
            );
            console.log("tickets are", this.allTickets);
            return;
          }
          this.allTickets = res["data"].getTicketswithfilter.transaction;
          console.log("tickets are", this.allTickets);
        },
        err => {
          this.loadTickets = false;
          console.log("err while loading tickets", err);
        }
      );
  }

  recentCheckedOutTransactionID = null;
  applyFlashingClass(className, ticket) {
    if(!ticket.invoice_for_repair_room) return false
    if (className == "green") {
      if (this.ticketFilters.is_checkout) {
        return this.recentCheckedOutTransactionID != null
          ? this.recentCheckedOutTransactionID == ticket._id
            ? true
            : false
          : false;
      }
      if (
        (!this.ticketFilters.is_checkout &&
          ticket.remaining_amount == 0 &&
          ticket.is_repair_done &&
          !ticket.is_checkout) ||
        (ticket.is_partial_repair_done && !this.ticketFilters.is_checkout)
      ) {
        return true;
      }
      return false;
    } else if (className == "red") {
      if (
        ticket.is_checkout &&
        ticket.remaining_amount != 0 &&
        this.ticketFilters.is_checkout
      ) {
        return true;
      }
      return false;
    } else {
      if (
        (!this.ticketFilters.is_checkout &&
          ticket.remaining_amount != 0 &&
          ticket.is_repair_done &&
          !ticket.is_checkout) ||
        (ticket.is_partial_repair_done && !this.ticketFilters.is_checkout)
      ) {
        return true;
      }
      return false;
    }
  }

  isScrolling = false;
  onScroll() {
    console.log("scroll");
    this.ticketFilters = this.ticketsGQLService.updateFilter(
      "skip",
      this.ticketFilters["skip"] + 100
    );
    // this.ticketFilters = this.ticketsGQLService.updateFilter('limit', this.ticketFilters['limit'] + 100)
    this.getAllTickets(true);
  }

  gotoRepairRoom(id) {
    this.router.navigate(["Pointofsale", "RepairRoom", "detail", id]);
  }

  showInvoiceDetail(ticket) {
    console.log("ticket is", ticket);
    this.router.navigate(["/Pointofsale/Tickets/Detail/" + ticket._id]);
  }

  showOrderInCart(ticket) {
    this.router.navigateByUrl(
      `Pointofsale/CreateSale/Order/(panel:${ticket._id})`
    );
  }

  goToCheckOut(ticket) {
    this.router.navigateByUrl(
      `/Pointofsale/CreateSale/Checkout/${ticket._id})`
    );
  }

  getRepairStatus() {
    this.ticketsGQLService.getReparStatus().subscribe(
      res => {
        console.log("rep status are", res["data"].repairRoomStatus);
        this.repairStatuses = res["data"].repairRoomStatus;
        this.repairStatuses.forEach(element => {
          switch (element.status_name) {
            case "Working on it":
              element.class_name = "workingCheck";
              element.class_name_detail = "workingOnitBox";
              break;
            case "Stuck":
              element.class_name = "stuckCheck";
              element.class_name_detail = "stuckBox";
              break;
            case "Done":
              element.class_name = "doneCheck";
              element.class_name_detail = "doneBox";
              break;
            case "Not started":
              element.class_name = "notStartedCheck";
              element.class_name_detail = "notStartedBox";
              break;
            case "Reopen":
              element.class_name = "reOpenCheck";
              element.class_name_detail = "reOpenBox";
              break;
          }
        });
      },
      err => {
        console.log("rep status err", err);
      }
    );
  }

  repairStatusFilters = [];
  setRepairFilters(event, status) {
    if (event.target.checked) {
      this.repairStatusFilters.push(status.status_background_color);
    } else {
      for (let i = 0; i < this.repairStatusFilters.length; i++) {
        if (this.repairStatusFilters[i] == status.status_background_color) {
          this.repairStatusFilters.splice(i, 1);
          return;
        }
      }
    }
  }

  applyRepairFilters() {
    this.ticketFilters = this.ticketsGQLService.updateFilter(
      "repairStatus",
      this.repairStatusFilters
    );
    this.getAllTickets(false);
  }

  theCheckbox = false;
  checkedAllField(list, event) {
    for (let i = 0; i < list.length; i++) {
      list[i]["checked"] = event.target.checked;
    }
  }

  singleItemChecked(list, index, event) {
    this.theCheckbox = true;
    list[index]["checked"] = event.target.checked;
    for (let i = 0; i < list.length; i++) {
      if (!list[i]["checked"]) {
        this.theCheckbox = false;
      }
    }
  }

  returnSelectedRepairFilters(status) {
    let found = this.repairStatusFilters.find(
      element => element == status.status_background_color
    );
    return found == undefined ? false : true;
  }

  isTicketChecked() {
    const ticket = this.allTickets.filter(tic => tic.checked);
    if (ticket.length > 0) {
      return true;
    }
    return false;
  }

  showDeleteQuote() {
    const ticket = this.allTickets.filter(tic => tic.checked);
    for (let index = 0; index < ticket.length; index++) {
      if (ticket[index].transaction_status != "quote") {
        return false;
      }
    }
    return ticket.length == 0 ? false : true;
  }

  deleteQuotes() {
    let orderIDs = [];
    this.allTickets.forEach(element => {
      if (element["checked"]) {
        orderIDs.push(element["_id"]);
      }
    });
    this.ticketsGQLService.deleteQuotes(orderIDs).subscribe(
      res => {
        if (res["data"].deleteSaleQuote) {
          this.showToasterMessage("Quotes deleted successfully", "success");
          this.getAllTickets(false);
          return;
        }
        this.showToasterMessage("Something went wrong", "error");
      },
      err => {
        this.showToasterMessage(err.graphQLErrors[0].message, "error");
      }
    );
  }

  getCheckedTicketById(id) {
    const ticket = this.allTickets.filter(tic => tic._id == id && tic.checked);
    if (ticket.length > 0) {
      return true;
    }
    return false;
  }

  updateFilters(type, val) {
    this.ticketFilters = this.ticketsGQLService.updateFilter(type, val);
    this.getAllTickets(false);
  }

  sortBalance(filter) {
    if (this.ticketFilters["balance_sorting"] == filter) {
      this.ticketFilters = this.ticketsGQLService.updateFilter(
        "balance_sorting",
        "none"
      );
      this.getAllTickets(false);
      return;
    }
    this.ticketFilters = this.ticketsGQLService.updateFilter(
      "balance_sorting",
      filter
    );
    this.getAllTickets(false);
  }

  applyDateRange() {
    this.ticketsGQLService.updateFilter("start_date", this.fromDate);
    this.ticketFilters = this.ticketsGQLService.updateFilter(
      "end_date",
      this.toDate
    );
    this.ticketFilters = this.ticketsGQLService.updateFilter(
      "dateFilter",
      "dateRange"
    );
    this.modalRef.hide();
    this.getAllTickets(false);
  }

  clearAllFilters() {
    this.ticketFilters = this.ticketsGQLService.clearAllFilters();
    this.searchOrder.setValue(this.ticketFilters["search"]);
    this.repairStatusFilters = [];
    this.ticketFilters = this.ticketsGQLService.updateFilter(
      "repairStatus",
      []
    );
    this.fromDate = null;
    this.toDate = null;
    this.getAllTickets(false);
  }

  downloadInvoices(type) {
    let orderIDs = [];
    this.allTickets.forEach(element => {
      if (element["checked"]) {
        orderIDs.push(element["_id"]);
      }
    });
    let obj = {
      orderIDs: orderIDs,
      fileType: type == "csv" ? "csv" : "pdf",
      is_detail: true
    };
    if (type == "csv") {
      if (orderIDs.length > 1) {
        this.printService.downloadZip("api/order/download/", obj, "tickets");
        this.modalRef.hide();
        return;
      }
      this.printService.downloadCSV("api/order/download/", obj, "tickets");
    } else {
      if (orderIDs.length > 1) {
        this.printService.downloadZip("api/order/download/", obj, "tickets");
        this.modalRef.hide();
        return;
      }
      this.printService.downloadPDF("api/order/download/", obj, "tickets");
    }
    this.modalRef.hide();
  }

  checkoutDevices = [];
  getCheckoutDevices(id, template: TemplateRef<any>, cls) {
    this.checkoutDevices = [];
    this.ticketsGQLService.getDevicesForCheckout(id).subscribe(
      res => {
        if (res["data"].repairRoomInvoiceCheckOutDetail == null) {
          this.showToasterMessage("Device under reapir.", "warning");
          return;
        }
        this.modalRef = this.modalService.show(template, {
          class: "modal-sm " + cls,
          backdrop: "static",
          keyboard: false
        });
        this.checkoutDevices =
          res["data"].repairRoomInvoiceCheckOutDetail.Devices;
      },
      err => {
        console.log("error while loading checkout devices", err);
      }
    );
  }

  devicesForCheckout = [];
  selectDeviceForCheckout(event, dev) {
    if (event.target.checked) {
      this.devicesForCheckout.push(dev["device"]["_id"]);
      return;
    }
    const index = this.devicesForCheckout.findIndex(x => x == dev["_id"]);
    if (index == -1) {
      this.devicesForCheckout.splice(index, 1);
    }
  }

  isDevSelectedForCheckout(dev) {
    const index = this.devicesForCheckout.findIndex(
      x => x == dev["device"]["_id"]
    );
    return index == -1 ? false : true;
  }

  checkoutDevice() {
    if(this.devicesForCheckout.length){
      this.ticketsGQLService
      .checkoutDevices(this.transactionToCheckout, this.devicesForCheckout)
      .subscribe(
        res => {
          if (res["data"].checkOutDevice) {
            this.closeModel();
            this.getAllTickets(false);
            this.showToaster = false;
            this.toasterMsg =
              this.devicesForCheckout.length > 1
                ? "Devices checkout successfully"
                : "Device checkout successfully";
            this.toasterType = "success";
            this.recentCheckedOutTransactionID = this.transactionToCheckout;
            this.devicesForCheckout = [];
            this.checkoutDevices = [];
          }
        },
        err => {
          this.closeModel();
          this.checkoutDevices = [];
          this.devicesForCheckout = [];
          this.showToaster = false;
          this.toasterType = "error";
          this.toasterMsg = err.message;
          console.log("err while sending email", err);
        }
      );
    }else{
      this.showToaster = false;
      this.toasterType = "error";
      this.toasterMsg = "Select device to checkout";
    }
  }

  tasksReopen = [];
  tasksReopenTransaction;
  getTaskDetail(id) {
    this.tasksReopen = [];
    this.loadTickets = true
    this.ticketsGQLService
      .getRepairRoomTaskDetail(localStorage.getItem("location_id"), id)
      .subscribe(
        res => {
          this.loadTickets = false
          if (res["data"].repairRoomReOpenInvoiceDetail == null) {
            this.showToasterMessage("Device is under repair.", "warning");
            return;
          }
          this.tasksReopenTransaction =
            res.data.repairRoomReOpenInvoiceDetail.Transaction;
          this.tasksReopen = res["data"].repairRoomReOpenInvoiceDetail.Devices;
          this.modalRef = this.modalService.show(this.template, {
            class: "modal-sm " + this.cls,
            backdrop: "static",
            keyboard: false
          });
        },
        err => {
          this.loadTickets = false
          console.log("err while loading tasks", err);
        }
      );
  }

  openDeviceAllTasks(event, dev) {
    let service = [];
    dev["deviceItems"].forEach(element => {
      service.push({ _id: element._id });
    });
    const index = this.servicesToReOpen.findIndex(x => x.device == dev["_id"]);
    if (event.target.checked) {
      if (index == -1) {
        this.servicesToReOpen.push({
          deviceID: dev.device["_id"],
          services: service
        });
        console.log("services are", this.servicesToReOpen);
        return;
      }
      this.servicesToReOpen[index]["services"] = service;
      console.log("services are", this.servicesToReOpen);
      return;
    }
    this.servicesToReOpen.splice(index, 1);
    console.log("services are", this.servicesToReOpen);
  }

  taskDeviceReOpen() {
    if(this.servicesToReOpen.length == 0){
      return
    }
    
    this.ticketsGQLService
      .taskDeviceReOpen(
        this.tasksReopenTransaction["_id"],
        localStorage.getItem("location_id"),
        this.servicesToReOpen
      )
      .subscribe(
        res => {
          this.closeModel(); 
          this.showToasterMessage("Ticket repoened", "success");
          this.servicesToReOpen = [];
          this.getAllTickets(false);
        },
        err => {
          this.showToasterMessage(err.message, "error");
          this.servicesToReOpen = [];
        }
      );
  }

  checkOutTicket(
    transactionID,
    location_id = localStorage.getItem("location_id")
  ) {
    this.ticketsGQLService.checkOutTicket(transactionID, location_id).subscribe(
      res => {
        this.showToasterMessage("Ticket check out", "success");
        this.getAllTickets(false);
      },
      err => {
        this.showToasterMessage(err.message, "error");
      }
    );
  }

  showToast = false;
  showToasterMessage(message, type) {
    this.showToast = true;
    this.toasterMsg = message;
    this.toasterType = type;
    this.showToaster = false;
  }

  showToaster = true;
  toasterMsg = "something went wrong";
  toasterType = "error";
  closeToaster() {
    this.showToast = false;
    this.showToaster = true;
  }

  openDeviceTask(event, dev, id) {
    let service = [];
    const index = this.servicesToReOpen.findIndex(x => x.device == dev);
    if (event.target.checked) {
      if (index == -1) {
        service.push({ _id: id });
        this.servicesToReOpen.push({
          deviceID: dev,
          services: service
        });
        console.log("services are", this.servicesToReOpen);
        return;
      }
      this.servicesToReOpen[index]["services"].push({ _id: id });
      console.log("services are", this.servicesToReOpen);
      return;
    }
    this.servicesToReOpen.splice(index, 1);
    console.log("services are", this.servicesToReOpen);
  }

  servicesToReOpen = [];
  reOpenService(dev, id) {
    this.servicesToReOpen.push(id);
  }

  checkServiceInList(dev, id) {
    const index = this.servicesToReOpen.findIndex(x => x.deviceID == dev);
    if (index != -1) {
      const serviceIndex = this.servicesToReOpen[index]["services"].findIndex(
        s => s._id == id
      );
      return serviceIndex == -1 ? false : true;
    }
  }

  transactionToCheckout = null;
  openCheckoutDeviceModal(template: TemplateRef<any>, cls, ticket) {
    this.transactionToCheckout = ticket["_id"];
    this.getCheckoutDevices(ticket["_id"], template, cls);
  }

  template: any = null;
  cls: any = null;
  openTicketReOpenModal(template: TemplateRef<any>, cls, id) {
    this.getTaskDetail(id);
    this.template = template;
    this.cls = cls;
  }

  openModal(template: TemplateRef<any>, cls) {
    this.modalRef = this.modalService.show(template, {
      class: "modal-sm " + cls,
      backdrop: "static",
      keyboard: false
    });
  }

  closeModel() {
    this.modalService.hide(1);
  }

  noOpenAccordianOnInput(e) {
    e.stopPropagation();
  }

  isObjectEmpty(Obj) {
    for (var key in Obj) {
      if (Obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  cleanObject(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  isActionAllowed(actionName) {
    return true; //this._authenticate.isActionAllowed(TICKETS_FEATURE_NAME.tickets_listing_tab, actionName)
  }

  refundAmountToCustomer(ticket){
    this.loadTickets = true
    this.ticketsGQLService.getTicketDetailByID(ticket._id)
    .valueChanges.subscribe((res)=>{
      this.loadTickets = false
      this.refundByOrigianlPaymentMethod(res.data.getTicketDetailById)
    })
  }

  refundByOrigianlPaymentMethod(ticket) {

    let refundInformation = {
      transactionID: ticket["_id"],
      reason: 'Service change by technician',
      note: 'Refund amount due to service change by technician',
      amount: Number(ticket.refund_amount_to_customer),
      location_id: localStorage.getItem("location_id"),
      refund_amount_to_customer:true
    };
    const initialState = {
      id: ticket._id,
      isRefundByAmount: true,
      refundInformation: refundInformation,
      transcation: ticket
    };
   
    this.modalRef = this.modalService.show(
      RefundByOrigianlPaymentMethodComponent,
      {
        class: "modal-sm custModal wd600",
        backdrop: "static",
        keyboard: false,
        initialState
      }
    );
    this.modalRef.content.event.subscribe(res => {
      this.getAllTickets(false);
      //this.getRefundItems();
    });
  }
}
