import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentUrl } from 'src/environments/environment-url';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { DefaultOptions } from 'apollo-client';


@NgModule({
  exports: [HttpClientModule, ApolloModule, HttpLinkModule]
})

export class GraphQLConfigModule {
  constructor(apollo: Apollo, private httpClient: HttpClient) {

    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }

    let token = localStorage.getItem('token');

    interface Definintion {
      kind: string;
      operation?: string;
    };

    const httpLink = new HttpLink(httpClient).create({
      uri: EnvironmentUrl.getAPIUrl
    });

    const authLink = new ApolloLink((operation, forward) => {
      let token = localStorage.getItem('token');
      // Use the setContext method to set the HTTP headers.
      operation.setContext({
        headers: {
          'x-token': token ? `${token}` : ''
        }
      });
      // Call the next link in the middleware chain.
      return forward(operation);
    });

    const subscriptionLink = new WebSocketLink({
      uri: EnvironmentUrl.getSubscriptionSocketURL,
      options: {
        lazy: true,
        reconnect: true,
        connectionParams: async () => {
          const token = await localStorage.getItem('token');
          return {
            headers: {
              Authorization: token ? `x-token ${token}` : "",
            },
          }
        },
      },
    });

    const link = split(
      ({ query }) => {
        const { kind, operation }: Definintion = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      subscriptionLink,
      authLink.concat(httpLink)
    );

    apollo.create({
      link,
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions
    });
  }
}