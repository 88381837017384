import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Is_Active, SearchStatus } from 'src/app/generated/graphql';
import { DiscountGQLService } from 'src/app/services/discounts/discount-gql.service'
import { SYSTEM_FEATURE_NAME, DISCOUNT_LISTING_ACTION_NAME } from "src/app/services/authentication/permissions-name"
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import * as dummydata from "src/app/modules/shared/data-role-permission.json"
import { Router } from '@angular/router';
@Component({
  selector: 'app-discount-listing',
  templateUrl: './discount-listing.component.html',
  styleUrls: ['./discount-listing.component.css']
})
export class DiscountListingComponent implements OnInit, AfterViewInit {

  campaignList = []
  discountLoader = false
  discountSearch: FormControl = new FormControl();
  SYSTEM_FEATURE_NAME = SYSTEM_FEATURE_NAME
  DISCOUNT_LISTING_ACTION_NAME = DISCOUNT_LISTING_ACTION_NAME

  searchDiscountInput = {
    limit: 1000,
    skip: 0,
    BusinessLocation: localStorage.getItem('location_id'),
    status: SearchStatus.All,
    search: this.discountSearch.value || '',
  }
  isRolePermissionModule = false

  constructor(private _discountGQL: DiscountGQLService,
    private _authenticate: AuthenticationService,
    private router: Router,
    private elRef: ElementRef) { }

  ngAfterViewInit(): void {
    var onoffswitch = this.elRef.nativeElement.getElementsByClassName('onoffswitch-checkbox')
    for (var i = 0; i < onoffswitch.length; i++) {
      onoffswitch[i].disabled = this.router.url.includes("Permissions") ? true : false
    }
  }

  ngOnInit() {
    if (this.router.url.includes("Permissions")) {
      this.isRolePermissionModule = true
      this.campaignList = dummydata.campaignList
    } else {
      this.getAllDiscounts()
      //this._authenticate.getLoggedInUserModulePermissions('system_menu')
    }
    this.discountSearch.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(term => {
      this.searchDiscountInput.search = this.discountSearch.value || ''
      this.getAllDiscounts()
    });
  }

  isActionAllowed(actionName) {
    return this._authenticate.isActionAllowed(SYSTEM_FEATURE_NAME.discount_listing_tab, actionName)
  }

  getAllDiscounts() {
    this.discountLoader = true
    this._discountGQL.getDiscountsAndSearch(this.searchDiscountInput)
      .subscribe(
        (res) => {
          console.log('all discount', res['data']);
          this.discountLoader = false
          this.campaignList = res['data'].getDiscountsAndSearch
          console.log('campaignList', this.campaignList)
        }, (err) => {
          console.log('error while loading dis', err);
          this.discountLoader = false
        }
      )
  }

  setDefaultSearchDiscountInput() {
    this.searchDiscountInput = {
      limit: 1000,
      skip: 0,
      BusinessLocation: localStorage.getItem('location_id'),
      status: SearchStatus.All,
      search: null,
    }
  }

  dicountStatusUpdate(campaign, discount, isActive) {
    this._discountGQL.discountStatusChanged(campaign._id, discount._id, isActive)
      .subscribe((res) => {
        discount.is_active = isActive ? Is_Active.Enabled : Is_Active.Disabled
        isActive ? campaign.is_active = true : ''
      })
  }

  campaignStatusChanged(campaign, isActive) {
    //this.discountLoader = true
    this._discountGQL.campaignStatusChanged(campaign._id, isActive)
      .subscribe((res) => {
        campaign.is_active = isActive
        campaign.Discount.forEach(discount => {
          discount['is_active'] = isActive ? Is_Active.Enabled : Is_Active.Disabled
        });
        //this.discountLoader = false
      }, (err) => {
        //this.discountLoader = false
      })
  }

  changeStatusFilter(statusFilter) {
    this.searchDiscountInput.status = statusFilter
    this.getAllDiscounts()
  }

  noOpenAccordianOnInput(e) {
    e.stopPropagation();
  }

  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

}
