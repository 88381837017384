import { Component, OnInit } from '@angular/core';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  personalInfo: FormGroup;
  businessInfo: FormGroup;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.Pakistan, CountryISO.Mexico];
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  fy_end_month = 'Last day of December'
  accounting_method = 'accrual'
  section1 = true
  section2 = false
  section3 = false 
  section4 = false 
  userCreated = false
  
  constructor(private formbulider: FormBuilder,) { }

  ngOnInit() {
    this.initializePersonalInfoForm()
  }

  initializePersonalInfoForm() {
    this.personalInfo = this.formbulider.group({
      first_name: ['', [Validators.required]],
      last_name: [, [Validators.required]],
      email: [, [Validators.required,
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone: [, [Validators.required]],
      password: [, [Validators.required, Validators.minLength(6)]],
      confirm_password: [, [Validators.required]],
    }, 
    // {
    //   validator: MustMatch('password', 'confirm_password')
    // }
    );
  }
  get fPersonalInfoForm() {
    return this.personalInfo.controls;
  }

  initializeBusinessInfoForm() {
    this.businessInfo = this.formbulider.group({
      business_system_name: ['', [Validators.required]],
      unique_code: [''],
      number_of_stores: [, [Validators.required, Validators.minLength(1)]],
      Country: [, [Validators.required]],
      store_type: ['Franchise']
    });
  }

  get fBusinessInfoForm() {
    return this.businessInfo.controls;
  }

  setACCMethod(met) {
    this.accounting_method = met
  }

}
