import { Injectable } from '@angular/core';

import { DropdownQuestion } from './question-dropdown';
import { QuestionBase } from './question-base';
import { TextboxQuestion } from './question-textbox';
import { of } from 'rxjs';

import { QuestionGqlService } from "./question-gql.service"


@Injectable()
export class QuestionService {

  private _formElementValue = [];

  public get formElementValue() {
    return this._formElementValue;
  }

  public set formElementValue(value) {
    this._formElementValue = value;
  }

  constructor(private questionGql: QuestionGqlService) { }

  // TODO: get from a remote source of question metadata
  getQuestions(questionBase) {
    const questions: QuestionBase<string>[] = []
    questionBase.forEach(question => {
      questions.push (new QuestionBase<string>({
        controlType: question.controlType,
        key: question.key,
        label: question.label,
        type: question.type,
        order: question.order,
        options: question.options,
        _id: question._id,
        required: question.required,
      }))
    });

    // const questions: QuestionBase<string>[] = [

    //   new DropdownQuestion({
    //     controlType: "dropdown",
    //     key: 'brave',
    //     label: 'Bravery Rating',
    //     _id: 'brave',
    //     options: [
    //       { key: 'solid', value: 'Solid' },
    //       { key: 'great', value: 'Great' },
    //       { key: 'good', value: 'Good' },
    //       { key: 'unproven', value: 'Unproven' }
    //     ],
    //     order: 3
    //   }),

    //   new TextboxQuestion({
    //     controlType: "textbox",
    //     key: 'firstName',
    //     _id: 'firstName',
    //     label: 'First name',
    //     value: 'Bombasto',
    //     required: true,
    //     order: 1
    //   }),

    //   new QuestionBase<string>({
    //     controlType: "textbox",
    //     _id: 'emailAddress',
    //     key: 'emailAddress',
    //     label: 'Email',
    //     type: 'email',
    //     order: 2
    //   }),

    //   new QuestionBase<string>({
    //     controlType: "checkbox",
    //     key: 'agree',
    //     _id: 'agree',
    //     label: 'I accept terms and services',
    //     type: 'checkbox',
    //     required: true,
    //     options: [
    //       { key: 'agree', value: 'Agree' },
    //       { key: 'disagree', value: 'Disagree' }
    //     ],
    //     order: 4
    //   }),

    //   new QuestionBase<string>({
    //     controlType: "radio",
    //     key: 'sex',
    //     _id: 'sex',
    //     label: 'Sex',
    //     type: 'radio',
    //     options: [
    //       { key: 'male', value: 'Male' },
    //       { key: 'female', value: 'Female' }
    //     ],
    //     order: 5
    //   }),

    //   new QuestionBase<string>({
    //     controlType: "textarea",
    //     key: 'message',
    //     _id: 'message',
    //     label: 'Mesage',
    //     type: 'textarea',
    //     order: 6
    //   })
    // ];

    return of(questions.sort((a, b) => a.order - b.order));
  }


  createUpdateMultiStoreCustomField(customFieldTypeEnum, multiCustomFieldTypeInput, location_id) {
    return this.questionGql.createUpdateMultiStoreCustomField(customFieldTypeEnum, multiCustomFieldTypeInput, location_id)
  }

  getStoreCustomFields(customFieldTypeEnum, location_id) {
    return this.questionGql.getStoreCustomFields(customFieldTypeEnum, location_id)
  }

  deleteStoreCustomField(customFieldId,customFieldType,location_id){
    return this.questionGql.deleteStoreCustomField(customFieldId,customFieldType,location_id)
  }
}