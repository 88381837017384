import { Injectable } from '@angular/core';
import { CustomerImportRecordProcessedGQL, ImportCustomerResultGQL, ImportProductResultGQL, RecordProcessedGQL } from 'src/app/generated/graphql';
import { CSVService } from '../csv.service';
import { GenericService } from '../stock/generic.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  showImportRecordbar = false;
  isFileDownloaded = false;
  recordProcessed = 0
  importProductReturnData: any

  showImportCustomerRecordbar = false
  customerRecordProcessed = 0
  importCustomerReturnData: any

  newlyAddedProduct = [];

  constructor(
    private recordProcessing: RecordProcessedGQL,
    private importProductData: ImportProductResultGQL,
    private customerImportRecordProcessed: CustomerImportRecordProcessedGQL,
    private importCustomersResult: ImportCustomerResultGQL,
    private _CSVService: CSVService,
    private genericService:  GenericService
  ) { }

  importSubscription() {
    this.recordProcessing.subscribe({}).subscribe(
      (res) => {
        if(res['data'].recordProcessed < 100) {
          this.showImportRecordbar = true;
        }
        if (!this.showImportRecordbar) {
          this.importProductResult();
        }
        console.log('records processing ======>>>', res);
        this.recordProcessed = res['data'].recordProcessed
      }, (err) => {
        console.log('err while record processing', err);
        this.showImportRecordbar = false
      }
    )
  }

  importProductResult() {
    this.importProductData.subscribe({}).subscribe(
      (res) => {
        console.log('product import completed',res)
        if (!this.isFileDownloaded) {
          this.genericService.newlyAddedProduct = [];
          this.isFileDownloaded = true;
          console.log('final result ======>>>', res);
          this.importProductReturnData = res['data'].importProductResult
          let productData = res['data'].importProductResult
          this.showImportRecordbar = false
          let data = [];
          for (let index = 0; index < productData.newlyAddedProducts.length; index++) {
            let obj = {}
            obj['Product Name'] = productData.newlyAddedProducts[index].product_name
            obj['SKU'] = productData.newlyAddedProducts[index].sku
            obj['Status'] = 'Added'
            data.push(obj);
            this.genericService.newlyAddedProduct.push(productData.newlyAddedProducts[index].sku);
          }
          for (let index = 0; index < productData.alreadyExistProducts.length; index++) {
            let obj = {}
            obj['Product Name'] = productData.alreadyExistProducts[index].product_name
            obj['SKU'] = productData.alreadyExistProducts[index].description
            obj['Status'] = 'Already exist'
            data.push(obj);
          }
          for (let index = 0; index < productData.productsInvalidData.length; index++) {
            let obj = {}
            obj['Product Name'] = productData.productsInvalidData[index].product_name
            obj['SKU'] = productData.productsInvalidData[index].sku
            obj['Status'] = productData.productsInvalidData[index].description
            data.push(obj);
          }
          this.genericService.refreshProductListing(productData);
          this.genericService.showToaster(['Import product process has bee completed', 'success']);
          this._CSVService.saveAsCSVFile(data, 'Import Product Detail');
        }
      }, (err) => {
        this.isFileDownloaded = true;
        this.genericService.showToaster([err.message, 'error']);
        console.log('err while record processing', err);
        this.showImportRecordbar = false
      }
    )
  }

  importCustomerSubscription() {
    this.customerImportRecordProcessed.subscribe({}).subscribe(
      (res) => {
        if (!this.showImportCustomerRecordbar) {
          this.importCustomerResult();
        }
        console.log('records processing ======>>>', res);
        this.customerRecordProcessed = res['data'].customerImportRecordProcessed
      }, (err) => {
        console.log('err while record processing', err);
        this.showImportCustomerRecordbar = false
      }
    )
  }

  importCustomerResult() {
    this.importCustomersResult.subscribe({}).subscribe(
      (res) => {
        console.log('final result customer import ======>>>', res);
        let customersData = res['data'].importCustomerResult
        this.showImportCustomerRecordbar = false
        let data = []
        for (let index = 0; index < customersData.newlyAddedCustomers.length; index++) {
          let obj = {}
          obj['First Name'] = customersData.newlyAddedCustomers[index].first_name
          obj['Last Name'] = customersData.newlyAddedCustomers[index].last_name
          obj['Email'] = customersData.newlyAddedCustomers[index].email
          obj['Status'] = 'Added'
          data.push(obj);
        }
        for (let index = 0; index < customersData.alreadyExistCustomers.length; index++) {
          let obj = {}
          obj['First Name'] = customersData.alreadyExistCustomers[index].first_name
          obj['Last Name'] = customersData.alreadyExistCustomers[index].last_name
          obj['Email'] = customersData.alreadyExistCustomers[index].email
          obj['Status'] = 'Already exist'
          data.push(obj);
        }
        for (let index = 0; index < customersData.InvalidDataCustomers.length; index++) {
          let obj = {}
          obj['First Name'] = customersData.InvalidDataCustomers[index].first_name
          obj['Last Name'] = customersData.InvalidDataCustomers[index].last_name
          obj['Email'] = customersData.InvalidDataCustomers[index].email
          obj['Status'] = 'Invalid Data'
          data.push(obj);
        }
        this._CSVService.saveAsCSVFile(data, 'Import Customer Detail');
      }, (err) => {
        console.log('err while customer record processing', err);
        this.showImportCustomerRecordbar = false
      }
    )
  }

}
