import { Injectable } from '@angular/core';
import {
  GetRoleGQL, GetRolesGQL, CreateRoleGQL, DeleteRoleGQL, GetLoggedInUserAllowModulesGQL,
  GetLoggedInUserModulePermissionsGQL, UpdateRoleGQL, GetUserRolePermissionsGQL,
  AddCustomUserRolePermissionsGQL
} from 'src/app/generated/graphql'

@Injectable({
  providedIn: 'root'
})
export class RolesPermissionsGQLService {

  constructor(private getRoleGQL: GetRoleGQL,
    private getRolesGQL: GetRolesGQL,
    private createRoleGQL: CreateRoleGQL,
    private getLoggedInUserAllowModulesGQL: GetLoggedInUserAllowModulesGQL,
    private getLoggedInUserModulePermissionsGQL: GetLoggedInUserModulePermissionsGQL,
    private updateRoleGQL: UpdateRoleGQL,
    private getUserRolePermissionsGQL: GetUserRolePermissionsGQL,
    private addCustomUserRolePermissionsGQL: AddCustomUserRolePermissionsGQL,
    private deleteRoleGQL: DeleteRoleGQL) { }

  getRoleById(roleID, location_id) {
    return this.getRoleGQL.watch({
      location_id: location_id,
      roleID: roleID
    }).valueChanges
  }

  getAllRoles(roleFilter) {
    return this.getRolesGQL.watch({
      roleFilter: roleFilter
    }).valueChanges
  }

  createRole(roleName, permissionModule, location_id) {
    return this.createRoleGQL.mutate({
      location_id: location_id,
      roleName: roleName,
      permissionModule: permissionModule
    })
  }

  updateRole(roleID, roleName, addModule, removeModule, location_id) {
    return this.updateRoleGQL.mutate({
      location_id: location_id,
      roleID: roleID,
      roleName: roleName,
      addModule: addModule,
      removeModule: removeModule
    })
  }

  deleteRole(roleId, locationId) {
    return this.deleteRoleGQL.mutate({
      roleID: roleId,
      location_id: locationId
    })
  }

  getLoggedInUserAllowModules(location_id) {
    return this.getLoggedInUserAllowModulesGQL.watch({
      location_id: location_id
    }).valueChanges
  }

  getLoggedInUserModulePermissions(module, location_id) {
    return this.getLoggedInUserModulePermissionsGQL.watch({
      location_id: location_id,
      module: module
    }).valueChanges
  }

  getUserRolePermissions(userID, roleID, location_id) {
    return this.getUserRolePermissionsGQL.watch({
      userID: userID,
      roleID: roleID,
      location_id: location_id
    }).valueChanges
  }

  addCustomUserRolePermissions(userID, roleID, location_id, addPermissions, removePermissions) {
    return this.addCustomUserRolePermissionsGQL.mutate({
      userID: userID,
      roleID: roleID,
      location_id: location_id,
      addPermissions: addPermissions,
      removePermissions: removePermissions
    })
  }




}
