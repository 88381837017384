import { Pipe, PipeTransform } from '@angular/core';
import { arrayMax } from 'highcharts';

@Pipe({
  name: 'permissionFilter'
})
export class PermissionFilterPipe implements PipeTransform {

  transform(list: any, ...args: any[]): any {
    return list.filter(item => item.feature == args)
  }

}
