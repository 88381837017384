import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ProfilePhotoUploaderComponent } from 'src/app/modules/shared/profile-photo-uploader/profile-photo-uploader.component';
import { GenericUtility } from 'src/app/utilties/generic-utility';
import { SettingGqlService } from "./setting-gql.service"
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { AddressComponent } from "ngx-google-places-autocomplete/objects/addressComponent";


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  bsModalRef: BsModalRef;
  countryList: any[] = []
  emailPattern = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  hideToaster: boolean = true
  toasterMsg: String = ''
  toasterType: String = ''
  showToast = false

  constructor(private _settingGQL: SettingGqlService,
    private modalService: BsModalService) { }


  arrayCompareAddRemoveItem(oldArray, newArray) {
    var remove = oldArray.filter(x => newArray.indexOf(x) === -1);
    var add = newArray.filter(x => oldArray.indexOf(x) === -1);
    return { add: add, remove: remove }

  }

  model_ProfilePhoto(imageURL, changeCallBack, deleteCallBack) {
    const initialState = { imageURL: imageURL };
    this.bsModalRef = this.modalService.show(ProfilePhotoUploaderComponent, { class: 'modal-sm box-saveorder profilepopUp wd600', initialState });

    this.bsModalRef.content.event.subscribe(res => {
      if (res.data && res.data.isSave)
        changeCallBack(res.data.imageURL)
      else if (res.data && !res.data.isSave)
        deleteCallBack()
    });
  }

  toDataURL = url => fetch(url, {
    method: 'GET',
    headers: { 'Access-Control-Allow-Origin': '*' }
  })
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    }))


  getAllCountries() {
    this._settingGQL.getCountries()
      .subscribe((res) => {
        this.countryList = res['data'].countries
        console.log('All countries-->', this.countryList);
      }, (err) => {
        console.log('err in countries');
      }
      )
  }

  public getComponentByType(address: Address, type: string): AddressComponent {
    if (!type)
      return null;

    if (!address || !address.address_components || address.address_components.length == 0)
      return null;

    type = type.toLowerCase();

    for (let comp of address.address_components) {
      if (!comp.types || comp.types.length == 0)
        continue;

      if (comp.types.findIndex(x => x.toLowerCase() == type) > -1)
        return comp;
    }

    return null;
  }

  async convertToImage(url, type = 'png', name = 'default') {
    return new Promise(
      (resolve) => {
        var image = (fetch(url)
          .then(function (res) { return res.arrayBuffer(); })
          .then(function (buf) { return new File([buf], name + '.' + type, { type: type }); })
        );
        resolve(image)
      }
    )
  }

  showToaster(message, status) {
    this.showToast = true
    this.hideToaster = false
    this.toasterMsg = message
    this.toasterType = status
  }

  closeToaster() {
    this.showToast = false
    this.hideToaster = true
  }



}
