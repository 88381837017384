import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Location } from '@angular/common';
import { AllowMethodPhoneVerify, EmailOtpInput, EmailVerifyOtpInput, EmployeeSections, ListFilter, PhoneOtpInput, PhoneVerifyOtpInput } from 'src/app/generated/graphql';
import { SettingGqlService } from 'src/app/services/settings/setting-gql.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { MustMatch } from 'src/app/modules/account/register-old/_helper/must-match.validator';
import { filter } from 'rxjs/operators';
import { BusinessService } from 'src/app/services/settings/business.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {

  @ViewChild('openUpdateEmployee', { static: true }) template: ElementRef;
  @ViewChild('emailOTPModal', { static: true }) public emailOTPModal: ModalDirective;
  modalRef: BsModalRef;
  emailPatern = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  isEmailVerified = false
  verifiedEmail = null
  isPhoneVerified = false
  verifiedPhone = null
  bsModalRef: BsModalRef;
  currentEmployee = null
  loadingEmployeeDetail = false
  updateEmployeeSubmitted = false
  updateEmployeeAccountSubmitted = false
  updateContactSubmitted = false
  updateEmploymentSubmitted = false
  updatePayrollSubmitted = false
  updateDirectDepositSubmitted = false
  updateStateTaxSubmitted = false
  updateFederalTaxSubmitted = false
  userlogo: any
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.Pakistan, CountryISO.Mexico];
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;

  personalInfoForm: any
  accountForm: any
  contactForm: any
  employmentForm: any
  payrollForm: any
  directDepositForm: any
  stateTaxForm: any
  federalTaxForm: any

  customUser = ['Yes', 'No']
  accountStatus = ['Active', 'Inactive']
  sendSalesOrderEmail = ['Yes', 'No']
  sendSalesOrderEmailToParent = ['Yes', 'No']
  employeeStatus = ['Active', 'Inactive']
  genderTypes = ['Male', 'Female', 'Other'] //, 'Transgender', 'Two-Spirit', 'Cisgender', 'Non-Binary', 'Genderqueer', 'Gender expression', 'Gender fluid', 'Gender neutral'
  payType = ['Full Time', 'Part Time']
  payoutFrequency = ['Monthly', 'Weekly', 'By Weekly', 'Hourly']
  employeeType = ['Hourly', 'Salary']
  seasonalEmployee = ['Yes', 'No']
  taxOverride = ['Additional Amount Withheld', 'Additional Percentage Withheld', 'Flat $ Amount', 'Flat % Amount']
  additionalWithholding = ['Additional Amount Withheld', 'Additional Percentage Withheld', 'Flat $ Amount', 'Flat % Amount']

  employeeLiveAddress = ['Pak', 'USA', 'Mex', 'Aus']
  employeeWorkAddress = ['Pak', 'USA', 'Mex', 'Aus']
  Countries = [
    { name: "Pakistan", _id: "5f7c18a74a915e54c6a0be88" },
    { name: "USA", _id: "5f7c18a74a915e54c6a0be88" },
    { name: "Mexico", _id: "5f7c18a74a915e54c6a0be88" }
  ]

  showToast = false
  hideToaster = true
  toasterType = ''
  toasterMsg = ''

  maxDate: any
  maxHireDate: any
  currentUrl: any
  previousUrl: any
  OTPCode: string;
  otpEmailValid: boolean = true;
  emailCodeVerified: boolean = true;
  otpSMSValid: boolean = true;
  departmentListFilter: ListFilter = {
    limit: 1000,
    skip: 0,
    location_id: '',
    is_deleted: false,
    search: ''
  }

  constructor(private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private formbulider: FormBuilder,
    private datePipe: DatePipe,
    private settingsService: SettingsService,
    private _settingGqlService: SettingGqlService,
    private commonService: CommonService,
    private _businessService: BusinessService,
    private location: Location,
  ) { }

  ngOnInit() {
    this.maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 12));
    this.maxHireDate = new Date()
    this.getLogedInUser()
    this.renderForms()
    this.getAllStores()
    setTimeout(()=>{
      this.getUserDetail(this.route.snapshot.paramMap.get("id"));
    }) 
    this.bsModalRef = this.modalService.show(this.template, { class: 'modal-xl box-add-supplier', backdrop: 'static', keyboard: false })
  }

  logedInUser = null
  getLogedInUser() {
    this._settingGqlService.getLogedInUser().subscribe(
      (res) => {
        this.logedInUser = res['data'].me
      }, (err) => {
        console.log('err while getting loged in user', err);
      }
    )
  }

  renderForms() {
    this.initializePersonalInfoForm()
    this.initializeAccountForm()
    this.initializeContactForm()
    this.initializeEmploymentForm()
    this.initializePayrollForm()
    this.initializeStateTaxForm()
    this.initializeFederalTaxForm()
    this.initializeDirectDepositForm()
  }

  rolesList = []
  getRoles(id) {
    this._settingGqlService.getLocationwiseRoles(id)
      .subscribe((res) => {
        this.rolesList = res.data.getRolesByStore
      }, (err) => {
        console.log(err.message)
      })
  }

  departmentList = []
  getDepartments(id) {
    this.departmentListFilter.location_id = id
    this._settingGqlService.getDepartments(this.departmentListFilter)
      .subscribe((res) => {
        this.departmentList = res.data.getDepartments
      }, (err) => {
        console.log(err.message)
      })
  }

  cashRegisters = []
  getAllCashRegister(location_id){
    this._settingGqlService.cashRegistersOfLocation(location_id)
    .subscribe(res=>{
      this.cashRegisters = res.data.cashRegistersOfLocation
    },err=>{
      console.log('Cash register error', err.message)
    })
  }

  storeList = []
  getAllStores() {
    this._settingGqlService.getAllBusinessStoreAdmin(localStorage.getItem('BusinessId'), 'active').subscribe(
      (res) => {
        this.storeList = res['data'].getAllBusinessStoreAdmin
      }, (err) => {
        console.log('err while getting stores', err);
      }
    )
  }

  employeeID = null
  getUserDetail(id) {
    if (id == '0') {
      this.employeeID = null
      this.userlogo = null
      this.removeValidators()
      return
    }
    this.employeeID = id
    this.removePasswordValidation()
    this.loadingEmployeeDetail = true
    this._settingGqlService.getUserById(id).subscribe(
      (res) => {
        if (res['data'] == null) {
          return
        }
        this.loadingEmployeeDetail = false
        this.currentEmployee = res['data'].getUserByID
        this.updateProfileBit();
        this.isEmailVerified = res['data'].getUserByID.is_email_verify
        this.verifiedEmail = res['data'].getUserByID.is_email_verify ? res['data'].getUserByID.email : null
        this.isPhoneVerified = res['data'].getUserByID.is_phone_verify
        this.verifiedPhone = res['data'].getUserByID.is_phone_verify ? res['data'].getUserByID.phone : null
        this.setEmployeeDetails()
        console.log('user detail is', res['data'].getUserByID);
      }, (err) => {
        this.loadingEmployeeDetail = false
        this.settingsService.showToaster('Employee not found!', 'error')
        this.router.navigateByUrl('/Pointofsale/Settings/user')
        console.log('error while getting user', err);
      }
    )
  }

  calledFromLogin = false
  updateProfileBit() {
    if (this.logedInUser.id != this.employeeID) {
      this.calledFromLogin = false
      return
    }
    if (this.currentEmployee['is_profile_updated']) {
      this.calledFromLogin = false
      return
    }
    this.calledFromLogin = true
    this._settingGqlService.updateEmployeeProfileBit(this.employeeID, true).subscribe(
      (res) => {
        console.log('profile bit updated ', res['data'].employeeUpdatedProfile);
      }, (err) => {
        console.log('err while updating bit', err);
      }
    )
  }

  removeValidators() {
    this.personalInfoForm.controls['address_1'].setValidators(null)
    this.personalInfoForm.controls['address_1'].updateValueAndValidity()
    this.personalInfoForm.controls['city'].setValidators(null)
    this.personalInfoForm.controls['city'].updateValueAndValidity()
    this.personalInfoForm.controls['state'].setValidators(null)
    this.personalInfoForm.controls['state'].updateValueAndValidity()
    this.personalInfoForm.controls['zipcode'].setValidators(null)
    this.personalInfoForm.controls['zipcode'].updateValueAndValidity()
  }

  removePasswordValidation() {
    this.personalInfoForm.controls['password'].setValidators(null)
    this.personalInfoForm.controls['password'].updateValueAndValidity()
    this.personalInfoForm.controls['confirm_password'].setValidators(null)
    this.personalInfoForm.controls['confirm_password'].updateValueAndValidity()
  }

  setEmployeeDetails() {
    this.personalInfoForm.patchValue(this.currentEmployee)
    this.accountForm.patchValue(this.currentEmployee)
    this.contactForm.patchValue(this.currentEmployee)
    this.employmentForm.patchValue(this.currentEmployee)
    this.payrollForm.patchValue(this.currentEmployee)
    this.directDepositForm.patchValue(this.currentEmployee)
    this.stateTaxForm.patchValue(this.currentEmployee)
    this.federalTaxForm.patchValue(this.currentEmployee)
    this.employmentForm.controls.birth_date.setValue(this.datePipe.transform(this.currentEmployee['birth_date']));
    this.employmentForm.controls.termination_date.setValue(this.datePipe.transform(this.currentEmployee['termination_date']));
    this.employmentForm.controls.hire_date.setValue(this.datePipe.transform(this.currentEmployee['hire_date']));
    this.employmentForm.controls.last_day_worked.setValue(this.datePipe.transform(this.currentEmployee['last_day_worked']));
    this.userlogo = this.currentEmployee['avatar_location']
    for (let i = 0; i < this.currentEmployee['storeAccess'].length; i++) {
      let rolesName = []
      let departmentName = []
      let roles = []
      let departments = []
      let selectedCashRegister = []
      let cashRegister = []
      for (let index = 0; index < this.currentEmployee['storeAccess'][i]['roles'].length; index++) {
        rolesName.push(this.currentEmployee['storeAccess'][i]['roles'][index]['roleName'])
        roles.push(this.currentEmployee['storeAccess'][i]['roles'][index]['_id'])
      }
      for (let index = 0; index < this.currentEmployee['storeAccess'][i]['department'].length; index++) {
        departmentName.push(this.currentEmployee['storeAccess'][i]['department'][index]['department_name'])
        departments.push(this.currentEmployee['storeAccess'][i]['department'][index]['_id'])
      }
      if(this.currentEmployee['storeAccess'][i]['cashRegister']){
      for (let index = 0; index < this.currentEmployee['storeAccess'][i]['cashRegister'].length; index++) {
        selectedCashRegister.push(this.currentEmployee['storeAccess'][i]['cashRegister'][index])
        cashRegister.push(this.currentEmployee['storeAccess'][i]['cashRegister'][index]['_id'])
      }}
      this.currentEmployee['storeAccess'][i]['isEdit'] = false
      this.currentEmployee['storeAccess'][i]['roles'] = roles
      this.currentEmployee['storeAccess'][i]['department'] = departments
      this.currentEmployee['storeAccess'][i]['rolesName'] = rolesName
      this.currentEmployee['storeAccess'][i]['departmentName'] = departmentName
      this.currentEmployee['storeAccess'][i]['SelectedStoreCashRegister'] = selectedCashRegister
      this.currentEmployee['storeAccess'][i]['cashRegister'] = cashRegister

    }
    this.storeAssigned = this.currentEmployee['storeAccess']
    console.log('store access', this.storeAssigned);
    
    this.stateTaxForm.controls.exempt_income_tax.setValue(this.currentEmployee['exempt_income_tax'] == null ? false : this.currentEmployee['exempt_income_tax'])
    this.stateTaxForm.controls.exempt_any_state_tax.setValue(this.currentEmployee['exempt_any_state_tax'] == null ? false : this.currentEmployee['exempt_any_state_tax'])

    // this.federalTaxForm.controls.additional_tax_override.setValue(this.currentEmployee['additional_tax_override'] == null ? '' : this.currentEmployee['additional_tax_override'])
    this.federalTaxForm.controls.exempt_withholding.setValue(this.currentEmployee['exempt_withholding'] == null ? false : this.currentEmployee['exempt_withholding'])
    this.federalTaxForm.controls.exempt_federal_tax.setValue(this.currentEmployee['exempt_federal_tax'] == null ? false : this.currentEmployee['exempt_federal_tax'])
    this.federalTaxForm.controls.withholding_status.setValue(this.currentEmployee['withholding_status'] == null ? 'Single' : this.currentEmployee['withholding_status'])

  }

  initializePersonalInfoForm() {
    this.personalInfoForm = this.formbulider.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      middle_name: [''],
      preffered_name: [''],
      altername: [''],
      email: ['', [Validators.required, Validators.pattern(this.emailPatern)]],
      phone: ['', [Validators.required]],
      phone_country_code: [],
      password: ['', Validators.required],
      confirm_password: ['', [Validators.required]],
      // Country: ['', [Validators.required]],
      address_1: ['', [Validators.required]],
      address_2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      logo: [''],
    }, {
      validator: MustMatch('password', 'confirm_password')
    })
  }

  get fupdatePersonalInfo() {
    return this.personalInfoForm.controls
  }

  initializeAccountForm() {
    this.accountForm = this.formbulider.group({
      user_name: ['', [Validators.required]],
      auth_pin: ['', [Validators.required]],
      // department: [],
      // BusinessLocation: [],
      account_representive_code: [''],
      custom_user: [null, [Validators.required]],
      commision: [''],
      account_status: ['Active'],
      send_sales_order_email: [null],
      send_order_email_to_parent: [null],
      associated_customer: [''],
      badge_code: ['', [Validators.required]],
    })
  }

  get fupdateAccountForm() {
    return this.accountForm.controls
  }

  isAuthPinUnique = true
  verifyAuthPin() {
    let pin = this.accountForm.controls.auth_pin.value
    if (pin == '') {
      this.isAuthPinUnique = true
      return
    }
    this.commonService.verifyClockInPin(Number(pin), this.employeeID).subscribe(
      (res) => {
        if (!res['data'].uniquePinCodeVerify) {
          this.isAuthPinUnique = false
          this.showToaster('Auth PIN already taken please use alternate', 'error')
        }
        this.isAuthPinUnique = true
      }, (err) => {
        this.showToaster(err, 'error')
        this.isAuthPinUnique = false
      }
    )
  }

  assignMoreStore = true
  openIsNewStore() {
    this.assignMoreStore = !this.assignMoreStore
  }

  getRolesAndDepartments(store) {
    console.log('store is', store);
    this.rolesList = []
    this.departmentList = []
    this.cashRegisters = []
    this.currentRoles = null
    this.currentDepartments = null
    this.currentCashRegister = null
    this.getRoles(store)
    this.getDepartments(store)
    this.getAllCashRegister(store)
  }

  storeAssigned = []
  currentStore = null
  currentRoles = null
  currentDepartments = null
  currentCashRegister = null
  addStore() {
    if (this.currentStore == null) {
      this.showToaster('Please select store', 'error')
      return
    }
    if (this.currentRoles == null) {
      this.showToaster('Please select role', 'error')
      return
    }
    if (this.currentDepartments == null) {
      this.showToaster('Please select department', 'error')
      return
    }
    for (let index = 0; index < this.storeAssigned.length; index++) {
      if (this.currentStore == this.storeAssigned[index].businessLocation._id) {
        this.showToaster('Store already added in list', 'error')
        // this.openIsNewStore();
        return
      }
    }
    this.currentStore = this.storeList.find(element => element._id == this.currentStore)
    let obj = {
      businessLocation: this.currentStore,
      isEdit: false
    }
    let rolesName = []
    let departmentName = []
    let roles = []
    let departments = []
    let cashRegister = []
    for (let index = 0; index < this.currentRoles.length; index++) {
      let role = this.rolesList.find(element => element._id == this.currentRoles[index])
      rolesName.push(role['roleName'])
      roles.push(role['_id'])
    }
    for (let index = 0; index < this.currentDepartments.length; index++) {
      let department = this.departmentList.find(element => element._id == this.currentDepartments[index])
      departmentName.push(department['department_name'])
      departments.push(department['_id'])
    }

    obj['SelectedStoreCashRegister'] = this.currentCashRegister ? this.cashRegisters.filter(cashRegister => 
       this.currentCashRegister.find(register => register == cashRegister._id)  
    ) : []
    obj['cashRegister'] = this.currentCashRegister ? this.currentCashRegister : []
    obj['roles'] = roles
    obj['department'] = departments
    obj['departmentName'] = departmentName
    obj['rolesName'] = rolesName
    obj['']
    this.storeAssigned.push(obj)
    let index = this.storeList.findIndex((element) => element._id == this.currentStore['_id']);
    this.storeList.splice(index, 1);
    this.currentStore = null
    this.currentRoles = null
    this.currentDepartments = null
    this.openIsNewStore()
  }

  assignMoreRolesAndDepartment(event, type, index) {
    if (type == 'role') {
      if(event.length == 0) {
        this.storeAssigned[index]['rolesName'] = []
        return
      }
      let rolesName = []
      for (let i = 0; i < event.length; i++) {
        rolesName.push(event[i]['roleName'])
      }
      this.storeAssigned[index]['rolesName'] = rolesName
      return
    }
    if(event.length == 0) {
      this.storeAssigned[index]['departmentName'] = []
      return
    }
    let departmentName = []
    for (let i = 0; i < event.length; i++) {
      departmentName.push(event[i]['department_name'])
    }
    this.storeAssigned[index]['departmentName'] = departmentName
  }

  assignCashRegister(event,store){
    if(event.length == 0) {
      store['cashRegister'] = []
      return
    }
    store['SelectedStoreCashRegister'] = []
    event.forEach(cashRegister => {
      store['SelectedStoreCashRegister'].push(cashRegister)  
    });
  }

  removeRole(role, index) {
    this.storeAssigned[index]['rolesName'].splice(role['index'], 1);
  }

  removeDepartment(department, index) {
    this.storeAssigned[index]['departmentName'].splice(department['index'], 1);
  }

  removeCashRegister(cashRegister,store){
    store.SelectedStoreCashRegister.filter(register=>register._id !== cashRegister.value._id)
  }


  editStore(index) {
    this.getRolesAndDepartments(this.storeAssigned[index]['businessLocation']['_id'])
    this.toggleStoreInputs(index)
  }

  toggleStoreInputs(index) {
    for (let i = 0; i < this.storeAssigned.length; i++) {
      if(index != i) {
        this.storeAssigned[i]['isEdit'] = false
      }
    }
    this.storeAssigned[index]['isEdit'] = !this.storeAssigned[index]['isEdit']
  }

  deleteStoreAccess(index) {
    this.storeAssigned.splice(index, 1);
    this.showToaster('Store removed successfully', 'success')
  }

  initializeContactForm() {
    this.contactForm = this.formbulider.group({
      home_no: [''],
      mobile_no: [null],
      work_no: [''],
      extra_no: [''],
      personal_email: ['', [Validators.pattern(this.emailPatern)]],
      work_email: ['', [Validators.required, Validators.pattern(this.emailPatern)]],
      mobile_no_to_send_sms: [null]
    })
  }

  get fupdateContactForm() {
    return this.contactForm.controls
  }

  initializeEmploymentForm() {
    this.employmentForm = this.formbulider.group({
      length_of_service: [''],
      hire_date: ['', [Validators.required]],
      ssn: ['', [Validators.required]],
      employee_status: ['', [Validators.required]],
      birth_date: [null, [Validators.required]],
      gender: ['', [Validators.required]],
      termination_date: [''],
      last_day_worked: [''],
      termination_reason: ['']
    })
  }

  get fupdateEmploymentForm() {
    return this.employmentForm.controls
  }

  initializePayrollForm() {
    this.payrollForm = this.formbulider.group({
      pay_type: ['', [Validators.required]],
      payout_rate: [null, [Validators.required]],
      payout_frequency: [''],
      employee_type: [''],
      seasonal_employee: ['', [Validators.required]]
    })
  }

  get fupdatePayrollForm() {
    return this.payrollForm.controls
  }

  initializeDirectDepositForm() {
    this.directDepositForm = this.formbulider.group({
      bank_name: [''],
      routing_number: [''],
      account_number: ['']
    })
  }

  get fupdateDirectDepositForm() {
    return this.directDepositForm.controls
  }

  initializeStateTaxForm() {
    this.stateTaxForm = this.formbulider.group({
      empolyee_home_address: ['', [Validators.required]],
      empolyee_work_address: ['', [Validators.required]],
      sales_tax_allowances: [''],
      exempt_income_tax: [false],
      tax_override: [''],
      tax_override_amount: [],
      exempt_any_state_tax: [false]
    })
  }

  get fupdateStateTaxForm() {
    return this.stateTaxForm.controls
  }

  initializeFederalTaxForm() {
    this.federalTaxForm = this.formbulider.group({
      w4_form: [''],
      withholding_status: ['Single'],
      total_allowances: [''],
      additional_tax_override: [''],
      additional_withholding_amount: [],
      exempt_withholding: [false],
      exempt_federal_tax: [false]
    })
  }

  get fupdateFederalTaxForm() {
    return this.federalTaxForm.controls
  }

  setFormValues(form, field, value) {
    this[form].controls[field].setValue(value)
  }

  modelProfilePhoto(img) {
    if (img == null) {
      this.settingsService.model_ProfilePhoto('', this.updateProfilePhoto, this.deleteProfilePhoto)
      return
    }
    this.settingsService.toDataURL(img).then(result => {
      console.log('img is', img);
      // this.img = profilePicture('.profile', result, '')
      this.settingsService.model_ProfilePhoto(result, this.updateProfilePhoto, this.deleteProfilePhoto)
    }
    );
  }

  imageUpdated = false
  updateProfilePhoto = async (img) => {
    this.imageUpdated = true
    this.userlogo = img.getAsDataURL()
    this.setFormValues('personalInfoForm', 'logo', await this.settingsService.convertToImage(this.userlogo))
  }

  deleteProfilePhoto = () => {
    this.imageUpdated = false
  }

  async updateEmployee(form, tab, error, nextTab, input) {
    if (this[form].invalid) {
      this[error] = true
      return
    }
    if (form == 'contactForm') {
      if (this.contactForm.controls.mobile_no.value != null) {
        this.contactForm.controls.mobile_no.setValue(this.contactForm.controls.mobile_no.value['internationalNumber'])
      }
      if (this.contactForm.controls.mobile_no_to_send_sms.value != null) {
        this.contactForm.controls.mobile_no_to_send_sms.setValue(this.contactForm.controls.mobile_no_to_send_sms.value['internationalNumber'])
      }
    }
    this[error] = false
    let data = JSON.parse(JSON.stringify(this[form].value))
    if (form == 'personalInfoForm') {
      if (this.emailAlreadyExist) {
        this.showToaster('Email already exist', 'error')
        return
      }
      if (!this.isEmailVerified && this.employeeID != null) {
        this.showToaster('Please verify email', 'error')
        return
      }
      if (!this.isPhoneVerified && this.employeeID != null) {
        this.showToaster('Please verify phone number', 'error')
        return
      }
      if (this.imageUpdated) {
        data['logo'] = await this.settingsService.convertToImage(this.userlogo)
      }
      data['Business'] = localStorage.getItem('BusinessId')
      data['email_verified'] = this.isEmailVerified
      data['phone_verified'] = this.isPhoneVerified
      if (data['phone'] != null) {
        data['phone_country_code'] = data['phone']['dialCode']
        data['phone'] = data['phone']['internationalNumber']
        if (this.employeeID != null) {
          delete data['password']
        }
        delete data['confirm_password']
      }
    }
    if (form == 'accountForm') {
      if (!this.isAuthPinUnique) {
        this.showToaster('Auth PIN must be unique', 'error')
        return
      }
      let storeAccess = []
      for (let i = 0; i < this.storeAssigned.length; i++) {
        let obj = {
          businessLocation: this.storeAssigned[i]['businessLocation']['_id']
        }
        // let roles = []
        // let department = []
        // for (let j = 0; j < this.storeAssigned[i]['roles'].length; j++) {
        //   roles.push(this.storeAssigned[i]['roles'][j]['_id'])
        // }
        // for (let j = 0; j < this.storeAssigned[i]['department'].length; j++) {
        //   department.push(this.storeAssigned[i]['department'][j]['_id'])
        // }
        obj['cashRegister'] = this.storeAssigned[i]['cashRegister']
        obj['roles'] = this.storeAssigned[i]['roles']
        obj['department'] = this.storeAssigned[i]['department']
        storeAccess.push(obj)
      }
      data['storeAccess'] = storeAccess
    }
    if(form == 'federalTaxForm') {
      data['exempt_federal_tax'] = Boolean(data['exempt_federal_tax'])
      data['exempt_withholding'] = Boolean(data['exempt_withholding'])
    }
    let obj = {}
    obj[input] = data
    obj['section'] = EmployeeSections[tab]
    console.log('data of employee to update', obj);
    this.addUpdateEmployee(obj, nextTab)
  }

  addUpdateEmployee(data, nextTab) {
    this._settingGqlService.addUpdateEmployee(this.employeeID, data).subscribe(
      (res) => {
        if (res['data'].adminCreateUpdateEmployee != null) {
          this.commonService.setHeaderItem(false)
          this.employeeID = res['data'].adminCreateUpdateEmployee.id
          if (nextTab == 'null') {
            this.backToUser()
            return
          }
          nextTab = nextTab + '-link'
          document.getElementById(nextTab).click()
          this.showToaster('Employee updated successfully', 'success')
          return
        }
        this.showToaster('Something went wrong while updating employee', 'error')
      }, (err) => {
        console.log('err while updating employee', err);
        this.showToaster(err.graphQLErrors[0].message, 'error')
      }
    )
  }

  verifySameEmail() {
    if (this.verifiedEmail === this.personalInfoForm.controls.email.value && this.verifiedEmail !== '') {
      this.otpEmailValid = true
      this.emailCodeVerified = true
      this.isEmailVerified = true
      return
    }
    this.emailCodeVerified = false
    this.isEmailVerified = false
    return false
  }

  emailAlreadyExist = false
  openMailModal(template: TemplateRef<any>, cls, openToaster) {
    if (this.personalInfoForm.controls.email.value == null) {
      return
    }
    this._businessService.verifyEmailExist(this.personalInfoForm.controls.email.value, this.employeeID).subscribe((response) => {
      console.log('email ver res is', response);
      if (!response['data'].checkUserEmail) {
        this.emailAlreadyExist = false
        if (openToaster) {
          this.sendEmailOTPCode()
          this.modalRef = this.modalService.show(template, {
            class: 'modal-sm ' + cls,
            backdrop: 'static', keyboard: false
          });
        }
      }
      else {
        this.emailAlreadyExist = true
        this.showToaster('Email already exists', 'error');
      }
    }, (error) => {
      console.log('there was an error sending the query', error);
      this.showToaster('Email already exists', 'error');
    });
  }

  sendEmailOTPCode() {
    let obj: EmailOtpInput = {
      email: this.personalInfoForm.controls.email.value
    }
    this._businessService.sendEmailOTP(obj).subscribe(
      (res) => {
        console.log('OTP res', res['data'].createEmailOTP);
        this.showToaster('Email Verification code sent', 'success')
      }, (err) => {
        console.log('OTP err', err);
        this.showToaster(err.graphQLErrors[0].message, 'error');
      }
    )
  }

  verifyCode() {
    let obj: EmailVerifyOtpInput = {
      email: this.personalInfoForm.controls.email.value,
      otp_code: this.OTPCode
    }
    this._businessService.verifyEmailOTP(obj).subscribe(
      (res) => {
        if (res['data'].verifyEmailOTP == null) {
          this.otpEmailValid = false
          this.emailCodeVerified = false
          this.OTPCode = ''
          return
        }
        console.log('OTP  ver res', res['data'].verifyEmailOTP.verified);
        if (!res['data'].verifyEmailOTP.verified) {
          this.otpEmailValid = false
          this.emailCodeVerified = false
        }
        else {
          this.otpEmailValid = true
          this.emailCodeVerified = true
          this.isEmailVerified = true
          this.verifiedEmail = this.personalInfoForm.controls.email.value
          this.modalRef.hide()
          this.OTPCode = ''
        }
      }, (err) => {
        console.log('OTP  ver err', err);
        this.otpEmailValid = false
        //this.OTPCode = ''
      }
    )
  }

  sendSMSOTPCode(template: TemplateRef<any>, cls) {
    let obj: PhoneOtpInput = {
      phone: this.personalInfoForm.controls.phone.value['internationalNumber'],
      method: AllowMethodPhoneVerify.Sms
    }
    this._businessService.sendOTP(obj).subscribe(
      (res) => {
        console.log('sms otp res', res);
        this.modalRef = this.modalService.show(template, {
          class: 'modal-sm ' + cls,
          backdrop: 'static', keyboard: false
        });
        this.showToaster('OTP code sent via SMS', 'success');
      }, (err) => {
        this.showToaster(err.graphQLErrors[0].message, 'error');
        console.log('sms otp err', err);
      }
    )
  }

  verifySMSOTP() {
    let obj: PhoneVerifyOtpInput = {
      phone: this.personalInfoForm.controls.phone.value['internationalNumber'],
      otp_code: this.OTPCode
    }
    this._businessService.verifyOTP(obj).subscribe(
      (res) => {
        console.log('res of ver phone', res);
        if (!res['data'].verifyOTP) {
          this.otpSMSValid = false
          this.emailCodeVerified = false
          this.OTPCode = ''
        }
        else {
          this.verifiedPhone = this.personalInfoForm.controls.phone.value['internationalNumber']
          this.isPhoneVerified = true
          this.otpSMSValid = true
          this.emailCodeVerified = true
          this.OTPCode = ''
          this.modalRef.hide()
        }
      }, (err) => {
        this.showToaster('Invalid OTP Code. Try Again', 'success')
        console.log('err of ver phone', err);
        this.otpSMSValid = false
        //this.OTPCode = ''
      }
    )
  }

  verifySameNumber() {
    if (this.personalInfoForm.controls.phone.value == null) {
      return
    }
    if (this.verifiedPhone != null || this.verifiedPhone != undefined) {
      let VerifiedNum = this.verifiedPhone.replace(/\s/g, "")
      let NewNum = this.personalInfoForm.controls.phone.value['internationalNumber'].replace(/\s/g, "")
      if (VerifiedNum === NewNum) {
        this.isPhoneVerified = true
        this.otpSMSValid = true
        return true
      }
      this.isPhoneVerified = false
    }
    return false
  }

  backToUser() {
    this.bsModalRef.hide();
    if (this.calledFromLogin) {
      this.router.navigateByUrl('/Pointofsale')
      return
    }
    this.location.back()
    return
  }

  showToaster(message, status) {
    this.showToast = true
    this.hideToaster = false
    this.toasterMsg = message
    this.toasterType = status
  }

  closeToaster() {
    this.showToast = false
    this.hideToaster = true
  }

  closeModal() {
    this.modalRef.hide()
  }

  noOpenAccordianOnInput(e) {
    e.stopPropagation();
  }


}
