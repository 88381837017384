import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { EnvironmentUrl } from 'src/environments/environment-url';

@Injectable({
  providedIn: 'root'
})
export class FooterServicesService {
  //secret_key = 'MXCAN-60f0133de0acf';
  connProject={
    "project_id": "200",
    "business_id": "businessid1234",
    "store_id": "storeid12345331",
    "secret_key": "MXCAN-610a408b3577d",
    "organization_domain_name": "techbarllc",
    "employees": [
        { "first_name": "Techbar Kamran", "last_name": " Manzoor Khan", "email": "techkamran@gmail.com", "_id": 1 },
        { "first_name": "Techbar ", "last_name": "Sohail Khan", "email": "techsohail@gmail.com", "_id": 2 },
        { "first_name": "Techbar", "last_name": "Aamir Khan", "email": "techaamir@gmail.com", "_id": 3 }
    ]
}
  constructor(private httpClient: HttpClient) { }

   httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

  public setConnection(key){
    return this.httpClient.post('http://canny.techbar.com/api/organization/connect', {
      secret_key: key
    },this.httpOptions
    )
  }

  public connectProject(data) {
    return this.httpClient.post(`${EnvironmentUrl.getAPIUrl}api/canny/connectproject`,data, this.httpOptions
    )
  }
  public linkModules(mapped) {
    return this.httpClient.post(`${EnvironmentUrl.getAPIUrl}api/canny/link-modules`,{ mapped }, this.httpOptions)
  }

  public getCannyBoards(project_id) {
    return this.httpClient.post(`${EnvironmentUrl.getAPIUrl}api/canny/boards`,{ project_id }, this.httpOptions)
  }  

  public getCannyPosts(data) {
    return this.httpClient.post(`${EnvironmentUrl.getAPIUrl}api/canny/posts`, data, this.httpOptions)

  }

  public connectedProjects(projects) {
    return this.httpClient.post(`${EnvironmentUrl.getAPIUrl}api/canny/connected-projects`, {projects}, this.httpOptions)

  }

  public getTechbarModules() {
    return this.httpClient.get(`${EnvironmentUrl.getAPIUrl}api/canny/modules`, this.httpOptions
    )
  }

  public unlinkProject(project) {
    return this.httpClient.post(`${EnvironmentUrl.getAPIUrl}api/canny/unlink`, { project }, this.httpOptions)
  }
 
}