import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PointofsaleRoutingModule } from '../point-of-sale/point-of-sale-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { UtilitiesModule } from 'src/app/utilties/utilities.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AngularDraggableModule } from 'angular2-draggable';
import { MinimizerListComponent } from './minimizer-list/minimizer-list.component';
import { SaleCartComponent } from '../point-of-sale/create-sale/sale-cart/sale-cart.component'
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ChatBotComponent } from './chat-bot/chat-bot.component';

@NgModule({
  declarations: [HeaderComponent,
    FooterComponent,
    MinimizerListComponent,
    ChatBotComponent],
  imports: [
    CommonModule,
    PointofsaleRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    CarouselModule.forRoot(),
    UtilitiesModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    AngularDraggableModule,
    ProgressbarModule,
    SharedModule,
    NgSelectModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ],
  providers:[
    SaleCartComponent
  ]
})
export class LayoutModule {
}
