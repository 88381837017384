import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RolesPermissionsService } from 'src/app/services/role-permission/roles-permissions.service';
declare function onloadTabLocks(): any;

@Component({
  selector: 'permission-checkbox',
  templateUrl: './permission-checkbox.component.html',
  styleUrls: ['./permission-checkbox.component.css']
})
export class PermissionCheckboxComponent implements OnInit {

  @Input() permissionName
  @Input() featureName

  isShowCheckBox: boolean = false
  constructor(private router: Router,
    private elRef: ElementRef,
    private _rolesPermission: RolesPermissionsService) { }

  ngOnInit() {
    //this.elRef.nativeElement.parentElement.classList.add('dInlineBlock');
    if (this.router.url.includes('Permissions')) {
      this.isShowCheckBox = true;
      this.elRef.nativeElement.parentElement.classList.add('posRelative');
    } else {
      this.elRef.nativeElement.parentElement.classList.remove('posRelative');
      this.elRef.nativeElement.remove()
    }

    // onloadTabLocks();
  }


  isActionNotAllowed(featureName, permissionName) {
    return this._rolesPermission.isActionNotAllowed(this._rolesPermission.selectedModule,featureName, permissionName);
  }

  onChangeActionPermission(featureName, permissionName, event) {
    this._rolesPermission.onChangeActionPermission(featureName, permissionName, event);
  }

  changeStyle($event) {
    if ($event.type == 'mouseover') {
      document.getElementById('permissionList-' + this.permissionName).classList.add("active", "mapItem")
    } else {
      document.getElementById('permissionList-' + this.permissionName).classList.remove("active", "mapItem")
    }
  }

}
