import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Is_Active, Status } from 'src/app/generated/graphql';
import { DiscountGQLService } from 'src/app/services/discounts/discount-gql.service'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { TabsetComponent } from 'ngx-bootstrap';

@Component({
  selector: 'app-add-discount',
  templateUrl: './add-discount.component.html',
  styleUrls: ['./add-discount.component.css'],
})
export class AddDiscountComponent implements OnInit {

  @Input() discountId
  @ViewChild('staticTabs', { static: true }) tabset: TabsetComponent;
  discountForm: FormGroup;
  addDiscountSubmitted = false
  allCampaigns = []
  selectedDicountTags = []
  allDiscountTags = []
  allCustomerTags = []
  selectedCustomerTags = []
  totalCustomers = 0
  effectedCustomers = 0
  selectedCampaign = {}
  allStores = []
  selectedStores = []
  campaignSearchString = "name"
  showCampaignsList = false
  showDiscountTagList = false
  showStoreList = false
  showCustomerTagList = false
  discountLoader = false
  dicountOnSelectedStores = false
  minDate = new Date()
  totalEffectedCustomers = 0
  today = new Date()
  isfromTimeValid = true
  isToTimeValid = true
  storeName = localStorage.getItem('storeName')
  storeDetail

  campaignSearch: FormControl = new FormControl();
  discountSearch: FormControl = new FormControl();
  searchProducts: FormControl = new FormControl();
  searchDiscountTag: FormControl = new FormControl();
  searchCustomerTag: FormControl = new FormControl();
  searchStore: FormControl = new FormControl();
  toasterMsg: any;
  hideToaster: boolean;
  toasterType: any;



  constructor(private formbulider: FormBuilder,
    private _discountGQL: DiscountGQLService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.getAllCampaigns()
    this.getAllDiscountTags()
    this.searchProduct()
    this.getCustomerTags()
    this.searchBusinessLocation()

    this.storeDetail = JSON.parse(CryptoJS.AES.decrypt(window.localStorage.getItem('store'), 'luna').toString(CryptoJS.enc.Utf8));
    this.discountId = this.route.snapshot.paramMap.get("_id");
    this.discountId ? this.getDiscountById(this.discountId) : this.initializeaddDiscountForm(null)


    // this.campaignSearch.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(term => {
    //   this.getAllCampaigns()
    // });
  }

  changeValue(type, val) {
    this[type] = val
  }

  getDiscountById(discountid) {
    this.discountLoader = true
    this._discountGQL.getDiscountById(discountid)
      .subscribe((res) => {
        this.initializeaddDiscountForm(res.data.DiscountById)
        this.updateDiscountBar()
        this.discountLoader = false
      })
  }


  initializeaddDiscountForm(formData) {
    if (!formData) {
      this.discountForm = this.formbulider.group({
        Campaign: [, [Validators.required]],
        code: ['', [Validators.required]],
        tags: [],
        is_discount_percentage: [false, [Validators.required]],
        is_single_store: [true, [Validators.required]],
        discount_amount: ['', [Validators.required]],
        is_minimum_purchase: [false, [Validators.required]],
        is_minimum_purchase_amount: [false],
        purchaseAmountQuantity: [''],
        is_entire_order: [true, [Validators.required]],
        Product: [],
        is_customer_since: [true, [Validators.required]],
        customer_since: [new Date()],
        is_new_customer_only: [false, [Validators.required]],
        new_customer_register_after_date: [''],
        is_customer_spent_amount: [false, [Validators.required]],
        is_customer_more_then_said_amount: [false, [Validators.required]],
        spent_amount: [''],
        customerTags: [],
        is_customer_can_use_only_once: [true, [Validators.required]],
        number_of_usage_per_customer: [''],
        can_schedule: [true, [Validators.required]],
        schedule_from_date: [new Date()],
        schedule_from_time: [new Date()],
        schedule_to_date: [new Date()],
        schedule_to_time: [new Date()],
        isSendEmail: [true],
        multi_stores: [''],
        single_store: [''],
        BusinessLocation: [window.localStorage.getItem('location_id')],
        reach: [0]
      });
    } else {
      this.discountForm = this.formbulider.group({
        Campaign: [formData.Campaign._id, [Validators.required]],
        code: [formData.code, [Validators.required]],
        tags: [formData.tags],
        is_discount_percentage: [formData.is_discount_percentage, [Validators.required]],
        is_single_store: [formData.is_single_store, [Validators.required]],
        discount_amount: [formData.discount_amount, [Validators.required]],
        is_minimum_purchase: [formData.is_minimum_purchase, [Validators.required]],
        is_minimum_purchase_amount: [formData.is_minimum_purchase_amount],
        purchaseAmountQuantity: [formData.purchaseAmountQuantity],
        is_entire_order: [formData.is_entire_order, [Validators.required]],
        Product: [formData.Product],
        is_customer_since: [formData.is_customer_since, [Validators.required]],
        customer_since: [new Date(formData.customer_since)],
        is_new_customer_only: [formData.is_new_customer_only, [Validators.required]],
        new_customer_register_after_date: [formData.new_customer_register_after_date],
        is_customer_spent_amount: [formData.is_customer_spent_amount, [Validators.required]],
        is_customer_more_then_said_amount: [formData.is_customer_more_then_said_amount, [Validators.required]],
        spent_amount: [formData.spent_amount],
        customerTags: [formData.customerTags],
        is_customer_can_use_only_once: [formData.is_customer_can_use_only_once, [Validators.required]],
        number_of_usage_per_customer: [formData.number_of_usage_per_customer],
        can_schedule: [formData.can_schedule, [Validators.required]],
        schedule_from_date: [new Date(formData.schedule_from)],
        schedule_from_time: [new Date(formData.schedule_from)],
        schedule_to_date: [new Date(formData.schedule_to)],
        schedule_to_time: [new Date(formData.schedule_to)],
        isSendEmail: [formData.isSendEmail],
        multi_stores: [''],
        single_store: [''],
        BusinessLocation: [window.localStorage.getItem('location_id')],
        reach: [formData.reach]
      })

      this.selectedCustomerTags = formData.customerTags
      this.selectedDicountTags = formData.tags
      this.selectedProduct = formData.Product
      this.minDate = new Date(formData.schedule_from)
      formData.is_single_store ? this.selectedStores.push(formData.single_store) : this.selectedStores = formData.multi_stores

      this.dicountOnSelectedStores = !formData.is_single_store && this.selectedStores !== this.allStores ? true : false
      this.selectedCampaign = formData.Campaign

      console.log('From Data', this.discountForm.value)
    }
    this.setOptionalValidators()
  }

  getTime(date) {
    date.setHours(date.getHours())
    date.setMinutes(date.getMinutes());
    return date
  }

  setOptionalValidators() {
    const purchaseAmountQuantity = this.discountForm.get('purchaseAmountQuantity');
    const Product = this.discountForm.get('Product');
    const customer_since = this.discountForm.get('customer_since');
    const spent_amount = this.discountForm.get('spent_amount')
    const number_of_usage_per_customer = this.discountForm.get('number_of_usage_per_customer')
    const single_store = this.discountForm.get('single_store')
    const multi_stores = this.discountForm.get('multi_stores')
    const schedule_from_date = this.discountForm.get('schedule_from_date')
    const schedule_to_date = this.discountForm.get('schedule_to_date')

    this.discountForm.get('is_minimum_purchase').valueChanges
      .subscribe(is_minimum_purchase => {

        if (is_minimum_purchase) {
          purchaseAmountQuantity.setValidators([Validators.required])
        }else{
          purchaseAmountQuantity.setValidators(null)
        }
        purchaseAmountQuantity.updateValueAndValidity();
      });

    this.discountForm.get('is_entire_order').valueChanges
      .subscribe(is_entire_order => {
        if (!is_entire_order) {
          Product.setValidators([Validators.required])
        } else {
          Product.setValidators(null);
        }
        Product.updateValueAndValidity();
      })

    this.discountForm.get('is_customer_since').valueChanges
      .subscribe(is_customer_since => {
        if (is_customer_since) {
          customer_since.setValidators([Validators.required])
        }
        else {
          customer_since.setValidators(null);
        }
        customer_since.updateValueAndValidity();
      })

    this.discountForm.get('is_customer_spent_amount').valueChanges
      .subscribe(is_customer_spent_amount => {
        if (is_customer_spent_amount) {
          spent_amount.setValidators([Validators.required])
        }
        else {
          spent_amount.setValidators(null);
        }
        spent_amount.updateValueAndValidity();
      })

    this.discountForm.get('is_customer_can_use_only_once').valueChanges
      .subscribe(is_customer_can_use_only_once => {
        if (is_customer_can_use_only_once) {
          number_of_usage_per_customer.setValidators([Validators.required])
        }
        else {
          number_of_usage_per_customer.setValidators(null);

        }
        number_of_usage_per_customer.updateValueAndValidity();
      })

    this.discountForm.get('is_single_store').valueChanges
      .subscribe(is_single_store => {
        if (is_single_store) {
          single_store.setValidators([Validators.required])
          multi_stores.setValidators(null);
        } else {
          multi_stores.setValidators([Validators.required])
          single_store.setValidators(null);
        }
        single_store.updateValueAndValidity();
        multi_stores.updateValueAndValidity();
      })

    this.discountForm.get('can_schedule').valueChanges
      .subscribe(can_schedule => {
        if (can_schedule) {
          schedule_to_date.setValidators([Validators.required])
          schedule_from_date.setValidators([Validators.required])
        }
        else {
          schedule_to_date.setValidators(null);
          schedule_from_date.setValidators(null);
        }
        schedule_to_date.updateValueAndValidity();
        schedule_from_date.updateValueAndValidity()
      })

  }


  get fDiscountForm() {
    return this.discountForm.controls;
  }

  selectCampaign(campaign) {
    this.campaignSearch.setValue(campaign.name)
    this.selectedCampaign = campaign
    this.fDiscountForm.Campaign.setValue(campaign._id)
    //this.campaignsList = true
  }

  getAllCampaigns() {
    this._discountGQL.searchCampaigns(this.campaignSearch.value || '')
      .subscribe(
        (res) => {
          this.allCampaigns = res['data'].searchCampaigns
        }, (err) => {
          console.log('error while getting all campains', err);
        }
      )
  }

  createCampaing() {
    if (this.campaignSearch.value == null)
      return

    let campaignInput = {
      name: this.campaignSearch.value,
      BusinessLocation: localStorage.getItem('location_id')
    }
    this._discountGQL.createCampaign(campaignInput)
      .subscribe(
        (res) => {
          console.log('create campaign res', res['data'].createCampaign);
          this.allCampaigns.push(res['data'].createCampaign)
          this.fDiscountForm.Campaign.setValue(res['data'].createCampaign._id)
          this.selectedCampaign = res['data'].createCampaign
        }, (err) => {
          console.log('create campaign err', err);
          //this.toaster.showError(err['message'].substring(15, 50), 'Campaign')
        }
      )
  }

  generateCouponCode() {
    this._discountGQL.createCouponCode()
      .subscribe(
        (res) => {
          console.log('coupon code is', res['data'].createCouponCode);
          this.fDiscountForm.code.setValue(res['data'].createCouponCode.code)
        }, (err) => {
          console.log('coupon code err', err);
        }
      )
  }


  getAllDiscountTags() {
    this._discountGQL.searchDiscountTags(this.searchDiscountTag.value || '')
      .subscribe(
        (res) => {
          this.allDiscountTags = res['data'].searchDiscountTags
        }, (err) => {
          console.log('tags of discount err', err);
        }
      )
  }

  addTag(event, type) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 32 || charCode == 13 || charCode == 9) {
      this.addTagToList(type)
      this.searchDiscountTag.setValue(null)
    }
  }

  addTagToList(type) {
    if (type == null)
      return
    this.removeTagFromList(type)
    this.selectedDicountTags.push(type)
    this.searchDiscountTag.setValue(null)
  }
  removeTagFromList(tagInput) {
    this.selectedDicountTags = this.selectedDicountTags.filter(tag => tag !== tagInput)
  }

  setDiscountProperties(type, property) {
    this[type].controls[property].setValue(!this[type].controls[property].value)
    this.updateDiscountBar()
  }

  //effectedCustomers
  updateDiscountBar() {
    if (this.fDiscountForm.is_customer_since.value && this.fDiscountForm.customer_since.value == '') {
      return
    }
    let progressBarInput = {
      is_customer_since: this.fDiscountForm.is_customer_since.value,
      customer_since: this.fDiscountForm.is_customer_since.value ? new Date(this.fDiscountForm.customer_since.value).toLocaleDateString() : null,
      is_customer_spent_amount: this.fDiscountForm.is_customer_spent_amount.value,
      is_customer_more_then_said_amount: this.fDiscountForm.is_customer_more_then_said_amount.value,
      spent_amount: Number(this.fDiscountForm.spent_amount.value),
      customerTags: this.selectedCustomerTags,
      businessLocations: this.getSelectedStoreID()
    }


    this._discountGQL.effectDiscountToCustomer(progressBarInput)
      .subscribe(
        (res) => {
          console.log('update discount bar res', res['data'].effectDiscountToCustomer);
          this.totalCustomers = res['data'].effectDiscountToCustomer.Total_customer
          this.effectedCustomers = res['data'].effectDiscountToCustomer.effect_customer
          this.fDiscountForm.reach.setValue(this.effectedCustomers)
          this.totalEffectedCustomers = (this.effectedCustomers / this.totalCustomers) * 100

        }, (err) => {
          console.log('update discount bar err', err);
        }
      )
  }

  allProducts = []
  selectedProduct = []
  searchProduct() {
    this._discountGQL.searchProduct(this.searchProducts.value || '', localStorage.getItem('location_id'))
      .subscribe(
        (res) => {
          console.log('product search response is', res['data'].searchProduct);
          this.allProducts = JSON.parse(JSON.stringify(res['data'].searchProduct))
        }, (err) => {
          console.log('product search error is ', err);
        }
      )
  }

  selectProduct(product) {
    this.removeProduct(product)
    this.selectedProduct.push(product)
    this.searchProducts.setValue(null)
  }

  removeProduct(product) {
    this.selectedProduct = this.selectedProduct.filter(item => item._id != product._id)
  }

  getCustomerTags() {
    this._discountGQL.searchCustomerTags(this.searchCustomerTag.value || '')
      .subscribe(
        (res) => {
          this.allCustomerTags = res['data'].searchCustomerTags
        }, (err) => {
          console.log('tags of customer err', err);
        }
      )
  }

  selectCustomerTag(tag) {
    this.removeCustomerTags(tag.value || tag)
    this.selectedCustomerTags.push(tag)
    this.searchCustomerTag.setValue(null)
  }

  removeCustomerTags(tagValue) {
    this.selectedCustomerTags = this.selectedCustomerTags.filter(tag => tag !== tagValue)
  }

  searchBusinessLocation() {
    this._discountGQL.searchBusinessLocation(this.searchStore.value || '')
      .subscribe((res) => {
        this.allStores = res.data.searchBusinessLocation.filter(store=> store.status !== "inactive")
        this.dicountOnSelectedStores = !this.discountForm.value.is_single_store && this.selectedStores !== this.allStores ? true : false
      }, (err) => {
        console.log('Serach store error', err.message)
      })
  }

  addStoreToList(store) {
    this.removeSelectedStore(store)
    this.selectedStores.push(store)
  }


  selectThisStore() {
    this.selectedStores = this.allStores.filter(store => store._id == localStorage.getItem('location_id'))
  }

  removeSelectedStore(store) {
    this.selectedStores = this.selectedStores.filter(item => item._id != store._id)
  }

  addDiscount() {
    this.addDiscountSubmitted = true

    this.addValuesToForm()

    if (this.discountForm.invalid && this.isfromTimeValid && this.isToTimeValid) {
      this.toasterFunction('Form data is invalid, please check again', 'error')
      this.onChangeScreenClick('discount_code')
      return;
    }
    this.discountLoader = true
    this._discountGQL.createDiscount(this.discountMapperForBackend('discountForm'))
      .subscribe(
        (res) => {
          this.discountLoader = false
          this.navigateToListing()
          //this.toaster.showSuccess('Discount added sucessfully', 'Discount added')
          //this.initializeaddDiscountForm()
        }, (err) => {
          this.discountLoader = false
          console.log('discount added err', err);
          //this.toaster.showError(err['message'].substr(15, 47), 'Discount err')
        }
      )
  }

  updateDiscount() {
    this.addDiscountSubmitted = true
    this.addValuesToForm()
    if (this.discountForm.invalid) {
      return
    }
    this.discountLoader = true
    this._discountGQL.updateDiscount(this.discountId, this.discountMapperForBackend('discountForm'))
      .subscribe((res) => {
        this.discountLoader = false
        //this.router.navigateByUrl(`Pointofsale/Discount`);
        this.navigateToListing()
      }, (err) => {
        this.discountLoader = false
        console.log('Update Discout Error', err.message)
      })
  }

  navigateToListing() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['Pointofsale', 'Settings', 'discount']))
  }

  getSelectedStoreID() {
    if (this.fDiscountForm.is_single_store.value) {
      return localStorage.getItem('location_id')
    } else {
      let storeIds = []
      this.selectedStores.forEach(element => {
        storeIds.push(element._id)
      });
      return storeIds
    }
  }

  addValuesToForm() {
    if (!this.fDiscountForm.is_entire_order.value) {
      this.fDiscountForm.Product.setValue(this.getSelectedProducts())
    }
    if (this.fDiscountForm.is_single_store.value) {
      this.fDiscountForm.single_store.setValue(this.getSelectedStoreID())
    } else {
      this.fDiscountForm.multi_stores.setValue(this.getSelectedStoreID())
    }
    this.fDiscountForm.customerTags.setValue(this.selectedCustomerTags)
    this.fDiscountForm.tags.setValue(this.selectedDicountTags)

  }


  getSelectedProducts() {
    let finalProducts = []
    this.selectedProduct.forEach(product => {
      finalProducts.push(product._id)
    });
    return finalProducts
  }

  discountMapperForBackend(type) {
    let discountObject = this[type].value

    discountObject['schedule_from'] = (this.combineDateAndTime(discountObject['schedule_from_date'], discountObject['schedule_from_time'])).toString()
    discountObject['schedule_to'] = (this.combineDateAndTime(discountObject['schedule_to_date'], discountObject['schedule_to_time'])).toString()
    discountObject['is_active'] = Is_Active.Enabled
    discountObject['purchaseAmountQuantity'] = Number(discountObject['purchaseAmountQuantity'])
    discountObject['customer_since'] = discountObject['is_customer_since'] ? discountObject['customer_since'].toString() : null
    discountObject['new_customer_register_after_date'] = !this[type].controls.is_new_customer_only.value ? null : discountObject['new_customer_register_after_date'].toString()
    discountObject['spent_amount'] = parseFloat(discountObject['spent_amount'])
    discountObject['discount_amount'] = parseFloat(discountObject['discount_amount'])
    discountObject['number_of_usage_per_customer'] = Number(discountObject['number_of_usage_per_customer'])
    discountObject['schedule_from'] = !this[type].controls.can_schedule.value ? null : discountObject['schedule_from']
    discountObject['schedule_to'] = !this[type].controls.can_schedule.value ? null : discountObject['schedule_to']
    discountObject['spent_amount'] = !this[type].controls.is_customer_spent_amount.value ? null : discountObject['spent_amount']
    discountObject['new_customer_register_after_date'] = !this[type].controls.is_new_customer_only.value ? null : discountObject['new_customer_register_after_date']
    discountObject['customer_since'] = !this[type].controls.is_customer_since.value ? null : discountObject['customer_since']
    discountObject['status'] = Status.Active

    delete discountObject['schedule_from_date']
    delete discountObject['schedule_to_date']
    delete discountObject['schedule_from_time']
    delete discountObject['schedule_to_time']

    discountObject = this.cleanObject(discountObject)


    console.log('final object is', discountObject);
    return discountObject
  }

  combineDateAndTime = function (date, time) {
    var timeString = time.getHours() + ':' + time.getMinutes() + ':00';

    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Jan is 0, dec is 11
    var day = date.getDate();
    var dateString = '' + year + '-' + month + '-' + day;
    var combined = new Date(dateString + ' ' + timeString);

    return combined;
  };

  cleanObject(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '' || obj[propName] == NaN) {
        delete obj[propName];
      }
    }
    return obj
  }
  showProductList = false
  closeDropDowns() {
    this.showCampaignsList = false
    this.showCustomerTagList = false
    this.showDiscountTagList = false
    this.showStoreList = false
    this.showProductList = false
  }

  onChangeScreenClick(screen) {
    switch (screen) {
      case 'discount_detail': {
        document.getElementById("discount_detail-link").click()
        break
      }
      case 'customer': {
        document.getElementById("customer-link").click()
        break
      }
      case 'discount_limit': {
        document.getElementById("discount_limit-link").click()
        break
      }
      case 'discount_code': {
        document.getElementById("discount_code-link").click()
        break
      }
    }
  }

  showToast = false
  toasterFunction(message, status) {
    this.showToast = true
    this.hideToaster = false
    this.toasterMsg = message
    this.toasterType = status
  }

  closeToaster() {
    this.showToast = false
    this.hideToaster = true
  }

}
