import { Injectable } from '@angular/core';
import { CreateUpdateMultiStoreCustomFieldGQL, CreateUpdateStoreCustomFieldGQL, 
  GetStoreCustomFieldsGQL, DeleteStoreCustomFieldGQL } from "src/app/generated/graphql"

@Injectable({
  providedIn: 'root'
})
export class QuestionGqlService {

  constructor(private createUpdateMultiStoreCustomFieldGQL: CreateUpdateMultiStoreCustomFieldGQL,
    private createUpdateStoreCustomFieldGQL: CreateUpdateStoreCustomFieldGQL,
    private getStoreCustomFieldsGQL: GetStoreCustomFieldsGQL,
    private deleteStoreCustomFieldGQL: DeleteStoreCustomFieldGQL) { }

  createUpdateMultiStoreCustomField(customFieldTypeEnum, multiCustomFieldTypeInput, location_id) {
    return this.createUpdateMultiStoreCustomFieldGQL.mutate({
      customFieldType: customFieldTypeEnum,
      location_id: location_id,
      input: multiCustomFieldTypeInput
    })
  }

  createUpdateStoreCustomField(customFieldTypeEnum, CustomFieldTypeInput, customFieldId, location_id) {
    return this.createUpdateStoreCustomFieldGQL.mutate({
      customFieldType: customFieldTypeEnum,
      location_id: location_id,
      _id: customFieldId,
      input: CustomFieldTypeInput
    })
  }

  getStoreCustomFields(customFieldTypeEnum, location_id){
    return this.getStoreCustomFieldsGQL.watch({
      customFieldType: customFieldTypeEnum,
      location_id: location_id
    }).valueChanges
  }

  deleteStoreCustomField(customFieldId,customFieldType,location_id){
    return this.deleteStoreCustomFieldGQL.mutate({
      customFieldId:customFieldId,
      customFieldType:customFieldType,
      location_id: location_id
    })
  }

  



}
