import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AllowedOrdertStatus, AllowedTransactionStatus, AllowedTransactionType, ChangePoStatusGQL, GetProductsWithSupplierWiseAndSearchGQL } from 'src/app/generated/graphql';
import { GenericService } from '../stock/generic.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderMinimizerService {

  // Subjects /////
  private refreshData = new Subject<any>();
  refreshData$ = this.refreshData.asObservable();

  poDetail: any
  supplier: any
  shipment = {
    id: null,
    name: 'Other',
    price: 0,
    actualPrice: 0,
    threshold: 0
  }
  discount = {
    isPercentage: false,
    amount: null
  }
  taxes = []
  totalTax = 0
  transaction = null
  productsOrdered = []
  filters = null
  total = 0
  subTotal = 0

  constructor(
    private getProductsWithSupplierWiseAndSearch: GetProductsWithSupplierWiseAndSearchGQL,
    private changePOStatus: ChangePoStatusGQL,
    private genericService: GenericService,
    private router: Router,

    ) { }

  setSupplierDetail(supp) {
    this.supplier = supp
  }

  getSupplier() {
    return this.supplier
  }

  autoUpdate(bool) {
    this.refreshData.next(bool)
  }

  calculateTotalAmount() {
    let subTotal = 0
    this.total = 0
    for (let index = 0; index < this.productsOrdered.length; index++) {
      this.productsOrdered[index]['total'] = parseFloat((this.productsOrdered[index]['qty'] * this.productsOrdered[index]['average_cost']).toFixed(2));
      subTotal = subTotal + (this.productsOrdered[index]['qty'] * this.productsOrdered[index]['average_cost'])
    }
    this.subTotal = parseFloat(subTotal.toFixed(2));
    let discount = this.discount['amount'] == null ? 0 : this.discount['amount']
    let totalDiscount : any
    if(this.discount['isPercentage']) {
      totalDiscount = ((discount * this.subTotal) / 100).toFixed(2)
      // this.subTotal = this.subTotal - parseFloat(totalDiscount)
    } else {
      totalDiscount = parseFloat(discount)
      // this.subTotal = this.subTotal - parseFloat(discount)
    }
    if(this.shipment['name'] != 'Other' && this.shipment['threshold'] <= subTotal) {
      // this.shipment['price'] = 0
    } else {
      this.shipment['price'] = Number(this.shipment['actualPrice'])
    }
    this.total = this.subTotal + this.shipment['price'];
    this.total = this.total + this.totalTax;
    this.total = this.total - totalDiscount;
    this.poDetail['total_amount'] = this.total;
    this.poDetail['sub_total_amount'] = this.subTotal;
    this.poDetail['shipping_amount'] = this.shipment['price'];
    this.poDetail['ShippingType'] = this.shipment['name'] == 'Other' ? null : this.shipment['id'];
    this.poDetail['discount_amount'] = totalDiscount;
    this.poDetail['discount_value'] = discount;
    this.poDetail['is_discount_percentage'] = this.discount['isPercentage'];
    let taxes = [];
    for (let index = 0; index < this.taxes.length; index++) {
      let obj = {
        taxId: this.taxes[index]['_id'],
        tax_amount: parseFloat(this.taxes[index]['amount'])
      }
      taxes.push(obj)
    }
    this.poDetail['taxes'] = taxes
  }

  getDefaultObject() {
    return {
      transaction_status: AllowedTransactionStatus.Minimize,
      transaction_type: AllowedTransactionType.Purchase,
      order_status: AllowedOrdertStatus.Ordered,
      transaction_date: new Date(),
      transaction_hours: '02',
      transaction_minutes: '00',
      transaction_am_pm: 'AM',
      discount_amount: 0,
      discount_value: 0,
      is_discount_percentage: true,
      total_amount: 0,
      sub_total_amount: 0,
      shipping_amount: 0,
      BusinessLocation: localStorage.getItem('location_id'),
      TransactionPurchaseLines: [],
      ShippingType: null,
      is_private: false,
      additional_notes: '',
      taxes: [],
      // is_tax_percentage: true,
      // tax_amount: 0,
      // tax_value: 0,
      route_url: 'Pointofsale/Stock/Orders/PurchaseOrder'
    }
  }

  setObjectsToDefault() {
    this.taxes = []
    this.productsOrdered = []
    this.total = 0
    this.subTotal = 0
    this.totalTax = 0
    this.shipment = {
      id: null,
      name: 'Other',
      price: 0,
      actualPrice: 0,
      threshold: 0
    }
    this.discount = {
      isPercentage: false,
      amount: null
    }
  }

  setPOdata(po) {
    this.setObjectsToDefault()
    this.poDetail = {
      transaction_status: AllowedTransactionStatus.Minimize,
      transaction_type: AllowedTransactionType.Purchase,
      order_status: AllowedOrdertStatus.Ordered,
      transaction_date: po.transaction_date,
      transaction_hours: po.transaction_hours,
      transaction_minutes: po.transaction_minutes,
      transaction_am_pm: po.transaction_am_pm,
      is_private: po.is_private,
      additional_notes: po.additional_notes,
      discount_amount: parseFloat(po.discount_amount),
      discount_value: parseFloat(po.discount_value),
      is_discount_percentage: po.is_discount_percentage,
      total_amount: po.total_amount,
      sub_total_amount: po.sub_total_amount,
      shipping_amount: po.shipping_amount,
      BusinessLocation: localStorage.getItem('location_id'),
      Supplier: po.Supplier == null ? null : po.Supplier.supplier_company == 'Unknown Supplier' ? null : po.Supplier._id,
      ShippingType: po.ShippingType == null ? null : po.ShippingType._id,
      taxes: [],
      // Tax: po.Tax == null ? null : po.Tax._id,
      // is_tax_percentage: po.is_tax_percentage,
      // tax_amount: po.tax_amount,
      // tax_value: po.tax_value,
      route_url: po.route_url
    }
    this.discount['amount'] = po.is_discount_percentage ? po.discount_value : parseFloat(po.discount_amount)
    this.discount['isPercentage'] = po.is_discount_percentage
    if(po.ShippingType != null) {
      this.shipment['id'] = po.ShippingType._id
      this.shipment['name'] = po.ShippingType.shipment_name
      this.shipment['price'] = po.ShippingType.shipment_price
      this.shipment['actualPrice'] = po.ShippingType.shipment_price
      this.shipment['threshold'] = po.ShippingType.threshold
    } else {
      this.shipment['id'] = null
      this.shipment['name'] = 'Other'
      this.shipment['price'] = po.shipping_amount
      this.shipment['actualPrice'] = po.shipping_amount

    }
    this.setOrderedProducts(po)
    po.taxes == null ? '' : this.setTaxData(po.taxes)
    this.calculateTotalAmount()
  }

  setOrderedProducts(po) {
    this.productsOrdered = []
    for (let index = 0; index < po.TransactionPurchaseLine.length; index++) {
      let productToAdd = {
        product: po.TransactionPurchaseLine[index]['Product']['_id'],
        average_cost: po.TransactionPurchaseLine[index]['product_cost_price'],
        suggested_quantity: po.TransactionPurchaseLine[index]['suggested_qty'],
        name: po.TransactionPurchaseLine[index]['Product']['product_name'],
        sku: po.TransactionPurchaseLine[index]['stock_sku_number'],
        supplier: po['Supplier'],
        qty: po.TransactionPurchaseLine[index]['quantity'],
        total: po.TransactionPurchaseLine[index]['total_amount']
      }
      this.productsOrdered.push(productToAdd)
    }
    console.log('products to order are ', this.productsOrdered);
    this.calculateTotalAmount()
  }

  setTaxData(taxes) {
    for (let index = 0; index < taxes.length; index++) {
      let obj = {
        _id: taxes[index]['taxId']['_id'],
        name: taxes[index]['taxId']['name'],
        amount: taxes[index]['tax_amount']
      }
      this.taxes.push(obj)
    }
    this.calculateTotalTax();
  }

  getUpdatedPO() {
    return this.poDetail
  }

  getAllProductsBySupplier(data) {
    return this.getProductsWithSupplierWiseAndSearch.watch(data).valueChanges
  }

  getPurchaseLineItems() {
    return new Promise(res => {
      let orderProductList = []
      for (let index = 0; index < this.productsOrdered.length; index++) {
        let obj = {
          Product: this.productsOrdered[index]['product'],
          skuProductDetail: {
            sku_number: this.productsOrdered[index]['sku'],
            quantity: parseInt(this.productsOrdered[index]['qty']),
            product_cost_price: parseFloat(this.productsOrdered[index]['average_cost']),
            suggested_quantity: parseFloat(this.productsOrdered[index]['suggested_qty']),
            total_amount: parseFloat(this.productsOrdered[index]['total'])
          }
        }
        orderProductList.push(obj)
      }
      res(orderProductList)
    })
  }

  calculateTotalTax() {
    this.totalTax = 0
    for (let index = 0; index < this.taxes.length; index++) {
      this.totalTax = parseFloat(this.totalTax.toString()) + parseFloat(this.taxes[index]['amount'])
    }
    if(this.taxes.length == 0) {
      this.totalTax = 0
    }
    this.calculateTotalAmount()
  }

  updatePOStatus(id) {
    return this.changePOStatus.mutate({
      id: id,
      location_id: localStorage.getItem('location_id')
    }).subscribe(
      (res)=>{
        if(res['data'].changePOStatus.status_updated) {
          localStorage.setItem("po", res['data'].changePOStatus.total_minimizers.toString());
          this.router.navigateByUrl('Pointofsale/Stock/Orders/PurchaseOrder');
          return
        }
        this.genericService.showToaster(['Please close one tab before proceeding', 'error'])
      }, (err)=>{
        this.genericService.showToaster(['Please close one tab before proceeding', 'error'])
      }
    )
  }

}
