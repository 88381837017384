import { Injectable } from '@angular/core';
import { ArchiveToActiveSupplierGQL, CashRegistersOfLocationGQL, CompareSupplierWithSystemGQL, CreateShippingTypeGQL, CreateSupplierGQL, CreateSupplierNettermGQL, CreateSupplierNetTermPaymentGQL, DeleteShippingTypeGQL, GetAllShippingTypeGQL, GetCountriesGQL, GetCreditLineGQL, GetSupplierNettermLogsGQL, GetTimeZonesGQL, ImportSuppliersDataGQL, PermanentDeleteSupplierGQL, RemovedMultiSuppliersGQL, RemovedSupplierGQL, SearchSupplierGQL, SupplierByIdGQL, SupplierNetTerm, SupplierNetTermGQL, SupplierNetTermRecordGQL, SupplierPaymentSettingListGQL, SupplierPaymentSettingsGQL, SupplierPoListGQL, SuppliersCheckDuplicationDataGQL, SupplierSummaryGQL, SuppliersVerificationWithSystemGQL, UpdateShippingTypeGQL, UpdateSupplierGQL } from 'src/app/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class ManageSupplierService {

  constructor(
    private searcingAndListingSupplier: SearchSupplierGQL,
    private removedMultiSuppliers: RemovedMultiSuppliersGQL,
    private removedSupplier: RemovedSupplierGQL,
    private archiveToActiveSupplier: ArchiveToActiveSupplierGQL,
    private supplierById: SupplierByIdGQL,
    private supplierSummaryGQL: SupplierSummaryGQL,
    private supplierPaymentSettingListGQL: SupplierPaymentSettingListGQL,
    private supplierPaymentSettingsGQL: SupplierPaymentSettingsGQL,
    private supplierPoListGQL: SupplierPoListGQL,
    private supplierNetTerm: SupplierNetTermGQL,
    private getSupplierNettermLogs: GetSupplierNettermLogsGQL,
    private getAllShippingType: GetAllShippingTypeGQL,
    private permanentDeleteSupplier: PermanentDeleteSupplierGQL,
    private createShippingType: CreateShippingTypeGQL,
    private updateShippingType: UpdateShippingTypeGQL,
    private deleteShippingType: DeleteShippingTypeGQL,
    private getTimeZones: GetTimeZonesGQL,
    private createSupplierNetterm: CreateSupplierNettermGQL,
    private cashRegistersOfLocationGQL: CashRegistersOfLocationGQL,
    private getCreditLineGQL: GetCreditLineGQL,
    private supplierNetTermRecord: SupplierNetTermRecordGQL,
    private createSupplierNetTermPayment: CreateSupplierNetTermPaymentGQL,
    private getCountriesGQL: GetCountriesGQL,
    private compareSupplierWithSystem: CompareSupplierWithSystemGQL,
    private createSupplierGQL: CreateSupplierGQL,
    private updateSupplier: UpdateSupplierGQL,
    private suppliersCheckDuplicationData: SuppliersCheckDuplicationDataGQL,
    private suppliersVerificationWithSystem: SuppliersVerificationWithSystemGQL,
    private importSuppliersData: ImportSuppliersDataGQL

  ) { }

  getAllSuppliers(obj) {
    return this.searcingAndListingSupplier.watch(obj).valueChanges
  }

  getSupplierByID(id) {
    return this.supplierById.watch({
      id: id,
      location_id: localStorage.getItem('location_id')
    }).valueChanges
  }

  deleteMultiSuppliers(id) {
    return this.removedMultiSuppliers.mutate({ id: id })
  }

  deleteSingleSupplier(id) {
    return this.removedSupplier.mutate({
      id: id
    })
  }

  activeSupplier(id) {
    return this.archiveToActiveSupplier.mutate({
      id: id
    })
  }

  getSupplierSummary(id) {
    return this.supplierSummaryGQL.watch({
      supplier_id: id
    }).valueChanges
  }

  getSupplierPaymentMethods(id) {
    return this.supplierPaymentSettingListGQL.watch({
      supplier_id: id
    }).valueChanges
  }

  updateSupplierPaymentMethod(id, method, bool) {
    return this.supplierPaymentSettingsGQL.mutate({
      is_active: bool,
      payment_id: method['paymentType']['_id'],
      supplier_id: id
    })
  }

  getSupplierOrders(obj) {
    return this.supplierPoListGQL.watch({
      input: JSON.parse(JSON.stringify(obj))
    }).valueChanges
  }

  getSupplierNettermLogsFun(id) {
    return this.supplierNetTerm.watch({
      supplier_id: id,
      skip: 0,
      limit: 100000,
      location_id: window.localStorage.getItem('location_id')
    }).valueChanges
  }

  getNettermOrders(id) {
    return this.getSupplierNettermLogs.watch({
      supplierId: id,
      skip: 0,
      limit: 100000
    }).valueChanges
  }

  getSupllierShippingType(skip, limit, id, isActive = true) {
    return this.getAllShippingType.watch({
      location_id: window.localStorage.getItem('location_id'),
      skip: skip,
      limit: limit,
      supplierID: id,
      isActive: isActive
    }).valueChanges
  }

  deleteSupplier(id) {
    return this.removedSupplier.mutate({
      id: id
    })
  }

  deletePermanentSupplierFun(id) {
    return this.permanentDeleteSupplier.mutate({
      id: id
    })
  }

  createShippingMethod(data) {
    return this.createShippingType.mutate({
      input: data
    })
  }

  updateShippingMethod(id, data) {
    return this.updateShippingType.mutate({
      id: id,
      input: data
    })
  }

  deleteShippingMethod(id, archive = true) {
    return this.deleteShippingType.mutate({
      id: id,
      isActive: archive
    })
  }

  getTimeZonesMethod() {
    return this.getTimeZones.watch().valueChanges
  }

  createSupplierNetTerm(data) {
    return this.createSupplierNetterm.mutate({
      input: data
    })
  }

  getSupplierNetTermDetail(id) {
    return this.supplierNetTerm.watch({
      supplier_id: id,
      skip: 0,
      limit: 100000,
      location_id: window.localStorage.getItem('location_id')
    }).valueChanges
  }

  getCashRegistersOfLocation() {
    return this.cashRegistersOfLocationGQL.watch({
      ID: localStorage.getItem('location_id')
    }).valueChanges
  }

  getAllCreditLines() {
    return this.getCreditLineGQL.watch().valueChanges
  }

  supplierNettermRecord(id) {
    return this.supplierNetTermRecord.watch({
      supplier_id: id,
      skip: 0,
      limit: 100000,
      location_id: window.localStorage.getItem('location_id')
    }).valueChanges
  }

  recordNetTermPayment(data) {
    return this.createSupplierNetTermPayment.mutate({
      input: data
    })
  }

  getAllCountries() {
    return this.getCountriesGQL.watch().valueChanges
  }

  compareSupplier(data) {
    return this.compareSupplierWithSystem.watch({
      input: data
    }).valueChanges
  }

  createSupplier(data) {
    return this.createSupplierGQL.mutate({
      input: data
    })
  }

  updateSupplierMutation(id, supplier) {
    return this.updateSupplier.mutate({
      id: id,
      input: supplier
    })
  }

  checkSupplierDuplicationData(data) {
    return this.suppliersCheckDuplicationData.watch(data).valueChanges
  }

  supplierVerification(data) {
    return this.suppliersVerificationWithSystem.watch(data).valueChanges
  }

  importSuppliersDataMethod(data) {
    return this.importSuppliersData.mutate(data)
  }

}
