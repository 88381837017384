import { Injectable } from '@angular/core';
import { AddProductToUnAvailableGQL, ArchiveProductLogGQL, AssignPoNumberToDamageProductGQL, CreateShelfGQL, DeleteShelfGQL, GetAllProductLogsGQL, GetAllShelfsGQL, GetArchiveTrackingGQL, 
  GetDamageTrackingGQL, GetProductsListingWithSupplierWiseAndSearchGQL, GetReasonsGQL, GetRmaTrackingGQL, GetUnavailableProductsAndSearchGQL, MoveProductUnavailableToInventoryGQL, 
  UpdateShelfGQL, GetDeviceSerialNumbersGQL } from 'src/app/generated/graphql';
import { GenericUtility } from 'src/app/utilties/generic-utility';

@Injectable({
  providedIn: 'root'

})
export class ProductsService {
  inventoryList: any = []
  constructor(
    private _serviceCaller: GenericUtility,
    private getReasonsGQL: GetReasonsGQL,
    private addProductToUnAvailableGQL: AddProductToUnAvailableGQL,
    private getAllShelfsGQL: GetAllShelfsGQL,
    private createShelfGQL: CreateShelfGQL,
    private updateShelfGQL: UpdateShelfGQL,
    private deleteShelfGQL: DeleteShelfGQL,
    private getUnavailableProductsAndSearchGQL: GetUnavailableProductsAndSearchGQL,
    private moveProductUnavailableToInventoryGQL: MoveProductUnavailableToInventoryGQL,
    private getProductsListingWithSupplierWiseAndSearchGQL: GetProductsListingWithSupplierWiseAndSearchGQL,
    private getRmaTackingGQL: GetRmaTrackingGQL,
    private assignPoNumberToDamageProductGQL : AssignPoNumberToDamageProductGQL,
    private getDamageTrackingGQL : GetDamageTrackingGQL,
    private getArchiveTrackingGQL : GetArchiveTrackingGQL,
    private getAllProductLogsGQL : GetAllProductLogsGQL,
    private archiveProductLogGQL : ArchiveProductLogGQL,
    private getDeviceSerialNumbersGQL : GetDeviceSerialNumbersGQL

  ) { }

  setInventoryState(list) {
    this.inventoryList = list;
  }

  getInventoryState() {
    return this.inventoryList;
  }

  getReasons(reason_type) {
    return this.getReasonsGQL.watch({ reason_type: reason_type }).valueChanges;
  }

  getDeviceSerialNumbers(input) {
    return this.getDeviceSerialNumbersGQL.watch({input: input}).valueChanges;
  }

  addProductToUnAvailable(input) {
    return this.addProductToUnAvailableGQL.mutate({ input: input })
  }

  getAllShelfs() {
    return this.getAllShelfsGQL.watch({}).valueChanges
  }

  createShelf(input) {
    return this.createShelfGQL.mutate({ input: input });
  }

  updateShelf(id, input) {
    return this.updateShelfGQL.mutate({ updateShelfId: id, input: input })
  }

  deleteShelf(id) {
    return this.deleteShelfGQL.mutate({ deleteShelfId: id });
  }

  getUnavailableProductsAndSearch(input) {
    return this.getUnavailableProductsAndSearchGQL.watch({ input: input }).valueChanges;
  }

  moveProductUnavailableToInventory(id) {
    return this.moveProductUnavailableToInventoryGQL.mutate({ moveProductUnavailableToInventoryId: id });
  }

  getProductsListingWithSupplierWiseAndSearch(input) {
    return this.getProductsListingWithSupplierWiseAndSearchGQL.watch({ input: input }).valueChanges;
  }
  
  getRmaTacking(id) {
    return this.getRmaTackingGQL.watch({ productId: id }).valueChanges;
  }

  assignPoNumberToDamageProduct(poNumber, unavailableId){
    return this.assignPoNumberToDamageProductGQL.mutate({poNumber,unavailableId});
  }

  getDamageTracking(productId){
    return this.getDamageTrackingGQL.watch({productId}).valueChanges;
  }

  getArchiveTracking(productId){
    return this.getArchiveTrackingGQL.watch({productId}).valueChanges;
  }

  getAllProductLogs(locationId, productId){
    return this.getAllProductLogsGQL.watch({locationId:locationId, productId:productId}).valueChanges
  }

  archiveProductLog(id){
    return this.archiveProductLogGQL.mutate({id:id});
  }


}
