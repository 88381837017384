import { Component, OnInit, TemplateRef, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService, parseTriggers } from 'ngx-bootstrap';
import { UserPinCodeVerifyGQL, UserClockedInGQL } from "src/app/generated/graphql";
import { CommonService } from 'src/app/services/common/common.service';
import { ToasterService } from 'src/app/services/toaster/toaster.service';
import { LocalStoreService } from 'src/app/modules/shared/service/local-store.service';
import configuration from "src/app/modules/shared/helpers/configuration"
import {FooterServicesService} from 'src/app/services/footer/footer-services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, AfterViewInit {
  time = ''
  timer;
  hours = '00'
  min = '00'
  i = 0
  isCollapsed=true  
  statusValue=0
  //secret_key = 'MXCAN-60f0133de0acf';
  secret_key=''
  project = null
  projects=[]
  connectedProjects=[]
  boards=[]
  boards_original=[]
  posts=[]
  active_post=[]
  modules=[]
  modules_original=[]
  active_module = null
  mapped=[{}]
  errorMsg=''
  @ViewChild('open_signIn', { static: true }) template: ElementRef;
  year = new Date().getFullYear();
  keyError: string;
  constructor(
    private modalService: BsModalService,
    private verifyUserPinGQL: UserPinCodeVerifyGQL,
    private toaster: ToasterService,
    private userClockedInGQL: UserClockedInGQL,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    private ls : LocalStoreService,
    private footerService:FooterServicesService,
    private router: Router,
  ) { }

  modalRef: BsModalRef;
  pinBox = false;
  clockedIn = false
  clockedOut = false
  temp = false
  userName = ''
  riseAndShine = false

  ngOnInit() {
    this.userName = window.localStorage.getItem('userPDet')
    this.commonService.footerEmitter.subscribe((res)=>{
      console.log(res, 'ressss')
      this.openCustmChatBox();
    })
  }
  submitKey=false
  invalidKey=false
  showAlert(txt) {
    alert(txt)
  }
  connectCanny(template: TemplateRef<any>, cls){
    if(this.secret_key!=''){
      this.footerService.setConnection(this.secret_key).subscribe(
        (data)=>{
        
        let obj:any = data
        if(obj['status']==200) {
          this.modalRef = this.modalService.show(template, { class: 'modal-sm ' + cls });
          this.projects = obj.data
          this.footerService.connectedProjects(this.projects).subscribe((projects) => {
            if (projects["status"] == 200) {
              this.connectedProjects = projects["data"]
              this.projects.forEach(project => {
                this.connectedProjects.filter(p => p.project_id == project.id).length > 0 ? project["techbar_connected"] = true : project["techbar_connected"] = false                
              })
            }
          })

          console.log(this.projects, "project-list")
        }
        if(obj['status']==500){
          this.invalidKey=true
          this.keyError='You enter invalid key!'
          console.log('Canny connection respose status is',data);
        }
      }, (err)=>{
        console.log('Canny connection respose err is',err);
      }
      );
     }else if(this.secret_key==''){
      this.submitKey=true
      this.errorMsg='Key is Required!'
     } 
  }

  unlinkProject(project) {
    this.footerService.unlinkProject(project).subscribe(
      (data)=>{
        if (data["status"] == 200) {
          project["techbar_connected"] = false
          this.connectedProjects.forEach((p, i) => {
            p.project_id == project.id || p.id == project.id ? this.connectedProjects.splice(i, 1) : null
          });
        }
        console.log(data, "unlink-project")
    }, (err)=>{
      console.log('Canny connection respose err is',err);
    })    
  }

  selectProject(project, template: TemplateRef<any>, cls){
    this.modalRef = this.modalService.show(template, { class: 'modal-sm ' + cls })
    this.footerService.getCannyBoards(project.id).subscribe(
      (data)=>{
        if (data["status"] == 200) {
          this.boards = data["data"]
          this.boards_original = JSON.parse(JSON.stringify(this.boards))
          this.project = project
        }
    }, (err)=>{
      console.log('Canny connection respose err is',err);
    })

    this.getTechbarModules((data) => {
      this.modules = data["data"]
      this.modules_original = JSON.parse(JSON.stringify(this.modules))
      if (this.modules.length > 0) {
        this.mapped = this.modules.filter(moudle => moudle.canny_board_id && moudle.canny_board_name)
        this.mapped.push({})
      }

    })


  }

  getTechbarModules(callback) {
    this.footerService.getTechbarModules().subscribe(
      (data)=>{
        if (data["status"] == 200) {
          callback(data)
        } else {
          console.log(data, "getTechbarModules Err!")
        }
    }, (err)=>{
      
      console.log('Canny connection respose err is',err);
    })    
  }

  connectProject() {

    if (this.boards_original.length > 0) {
      let project = {
        project_id: this.boards_original[0]["project"]["id"],
        business_id: localStorage.getItem("BusinessId"),
        store_id: localStorage.getItem("location_id"),
        secret_key: this.secret_key,
        organization_domain_name: "dummy_for_now",
        user_id: "5f7af7a656d45b4d18412b54"
      } 

      this.footerService.connectProject(project).subscribe(
        (data)=> {
          console.log(data, "project-connected")
          console.log(this.mapped, "mapped-boards")
          this.footerService.linkModules(this.mapped).subscribe((data) => {
            console.log(data, "linked_modules_data")
            if (this.connectedProjects.length == 0) {
              this.project["techbar_connected"] = true
              this.connectedProjects.push(this.project)
            }
            this.modalRef.hide()
          })
      }, (err)=>{
        console.log('Canny connection respose err is',err);
      })         
  
    }

  }

  // unamed(project, template: TemplateRef<any>, cls){
  //   this.modalRef = this.modalService.show(template, { class: 'modal-sm ' + cls })
  //   let obj = {
  //     project_id: project.id,
  //     business_id: localStorage.getItem("BusinessId"),
  //     store_id: localStorage.getItem("location_id"),
  //     secret_key: this.secret_key,
  //     organization_domain_name: project.organization.domain_name,
  //     user_id: "5f7af7a656d45b4d18412b54"
  //   }
  //   this.footerService.connectProject(obj).subscribe(
  //     (data)=>{
  //       if (data["status"] == 200) {
  //         this.boards = data["data"]["board"]
  //         this.boards_original = JSON.parse(JSON.stringify(this.boards))
  //         console.log(this.boards, "data")
  //       }
  //   }, (err)=>{
  //     console.log('Canny connection respose err is',err);
  //   })

  //   this.footerService.getTechbarModules().subscribe(
  //     (data)=>{
  //       if (data["status"] == 200) {
  //         this.modules = data["data"]
  //         this.modules_original = JSON.parse(JSON.stringify(this.modules))
  //       }
  //   }, (err)=>{
  //     console.log('Canny connection respose err is',err);
  //   })

  // }

  linkCannyBoardToTechbarModule(ev, mapped, type) {

    console.log(ev, "evn")

    if (ev._id) {
      mapped[type] = ev.module_name
      mapped["_id"] = ev._id
    } else {
      mapped[type] = ev.id
      mapped["canny_board_name"] = ev.name
    }
    
    let len = this.mapped.length
    mapped["canny_board_id"] && mapped["module_name"] && (this.mapped[len-1]["canny_board_id"] && this.mapped[len-1]["module_name"]) ? this.mapped.push({}) : null

    this.boards = JSON.parse(JSON.stringify(this.boards_original))
    this.modules = JSON.parse(JSON.stringify(this.modules_original))

    this.mapped.forEach(m => {
      if (m["canny_board_id"]) {
        this.boards = this.boards.filter(board => board.id != m["canny_board_id"])
      }
      if (m["module_name"]) {
        this.modules = this.modules.filter(module => module.module_name != m["module_name"])
      }
    })
  }

  findCannyBoardByRouterURL(url) {
    this.getTechbarModules(data => {
      let module = null,
        techbar_modules = data["data"]
        this.posts = []
        this.active_module = null

      switch(url) {
        case "/Pointofsale/Home":
          module = techbar_modules.filter(module => module.module_name.toUpperCase() == "HOME")
          break        
        case "/Pointofsale/CreateSale/Order":
          module = techbar_modules.filter(module => module.module_name.toUpperCase() == "CREATE A SALE")
          break             
        case "/Pointofsale/Tickets":
          module = techbar_modules.filter(module => module.module_name.toUpperCase() == "TICKETS")
          break      
        case "/Pointofsale/RepairRoom":
          module = techbar_modules.filter(module => module.module_name.toUpperCase() == "REPIAR ROOM")
          break      
        case "/Pointofsale/Settings/user":
          module = techbar_modules.filter(module => module.module_name.toUpperCase() == "EMPLOYEE MANAGEMENT")
          break
      }

      if (module && module.length > 0) {
        module = module[0]
        if (module.canny_board_id) {
          this.active_module = module
          this.footerService.getCannyPosts({user_id: localStorage.getItem("location_id"), board_id: module.canny_board_id}).subscribe(
          (data)=>{
            if (data["status"] == 200) {
              this.posts = data["data"]
              this.active_post = this.posts[0]
              
              this.posts.forEach(post => {
                post["comments_"] = post.comments.filter(comment => comment.comment_id == -1)
                post.comments_.forEach(comment => {
                  comment["comments"] = post.comments.filter(c => c.comment_id == comment.id)
                })
              });

              console.log(this.posts, "posts")

            } else {
              console.log(data, "getCannyPosts Err!")
            }
            console.log(data, "getCannyPosts")
          }, 
          (err) => console.log('Canny connection respose err is',err))           
        } else {
          // alert("This module is not mapped yet, please map this module to canny specified board and then come back")
        }
      } else {
        // alert("Please add entry of this module")
      }
      // let 
      // let module = techbar_modules.filter(module => module.module_path.toLowerCase().includes(url.toLowerCase()))
      // console.log(module, techbar_modules, "user is present in this module", url)  
    })
  }
  
  
  ngAfterViewInit() {
    let check = window.localStorage.getItem('StartModel')
    // if (check == null && (this.employeeClockIn && this.employeeClockIn['value'] == 'true')) {
      // this.modalRef = this.modalService.show(this.template, { class: 'modal-sm ' + 'box-clockInPin', backdrop: 'static', keyboard: false });
      // this.openpinBox()
    //   this.pinBox = true
    //   this.riseAndShine = true
    //   this.changeDetectorRef.detectChanges()

    // }
    localStorage.setItem("StartModel", 'No');
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  openpinBox() {
    this.clockInError = false
    this.riseAndShine = false
    this.Pin = ''
    if (!this.pinBox && (this.clockedIn || this.clockedOut)) {
      this.pinBox = false
      this.clockedIn = false
      this.clockedOut = false
    }
    else {
      this.pinBox = !this.pinBox
    }
  }

  Pin = '';

  handleInput(val) {
    if (this.Pin.length < 6) {
      this.Pin += val
    }
  }

  clockInData = {};
  clockInError = false;
  verifyPinCode() {
    if (this.Pin === '') {
      this.pinBox = true
      // this.toaster.showError('Please enter PIN', 'PIN Empty')
    } else {
      this.verifyUserPinGQL.mutate({
        pincode: this.Pin
      }).subscribe(
        (res) => {
          this.clockInError = false
          clearInterval(this.timer);
          this.time = ''
          this.clockInData = res['data'].userPinCodeVerify
          if (res['data'].userPinCodeVerify.oldClockHistoryId == null) {
            this.pinBox = false
            this.clockedIn = true
            this.clockedOut = false
            // this.toaster.showInfo('Please clock in', 'Clock in')
          }
          else {
            let userTime = res['data'].userPinCodeVerify.clocked_in_time.split(':')
            this.hours = userTime[0].toString()
            this.min = userTime[1].toString()
            this.i = Number(userTime[2])
            this.pinBox = false
            this.clockedIn = false
            this.clockedOut = true
            this.startTimer()
            // this.toaster.showInfo('You are already clocked in', 'Clock in')
          }
          this.userName = res['data'].userPinCodeVerify.User.first_name + ' ' + res['data'].userPinCodeVerify.User.last_name
          console.log("the rses data is", res['data']);
        }, (err) => {
          console.log("the err data is", err);
          this.clockInError = true
          // this.toaster.showError('Please enter correct PIN', 'PIN Incorrect')
        });
    }
  }

  userClockIn() {
    this.userClockedInGQL.mutate({
      businessLocation: window.localStorage.getItem('location_id'),
      userClockInPin: this.clockInData['UserClockInPin'],
      userId: this.clockInData['User']['id']
    }).subscribe(
      (res) => {
        clearInterval(this.timer);
        this.time = ''
        console.log('clocked in res', res['data'].userClockedIn);
        let userTime = res['data'].userClockedIn.clock_in_time.split(':')
        this.hours = userTime[0].toString()
        this.min = userTime[1].toString()
        this.i = Number(userTime[2])
        this.startTimer()
        this.clockedIn = false
        this.clockedOut = true
        // this.toaster.showSuccess('Successfully cloked in', 'Clocked IN')
        this.commonService.setFotterItem(true)
      }, (err) => {
        console.log('clocked in err', err);
        // this.toaster.showError('Something went wrong while cloked in', 'Clocked IN')
      }
    )
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.i = this.i + 1
      if (this.i >= 60) {
        this.min = Number(this.min) < 10 ? '0' + (Number(this.min) + 1).toString() : (Number(this.min) + 1).toString()
        this.i = 0
        if (Number(this.min) >= 60) {
          this.hours = Number(this.hours) < 10 ? '0' + (Number(this.hours) + 1).toString() : (Number(this.hours) + 1).toString()
          this.min = '00'
        }
      }
      this.time = this.hours + ':' + this.min + ':' + this.i
    }, 1000);
  }

  userClockOut() {
    this.pinBox = false
    this.clockedIn = false
    this.clockedOut = true
  }

  userClockOutNow() {
    this.userClockedInGQL.mutate({
      businessLocation: localStorage.getItem('location_id'),
      oldClockHistoryId: this.clockInData['oldClockHistoryId'],
      userClockInPin: this.clockInData['UserClockInPin'],
      userId: this.clockInData['User']['id']
    }).subscribe(
      (res) => {
        this.pinBox = false
        this.clockedIn = false
        this.clockedOut = false
        this.hours = '00'
        this.min = '00'
        this.time = ''
        this.i = 0
        clearInterval(this.timer);
        console.log('clock out res', res['data']);
        this.commonService.setFotterItem(true)
      }, (err) => {
        console.log('clock out err', err);
      }
    )
  }

  deleteOndeDigit() {
    this.Pin = this.Pin.slice(0, this.Pin.length - 1)
  }

  openModal(template: TemplateRef<any>, cls) {
      this.modalRef = this.modalService.show(template, { class: 'modal-sm ' + cls });
      this.findCannyBoardByRouterURL(this.router.url)
    }

  openCalculator(template: TemplateRef<any>, cls) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm ' + cls, backdrop: false });
  }

  closeModel() {
    this.modalRef.hide();
  }

  closeClockInModel() {
    // this.modalRef.hide();
    this.Pin = ''
    this.pinBox = false
    this.clockedIn = false
    this.clockedOut = false
  }

  togglePassword(event, span) {
    if (event.type === 'password') {
      event.type = 'text';
      span.target.className = 'fa fa-fw fa-eye-slash field-icon';
    } else {
      event.type = 'password';
      span.target.className = 'fa fa-fw fa-eye field-icon';
    }

  }
  selectRoleUser = true
  selectRolesList = false
  permissionBox = false
  enablePermissionBox() {
    this.modalRef.hide();
    this.permissionBox = true
  }
  savePermissionBox() {
    this.permissionBox = false
  }
  selectRolesListBtn() {
    this.selectRoleUser = false
    this.selectRolesList = true
  }
  backtoselectRoleUser() {
    this.selectRolesList = false
    this.selectRoleUser = true
  }
  boxCalculator = false
  openCalculatorBox() {
    this.boxCalculator = !this.boxCalculator
  }

  angularCalculator = false
  openAngularCalculator() {
    this.angularCalculator = !this.angularCalculator
  }

  closeCalculatorBox() {
    this.boxCalculator = false;
  }
  boxChat = false
  chatBox = false;
  boxFaqMain = true
  boxFaqDetail = false
  boxConvoIcon = false
  openChatBox() {
    this.boxChat = !this.boxChat
  }
  openCustmChatBox() {
    this.chatBox = !this.chatBox
  }
  closeCustomChatBox() {
    this.chatBox = false
  }
  closechatBox() {
    this.boxChat = false
  }
  openFaqDetail() {
    this.boxFaqMain = false
    this.boxFaqDetail = true
    this.boxConvoIcon = false
  }
  backtoFaqMain() {
    this.boxFaqMain = true
    this.boxFaqDetail = false
    this.boxConvoIcon = false
  }
  openConvo() {
    let convo: HTMLElement = document.getElementById("boxConvoid") as HTMLElement
    convo.click()
    this.boxConvoIcon = true
  }
  hideFooterToggle(any, foot) {

    if (foot == "foot") {
      if (document.getElementById("hiderArea").className == "")
        document.getElementById("hiderArea").className = "hidefooter hideheader";
      else
        document.getElementById("hiderArea").className = "";
    }
  }





  input: string = '';
  result: string = '';
  pressNum(num: string) {
    
    //Do Not Allow . more than once
    if (num==".") {
      if (this.input !="" ) {
 
        const lastNum=this.getLastOperand()
        console.log(lastNum.lastIndexOf("."))
        if (lastNum.lastIndexOf(".") >= 0) return;
      }
    }
 
    //Do Not Allow 0 at beginning. 
    //Javascript will throw Octal literals are not allowed in strict mode.
    if (num=="0") {
      if (this.input=="" ) {
        return;
      }
      const PrevKey = this.input[this.input.length - 1];
      if (PrevKey === '/' || PrevKey === '*' || PrevKey === '-' || PrevKey === '+')  {
          return;
      }
    }
 
    this.input = this.input + num
    this.calcAnswer();
  }
 


  getLastNumber() {
    return this.input.charAt(this.input.length - 1)
  }

  getLastOperand() {
    let pos: number;
    console.log(this.input)
    pos = this.input.toString().lastIndexOf("+")
    if (this.input.toString().lastIndexOf("-") > pos) pos = this.input.lastIndexOf("-")
    if (this.input.toString().lastIndexOf("*") > pos) pos = this.input.lastIndexOf("*")
    if (this.input.toString().lastIndexOf("/") > pos) pos = this.input.lastIndexOf("/")
    console.log('Last ' + this.input.substr(pos + 1))
    return this.input.substr(pos + 1)
  }


  pressOperator(op: string) {
    //Do not allow operators more than once
    const lastKey = this.input[this.input.length - 1];
    if (lastKey === '/' || lastKey === '*' || lastKey === '-' || lastKey === '+') {
      return;
    }
    this.input = this.input + op
    this.calcAnswer();
  }


  clear() {
    if (this.input != "") {
      this.input = this.input.substr(0, this.input.length - 1)
    }
  }

  allClear() {
    this.result = '';
    this.input = '';
  }

  calcAnswer() {
    let formula = this.input;
    let lastKey = formula[formula.length - 1];
    if (lastKey === '.') {
      formula = formula.substr(0, formula.length - 1);
    }
    lastKey = formula[formula.length - 1];
    if (lastKey === '/' || lastKey === '*' || lastKey === '-' || lastKey === '+' || lastKey === '.') {
      formula = formula.substr(0, formula.length - 1);
    }
    console.log("Formula " + formula);
    this.result = eval(formula);
  }

  getAnswer() {
    this.calcAnswer();
    this.input = this.result;
    if (this.input == "0") this.input = "";
  }

}
