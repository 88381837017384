import { Injectable } from '@angular/core';
import { ImportStoreUsersGQL } from 'src/app/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class ManageUserService {

  constructor(private importStoreUsers: ImportStoreUsersGQL) { }

  importUsers(data) {
    return this.importStoreUsers.mutate(data)
  }

}
