import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable, Subject } from 'rxjs';
import { QuestionBase } from '../../core/question-base';
import { QuestionService } from "../../core/question.service"
import { v4 as uuidv4 } from 'uuid';
import { maxLength, RxwebValidators } from '@rxweb/reactive-form-validators';
import { element } from 'protractor';


enum customFieldTypeEnum {
  Customer = 'customer'
}

@Component({
  selector: 'app-dynamic-form-creation',
  templateUrl: './dynamic-form-creation.component.html',
  styleUrls: ['./dynamic-form-creation.component.css']
})

export class DynamicFormCreationComponent implements OnInit {

  @Output() event: EventEmitter<any> = new EventEmitter();
  @Input() formOptions

  form: FormGroup;
  loading = false;
  errorMessage: string | null;
  successMessage: string | null;
  error: boolean = false;
  success: boolean = false;
  id = null;
  edit = false;
  private unsubscribe: Subject<any>;
  submitted: boolean = false;
  questions$: Observable<QuestionBase<any>[]>;
  builderSortArray = []
  public activeTab: string;

  filedTypes = [{
    key: 'textbox',
    value: 'Text Box'
  }, {
    key: 'email',
    value: 'Email'
  }, {
    key: 'checkbox',
    value: 'Check Box'
  }, {
    key: 'select',
    value: 'Drop Down'
  }, {
    key: 'textarea',
    value: 'Text Area'
  }, {
    key: 'radio',
    value: 'Radio Button'
  }
    // , {
    //   key: 'file',
    //   value: 'File Upload'
    // }
  ]

  formElementEvenIndex = []
  formElementOddIndex = []

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private questionService: QuestionService,
    private bsModalRef: BsModalRef) {
  }

  ngOnInit() {
    // this.id = this.route.snapshot.paramMap.get("id");
    // this.edit = !!this.id;
    this.initForm();
    if (this.formOptions.systemRequiredFileds && this.formOptions.systemRequiredFileds.length > 0) {
      this.formOptions.systemRequiredFileds = this.sortedArray(this.formOptions.systemRequiredFileds)
    }
    this.getStoreCustomFields()
    this.onViewClick()
  }

  initForm() {
    const validation = {
      formElement: this.fb.array([]),
      location_id: ['', Validators.compose([Validators.required])],
      customFieldType: ['', Validators.compose([Validators.required])],
    };

    this.form = this.fb.group(validation);
    this.form.reset();
    this.addFormElement();
    const controls = this.form.controls;

  }

  formElementGroup(): FormGroup {
    const validation = {
      value: [''],
      key: [uuidv4()],
      label: ['', Validators.compose([Validators.required, RxwebValidators.unique(),
      Validators.maxLength(25), this.noWhitespaceValidator])],
      required: [true, Validators.compose([Validators.required])],
      controlType: [null, Validators.compose([Validators.required])],
      type: ['', Validators.compose([Validators.required])],
      options: this.fb.array([]),
      order: [this.formElement.length + 1],
      _id: [''],
      isOpen: [true]
    };
    return this.fb.group(validation)
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


  elementOptionGroup(): FormGroup {
    const validation = {
      value: ['', Validators.compose([Validators.required, RxwebValidators.unique(),
      Validators.maxLength(25), this.noWhitespaceValidator])],
      key: [uuidv4()],
    };
    return this.fb.group(validation)
  }

  get f() {
    return this.form.controls;
  }

  get formElement() {
    return this.form.get('formElement') as FormArray;
  }


  addFormElement() {
    (<FormArray>this.form.get('formElement')).push(this.formElementGroup())
  }

  addElementOption(index: number) {
    (<FormArray>this.elementOption(index)).push(this.elementOptionGroup())
  }

  elementOption(index: number): FormArray {
    return this.formElement.at(index).get("options") as FormArray
  }

  removeFormElement(index): void {
    if(this.formElement.at(index).value._id !== ''){
      this.deleteStoreCustomField(index)
    }
    this.formElement.removeAt(index);
  }
  


  removeElementOptionsAll(index) {
    while (this.elementOption(index).length !== 0) {
      this.elementOption(index).removeAt(0)
    }
  }

  duplicateFormElement(index): void {
    const newFormElement = this.formElement.value;
    newFormElement.splice(index, 0, (this.formElement).at(index).value);
    this.form.setControl('formElement', this.setFormElement(newFormElement))
  }

  removeElementOption(elementIndex, optionIndex): void {
    this.elementOption(elementIndex).removeAt(optionIndex)
  }

  moveElementUp(index: number) {
    if (index > 0) {
      const newFormElement = this.swap(this.formElement.value, index - 1, index);
      this.form.setControl('formElement', this.setFormElement(newFormElement))
    }
  }

  moveElementDown(index: number) {
    if (index < this.formElement.length - 1) {
      const newFormElement = this.swap(this.formElement.value, index, index + 1);
      this.form.setControl('formElement', this.setFormElement(newFormElement))
    }
  }


  orderSorting(event) {
    event.forEach((element, index) => {
      element.order = index + 1
    });
  }

  onViewClick() {
    let value = []
    const formValue = this.formElement.value

    this.formOptions.systemRequiredFileds.forEach(element => {
      value.push(element)
    });
    formValue.forEach(element => {
      value.push(element)
    });

    this.formElementEvenIndex = value.filter((v, index) => !(index % 2))
    this.formElementOddIndex = value.filter((v, index) => (index % 2))
  }

  onBuilderClick() {
    let mergeFormElement = this.mergeSortedArray(this.formElementEvenIndex, this.formElementOddIndex)
    mergeFormElement = mergeFormElement.filter(v => v.systemRequired == false || v.systemRequired == undefined)
    mergeFormElement.length ?
      this.form.setControl('formElement', this.setFormElement(mergeFormElement)) : ''
  }

  changeTab($event) {
    this.activeTab = $event.heading;
  }

  sortedArray(a) {
    var arr = a.sort(function (a, b) {
      return a.order - b.order;
    });
    return arr;
  }

  mergeSortedArray(a, b) {
    var arr = a.concat(b).sort(function (a, b) {
      return a.order - b.order;
    });
    return arr;
  }

  swap(arr: any[], index1: number, index2: number): any[] {
    arr = [...arr];
    const temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;
    return arr;
  }

  setOptionValidation(index) {
    let controlType = this.formElement.at(index).get('controlType').value
    if (controlType === 'checkbox' || controlType === 'radio' || controlType === 'select') {
      if (this.elementOption(index).length == 0) {
        this.addElementOption(index)
      }
    } else {
      this.removeElementOptionsAll(index)
    }

    //set element type
    var result = this.filedTypes.find(obj => {
      return obj.key === controlType
    })
    result ? this.formElement.at(index).get('type').setValue(result.value) : this.formElement.at(index).get('type').setValue('')
  }

  setFormElement(formElements): FormArray {
    const formArray = new FormArray([])
    formElements.forEach(element => {
      let formGroup = {
        _id: element._id,
        value: element.value,
        key: element.key,
        label: element.label,
        required: element.required,
        controlType: element.controlType,
        type: element.type,
        options: this.setFormElementOption(element.options),
        order: formArray.length + 1
      }
      formArray.push(this.fb.group(formGroup))
    });
    return formArray
  }

  setFormElementOption(options): FormArray {
    const formArray = new FormArray([])
    options.forEach(option => {
      formArray.push(this.fb.group({
        value: option.value,
        key: option.key,
      }))
    });
    return formArray
  }

  get fromElementValue() {
    return this.f.formElement.value
  }


  private fillWithData(result) {
    this.form.setControl('formElement', this.setFormElement(result))
    this.onViewClick()
  }

  getStoreCustomFields() {
    this.questionService.getStoreCustomFields(this.formOptions.customFieldType, this.formOptions.location_id)
      .subscribe((res) => {
        if (res.data.getStoreCustomFields.length)
          this.fillWithData(res.data.getStoreCustomFields)
      })
  }


  view() {
    //this.questions$ = this.questionService.getQuestions(this.formElement.value);
  }

  submit(): void {
    console.log(this.form.value);

    this.submitted = true;
    this.error = false;
    const controls = this.form.controls;
    controls.customFieldType.setValue(this.formOptions.customFieldType)
    controls.location_id.setValue(this.formOptions.location_id)

    /** check form */
    if (this.form.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.formElement.controls.forEach((element: FormGroup, index: number) => {
        // form Element array
        Object.keys(element.controls).forEach((controlName) =>
          element.controls[controlName].markAsTouched()
        );
        // form element option array
        this.elementOption(index).controls.forEach((option: FormGroup) => {
          Object.keys(option.controls).forEach((controlName) =>
            option.controls[controlName].markAsTouched()
          );
        })
      });

      return;
    }

    this.loading = true;
    const data = this.removeEmpty(this.form.value);

    this.actionAdd(data);
  }

  removeEmpty = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] == null || obj[key] == "") {
        delete obj[key];
      }
    });

    return obj;
  };

  isControlHasError(controlName: string, validationType: string, formGroup: FormGroup): boolean {
    const control = formGroup.controls[controlName];
    if (!control) {
      return false;
    }
    let hasError = control.hasError(validationType) && (control.dirty || control.touched)
    return hasError
  }

  deleteStoreCustomField(index: number){
    this.questionService.deleteStoreCustomField(this.formElement.at(index).value._id,this.formOptions.customFieldType,this.formOptions.location_id)
    .subscribe((res)=>{
    },(err)=>{
      console.log('Delete form filed',err.message)
    })
  }

  private actionAdd(data) {
    this.questionService.createUpdateMultiStoreCustomField(data.customFieldType, data.formElement, data.location_id)
      .subscribe((res) => {
        this.loading = false;
        this.success = true;
        this.error = false;
        this.onSuccess()
        this.closeModal()
      }, (err) => {
        this.loading = false;
        this.error = true;
        this.success = false;
      })
  }

  onSuccess() {
    this.event.emit()
  }

  closeModal() {
    this.bsModalRef.hide();
  }

}
