import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { TicketsGQLService } from "src/app/services/order and invoice/ticketsGQLService";
import { EnvironmentUrl } from "src/environments/environment-url";
import { CustomerGQLService } from "src/app/services/customer/customerGQL.service";
import { RepairRoomService } from "src/app/services/repair-room/repair-room.service";
import { PrintService } from "src/app/print/print.service";
import { RepairRoomGQLService } from "src/app/services/repair-room/repair-room-gql.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ToasterService } from "src/app/services/toaster/toaster.service";
import { Orders } from "src/app/modules/Globals/order";
import {
  GetOrdersAndSearchGQL,
  InputTicketsFilter,
  ReasonType
} from "src/app/generated/graphql";
import { FormControl } from "@angular/forms";
import { DownloadFileComponent } from "../../shared-templates/download-file/download-file.component";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import {
  TICKETS_FEATURE_NAME,
  TICKETS_DETAIL_ACTION_NAME
} from "src/app/services/authentication/permissions-name";
import * as dummydata from "src/app/modules/shared/data-role-permission.json";
import { RefundByOrigianlPaymentMethodComponent } from "../refund-by-origianl-payment-method/refund-by-origianl-payment-method.component";
import { CommonService } from "src/app/services/common/common.service";
@Component({
  selector: "app-ticket-detail",
  templateUrl: "./ticket-detail.component.html",
  styleUrls: ["./ticket-detail.component.css"]
})
export class TicketDetailComponent implements OnInit {
  modalRef: BsModalRef;
  listOfAllOrders = [];
  searchText = "";
  searchOrderData = [];
  limit: number = 100;
  skip: number = 0;
  searchOrder: FormControl = new FormControl();
  loadOrderInvoice = false;
  isCustomerSpecific = false;
  customerId = "";
  orderId = "";
  existingOrders = true;
  viewInvoice = false;
  statusFilter = "Repair Status";
  ticketFilters: InputTicketsFilter = null;
  repairStatuses = [];
  documentPath = EnvironmentUrl.Images;
  selectedTicket = {};
  selectTransactionPayment = {};
  TICKETS_DETAIL_ACTION_NAME = TICKETS_DETAIL_ACTION_NAME;
  TICKETS_FEATURE_NAME = TICKETS_FEATURE_NAME;
  @ViewChild("downloadFileModal", { static: true })
  downloadFileModal: DownloadFileComponent;

  constructor(
    private modalService: BsModalService,
    private toaster: ToasterService,
    private order: Orders,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private getOrdersAndSearchGQL: GetOrdersAndSearchGQL,
    private ticketsGQLService: TicketsGQLService,
    private customerGQLService: CustomerGQLService,
    private repairRoomService: RepairRoomService,
    private printService: PrintService,
    private repairRoomGQLService: RepairRoomGQLService,
    private _repairRoom: RepairRoomService,
    private _authenticate: AuthenticationService,
    private commonService: CommonService
  ) {}
  isRolePermissionModule = false;
  selectedCustomerForDetial = null
  ngOnInit() {
    if (this.router.url.includes("Permissions")) {
      this.isRolePermissionModule = true;
      this.ticketDetail = dummydata.ticketDetail;
    }
    this.activatedRoute.params.subscribe((params: Params) => {
      console.log("paramameters are", params.id);
      this.orderId = params.id;
      this.isRolePermissionModule ? "" : this.getTicketDetailByID(this.orderId);
    });
    if(history.state.hasOwnProperty('customer')){
      this.selectedCustomerForDetial = history.state.customer
    }
  }

  ticketDetail: any = null;
  loadTicketDetail = false;
  topHeaders = [];
  getTicketDetailByID(id) {
    this.loadTicketDetail = true;
    this.ticketsGQLService.getTicketDetailByID(id).valueChanges.subscribe(
      res => {
        this.loadTicketDetail = false;
        if (res["data"].getTicketDetailById == null) {
          return;
        }
        this.ticketDetail = res["data"].getTicketDetailById;
        this.topHeaders = res["data"].getTicketDetailById.filter_type;
        console.log("ticket detail is", this.ticketDetail);
      },
      err => {
        this.loadTicketDetail = false;
        console.log("err while loading ticket detail", err);
      }
    );
  }

  getInvoiceHeader(str) {
    if (str == "partial" && this.ticketDetail != null) {
      if (this.ticketDetail["transaction_payment_status"] == "partial") {
        return true;
      }
    }
    return this.topHeaders.includes(str);
  }

  printInVoice(bool) {
    let obj = {
      orderIDs: [this.ticketDetail["_id"]],
      fileType: "pdf",
      is_detail: bool
    };
    this.printService.getAndPrintDocument("api/order/download/", obj);
  }

  downloadInvoices(type) {
    let orderIDs = [];
    orderIDs.push(this.ticketDetail["_id"]);
    let obj = {
      orderIDs: orderIDs,
      fileType: type == "csv" ? "csv" : "pdf",
      is_detail: true
    };
    if (type == "csv") {
      if (orderIDs.length > 1) {
        this.printService.downloadZip("api/order/download/", obj, "tickets");
        this.modalRef.hide();
        return;
      }
      this.printService.downloadCSV("api/order/download/", obj, "tickets");
    } else {
      if (orderIDs.length > 1) {
        this.printService.downloadZip("api/order/download/", obj, "tickets");
        this.modalRef.hide();
        return;
      }
      this.printService.downloadPDF("api/order/download/", obj, "tickets");
    }
    this.modalRef.hide();
  }


  onBackClick(){
    if(this.selectedCustomerForDetial){
      this.router.navigate(['Pointofsale', 'Customers', 'Listing', 'detail', this.selectedCustomerForDetial._id])
    }else{
      this.router.navigate(["/Pointofsale/Tickets/"]);
    }
  }

  recordMorePayment() {
    this.router.navigate([
      "/Pointofsale/CreateSale/Checkout/" + this.ticketDetail["_id"]
    ]);
  }

  showOrderInCart(id) {
    this.router.navigateByUrl(
      `Pointofsale/CreateSale/Order/(panel:${this.ticketDetail["_id"]})`
    );
  }

  gotoCustomers(id) {
    this.router.navigateByUrl(`Pointofsale/Customers/Listing/detail/${id}`);
  }

  addTaxDocument(event) {
    if (event.target.files[0] == null || event.target.files[0] == undefined) {
      return
    }
    this.document = event.target.files[0];
    // var reader = new FileReader();
    // reader.readAsDataURL(this.document);
    // reader.onload = (event: any) => {
    //   this.document = event.target.result;
    // };
    //console.log("document is", this.document);
  }

  state = "";
  taxId = "";
  document: any = null;
  refundSalesTaxSubmitted = false;
  refundSalesTax() {
    if (this.state == "" || this.taxId == "" || this.document == null) {
      this.refundSalesTaxSubmitted = true;
      return;
    }
    let input = {
      customerId: this.ticketDetail["Customer"]["_id"],
      orderId: this.ticketDetail["_id"],
      location_id: localStorage.getItem("location_id"),
      file: this.document,
      state: this.state,
      tax_certificate_id: this.taxId
    };
    this.ticketsGQLService.refundSalesTax(input).subscribe(
      res => {
        this.ticketDetail["tax_refund"] =
          res["data"].createTaxRefund.tax_refund;
        this.ticketDetail["is_tax_refund"] =
          res["data"].createTaxRefund.is_tax_refund;
        this.ticketDetail["is_apply_sale_tax"] = false;
        this.ticketDetail["sub_total_amount"] =
          res["data"].createTaxRefund.sub_total_amount;
        this.ticketDetail["total_amount"] =
          res["data"].createTaxRefund.total_amount;
        this.ticketDetail["filter_type"] =
          res["data"].createTaxRefund.filter_type;
        this.topHeaders = res["data"].createTaxRefund.filter_type;
        this.ticketDetail["TicketLogs"] =
          res["data"].createTaxRefund.TicketLogs;
        this.ticketDetail["remaining_amount"] =
          res["data"].createTaxRefund.remaining_amount;
        this.modalRef.hide();
      },
      err => {
        this.showPopUpToaster = false;
        this.toasterType = "error";
        this.toasterMsg = err.graphQLErrors[0].message;
        console.log("err while refunding sales tax", err);
      }
    );
  }

  downloadDocument() {
    if (!this.ticketDetail["is_tax_refund"]) {
      this.showToasterMessage("error", "Document not attaced.");
      return;
    }
    window.open(
      this.ticketDetail["tax_refund"]["document_path"],
      "Download Document"
    );
  }

  sendEmail() {
    this.ticketsGQLService
      .sendEmail(this.ticketDetail["_id"])
      .valueChanges.subscribe(
        res => {
          if (res["data"].emailTicket) {
            this.showToasterMessage("success", "Email sent successfully.");
          }
        },
        err => {
          this.showToasterMessage("error", err.graphQLErrors[0].message);
          console.log("err while sending email", err);
        }
      );
  }

  refundReasons = [];
  refundAmountReasons = [];
  defectiveReasons = [];
  getReasons() {
    this.customerGQLService.getReasons(ReasonType.RefundByAmount).subscribe(
      res => {
        this.refundAmountReasons = res["data"].getReasons;
      },
      err => {
        console.log("err while loading reasons", err);
      }
    );
    this.customerGQLService.getReasons(ReasonType.RefundByItem).subscribe(
      res => {
        this.refundReasons = res["data"].getReasons;
      },
      err => {
        console.log("err while loading reasons", err);
      }
    );
    this.customerGQLService.getReasons(ReasonType.Defective).subscribe(
      res => {
        this.defectiveReasons = res["data"].getReasons;
      },
      err => {
        console.log("err while loading reasons", err);
      }
    );
  }

  loadRefundItems = false;
  getRefundItems() {
    this.loadRefundItems = true;
    this.ticketsGQLService.getRefundItems(this.ticketDetail["_id"]).subscribe(
      res => {
        this.sellLines = res["data"].getTicketRefundDetail.TransactionSellLine;
        for (let i = 0; i < this.sellLines.length; i++) {
          if (this.sellLines[i]["Device"] != null) {
            for (let j = 0; j < this.sellLines[i]["products"].length; j++) {
              this.sellLines[i]["products"][j]["total_amount"] = 0;
              this.sellLines[i]["products"][j]["return_quantity"] = 0;
              this.sellLines[i]["products"][j]["back_to_stock"] = 0;
            }
          } else {
            this.sellLines[i]["return_quantity"] = 0;
            this.sellLines[i]["total_amount"] = 0;
            this.sellLines[i]["back_to_stock"] = 0;
          }
        }
        this.loadRefundItems = false;
      },
      err => {
        this.loadRefundItems = false;
        console.log("error while loading refnd items", err);
      }
    );
  }

  refundMethod = false;
  refundByItem = true;
  refundByAmount = false;
  openRefund() {
    this.getRefundItems();
    this.refundMethod = true;
    this.getReasons();
  }

  sellLines = [];
  openRefundType(open, close) {
    this[open] = true;
    this[close] = false;
  }

  refundAmountReason = "Please select any reason";
  setRefundAmountReason(reason) {
    this.refundAmountReason = reason;
  }

  refundType() {
    if (this.refundByAmount) this.refundAmount();
    else this.refundItem();
  }

  amountToRefund = "";
  refundAmountSubmitted = false;
  refundAmountNote = "";
  refundAmount() {
    this.refundAmountSubmitted = true;
    if (this.amountToRefund == "" ||Number(this.amountToRefund) == 0 || this.refundAmountReason == "Please select any reason") {
      this.showToasterMessage('error',"Enter amount and reason to refund")
      return;
    }
    if(Number(this.amountToRefund) >this.ticketDetail.total_amount){
      this.showToasterMessage('error',"Amount can't be greater then total amount")
      return
    }

    this.refundAmountSubmitted = false;
    let obj = {
      transactionID: this.ticketDetail["_id"],
      reason: this.refundAmountReason,
      note: this.refundAmountNote,
      amount: Number(this.amountToRefund),
      location_id: localStorage.getItem("location_id")
    };
    if (this.ticketDetail.total_amount >= obj.amount) {
      this.refundByOrigianlPaymentMethod(obj);
    } else {
      this.showToasterMessage(
        "error",
        "Refund amount greater then total amount"
      );
    }

    // this.ticketsGQLService.refundByAmount(obj).subscribe(
    //   (res) => {
    //     this.ticketDetail['tax_refund'] = res['data'].InvoiceRefundByAmount.tax_refund
    //     this.ticketDetail['is_tax_refund'] = res['data'].InvoiceRefundByAmount.is_tax_refund
    //     this.ticketDetail['is_apply_sale_tax'] = false
    //     this.ticketDetail['sub_total_amount'] = res['data'].InvoiceRefundByAmount.sub_total_amount
    //     this.ticketDetail['total_amount'] = res['data'].InvoiceRefundByAmount.total_amount
    //     this.ticketDetail['filter_type'] = res['data'].InvoiceRefundByAmount.filter_type
    //     this.topHeaders = res['data'].InvoiceRefundByAmount.filter_type
    //     this.ticketDetail['TicketLogs'] = res['data'].InvoiceRefundByAmount.TicketLogs
    //     this.ticketDetail['remaining_amount'] = res['data'].InvoiceRefundByAmount.remaining_amount
    //     this.amountToRefund = ''
    //     this.refundAmountSubmitted = false
    //     this.refundAmountNote = ''
    //     this.refundAmountReason = 'Please select any reason'
    //     this.closeRefund()
    //   }, (err) => {
    //     this.showToasterMessage('error', err.graphQLErrors[0].message)
    //     console.log('err while refunding amount', err);
    //   }
    // )
  }

  increaseReturnQtyItem(index, itemIndex, isDev) {
    if (isDev) {
      if (
        this.sellLines[index]["products"][itemIndex]["return_quantity"] <
        this.sellLines[index]["products"][itemIndex]["quantity"]
      ) {
        this.sellLines[index]["products"][itemIndex]["return_quantity"] =
          this.sellLines[index]["products"][itemIndex]["return_quantity"] + 1;
        this.sellLines[index]["products"][itemIndex]["total_amount"] =
          this.sellLines[index]["products"][itemIndex]["return_quantity"] *
            this.sellLines[index]["products"][itemIndex]["product_sale_price"] -
          this.sellLines[index]["products"][itemIndex]["discount_amount"];
      }
      return;
    }
    if (
      this.sellLines[index]["return_quantity"] <
      this.sellLines[index]["quantity"]
    ) {
      this.sellLines[index]["return_quantity"] =
        this.sellLines[index]["return_quantity"] + 1;
      this.sellLines[index]["total_amount"] =
        this.sellLines[index]["return_quantity"] *
          this.sellLines[index]["product_sale_price"] -
        this.sellLines[index]["discount_amount"];
    }
  }

  decreaseReturnQtyItem(index, itemIndex, isDev) {
    if (isDev) {
      if (this.sellLines[index]["products"][itemIndex]["return_quantity"] > 0) {
        this.sellLines[index]["products"][itemIndex]["return_quantity"] =
          this.sellLines[index]["products"][itemIndex]["return_quantity"] - 1;
        this.sellLines[index]["products"][itemIndex]["total_amount"] =
          this.sellLines[index]["products"][itemIndex]["return_quantity"] *
          this.sellLines[index]["products"][itemIndex]["product_sale_price"];
      }
      return;
    }
    if (this.sellLines[index]["return_quantity"] > 0) {
      this.sellLines[index]["return_quantity"] =
        this.sellLines[index]["return_quantity"] - 1;
      this.sellLines[index]["total_amount"] =
        this.sellLines[index]["return_quantity"] *
        this.sellLines[index]["product_sale_price"];
    }
  }

  selectReason(index, itemIndex, isDev, reason, defReasonIndex) {
    if (isDev) {
      reason == 'Defected Item' ? this.sellLines[index]["products"][itemIndex]["reason"] = this.defectiveReasons[defReasonIndex].reason_type : this.sellLines[index]["products"][itemIndex]["reason"] = reason;
      reason == 'Defected Item' ? this.sellLines[index]["products"][itemIndex]["reasonId"] = this.defectiveReasons[defReasonIndex]._id : '';
      return;
    }
    reason == 'Defected Item' ? this.sellLines[index]["reason"] = this.defectiveReasons[defReasonIndex].reason_type : this.sellLines[index]["reason"] = reason;
    reason == 'Defected Item' ? this.sellLines[index]["reasonId"] = this.defectiveReasons[defReasonIndex]._id : '';
  }

  selectSupplier(index, itemIndex, isDev, supplier) {
    if(isDev) {
      this.sellLines[index]["products"][itemIndex]["supplier_id"] = supplier._id;
      this.sellLines[index]["products"][itemIndex]["supplier"] = supplier.supplier_company;
      return;
    }
    this.sellLines[index]["supplier_id"] = supplier._id;
    this.sellLines[index]["supplier"] = supplier.supplier_company;
  }

  closeOtherReason(product,isService){
    if(isService){
      this.sellLines.filter(product=>product.is_device).forEach(device=>{
        device.products.forEach(service => {
          service.isOpen = service._id == product._id ? true : false
        });
      })
    }else{
      this.sellLines.forEach(pro=>{
        pro.isOpen = product._id == pro._id ? true : false
      })
    }
  }

  backToStock(index, itemIndex, isDev) {
    if (isDev) {
      if (
        this.sellLines[index]["products"][itemIndex]["back_to_stock"] >
        this.sellLines[index]["products"][itemIndex]["return_quantity"]
      ) {
        this.sellLines[index]["products"][itemIndex][
          "back_to_stock"
        ] = this.sellLines[index]["products"][itemIndex]["return_quantity"];
      }
      return;
    }
    if (
      this.sellLines[index]["back_to_stock"] >
      this.sellLines[index]["return_quantity"]
    ) {
      this.sellLines[index]["back_to_stock"] = this.sellLines[index][
        "return_quantity"
      ];
    }
  }

  refundItemSubmitted = false;
  refundItem() {
    this.refundItemSubmitted = true;
    console.log("sell lines ===>>>>>>>>>>", this.sellLines);
    let refundItems = [];
    for (let i = 0; i < this.sellLines.length; i++) {
      if (this.sellLines[i].is_device) {
        for (let j = 0; j < this.sellLines[i]["products"].length; j++) {
          if (
            this.sellLines[i]["products"][j]["reason"] == null &&
            this.sellLines[i]["products"][j]["return_quantity"] > 0
          ) {
            this.showToasterMessage("error", "Reason is required.");
            return;
          }
          if (this.sellLines[i]["products"][j]["return_quantity"] > 0) {
            let obj = {
              TransactionSellID: this.sellLines[i]["products"][j]["_id"],
              Product: this.sellLines[i]["products"][j]["Product"]["_id"],
              sku_number: this.sellLines[i]["products"][j]["supplier_sku"],
              unit_price: this.sellLines[i]["products"][j][
                "product_sale_price"
              ],
              return_qty: Number(
                this.sellLines[i]["products"][j]["return_quantity"]
              ),
              return_reason: this.sellLines[i]["products"][j]["reason"],
              reason: this.sellLines[i]["products"][j]["reasonId"],
              supplierId: this.sellLines[i]["products"][j]["supplier_id"],
              stock_qty: Number(
                this.sellLines[i]["products"][j]["back_to_stock"]
              ),
              total: Number(this.sellLines[i]["products"][j]["total_amount"])
            };
            refundItems.push(obj);
          }
        }
      } else if (!this.sellLines[i].is_device) {
        if (
          this.sellLines[i]["reason"] == null &&
          this.sellLines[i]["return_quantity"] > 0
        ) {
          this.showToasterMessage("error", "Reason is required.");
          return;
        }
        if (this.sellLines[i]["return_quantity"] > 0) {
          let obj = {
            TransactionSellID: this.sellLines[i]["_id"],
            Product:
              this.sellLines[i].customProduct
                ? this.sellLines[i]["customProduct"]["_id"]
                : this.sellLines[i]["Product"]["_id"],
            sku_number: this.sellLines[i]["supplier_sku"],
            unit_price: this.sellLines[i]["product_sale_price"],
            return_qty: this.sellLines[i]["return_quantity"],
            reason: this.sellLines[i]["reasonId"],
            supplierId: this.sellLines[i]["supplier_id"],
            return_reason: this.sellLines[i]["reason"],
            stock_qty: Number(this.sellLines[i]["return_quantity"]),
            total: this.sellLines[i]["total_amount"]
          };
          refundItems.push(obj);
        }
      }
    }
    console.log("refund items are", refundItems);
    if (refundItems.length > 0) {
      this.refundByOrigianlPaymentMethod(refundItems);
    } else {
      this.showToasterMessage("error", "Select prodcut to refund");
    }
    // this.ticketsGQLService.refundByItems(this.ticketDetail['_id'], refundItems).subscribe(
    //   (res) => {
    //     console.log('items refunded', res['data'].InvoiceRefundByItems);
    //     this.ticketDetail['tax_refund'] = res['data'].InvoiceRefundByItems.tax_refund
    //     this.ticketDetail['is_tax_refund'] = res['data'].InvoiceRefundByItems.is_tax_refund
    //     this.ticketDetail['is_apply_sale_tax'] = false
    //     this.ticketDetail['sub_total_amount'] = res['data'].InvoiceRefundByItems.sub_total_amount
    //     this.ticketDetail['total_amount'] = res['data'].InvoiceRefundByItems.total_amount
    //     this.ticketDetail['filter_type'] = res['data'].InvoiceRefundByItems.filter_type
    //     this.topHeaders = res['data'].InvoiceRefundByItems.filter_type
    //     this.ticketDetail['TicketLogs'] = res['data'].InvoiceRefundByItems.TicketLogs
    //     this.ticketDetail['remaining_amount'] = res['data'].InvoiceRefundByItems.remaining_amount
    //     this.ticketDetail['TransactionSellLine'] = res['data'].InvoiceRefundByItems.TransactionSellLine
    //     this.getTicketDetailByID(this.ticketDetail['_id'])
    //     this.closeRefund()
    //   }, (err) => {
    //     this.showToasterMessage('error', err.graphQLErrors[0].message)
    //     console.log('err while refunding items', err);
    //   }
    // )
  }

  openModal(template: TemplateRef<any>, cls) {
    this.modalRef = this.modalService.show(template, {
      class: "modal-sm " + cls,
      backdrop: "static",
      keyboard: false
    });
  }

  closeModel() {
    this.modalService.hide(1);
  }

  closeRefund() {
    this.refundMethod = false;
  }

  showToaster = true;
  toasterMsg = "something went wrong";
  toasterType = "error";
  showToast = false;
  showToasterMessage(type, msg) {
    this.showToaster = false;
    this.toasterType = type;
    this.toasterMsg = msg;
    this.showToast = true;
  }

  closeToaster() {
    this.showToast = false;
    this.showToaster = true;
  }

  showPopUpToaster = true;
  closePopUpToaster() {
    this.showPopUpToaster = true;
  }

  noOpenAccordianOnInput(e) {
    e.stopPropagation();
  }

  isActionAllowed(actionName) {
    return this._authenticate.isActionAllowed(
      TICKETS_FEATURE_NAME.tickets_details_tab,
      actionName
    );
  }

  bsModalRef: BsModalRef;
  refundByOrigianlPaymentMethod(refundInformation) {
    const initialState = {
      id: this.orderId,
      isRefundByAmount: this.refundByAmount,
      refundInformation: refundInformation,
      transcation: this.ticketDetail
    };
    this.bsModalRef = this.modalService.show(
      RefundByOrigianlPaymentMethodComponent,
      {
        class: "modal-sm custModal wd600",
        backdrop: "static",
        keyboard: false,
        initialState
      }
    );
    this.bsModalRef.content.event.subscribe(res => {
      this.getTicketDetailByID(this.orderId);
      this.refundMethod = false
      //this.getRefundItems();
    });
  }

  chequePaymentVerify(transactionID, paymentID, userEmail, userPassword) {
    this.ticketsGQLService
      .chequePaymentVerify(
        transactionID,
        localStorage.getItem("location_id"),
        paymentID,
        userEmail,
        userPassword
      )
      .subscribe(
        res => {
          this.getTicketDetailByID(this.orderId);
          this.closeModel();
          this.showToasterMessage('success','Cheque verifed.')
        },
        err => {
          this.showToasterMessage("error", err.message);
        }
      );
  }
  isEmailValid = true;
  userPwd = "";
  userEmail;
  validateEmail(userEmail) {
    return this.commonService.validateEmail(userEmail);
  }

  checkoutDevices = [];
  getCheckoutDevices(id, template: TemplateRef<any>, cls) {
    this.checkoutDevices = [];
    this.ticketsGQLService.getDevicesForCheckout(id).subscribe(
      res => {
        if (res["data"].repairRoomInvoiceCheckOutDetail == null) {
          this.showToasterMessage("Device under reapir.", "warning");
          return;
        }
        this.openModal(template,cls)
        this.checkoutDevices =
          res["data"].repairRoomInvoiceCheckOutDetail.Devices;
      },
      err => {
        console.log("error while loading checkout devices", err);
      }
    );
  }

  devicesForCheckout = [];
  selectDeviceForCheckout(event, dev) {
    if (event.target.checked) {
      this.devicesForCheckout.push(dev["device"]["_id"]);
      return;
    }
    const index = this.devicesForCheckout.findIndex(x => x == dev["_id"]);
    if (index == -1) {
      this.devicesForCheckout.splice(index, 1);
    }
  }

  isDevSelectedForCheckout(dev) {
    const index = this.devicesForCheckout.findIndex(
      x => x == dev["device"]["_id"]
    );
    return index == -1 ? false : true;
  }

  checkoutDevice() {
    if(this.devicesForCheckout.length){
      this.ticketsGQLService
      .checkoutDevices(this.ticketDetail._id, this.devicesForCheckout)
      .subscribe(
        res => {
          if (res.data.checkOutDevice) {
            this.getTicketDetailByID(this.ticketDetail._id)
            this.closeModel();
            this.showToasterMessage('success','Device checkout successfully')
            this.devicesForCheckout = [];
            this.checkoutDevices = [];
          }
        },
        err => {
          this.closeModel();
          this.showToasterMessage('error',err.message)
          this.checkoutDevices = [];
          this.devicesForCheckout = [];
        }
      );
    }else{
      this.showToaster = false;
      this.showToasterMessage('warning','Select device to checkout')
      this.toasterType = "error";
    }
  }

  tasksReopen = [];
  tasksReopenTransaction;
  getDevicesToReopen(id,template,cls) {
    this.tasksReopen = [];
    this.loadTicketDetail = true
    this.ticketsGQLService
      .getRepairRoomTaskDetail(localStorage.getItem("location_id"), id)
      .subscribe(
        res => {
          this.loadTicketDetail = false
          if (res["data"].repairRoomReOpenInvoiceDetail == null) {
            this.showToasterMessage("Device is under repair.", "warning");
            return;
          }
          this.tasksReopenTransaction =
            res.data.repairRoomReOpenInvoiceDetail.Transaction;
          this.tasksReopen = res["data"].repairRoomReOpenInvoiceDetail.Devices;
          this.openModal(template,cls)
        },
        err => {
          this.loadTicketDetail = false
          console.log("err while loading tasks", err);
        }
      );
  }

  servicesToReOpen = [];
  openDeviceTask(event, dev, id) {
    let service = [];
    const index = this.servicesToReOpen.findIndex(x => x.device == dev);
    if (event.target.checked) {
      if (index == -1) {
        service.push({ _id: id });
        this.servicesToReOpen.push({
          deviceID: dev,
          services: service
        });
        console.log("services are", this.servicesToReOpen);
        return;
      }
      this.servicesToReOpen[index]["services"].push({ _id: id });
      console.log("services are", this.servicesToReOpen);
      return;
    }
    this.servicesToReOpen.splice(index, 1);
    console.log("services are", this.servicesToReOpen);
  }

  checkServiceInList(dev, id) {
    const index = this.servicesToReOpen.findIndex(x => x.deviceID == dev);
    if (index != -1) {
      const serviceIndex = this.servicesToReOpen[index]["services"].findIndex(
        s => s._id == id
      );
      return serviceIndex == -1 ? false : true;
    }
  }

  openDeviceAllTasks(event, dev) {
    let service = [];
    dev["deviceItems"].forEach(element => {
      service.push({ _id: element._id });
    });
    const index = this.servicesToReOpen.findIndex(x => x.device == dev["_id"]);
    if (event.target.checked) {
      if (index == -1) {
        this.servicesToReOpen.push({
          deviceID: dev.device["_id"],
          services: service
        });
        console.log("services are", this.servicesToReOpen);
        return;
      }
      this.servicesToReOpen[index]["services"] = service;
      console.log("services are", this.servicesToReOpen);
      return;
    }
    this.servicesToReOpen.splice(index, 1);
    console.log("services are", this.servicesToReOpen);
  }

  taskDeviceReOpen() {
    if(this.servicesToReOpen.length == 0){
      return
    }
    
    this.ticketsGQLService
      .taskDeviceReOpen(
        this.tasksReopenTransaction["_id"],
        localStorage.getItem("location_id"),
        this.servicesToReOpen
      )
      .subscribe(
        res => {
          this.closeModel(); 
          this.showToasterMessage("success","Ticket repoened" );
          this.servicesToReOpen = [];
          this.getTicketDetailByID(this.ticketDetail._id);
        },
        err => {
          this.showToasterMessage("error",err.message,);
          this.servicesToReOpen = [];
        }
      );
  }

  refundAmountToCustomer(ticket){
    this.loadTicketDetail = true
    this.ticketsGQLService.getTicketDetailByID(ticket._id)
    .valueChanges.subscribe((res)=>{
      this.loadTicketDetail = false
      this.refundCustomerByOrigianlPaymentMethod(res.data.getTicketDetailById)
    })
  }

  refundCustomerByOrigianlPaymentMethod(ticket) {

    let refundInformation = {
      transactionID: ticket["_id"],
      reason: 'Service change by technician',
      note: 'Refund amount due to service change by technician',
      amount: Number(ticket.refund_amount_to_customer),
      location_id: localStorage.getItem("location_id"),
      refund_amount_to_customer:true
    };
    const initialState = {
      id: ticket._id,
      isRefundByAmount: true,
      refundInformation: refundInformation,
      transcation: ticket
    };
   
    this.modalRef = this.modalService.show(
      RefundByOrigianlPaymentMethodComponent,
      {
        class: "modal-sm custModal wd600",
        backdrop: "static",
        keyboard: false,
        initialState
      }
    );
    this.modalRef.content.event.subscribe(res => {
      this.getTicketDetailByID(this.ticketDetail._id);
      //this.getRefundItems();
    });
  }


}
