import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Papa } from 'ngx-papaparse';
import { ManageProductsService } from 'src/app/services/inventory/manage-products.service';
import { ManageServicesService } from 'src/app/services/mange-services/manage-services.service';
import { ManageSupplierService } from 'src/app/services/suppliers/manage-supplier.service';
import { of } from 'rxjs';
import { ManageUserService } from 'src/app/services/users/manage-user.service';
import { CSVService } from 'src/app/services/csv.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import * as dummyCSVData from "src/app/modules/shared/sampleCSV.json"

@Component({
  selector: 'app-import-product-supplier',
  templateUrl: './import-product-supplier.component.html',
  styleUrls: ['./import-product-supplier.component.css']
})
export class ImportProductSupplierComponent implements OnInit, OnDestroy {

  @ViewChild("openImportProductAndSupplier", { static: false }) openImportProductAndSupplier: TemplateRef<any>;
  importText: any;
  sampleUrl: any
  columsTomatch = []
  @Output() importDone: EventEmitter<any> = new EventEmitter<any>();
  modalRef: BsModalRef;
  importFileName = ''
  suppFileFieldsName: any
  dataListSupplier = []
  fieldsSupplier = []
  fileNameSupplier: string;
  fileName: string;
  toasterMsg = 'no msg'
  toasterType = 'error'
  importSupplierLoader: boolean;
  limit = 30
  skip = 0

  constructor(
    private modalService: BsModalService,
    private papa: Papa,
    private manageProductsService: ManageProductsService,
    private manageSupplierService: ManageSupplierService,
    private manageUserService: ManageUserService,
    private csvService: CSVService,
    private settingsService: SettingsService

  ) { }

  ngOnInit() {
  }

  sampleCSV(filename){
    console.log(filename,'filename')
    if(filename.includes('ImportUsersSample')) {
      this.csvService.saveAsCSVFile(dummyCSVData.ImportUsersSample, 'ImportUsersSample')
    }
    if(filename.includes('ImportSuppliersSample.csv')) {
      this.csvService.saveAsCSVFile(dummyCSVData.ImportSuppliersSample, 'ImportSuppliersSample')
    }
    if(filename.includes('ImportProductsSample.csv')) {
      this.csvService.saveAsCSVFile(dummyCSVData.ImportProductsSample, 'ImportProductsSample');
    }
  }
  paginationList = []
  showMoreData() {
    this.skip = this.skip + 30
    this.limit = this.limit + 30
    this.paginationList.push(this.dataListSupplier.slice(this.skip, this.limit));
  }

  initializeModel() {
    this.modalRef = this.modalService.show(this.openImportProductAndSupplier, { class: 'modal-sm ' + 'box-importPO', backdrop: 'static', keyboard: false });
  }

  resetVariables() {
    this.suppFileFieldsName = null
    this.dataListSupplier = []
    this.fieldsSupplier = []
  }

  onFileChangedSupplier(event) {
    this.importFileName = event[0].name
    this.papa.parse(event[0], {
      header: true,
      skipEmptyLines: true,
      complete: (result, file) => {
        console.log('Field Names', result);
        this.suppFileFieldsName = result.meta.fields
        this.dataListSupplier = result.data;
        this.paginationList = this.dataListSupplier.slice(this.skip, this.limit);
        console.log("dataListSupplier ---->", this.dataListSupplier);
        for (let i = 0; i < this.suppFileFieldsName.length; i++) {
          let obj = {}
          for (let k = 0; k < this.columsTomatch.length; k++) {
            if (this.columsTomatch[k] == this.suppFileFieldsName[i]) {
              obj['name'] = this.suppFileFieldsName[i]
              obj['nameForShowData'] = this.suppFileFieldsName[i]
              obj['selectionBox'] = false
              obj['selectedBox'] = true
              obj['notMapped'] = false
              obj['skip'] = false
              this.fieldsSupplier.push(obj)
            }
          }
          if (this.isObjectEmpty(obj)) {
            obj['name'] = ''
            obj['csvName'] = this.suppFileFieldsName[i]
            obj['selectionBox'] = true
            obj['selectedBox'] = false
            obj['notMapped'] = true
            obj['skip'] = false
            this.fieldsSupplier.push(obj)
          }
        }
        this.initializeColumnsSupplier(result.meta.fields)
      }
    });
  }

  columnToMapSupplier = []
  colSupplier = []
  initializeColumnsSupplier(col) {
    this.columnToMapSupplier = []
    for (let i = 0; i < col.length; i++) {
      for (let k = 0; k < this.columsTomatch.length; k++) {
        if (col[i] === this.columsTomatch[k]) {
          this.columnToMapSupplier.push(
            {
              c_db: col[i],
              c_csv: this.columsTomatch[k]
            }
          )
          this.colSupplier[i] = this.columsTomatch[k]
        }
      }
      if (this.colSupplier[i] == undefined || this.colSupplier[i] == '') {
        this.colSupplier[i] = 'Make A selection'
      }
    }
  }

  skipSlideSupplier(index) {
    if (this.importText == 'Supplier(s)') {
      if (this.fieldsSupplier[index].name == 'Supplier Company' || this.fieldsSupplier[index].name == 'Supplier Company Email' || this.fieldsSupplier[index].name == 'Supplier Company Phone'
        || this.fieldsSupplier[index].name == 'Address 1' || this.fieldsSupplier[index].name == 'Website'
        || this.fieldsSupplier[index].name == 'Country' || this.fieldsSupplier[index].name == 'Zip Code' || this.fieldsSupplier[index].name == 'City' || this.fieldsSupplier[index].name == 'State') {
        this.showToaster('showFirstToast', 'This field is must for add supplier', 'error')
        return
      } else {
        this.fieldsSupplier[index]['skip'] = true
        this.fieldsSupplier[index]['name'] = ''
        this.fieldsSupplier[index]['notMapped'] = true
        this.colSupplier[index] = 'Make A selection'
      }
    } else if (this.importText == 'User(s)') {
      this.showToaster('showFirstToast', 'This field is must for add user', 'error')
      return
    } else {
      if (this.fieldsSupplier[index].name == 'Product Name' || this.fieldsSupplier[index].name == 'Product Type' || this.fieldsSupplier[index].name == 'Average Cost' || this.fieldsSupplier[index].name == 'SKU' || this.fieldsSupplier[index].name == 'Ideal Qty'
        || this.fieldsSupplier[index].name == 'Alert Qty' || this.fieldsSupplier[index].name == 'Sell Price') {
        this.showToaster('showFirstToast', 'This field is must for add product', 'error')
        return
      } else {
        this.fieldsSupplier[index]['skip'] = true
        this.fieldsSupplier[index]['name'] = ''
        this.fieldsSupplier[index]['notMapped'] = true
        this.colSupplier[index] = 'Make A selection'
      }
    }
  }

  changeColItemName(value, i) {
    var index = this.fieldsSupplier.map(x => {
      return x.name;
    }).indexOf(value);
    if (index != -1) {
      this.showToaster('showFirstToast', 'This field is already selected', 'error')
      return
    }
    this.colSupplier[i] = value
    this.newColName = value
  }

  newColName = ''
  changeValueOfMapColumnsSupplier(type, i) {
    if (this.newColName == '') {
      this.fieldsSupplier[i].selectionBox = false
      this.fieldsSupplier[i].selectedBox = true
      return
    }
    this[type] = this.newColName
    this.colSupplier[i] = this.newColName
    this.fieldsSupplier[i].selectionBox = false
    this.fieldsSupplier[i].selectedBox = true
    this.fieldsSupplier[i]['skip'] = false
    this.fieldsSupplier[i]['name'] = this.newColName
    this.columnToMapSupplier.push(
      { c_db: this.newColName, c_csv: this.fieldsSupplier[i]['csvName'] }
    )
    this.newColName = ''
  }

  saveUnkownCol(i) {
    if (this.newColName == "") {
      this.showToaster('showFirstToast', 'Please select field first', 'error')
      return
    }
    var index = this.fieldsSupplier.map(x => {
      return x.name;
    }).indexOf(this.newColName);
    if (index != -1) {
      this.showToaster('showFirstToast', 'This field is already selected', 'error')
      return
    }
    this.fieldsSupplier[i].selectionBox = false
    this.fieldsSupplier[i].selectedBox = true
    this.fieldsSupplier[i]['skip'] = false
    this.fieldsSupplier[i]['notMapped'] = false
    this.fieldsSupplier[i]['name'] = this.newColName
    this.columnToMapSupplier.push(
      { c_db: this.newColName, c_csv: this.fieldsSupplier[i]['csvName'] }
    )
    this.newColName = ''
  }

  saveEditedCol(i) {
    this.fieldsSupplier[i].selectionBox = false
    this.fieldsSupplier[i].selectedBox = true
    this.fieldsSupplier[i]['skip'] = false
    this.fieldsSupplier[i]['name'] = this.newColName
  }

  selectedBox = true
  selectionBox = false
  editCol(i) {
    this.fieldsSupplier[i]['selectedBox'] = false
    this.fieldsSupplier[i]['selectionBox'] = true
  }

  skipEditedCol(i) {
    this.fieldsSupplier[i].selectionBox = false
    this.fieldsSupplier[i].selectedBox = true
  }

  ///////////////////////// Import Product functions start from here////////////////////

  importProductsDataArray = []
  importStarted = false
  doneMappingProducts(template: TemplateRef<any>, cls) {
    this.importStarted = true
    this.importSupplierLoader = true
    this.modalRef.hide()
    if (this.importText == 'Employee(s)') {
      this.importUser(template, cls)
      return
    }
    this.importProductsDataArray = []
    for (let i = 0; i < this.dataListSupplier.length; i++) {
      let product = {}
      for (let k = 0; k < this.columnToMapSupplier.length; k++) {
        product[this.columnToMapSupplier[k].c_db] = this.dataListSupplier[i][this.columnToMapSupplier[k].c_csv]
      }
      let prod = {}
      prod['product_name'] = product['Product Name'] == undefined ? "" : product['Product Name']
      prod['product_type'] = product['Product Type'] == undefined ? "" : product['Product Type']
      prod['sku'] = product['SKU'] == undefined ? "" : product['SKU']
      prod['supplier'] = product['Supplier'] == undefined ? "" : product['Supplier']
      prod['serial_numbers'] = product['Serial Numbers'] == undefined ? "" : product['Serial Numbers']
      prod['opening_stock'] = product['Opening Stock'] == undefined ? 0 : Number(product['Opening Stock'])
      prod['Brand'] = product['Brand'] == undefined ? "" : product['Brand']
      prod['DeviceModel'] = product['Models'] == undefined ? "" : product['Models']
      prod['ideal_qty'] = Number(product['Ideal Qty'] == undefined ? 0 : product['Ideal Qty'])
      prod['alert_qty'] = Number(product['Alert Qty'] == undefined ? 0 : product['Alert Qty'])
      prod['sell_price'] = parseFloat(product['Sell Price'] == undefined ? 0 : product['Sell Price'])
      prod['average_cost'] = parseFloat(product['Average Cost'] == undefined ? 0 : product['Average Cost'])
      prod['description'] = product['Description'] == undefined ? "" : product['Description']
      prod['product_name'] = prod['product_name'].replace(/[|&;$%@"<>()+,]/g, "");
      prod['DeviceModel'] = prod['DeviceModel'].replace(/[|&;$%@"<>()+,]/g, "");
      this.importProductsDataArray.push(prod);
    }
    this.importProductsFun(this.importProductsDataArray, template, cls)
  }

  usersToImport = []
  importUser(template: TemplateRef<any>, cls) {
    this.usersToImport = []
    for (let index = 0; index < this.dataListSupplier.length; index++) {
      let user = {
        first_name: this.dataListSupplier[index]['First Name'] == undefined ? '' : this.dataListSupplier[index]['First Name'],
        last_name: this.dataListSupplier[index]['Last Name'] == undefined ? '' : this.dataListSupplier[index]['Last Name'],
        email: this.dataListSupplier[index]['Email'] == undefined ? '' : this.dataListSupplier[index]['Email'],
        phone: this.dataListSupplier[index]['Contact Number'] == undefined ? '' : this.dataListSupplier[index]['Contact Number'],
        password: this.dataListSupplier[index]['Password'] == undefined ? '' : this.dataListSupplier[index]['Password']
      }
      this.usersToImport.push(user)
    }
    this.manageUserService.importUsers({ input: this.usersToImport,  location_id: localStorage.getItem('location_id')}).subscribe(
      (res) => {
        this.importSupplierLoader = false
        this.importStarted = false
        if (res['data'].importStoreUsers == null) {
          this.showToaster('showFirstToast', 'Something went wrong', 'error')
          return
        }
        this.modalRef = this.modalService.show(template, { class: 'modal-sm ' + cls, backdrop: 'static', keyboard: false });
        this.productsInvalidData = 0
        this.newlyAddedProducts = 0
        this.alreadyExistProducts = 0
        this.productsInvalidData = res['data'].importStoreUsers.InvalidDataUsers.length
        this.newlyAddedProducts = res['data'].importStoreUsers.newlyAddedUsers.length
        this.alreadyExistProducts = res['data'].importStoreUsers.alreadyExistUsers.length
      }, (err) => {
        this.importSupplierLoader = false
        this.importStarted = false
        console.log(' err', err);
        this.showToaster('showFirstToast', 'Something went wrong', 'error')
      }
    )
    console.log('Users are', this.usersToImport);
  }

  productsInvalidData = 0
  newlyAddedProducts = 0
  alreadyExistProducts = 0
  async importProductsFun(importProductsData, template: TemplateRef<any>, cls) {
    console.log('importProductsData for API-->', importProductsData);
    let file  = await this.settingsService.convertToImage(this.csvService.createAndReturnCSVFile(importProductsData), 'csv')
    console.log('CSV file is', file)
    this.manageProductsService.importProductWithFile(file, localStorage.getItem('location_id') )
    // this.manageProductsService.importProducts({ input: importProductsData, location_id: localStorage.getItem('location_id') })
    this.importSupplierLoader = false
    this.importStarted = false
    this.productsInvalidData = 0
    this.newlyAddedProducts = 0
    this.alreadyExistProducts = 0
    this.modalService.hide(1)
    this.importDone.emit(true)
    // .subscribe(
    //   (res) => {
    //     this.importSupplierLoader = false
    //     this.importStarted = false
    //     console.log('importProductsData res', res['data'].importProductsData);
    //     let importData = res['data'].importProductsData
    //     this.modalRef = this.modalService.show(template, { class: 'modal-sm ' + cls, backdrop: 'static', keyboard: false });
    //     if (!this.isObjectEmpty(importData.productsInvalidData)) {
    //       this.productsInvalidData = importData.productsInvalidData.length
    //     }
    //     if (!this.isObjectEmpty(importData.newlyAddedProducts)) {
    //       this.newlyAddedProducts = importData.newlyAddedProducts.length
    //     }
    //     if (!this.isObjectEmpty(importData.alreadyExistProducts)) {
    //       this.alreadyExistProducts = importData.alreadyExistProducts.length
    //     }
    //   }, (err) => {
    //     this.importSupplierLoader = false
    //     this.importStarted = false
    //     console.log(' err', err);
    //     this.showToaster('showFirstToast', 'Something went wrong', 'error')
    //   }
    // )
  }

  openModalImportModel(template: TemplateRef<any>, cls) {
    this.fileName = ''
    this.fileNameSupplier = ''
    this.modalRef = this.modalService.show(template, { class: 'modal-sm ' + cls, backdrop: 'static', keyboard: false });
    this.closeModel()
  }

  ///////////////////////// Import Product functions ends here///////////////////////////

  ///////////////////////// Import Supplier functions start from here////////////////////

  closeModelSuppImported(template: TemplateRef<any>, cls) {
    this.modalRef.hide()
    this.modalRef = this.modalService.show(template, { class: 'modal-sm ' + cls, backdrop: 'static', keyboard: false });
  }

  doneMappingSupplier() {
    this.modalRef.hide()
    this.importSupplierLoader = true
    for (let i = 0; i < this.dataListSupplier.length; i++) {
      let supplier0 = {}
      for (let k = 0; k < this.columnToMapSupplier.length; k++) {
        supplier0[this.columnToMapSupplier[k].c_db] = this.dataListSupplier[i][this.columnToMapSupplier[k].c_csv]
      }
      let Supplier = {}
      Supplier['supplier_company'] = supplier0['Supplier Company'] == undefined ? "" : supplier0['Supplier Company']
      Supplier['supplier_company_phone'] = supplier0['Supplier Company Phone'] == undefined ? "" : supplier0['Supplier Company Phone']
      Supplier['supplier_company_email'] = supplier0['Supplier Company Email'] == undefined ? "" : supplier0['Supplier Company Email']
      Supplier['website'] = supplier0['Website'] == undefined ? "" : supplier0['Website']
      Supplier['address_1'] = supplier0['Address 1'] == undefined ? "" : supplier0['Address 1']
      Supplier['address_2'] = supplier0['Address 2'] == undefined ? "" : supplier0['Address 2']
      Supplier['Country'] = supplier0['Country'] == undefined ? "" : supplier0['Country']
      Supplier['city'] = supplier0['City'] == undefined ? "" : supplier0['City']
      Supplier['state'] = supplier0['State'] == undefined ? "" : supplier0['State']
      Supplier['zip_code'] = supplier0['Zip Code'] == undefined ? "" : supplier0['Zip Code']
      Supplier['supplier_first_name'] = supplier0['Supplier First Name'] == undefined ? "" : supplier0['Supplier First Name']
      Supplier['supplier_last_name'] = supplier0['Supplier Last Name'] == undefined ? "" : supplier0['Supplier Last Name']
      Supplier['supplier_mobile'] = supplier0['Supplier Mobile'] == undefined ? "" : supplier0['Supplier Mobile']
      Supplier['supplier_email'] = supplier0['Supplier Email'] == undefined ? "" : supplier0['Supplier Email']
      Supplier['supplier_phone'] = supplier0['Supplier Phone'] == undefined ? "" : supplier0['Supplier Phone']
      Supplier['is_verify_supplier'] = false
      Supplier['is_buyback'] = true
      Supplier['BusinessLocation'] = window.localStorage.getItem('location_id')
      this.importSupllierData.push(Supplier);
    }
    console.log('importSupllierData ---Final-->', this.importSupllierData)
    this.suppliersCheckDuplicationDataFun(this.importSupllierData)
  }

  importSupllierData = []
  suppliersInvalid = 0
  alreadyExistSuppliers = 0
  newlyAddedSuppliers = 0
  importSupplierData = []
  dataVerifyBox = true
  suppliersCheckDuplicationDataFun(importSuppliers) {
    this.suggestedSupplierArray = []
    this.givenSupplierArray = []
    this.importSupplierData = []
    this.dataVerifyBox = true
    this.alreadyExistSuppliers = 0
    this.newlyAddedSuppliers = 0
    this.suppliersInvalid = 0
    console.log("import Supplier Data ---->", importSuppliers)
    this.manageSupplierService.checkSupplierDuplicationData({
      input: importSuppliers
    }).subscribe(
      (res) => {
        let importData = res['data'].suppliersCheckDuplicationData
        console.log("suppliersCheckDuplicationData  ---->", importData);
        if (!this.isObjectEmpty(importData.alreadyExistSuppliers)) {
          this.alreadyExistSuppliers = importData.alreadyExistSuppliers.length
        }
        if (!this.isObjectEmpty(importData.suppliersInvalidData)) {
          this.suppliersInvalid = importData.suppliersInvalidData.length
        }
        if (!this.isObjectEmpty(importData.newlyAddedSuppliers)) {
          for (let i = 0; i < importData.newlyAddedSuppliers.length; i++) {
            delete importData.newlyAddedSuppliers[i]['__typename']
            this.importSupplierData.push(importData.newlyAddedSuppliers[i])
          }
        }
        console.log("importSupplierData  ---->", this.importSupplierData);
        if (!this.isObjectEmpty(this.importSupplierData)) {
          this.SuppliersVerificationWithSystemtFun()
        } else {
          this.dataVerifyBox = false
        }
        this.importSupplierLoader = false
        this.importSupllierData = []
      }, (err) => {
        console.log('GQL err', err);
        this.importSupplierLoader = false
        this.modalRef.hide()
        this.importSupllierData = []
        this.showToaster('showFirstToast', 'Supplier Not import, Please try again.', 'error')
      }
    )
  }

  sentSupplierForImport = []
  postVerifySuppForImport = []
  noPostVerificationSuppliers = []
  SuppliersVerificationWithSystemtFun() {
    this.importSupplierLoader = true
    this.sentSupplierForImport = []
    this.postVerifySuppForImport = []
    this.noPostVerificationSuppliers = []
    this.manageSupplierService.supplierVerification({
      input: this.importSupplierData
    }).subscribe(
      (res) => {
        this.importSupplierLoader = false
        console.log('SuppliersVerificationWithSystem--> res', res['data'].SuppliersVerificationWithSystem);
        let returnVal = res['data'].SuppliersVerificationWithSystem
        if (returnVal != null) {
          for (let i = 0; i < returnVal.length; i++) {
            if (this.isObjectEmpty(returnVal[i]['postVerifyData'])) {
              delete returnVal[i]['supplier']['__typename']
              returnVal[i]['supplier']['BusinessLocation'] = window.localStorage.getItem('location_id')
              this.noPostVerificationSuppliers.push(returnVal[i]['supplier'])
            }
            if (!this.isObjectEmpty(returnVal[i]['postVerifyData'])) {
              delete returnVal[i]['supplier']['__typename']
              returnVal[i]['supplier']['BusinessLocation'] = window.localStorage.getItem('location_id')
              returnVal[i]['supplier']['selected'] = ''
              this.sentSupplierForImport.push(returnVal[i]['supplier'])

              delete returnVal[i]['postVerifyData'][0]['__typename']
              returnVal[i]['postVerifyData'][0]['BusinessLocation'] = window.localStorage.getItem('location_id')
              returnVal[i]['postVerifyData'][0]['selected'] = ''
              let Country = returnVal[i]['postVerifyData'][0]['Country'].name
              delete returnVal[i]['postVerifyData'][0]['Country']
              let supp = JSON.parse(JSON.stringify(returnVal[i]['postVerifyData'][0]))
              supp['Country'] = Country//returnVal[i]['postVerifyData'][0]['Country'].name
              this.postVerifySuppForImport.push(supp)
            }
          }
          if (this.isObjectEmpty(this.postVerifySuppForImport)) {
            this.createImportSuppliers(false)
            return
          }
          console.log('sentSupplierForImport-->', this.sentSupplierForImport)
          console.log('postVerifySuppForImport-->', this.postVerifySuppForImport)
        }
      }, (err) => {
        this.importSupplierLoader = false
        console.log(' err', err);
        this.showToaster('showFirstToast', 'Something went wrong.', 'error')
      }
    )
  }

  createImportSuppliers(check) {
    let results = this.suggestedSupplierArray.concat(this.givenSupplierArray)
    if (check) {
      if (this.isObjectEmpty(results) || this.postVerifySuppForImport.length != results.length) {
        this.showToaster('showSecondToast', 'Please select one option! "Information You Entered or Verified Information"', 'error')
        return
      }
      for (let i = 0; i < results.length; i++) {
        delete results[i]['index']
        delete results[i]['selected']
      }
    }
    results = this.noPostVerificationSuppliers.concat(results)
    this.importSupplierLoader = true
    this.manageSupplierService.importSuppliersDataMethod({
      input: results
    }).subscribe(
      (res) => {
        this.importSupplierLoader = false
        console.log('importSuppliersData res--->', res['data'].importSuppliersData);
        let importData = res['data'].importSuppliersData
        if (importData != null) {
          this.dataVerifyBox = false
          if (!this.isObjectEmpty(importData.newlyAddedSuppliers)) {
            this.newlyAddedSuppliers = importData.newlyAddedSuppliers.length
          }
        }
      }, (err) => {
        this.importSupplierLoader = false
        // let message=err.graphQLErrors[0].message
        console.log('importSuppliersData err', err);
        this.showToaster('showFirstToast', 'Something went wrong.', 'error')
      }
    )
  }

  suggestedSupplierArray = []
  suggestedSupplierArrayFun(supp) {
    this.postVerifyToaster = true
    let val = this.suggestedSupplierArray.filter((item) => {
      return JSON.stringify(item) == JSON.stringify(supp);
    })
    if (this.isObjectEmpty(val)) {
      delete supp['__typename']
      supp['BusinessLocation'] = window.localStorage.getItem('location_id')
      supp['index'] = this.count
      supp['selected'] = 'sugg'
      // supp['Country']=supp['Country'].name
      this.suggestedSupplierArray.push(supp)
      for (let i = 0; i < this.givenSupplierArray.length; i++) {
        if (Number(this.givenSupplierArray[i]['index']) == Number(this.count)) {
          this.givenSupplierArray.splice(i, 1);
        }
      }
    }
    this.sentSupplierForImport[this.count].selected = ''
    //  if(this.postVerifySuppForImport.length!=this.count+1){
    //   this.nextSupplierForCompare(this.count)
    //   }    
  }

  givenSupplierArray = []
  givenSupplierArrayFun(supp) {
    this.postVerifyToaster = true
    let val = this.givenSupplierArray.filter((item) => {
      return JSON.stringify(item) == JSON.stringify(supp);
    })
    if (this.isObjectEmpty(val)) {
      delete supp['__typename']
      supp['BusinessLocation'] = window.localStorage.getItem('location_id')
      supp['index'] = this.count
      supp['selected'] = 'given'
      this.givenSupplierArray.push(supp)
      for (let i = 0; i < this.suggestedSupplierArray.length; i++) {
        if (Number(this.suggestedSupplierArray[i]['index']) == Number(this.count)) {
          this.suggestedSupplierArray.splice(i, 1);
        }
      }
    }
    this.postVerifySuppForImport[this.count].selected = ''
    // if(this.postVerifySuppForImport.length!=this.count+1){
    // this.nextSupplierForCompare(this.count)
    // }
  }

  count = 0
  nextSupplierForCompare(count) {
    this.count = count + 1
  }

  backSupplierForCompare(count) {
    this.count = count - 1
  }

  postVerifyToaster = true
  closePostToaster() {
    this.showSecondToast = false
    this.postVerifyToaster = true
  }

  ///////////////////////// Import Supplier functions ends here/////////////////////////

  cancelImport() {
    this.importFileName = ''
    this.modalService.hide(1)
    this.importDone.emit(false)
  }

  doneImport(){
    this.importFileName = ''
    this.modalService.hide(1)
    this.importDone.emit(true)
  }

  showFirstToast = false
  showSecondToast = false
  showToaster(toaster, msg, type) {
    this[toaster] = true
    if (toaster == 'showFirstToast') {
      this.showImportToaster = false
    }
    else {
      this.postVerifyToaster = false
    }
    this.toasterMsg = msg
    this.toasterType = type
  }

  showImportToaster = true
  closeImportToaster() {
    this.showFirstToast = false
    this.showImportToaster = true
  }

  closeModel() {
    this.modalRef.hide()
  }

  isObjectEmpty(Obj) {
    for (var key in Obj) {
      if (Obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this.importFileName = null;
  }


}
