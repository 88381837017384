import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { observable, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service'
import { CREATE_SALE_FEATURE_NAME, MODULE_PERMISSIONS_NAME, STOCK_FEATURE_NAME, SYSTEM_FEATURE_NAME } from '../services/authentication/permissions-name';
import { CommonService } from '../services/common/common.service';
import { LoginauthService } from './loginauth.service'

@Injectable({
  providedIn: 'root'
})
export class PermissionGuardService implements CanActivate, CanActivateChild {

  constructor(private _authentication: AuthenticationService,
    private _loginAuth: LoginauthService,
    private router: Router,
    private commonService: CommonService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (route.data && route.data.hasOwnProperty('menu') && !route.data.hasOwnProperty('feature')) {
      return this.getUserAllowModule(route.data.menu)
    } else if (route.data && route.data.hasOwnProperty('feature')) {
      let action = route.data.action ? route.data.action : null
      return this.getUserAllowdFeature(route.data.menu, route.data.feature, action)
    } else {
      return true
    }
  }


  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }


  checkUserModulePermission(module) {
    if (this._loginAuth.lunaVerifyUser()) {
      if (module == MODULE_PERMISSIONS_NAME.dashboard_menu) {
        return true
      }
      if (!this._authentication.isModuleAllowed(module)) {
        console.log('Module not allowed', module, 'Allowed modue', this._authentication.allowedModules)
        this.commonService.showToaster(['PLEASE CONTACT WITH YOUR SYSTEM ADMINISTRATOR FOR PERMISSION! THANKS','error'])
        //this.router.navigateByUrl(`403`)
        return false
      }
      return true
    }
    this.router.navigate['/login']
    return false
  }


  getUserAllowModule(moduleName): Observable<boolean> {
    if (this._authentication.allowedModules.length == 0)
      return new Observable<boolean>((observer) => {
        this._authentication.getLoggedInUserAllowModulesObservable()
          .subscribe(
            (res) => {
              if (res['data'].getLoggedInUserAllowModules == null) {
                this.signout()
                return
              }
              this._authentication.allowedModules = res.data.getLoggedInUserAllowModules
              observer.next(this.checkUserModulePermission(moduleName))
              observer.complete();
            }, (err) => {
              console.log('err is', err.message);
            })
      })
    else {
      return new Observable<boolean>((observer) => {
        observer.next(this.checkUserModulePermission(moduleName))
        observer.complete();
      })
    }
  }

  checkUserModuleFeaturePermission(module, feature, action) {
    if (this._loginAuth.lunaVerifyUser()) {
      if (!this._authentication.isFeatureAllowed(feature) && !this._authentication.isActionAllowed(feature, action)) {
        if (feature == SYSTEM_FEATURE_NAME.business_management_tab) {
          return this._authentication.isChangeLandingPage(module)
          // && this.commonService.getConfigurationElementByKey('processRepair').value == 'false'
        } else if (feature == 'device_list_tab' || feature == STOCK_FEATURE_NAME.manage_service_tab) {
          return true
        } else {
          this.commonService.showToaster(['PLEASE CONTACT WITH YOUR SYSTEM ADMINISTRATOR FOR PERMISSION! THANKS','error'])
          //this.router.navigateByUrl(`403`);
          return false
        }
      }
      return true
    }
    this.router.navigate['/login']
    return false
  }

  getUserAllowdFeature(module, feature, action): Observable<boolean> {
    if (this._authentication.activeModule === module && this._authentication.allowedModuleFeatures.length > 0) {
      return new Observable<boolean>((observer) => {
        observer.next(this.checkUserModuleFeaturePermission(module, feature, action))
        observer.complete();
      })
    } else {
      return new Observable<boolean>((observer) => {
        this._authentication.getLoggedInUserModulePermissionsObservable(module)
          .subscribe((res) => {
            this._authentication.activeModule = module
            this._authentication.allowedModuleFeatures = res.data.getLoggedInUserModulePermissions.features
            this._authentication.allowedFeatureActions = res.data.getLoggedInUserModulePermissions.permissions
            observer.next(this.checkUserModuleFeaturePermission(module, feature, action))
            observer.complete();
          })
      })
    }
  }

  signout() {
    localStorage.removeItem('StartModel');
    localStorage.removeItem('location_id');
    localStorage.removeItem('user_id')
    localStorage.removeItem('User_Id');
    localStorage.removeItem('token');
    this.router.navigate(['/Login']);
  }

}
