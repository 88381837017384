import { Injectable, Injector, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { Subject } from "rxjs";
import {
  GetAllMinimizersGQL,
  DeleteMinimizerGQL,
  MinimizerEnum,
  AllowedTransactionStatus,
  AllowedTransactionType,
  CreatePurchaseOrderMinimizerGQL,
  AllowedOrdertStatus,
  CreateRepairRoomMinimizerGQL
} from "src/app/generated/graphql";
import { CreateSaleGQLService } from "../create-sale/create-sale-gql.service";
import { SaleCartService } from "../create-sale/sale-cart.service";
import { PoMinimizerService } from "../orders/po-minimizer.service";
import { RepairRoomService } from "../repair-room/repair-room.service";
import { PurchaseOrderMinimizerService } from "../purchase-order/purchase-order-minimizer.service";
import { StockService } from "../stock/stock.service";

enum EMPTY_TAB_NAME {
  create_sale = "CREATE A SALE",
  po = "PO",
  home = "HOME",
  tickets = "TICKETS",
  repair_room = "REPAIR ROOM",
  customers = "CUSTOMERS",
  discounts = "DISCOUNTS",
  system = "SYSTEM",
  reports = "REPORTS"
}

enum DEFAULT_ROUTE {
  create_sale = "Pointofsale/CreateSale/Order",
  po = "Pointofsale/Stock/Orders/PO"
}

@Injectable({
  providedIn: "root"
})
export class MinimizerService implements OnInit {
  private _minimizerList = [];
  private _activeIndex = 0;
  private _maxMinmizer = 5;
  private _minimizer_type = "";
  private _isShowMinimizer: boolean = false;

  loadPOMinimizer = true;

  public get isShowMinimizer() {
    return this._isShowMinimizer;
  }

  public set isShowMinimizer(isShowMinimizer) {
    this._isShowMinimizer = isShowMinimizer;
  }

  public get minimizer_type() {
    return this._minimizer_type;
  }
  public set minimizer_type(value) {
    this._minimizer_type = value;
  }

  public get maxMinmizer() {
    return this._maxMinmizer;
  }
  public set maxMinmizer(value) {
    this._maxMinmizer = value;
  }

  public get activeIndex() {
    return this._activeIndex;
  }
  public set activeIndex(value) {
    this._activeIndex = value;
    this.minimizer_type !== ""
      ? localStorage.setItem(this.minimizer_type, this.activeIndex.toString())
      : "";
  }

  public get minimizerList() {
    return this._minimizerList;
  }
  public set minimizerList(value) {
    this._minimizerList = value;
  }

  constructor(
    private getAllMinimizersGQL: GetAllMinimizersGQL,
    private deleteMinimizerGQL: DeleteMinimizerGQL,
    private router: Router,
    private saleCartService: SaleCartService,
    private _createSaleGQL: CreateSaleGQLService,
    private createPurchaseOrderMinimizer: CreatePurchaseOrderMinimizerGQL,
    private createRepairRoomMinimizerGQL: CreateRepairRoomMinimizerGQL,
    private poMinimizerService: PoMinimizerService,
    private _repairRoomService: RepairRoomService,
    private stockService: StockService,
    private minimizerServicePO: PurchaseOrderMinimizerService
  ) {}

  ngOnInit() {}

  getAllMinimizers(
    minimizer_type,
    location_id = localStorage.getItem("location_id"),
    fatchAgain = true
  ) {
    this.getAllMinimizersGQL
      .watch({ location_id: location_id, minimizer_type: minimizer_type })
      .valueChanges.subscribe(res => {
        this.minimizer_type = minimizer_type;
        this.minimizerList = res.data.getAllMinimizers;
        this.activeIndex = Number(localStorage.getItem(minimizer_type)) || 0;
        console.log(
          "Active Index:",
          this.activeIndex,
          "Length:",
          this.minimizerList.length
        );
        minimizer_type == MinimizerEnum.CreateSale &&
          this.setCreateSaleActiveIndex();
        minimizer_type == MinimizerEnum.RepairRoom &&
          this.setRepairRoomActiveIndex();
        fatchAgain && this.afterGetAllMinimzer(minimizer_type);
      });
  }

  setCreateSaleActiveIndex() {
    let minimizerIndex = -1;
    setTimeout(() => {
      minimizerIndex = this._minimizerList.findIndex(
        minizer => minizer._id == this.saleCartService.createSaleInput._id
      );
      minimizerIndex !== -1 ? (this.activeIndex = minimizerIndex) : "";
    }, 300);
  }

  setRepairRoomActiveIndex() {
    setTimeout(() => {
      this.activeIndex = this._minimizerList.findIndex(
        minizer => minizer._id == this._repairRoomService.getTaskId()
      );
      if (
        this.activeIndex == -1 &&
        this.router.url.includes("RepairRoom/detail") &&
        this.minimizerList.length < 5
      ) {
        this.minimizerList.push({
          _id: this._repairRoomService.getTaskId(),
          name: EMPTY_TAB_NAME.repair_room,
          amount: 0,
          customer:this._repairRoomService.customer,
          route_url:
            "Pointofsale/RepairRoom/detail/" +
            this._repairRoomService.getTaskId(),
          minimizer_type: this.minimizer_type
        });
        this.activeIndex = this.minimizerList.length - 1;
      }
    }, 300);
  }

  afterGetAllMinimzer(minimizer_type) {
    if (!this.minimizerList || this.minimizerList.length == 0) {
      this.createNewTab(minimizer_type);
    } else if (this.activeIndex < this.minimizerList.length) {
      this.onRestoreMinimizer(this.minimizerList[this.activeIndex]);
    } else if (this.activeIndex > this.minimizerList.length - 1) {
      this.activeIndex = this.minimizerList.length - 1;
      this.onRestoreMinimizer(minimizer_type);
    }
  }

  deleteMinimizer(minimizerID, minimizer_type, location_id, indexToRemove) {
    this.deleteMinimizerGQL
      .mutate({
        transactionID: minimizerID,
        location_id: location_id,
        minimizer_type: minimizer_type
      })
      .subscribe(res => {
        this.minimizerList = this.minimizerList.filter(
          list => list._id !== minimizerID
        );
        this.mangeActiveIndexState(indexToRemove);
      });
  }

  mangeActiveIndexState(indexToRemove) {
    indexToRemove == -1 ? (indexToRemove = this.activeIndex) : "";
    if (this.activeIndex == indexToRemove) {
      if (this.minimizerList.length) {
        this.activeIndex = this.minimizerList.length - 1;
      } else {
        this.activeIndex = 0;
      }
      this.onRestoreMinimizer(this.minimizerList[this.activeIndex]);
    }
  }

  loading = false;
  createNewTab(minimizer_type) {
    if (this.minimizerList.length - 1 >= this.maxMinmizer || this.loading) {
      return;
    }
    switch (minimizer_type) {
      case MinimizerEnum.CreateSale: {
        this.loading = true;
        if(this.saleCartService.createSaleInput._id == null){
          this.onSaveTabChanges(minimizer_type)
        }else{
          this.newTabCreateSale();
        }
        break;
      }
      case MinimizerEnum.RepairRoom: {
        this.createRepairRoomMinizer();
        break;
      }
      case MinimizerEnum.Po: {
        let data = this.minimizerServicePO.getDefaultObject();
        this.createPOMinimizer(data, null);
        break;
      }
    }
  }

  createRepairRoomMinizer() {
    this.loading = true;
    this.createRepairRoomMinimizerGQL
      .mutate({
        location_id: localStorage.getItem("location_id"),
        transactionID: this._repairRoomService.getTaskId()
      })
      .subscribe(
        res => {
          //this.getAllMinimizers(MinimizerEnum.RepairRoom);
          this.router.navigate(["Pointofsale", "RepairRoom"]);
          this.loading = false;
        },
        err => {
          this.loading = false;
        }
      );
  }

  newTabCreateSale() {
    this.saleCartService.showLoadingScreen(true);
    this.loading = true;
    this.createSaleMinimizer().subscribe(
      res => {
        this.saleCartService.showLoadingScreen(false);
        this.saleCartService.onDiscardOrder();
        this.router.navigateByUrl(`Pointofsale/CreateSale/Order`);
        this.loading = false;
        this.onSaveTabChanges(MinimizerEnum.CreateSale);
      },
      err => {
        this.loading = false;
      }
    );
  }

  onSaveTabChanges(minimizer_type) {
    switch (minimizer_type) {
      case MinimizerEnum.CreateSale: {
        this.saleCartService.showLoadingScreen(true);
        this.loading = true;
        this.createSaleMinimizer().subscribe(
          res => {
            this.saleCartService.createSaleInput._id =
              res.data.createSaleV4._id;
            this.getAllMinimizers(
              this.minimizer_type,
              localStorage.getItem("location_id"),
              false
            );
            this.loading = false;
            this.saleCartService.showLoadingScreen(false);
          },
          err => {
            this.loading = false;
            this.saleCartService.showLoadingScreen(false);
            console.log("Create Sale error", err.message);
          }
        );
        break;
      }
    }
  }

  onRestoreMinimizer(minizerObject) {
    if (!minizerObject) return;
    switch (minizerObject.minimizer_type) {
      case MinimizerEnum.CreateSale: {
        this.restoreCreateSaleRecord(minizerObject);
        break;
      }
      case MinimizerEnum.Po: {
        if (!this.loadPOMinimizer) {
          return;
        }
        this.restorePOMinimizer(minizerObject);
        break;
      }
      case MinimizerEnum.RepairRoom: {
        this.restoreRepairRoomMinimzer(minizerObject);
        break;
      }
    }
  }

  restoreRepairRoomMinimzer(minimizerObject) {
    this.router.navigate([
      "Pointofsale",
      "RepairRoom",
      "detail",
      minimizerObject._id
    ]);
  }

  restoreCreateSaleRecord(minizerObject) {
    this.saleCartService.customer = null;
    this.saleCartService.getOrderById(minizerObject._id);
    this.router.navigateByUrl(DEFAULT_ROUTE.create_sale);
  }

  createSaleMinimizer() {
    let createSaleInput = this.saleCartService.getCreateSaleInput();
    createSaleInput["transaction_status"] = AllowedTransactionStatus.Minimize;
    return this._createSaleGQL.onCreateSale(
      createSaleInput["_id"],
      this.saleCartService.createSaleInputBackendMapper()
    );
  }

  createPOMinimizer(data, id) {
    this.createPurchaseOrderMinimizer
      .mutate({
        input: data,
        transactionId: id
      })
      .subscribe(res => {
        localStorage.setItem("po", this.minimizerList.length.toString());
        this.getAllMinimizers(MinimizerEnum.Po);
        // this.activeIndex = this.minimizerList.length - 1
        // this.minimizerList = []
        // this.minimizer_type = ''
        // this.router.navigateByUrl('Pointofsale/Stock/Orders')
      });
  }

  poMinimizerLoading = false;
  restorePOMinimizer(minizerObject) {
    if (this.poMinimizerLoading) {
      return;
    }
    this.poMinimizerLoading = true;
    return new Promise(async resolve => {
      this.stockService.getOrderByID(minizerObject._id).valueChanges.subscribe(
        res => {
          this.poMinimizerLoading = false;
          console.log(
            "current minimizer data",
            res["data"].getPurchaseOrderbyID
          );
          console.log(minizerObject, 'minizerObject')
          this.minimizerServicePO.setPOdata(res["data"].getPurchaseOrderbyID);
          if (res["data"].getPurchaseOrderbyID.Supplier != null) {
            this.minimizerServicePO.supplier =
              res["data"].getPurchaseOrderbyID.Supplier.supplier_company ==
              "Unknown Supplier"
                ? null
                : res["data"].getPurchaseOrderbyID.Supplier;
          } else {
            this.minimizerServicePO.supplier =
              res["data"].getPurchaseOrderbyID.Supplier;
          }
          this.minimizerServicePO.transaction =
            res["data"].getPurchaseOrderbyID._id;
          // this.minimizerServicePO.productsOrdered = res['data'].getPurchaseOrderbyID.TransactionPurchaseLine 
          this.minimizerServicePO.filters = minizerObject.filterJson;
          this.minimizerServicePO.autoUpdate(true);
          minizerObject.route_url == null ?  this.router.navigateByUrl('/PurchaseOrder') :  this.router.navigateByUrl(minizerObject.route_url);
          resolve(true);
        },
        err => {
          resolve(false);
          this.poMinimizerLoading = false;
          console.log("err is ", err);
          this.router.navigateByUrl("/Pointofsale/Stock/Orders");
        }
      );
    });
    return;
    return new Promise(async resolve => {
      this.updateFilters(minizerObject.filterJson);
      this.poMinimizerService.setMinimizerID(minizerObject._id);
      this.poMinimizerService.getOrderDetail(minizerObject._id);
      this.router.navigateByUrl(minizerObject.route_url);
      setTimeout(function() {
        resolve(true);
      }, 5000);
    });
  }

  updatePOMinimizer() {
    let data = this.minimizerServicePO.getUpdatedPO();
    if (data == undefined) return;
    // delete data["TransactionPurchaseLines"];
    data["discount_amount"] = parseFloat(data["discount_amount"]);
    data["discount_value"] = parseFloat(data["discount_value"]);
    this.createPurchaseOrderMinimizer
      .mutate({
        input: data,
        transactionId: this.minimizerServicePO.transaction
      })
      .subscribe(res => {
        // this.getAllMinimizers(MinimizerEnum.Po)
      });
  }

  updatePOMinimizerWithParameter(data, id) {
    this.createPurchaseOrderMinimizer
      .mutate({
        input: data,
        transactionId: id
      })
      .subscribe(res => {});
  }

  private refreshFilters = new Subject<any>();
  refreshFilters$ = this.refreshFilters.asObservable();
  updateFilters(filter) {
    if (filter == null) {
      return;
    }
    this.refreshFilters.next(JSON.parse(JSON.stringify(filter)));
  }
}
