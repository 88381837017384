import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-configuration',
  templateUrl: './store-configuration.component.html',
  styleUrls: ['./store-configuration.component.css']
})
export class StoreConfigurationComponent implements OnInit {

  constructor() { }
  questionNo: number = 1
  selectValue = 'Select the option'
  selectRegPrinter = 'Select a regular printer'
  selectThermalPrinter = 'Select a thermal printer'
  selectFiscalYear = 'Last day of December'
  selectCCMerchants = "Stripe"
  subQuestion1: boolean = false
  subQuestion1A: boolean = false
  subQuestion2: boolean = false
  subQuestion3: boolean = false
  subQuestion4: boolean = false
  subQuestion5: boolean = false
  subQuestion6: boolean = false
  subQuestion7: boolean = false
  subQuestion8: boolean = false
  subQuestion9: boolean = false
  subQuestion10:boolean = false
  subQuestion11:boolean = false
  subQuestion12:boolean = false
  subQuestion13:boolean = false
  subQuestion14:boolean = false
  subQuestion15:boolean = false
  subQuestion16:boolean = false
  subQuestion17:boolean = false
  progress = 1
  ngOnInit() {
  }

  endQuestions() {
  }

  goToNextConfiguration() {
  }

  finishConfiguration() {
  }

  
  goToNextQuestion() {
    document.getElementById('question' + this.questionNo).classList.remove('show', 'active')
    switch (this.questionNo) {
      case 37: {
        break
      }
      default: {
        this.questionNo += 1
        document.getElementById('question' + this.questionNo).classList.add('show', 'active')
        this.progress = Math.floor((this.questionNo / 33) * 100)
        break
      }
    }
  }
  
  goToQuestionNo(questionNo) {
    document.getElementById('question' + this.questionNo).classList.remove('show', 'active')
    this.questionNo = questionNo
    document.getElementById('question' + this.questionNo).classList.add('show', 'active')
  }

  backToLastQuestion() {
    document.getElementById('question' + this.questionNo).classList.remove('show', 'active')
    this.questionNo -= 1
    document.getElementById('question' + this.questionNo).classList.add('show', 'active')
  }

  scrollToView(subQuestionId) {
    setTimeout(() => {
      document.getElementById(subQuestionId).scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 500)
  }

  scrollToViewOnWheel(viewID, wheelId) {
    document.getElementById(wheelId).addEventListener("wheel",()=>{
      setTimeout(() => {
        document.getElementById(viewID).scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 200)
    })
  }
  
  changeValue(type, val) {
    this[type] = val
  }

}
