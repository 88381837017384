import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rolesPermissionsName'
})
export class RolesPermissionsNamePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let val = value.charAt(0).toUpperCase() + value.slice(1).substr(0, value.lastIndexOf("_")).replace(new RegExp('_', 'g'), ' '); 
    if(val.includes('user')){
     val = val.replace('user','employee')
    }
    return val
  }

}
