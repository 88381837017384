import { Injectable } from "@angular/core";
import { CustomerGQLService } from "../customer/customerGQL.service";
import { CreateSaleService } from "./create-sale.service";
import { BehaviorSubject, concat, Observable, of, Subject } from "rxjs";
import {distinctUntilChanged, debounceTime} from "rxjs/operators"
import { Router } from "@angular/router";
import { CreateSaleGQLService } from "./create-sale-gql.service";
import {
  AllowedTransactionType,
  AllowedTransactionStatus,
  SellLineProductType,
  InputProductType,
  ProductType,
  CategoryTypeEnum,
  SellLineEnumV4,
  Customer
} from "src/app/generated/graphql";
import * as createSaleDummydata from "src/app/modules/shared/data-role-permission.json";
import { CustomerService } from "src/app/services/customer/customer.service";
import { v4 as uuidv4 } from "uuid";
import { CommonService } from "../common/common.service";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { LocalStoreService } from "src/app/modules/shared/service/local-store.service";

@Injectable({
  providedIn: "root"
})
export class SaleCartService {
  locationId = window.localStorage.getItem("location_id");

  modalInputObject: {
    iconClass: "icon-delete";
    headerText: String;
    yesButtonText: String;
    noButtonText: String;
    modalLayout: {
      class: String;
      backdrop: String;
      keyboard: boolean;
    };
  };
  
  createSaleInput = {
    _id: null,
    transaction_date: new Date(),
    transaction_type: AllowedTransactionType.Sell,
    transaction_status: AllowedTransactionStatus.Minimize,
    sub_total_amount: 0,
    Tax: null,
    tax_amount: 0,
    tax_value: 0,
    is_tax_percentage: true,
    discount_amount: 0.0,
    discount_value: 0,
    is_discount_percentage: false,
    total_amount: 0,
    is_apply_sale_tax: true,
    is_private: false,
    additional_notes: "",
    Customer: this.customer_id,
    BusinessLocation: this.locationId,
    route_url: "",
    filterJson: "",
    saleCart: []
  };
  
  private loadingScreenSource = new BehaviorSubject(false);
  $loadingScreen = this.loadingScreenSource.asObservable();

  private _data = createSaleDummydata["default"];
  public get data() {
    return this._data;
  }

  private defaultUrl: string = "Pointofsale/CreateSale/Order";
  maxDiscount = 100;

  private _customer: Customer = null;
  private _customer_id: string = null;
  customers$: Observable<any>;
  customerLoading = false;
  customerInput$ = new Subject<string>();

  get customer() {
    return this._customer;
  }

  set customer(cust) {
    if (cust) {
      let customer = {
        _id: cust._id,
        first_name: cust.first_name,
        last_name: cust.last_name,
        full_name: cust.full_name ? cust.full_name : cust.first_name + ' ' + cust.last_name,
        email: cust.email,
        phone: cust.phone,
        is_tax_exempted: cust.is_tax_exempted
      }
      this.getCustomers(customer);
      this._customer = customer;
      this.customer_id = customer._id
    } else {
      this._customer = null;
      this.customer_id = null;
      this.getCustomers(null);
    }

    this.taxesApplyOnCustomer();
  }

  get customer_id() {
    return this._customer_id;
  }

  set customer_id(customer_id) {
    this._customer_id = customer_id;
  }

  
  constructor(
    private customerGQLService: CustomerGQLService,
    private createSaleService: CreateSaleService,
    private router: Router,
    private createSaleGQLService: CreateSaleGQLService,
    private _customerService: CustomerService,
    private _commonService: CommonService,
    private fb: FormBuilder,
    private ls: LocalStoreService
  ) {
    this.onDiscardOrder();
  }

  getCustomers(customer) {
    this.customers$ = concat(
      of(customer ? [...[],customer] : []), // default items
      this.customerInput$.pipe(
        filter(res => {
          return res !== null && res.length >= 2;
        }),
        distinctUntilChanged(),
        tap(() => (this.customerLoading = true)),
        switchMap(term =>
          this.customerGQLService.getSaleCartCustomersList(term).pipe(
            map(({ data }) => {
              return data.getCustomersWithSearch.customers;
            }),
            catchError(() => of([])), // empty list on error
            tap(() => (this.customerLoading = false))
          )
        )
      )
    );
  }

  setModalInput(input) {
    this.modalInputObject = input;
  }

  getModalInput() {
    return this.modalInputObject;
  }

  initCreateSaleInput() {
    this.createSaleInput = {
      _id: null,
      transaction_date: new Date(),
      transaction_type: AllowedTransactionType.Sell,
      transaction_status: AllowedTransactionStatus.Minimize,
      sub_total_amount: 0,
      Tax: null,
      tax_amount: 0,
      tax_value: 0,
      is_tax_percentage: true,
      discount_amount: 0.0,
      discount_value: 0,
      is_discount_percentage: false,
      total_amount: 0,
      is_apply_sale_tax: true,
      is_private: false,
      additional_notes: "",
      Customer: this.customer_id,
      BusinessLocation: this.locationId,
      filterJson: "",
      route_url: "",
      saleCart: []
    };
    this.getSaleTaxes();
  }

  getCreateSaleInput() {
    return this.createSaleInput;
  }

  generateKey() {
    return uuidv4();
  }

  saleTaxes = [];
  getSaleTaxes() {
    this._commonService.getAllTaxes().subscribe(
      res => {
        this.saleTaxes = res["data"].taxs.filter(tax => {
          return tax.tax_type == "sale_tax";
        });
        this.taxesApplyOnCustomer();
      },
      err => {
        console.log("all taxes are", err);
      }
    );
  }

  taxesApplyOnCustomer() {
    let taxesOnCustomer = this.saleTaxes;
    this.createSaleInput["tax_value"] = 0;
    this.createSaleInput["is_tax_percentage"] = true;
    if (this.customer && this.customer.is_tax_exempted) {
      taxesOnCustomer = this.saleTaxes.filter(tax => !tax.is_tax_exemptible);
    }
    taxesOnCustomer.forEach(tax => {
      this.createSaleInput["tax_value"] = Number(
        (
          Number(this.createSaleInput["tax_value"]) + Number(tax.amount)
        ).toFixed(2)
      );
    });
    this.onCalculateSaleTax();
  }

  getSaleTaxsByBusinessLocation() {
    this.createSaleGQLService
      .getSaleTaxByBusinessLocation(localStorage.getItem("location_id"))
      .valueChanges.subscribe(
        res => {
          this.createSaleInput["is_tax_percentage"] = true;
          this.createSaleInput["tax_value"] =
            res.data.BusinessLocationById.sales_tax;
          this.onCalculateSaleTax();
        },
        err => {
          this.getSaleTaxsByBusinessLocation();
          console.log("GQL Error", err);
        }
      );
  }

  getOrderById(id, location_id = localStorage.getItem("location_id")) {
    this.showLoadingScreen(true);
    this.createSaleGQLService.getOrderById(id, location_id).subscribe(
      res => {
        this.createSaleInputFrontEndMapper(res.data.getCartDataV4);
        this.showLoadingScreen(false);
        if (
          this.createSaleInput["transaction_status"] ==
          AllowedTransactionStatus.Minimize
        ) {
          var lisitngFilter = JSON.parse(res.data.getCartDataV4.filterJson);
          setTimeout(() => {
            this.onRestoreListingFilter(lisitngFilter);
          }, 100);
        } else {
          this.createSaleMinimizer(AllowedTransactionStatus.Minimize);
          this.router.navigateByUrl(`Pointofsale/CreateSale/Order`);
        }
        res.data.getCartDataV4.Customer
          ? this.createSaleService.setSectionTiles("", "", "Device List", "")
          : this.createSaleService.setSectionTiles("", "", "Customer List", "");
      },
      err => {
        this.showLoadingScreen(false);
        this.createSaleService.showToaster([err.message, "error"]);
      }
    );
  }

  onRestoreListingFilter(lisitngFilter) {
    lisitngFilter && lisitngFilter.customerFilter
      ? this._customerService.onCustomerListinRefresh(
          lisitngFilter.customerFilter
        )
      : "";
    lisitngFilter && lisitngFilter.deviceFilter
      ? this.createSaleService.getDeviceListWithFilter(
          lisitngFilter.deviceFilter
        )
      : "";
    lisitngFilter && lisitngFilter.productFilter
      ? this.createSaleService.getProductListWithFilter(
          lisitngFilter.productFilter
        )
      : "";
  }

  setCreateSaleInput(createSaleInput = this.createSaleInput) {
    this.createSaleInput = createSaleInput;
  }

  getSaleCart() {
    return this.createSaleInput.saleCart;
  }

  setSaleCart(saleCart) {
    this.createSaleInput.saleCart = saleCart;
  }

  updateItemFromSaleCart(product: {}): void {
    this.createSaleInput.saleCart[
      this.createSaleInput.saleCart.findIndex(
        item => item._id == product["_id"]
      )
    ] = product;
  }

  onDiscardOrder() {
    this.initCreateSaleInput();
    this.customer = null;
    this.createSaleService.setSectionTiles(
      "ORDER SUMMARY",
      "PRODUCT LIST",
      "CUSTOMER LIST",
      "NOTES"
    );
  }

  onInitSaleCart() {
    this.createSaleInput.saleCart = [];
  }

  getCustomerById(customerId) {
    //this.showLoadingScreen(true)
    this.customerGQLService
      .getCustomerById(customerId, this.locationId)
      .valueChanges.subscribe(
        res => {
          //this.showLoadingScreen(false)
          this.customer = res.data.getCustomerById;
        },
        err => {
          //this.showLoadingScreen(false)
          this.createSaleService.showToaster([err.message, "error"]);
        }
      );
  }

  showToaster(message, type) {
    this.createSaleService.showToaster([message, type]);
  }

  loading: Boolean = false;
  showLoadingScreen(isShow: boolean) {
    this.loading = isShow;
  }

  onCalculateSubTotal() {
    this.createSaleInput.sub_total_amount = 0;
    this.createSaleInput.saleCart.forEach(element => {
      this.createSaleInput.sub_total_amount += Number(
        element.total_amount || 0
      );
    });
    this.onCalculateDiscount();
    this.onCalculateSaleTax();
    this.onCalculateTotalAmount();
  }

  onCalculateDiscount() {
    let storePackage = this.ls.getDecrypt('store').business_location_package

    if (this.createSaleInput["is_discount_percentage"]) {
      if(Number(this.createSaleInput["discount_value"]) > storePackage.policy.sale_max_invoice_discount_percentage){
        this.createSaleService.showToaster(["Discount percentage can't be greater then " + storePackage.policy.sale_max_invoice_discount_percentage, 'warning'])
        this.createSaleInput["discount_value"] = storePackage.policy.sale_max_invoice_discount_percentage
      }
      if (Number(this.createSaleInput["discount_value"]) > this.maxDiscount) {
        this.createSaleInput["discount_value"] = this.maxDiscount;
        //this.createSaleService.showToaster(["Discount percentage can't be greater the 100", 'warning'])
      }
      this.createSaleInput["discount_amount"] = parseFloat(
        this.percentage(
          Number(this.createSaleInput["discount_value"]),
          this.createSaleInput["sub_total_amount"] - this.discountWithOutGift()
        )
      );
    } else {

      if(Number(this.createSaleInput["discount_value"]) > storePackage.policy.sale_max_invoice_discount_fixed){
        this.createSaleService.showToaster(["Discount amount can't be greater then " + storePackage.policy.sale_max_invoice_discount_fixed, 'warning'])
        this.createSaleInput["discount_value"] = storePackage.policy.sale_max_invoice_discount_fixed
      }

      if (
        Number(this.createSaleInput["discount_value"]) >
        Number(
          this.createSaleInput["sub_total_amount"] - this.discountWithOutGift()
        )
      ) {
        //this.createSaleService.showToaster(["Discount can't be greater the sub total amount", 'warning'])
        this.createSaleInput["discount_value"] = Number(
          (
            this.createSaleInput["sub_total_amount"] -
            this.discountWithOutGift()
          ).toFixed(2)
        );
      }
      this.createSaleInput["discount_amount"] = Number(
        this.createSaleInput["discount_value"]
      );
    }
    this.onCalculateSaleTax();
    this.onCalculateTotalAmount();
  }

  discountWithOutGift() {
    let giftCardTotal = 0;
    this.getSaleCart().forEach(product => {
      giftCardTotal +=
        product.product_type == "giftCard" && product.isSaved
          ? Number(product.total_amount)
          : 0;
    });
    return giftCardTotal;
  }

  onCalculateTotalAmount() {
    this.createSaleInput["total_amount"] =
      Number(this.createSaleInput["sub_total_amount"]) -
      this.createSaleInput["discount_amount"];
    if (this.createSaleInput["is_apply_sale_tax"])
      this.createSaleInput["total_amount"] += this.createSaleInput[
        "tax_amount"
      ];
  }

  onCalculateSaleTax() {
    if (this.createSaleInput["is_apply_sale_tax"]) {
      if (this.createSaleInput["is_tax_percentage"]) {
        this.createSaleInput["tax_amount"] = parseFloat(
          this.percentage(
            Number(this.createSaleInput["tax_value"]),
            this.createSaleInput["sub_total_amount"] -
              this.createSaleInput["discount_amount"]
          )
        );
      } else {
        this.createSaleInput["tax_amount"] =
          this.createSaleInput["sub_total_amount"] +
          Number(this.createSaleInput["tax_value"].toFixed(2));
      }
    } else {
      this.createSaleInput["tax_amount"] = 0;
    }
  }

  percentage(percent, total) {
    return ((percent / 100) * total).toFixed(2);
  }

  assignProductType(product: any) {
    if (product["is_product"] && !product["is_bundle_product"]) {
      //simple product
      product.productTypeClass = "addingProduct";
      product.iconClass = "icon-product";
      product.sell_line_product_type = SellLineProductType.Product;
    } else if (!product["is_product"] && !product["is_bundle_product"]) {
      // Simple service
      product.productTypeClass = "addingService";
      product.iconClass = "icon-service";
      product.sell_line_product_type = SellLineProductType.Service;
    } else if (
      product["is_product"] &&
      product["is_bundle_product"] &&
      product["bundle_products"]["is_manufactured_qty"]
    ) {
      // Manufactured Bundle  QTY Check
      product.productTypeClass = "addingManBundleProduct";
      product.iconClass = "icon-manufacture_bundle_product";
      product.sell_line_product_type = SellLineProductType.ManufacturedProduct;
      //this.isManufacturedProduct = true
    } else if (
      product["is_product"] &&
      product["is_bundle_product"] &&
      !product["bundle_products"]["is_manufactured_qty"]
    ) {
      // Bundle Product
      product.productTypeClass = "addingBundleProduct";
      product.iconClass = "icon-bundle_product";
      product.sell_line_product_type = SellLineProductType.BundleProduct;
    } else if (!product["is_product"] && product["is_bundle_product"]) {
      // Bundle service
      product.productTypeClass = "addingBundleService";
      product.iconClass = "icon-bundle_service";
      product.sell_line_product_type = SellLineProductType.BundleService;
    }
  }

  addItemToDeviceIfSelected(
    product,
    saleCart: any[] = this.createSaleInput.saleCart
  ) {
    if (
      product.product_type == "giftCard" ||
      product.product_type == "custom" ||
      product.is_device ||
      (product.category_type &&
        (product.category_type.category_name == CategoryTypeEnum.Device ||
          product.category_type.category_name == CategoryTypeEnum.Accessories))
    )
      return false;
    //search for selected device
    let index = saleCart.findIndex(
      item => item.is_device && item.isOpen == true
    );
    //if found
    if (index !== -1) {
      //if device already have product
      let device = saleCart[index];
      let productIndex = device.deviceProducts.findIndex(
        item => item._id == product["_id"]
      );
      //if found
      if (productIndex !== -1) {
        //Service can only be added one time
        if (!product["is_device"] && !product["is_product"]) {
          // Service
          this.createSaleService.showToaster([
            "Service already added in device",
            "warning"
          ]);
          this.scrollToProductView(product.key);
          return true;
        }
        device.deviceProducts[productIndex].quantity =
          parseFloat(device.deviceProducts[productIndex].quantity) + 1;
        this.scrollToProductView(product.key);
      } else {
        product["key"] = uuidv4();
        if (this.isProductDeviceCompatible(product, device)) {
          if (!this.addItemToService(product, device)) {
            if (
              product.sell_line_product_type == SellLineProductType.Service ||
              product.sell_line_product_type ==
                SellLineProductType.BundleService
            ) {
              product.sell_line_product_type == SellLineProductType.Service
                ? (product = this.addCompatibleServiceProduct(product, device))
                : "";
              product.quantity = 1;
              device.deviceProducts.push(product);
              this.scrollToProductView(product.key);
            } else {
              this.createSaleService.showToaster([
                "product can't be added in device directly",
                "warning"
              ]);
            }
          }
        } else {
          this.createSaleService.showToaster([
            product.product_name +
              " not compatible to " +
              device.deviceBrand.brand_name,
            "warning"
          ]);
        }
      }
      return true;
    }
    return false;
  }

  addCompatibleServiceProduct(product, device) {
    product.serviceProduct = product.serviceProduct
      ? product.serviceProduct
      : [];

    product.servicesBrandModel.forEach(servicebrandModal => {
      if (
        servicebrandModal.ServiceDeviceModel._id == device.deviceModel._id &&
        servicebrandModal.ServiceBrand._id == device.deviceBrand._id
      ) {
        if (servicebrandModal.ServiceItem) {
          servicebrandModal.ServiceItem.quantity = 1;
          product.serviceProduct.push(servicebrandModal.ServiceItem);
        }
      }
    });
    return product;
  }

  isProductDeviceCompatible(product, device) {
    let isProductDeviceCompatible = false;

    switch (product.sell_line_product_type) {
      case SellLineProductType.Service: {
        if (product.servicesBrandModel.length == 0)
          isProductDeviceCompatible = true;
        else {
          product.servicesBrandModel.forEach(servicebrandModal => {
            if (
              servicebrandModal.ServiceDeviceModel._id ==
                device.deviceModel._id &&
              servicebrandModal.ServiceBrand._id == device.deviceBrand._id
            ) {
              product.service_max_price = servicebrandModal.service_max_price;
              product.service_min_price = servicebrandModal.service_min_price;
              isProductDeviceCompatible = true;
            }
          });
        }
        break;
      }
      case SellLineProductType.Product: {
        if (product.isCustomProduct) {
          isProductDeviceCompatible = true;
          break;
        }

        product.BrandModels.forEach(brandModels => {
          brandModels.DeviceModel.forEach(model => {
            if (
              brandModels.Brand._id == device.deviceBrand._id &&
              device.deviceModel._id == model._id
            ) {
              isProductDeviceCompatible = true;
            }
          });
        });

        // if (product.Brand._id == device.deviceBrand._id && product.DeviceModel._id == device.deviceModel._id) {
        //   isProductDeviceCompatible = true
        // }
        break;
      }
      case SellLineProductType.BundleService: {
        if (
          product.Brand &&
          product.Brand._id == device.deviceBrand._id &&
          product.DeviceModel &&
          product.DeviceModel._id == device.deviceModel._id
        ) {
          isProductDeviceCompatible = true;
        }
        break;
      }
      case SellLineProductType.BundleProduct: {
        if (
          product.Brand &&
          product.Brand._id == device.deviceBrand._id &&
          product.DeviceModel &&
          product.DeviceModel._id == device.deviceModel._id
        ) {
          isProductDeviceCompatible = true;
        }
        break;
      }
      case SellLineProductType.ManufacturedProduct: {
        if (
          product.Brand &&
          product.Brand._id == device.deviceBrand._id &&
          product.DeviceModel &&
          product.DeviceModel._id == device.deviceModel._id
        ) {
          isProductDeviceCompatible = true;
        }
        break;
      }
    }
    return isProductDeviceCompatible;
  }

  addItemToService(product, device) {
    let isProdcutAddedToService = false;

    if (
      product.sell_line_product_type == SellLineProductType.Service ||
      product.sell_line_product_type == SellLineProductType.BundleService
    )
      return isProdcutAddedToService;

    let index = device.deviceProducts.findIndex(
      item =>
        item.sell_line_product_type == SellLineProductType.Service &&
        item.isOpen == true
    );

    if (index == -1) {
      return isProdcutAddedToService;
    } else {
      //device.deviceProducts[index].serviceProduct ? '' : device.deviceProducts[index].serviceProduct = []
      product.quantity = 1;
      device.deviceProducts[index].serviceProduct.push(product);
      isProdcutAddedToService = true;
    
      // let productIndex = device.deviceProducts[index].serviceProduct.findIndex(
      //   item => item._id == product._id
      // );
      // if (productIndex == -1) {
      //   product.quantity = 1;
      //   device.deviceProducts[index].serviceProduct.push(product);
      //   isProdcutAddedToService = true;
      // } else {
      //   this.createSaleService.showToaster([
      //     product.product_name + " already added",
      //     "warning"
      //   ]);
      //   isProdcutAddedToService = true;
      // }
    }
    this.scrollToProductView(product.key);
    return isProdcutAddedToService;
  }

  addItemToCart(product: any, saleCart: any[] = this.createSaleInput.saleCart) {
    product = JSON.parse(JSON.stringify(product));
    product.isOpen = true;

    if (
      product.product_type == InputProductType.Product ||
      product["product_type"] == InputProductType.Custom
    ) {
      this.assignProductType(product);
    }

    if (product["product_type"] == "giftCard" && this.customer == null) {
      this.createSaleService.showToaster([
        "Please select a customer to add giftcard",
        "warning"
      ]);
      return;
    }

    if (!this.addItemToDeviceIfSelected(product)) {
      if (!product["is_device"] && product["product_type"] !== "giftCard") {
        if (!product["is_product"]) {
          this.createSaleService.showToaster([
            "Please select a device to add service",
            "warning"
          ]);
          return;
        }
      }

      // check is product already in cart
      let index = saleCart.findIndex(item => item._id == product["_id"]);
      if (
        index !== -1 &&
        product.category_type.category_name !== CategoryTypeEnum.Device
      ) {
        //&& (!product['Suppliers'] || product['Suppliers'].length <= 1) Condition for multiple SKU
        if (!product.is_device) {
          product.key = saleCart[index].key;
          saleCart[index].quantity = parseInt(saleCart[index].quantity) + 1;
        } else {
          product.is_device
            ? this.createSaleService.showToaster([
                "Device already added",
                "warning"
              ])
            : this.createSaleService.showToaster([
                "Product already added",
                "warning"
              ]);
        }
      } else {
        product["key"] = uuidv4();
        product["quantity"] = product.isCustomProduct ? product["quantity"] : 1;
        saleCart.push(product);
        product["is_device"] ? this.createSaleService.reloadProductList() : "";
      }
      this.scrollToProductView(product.key);
    }
  }

  scrollToProductView(productId) {
    setTimeout(() => {
      if (document.getElementById(productId)) {
        document
          .getElementById(productId)
          .scrollIntoView({ behavior: "smooth", block: "center" });
        document.getElementById(productId).classList.remove("bounce");
        setTimeout(() => {
          document.getElementById(productId).classList.add("bounce");
        }, 100);
      }
    }, 200);
  }

  removeItemFromCart(key: string): void {
    this.createSaleInput.saleCart = this.createSaleInput.saleCart.filter(
      item => item.key !== key
    );
    this.onCalculateSubTotal();
  }

  removeDeviceFromCart() {
    this.createSaleInput.saleCart = this.createSaleInput.saleCart.filter(
      item => {
        if (item.product_type == "giftCard" || item.is_device) {
          return false;
        } else {
          return true;
        }
      }
    );
    this.onCalculateSubTotal();
  }

  isDeviceInCart(): boolean {
    let index = this.createSaleInput.saleCart.findIndex(item => item.is_device);
    return index == -1 ? false : true;
  }

  isDeviceAndServiceSelect() {
    let index = this.createSaleInput.saleCart.findIndex(
      item => item.is_device && item.isOpen == true
    );
    let device = null;
    let service = null;
    if (index !== -1) {
      device = this.createSaleInput.saleCart[index];
      let serviceIndex = device.deviceProducts.findIndex(
        item =>
          item.sell_line_product_type == SellLineProductType.Service &&
          item.isOpen == true
      );
      if (serviceIndex !== -1) {
        service = device.deviceProducts[serviceIndex];
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isDeviceSelected() {
    return (
      this.createSaleInput.saleCart.findIndex(
        item => item.is_device && item.isOpen == true
      ) !== -1
    );
  }

  isServiceSelected() {
    let index = this.createSaleInput.saleCart.findIndex(
      item => item.is_device && item.isOpen == true
    );
    let device = null;
    let service = null;
    if (index !== -1) {
      device = this.createSaleInput.saleCart[index];
      let serviceIndex = device.deviceProducts.findIndex(
        item =>
          item.sell_line_product_type == SellLineProductType.Service &&
          item.isOpen == true
      );
      if (serviceIndex !== -1) {
        service = device.deviceProducts[serviceIndex];
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getRouteURL(): string {
    if (
      this.router.url.includes("CreateSale") &&
      !this.router.url.includes("Checkout")
    ) {
      return this.router.url;
    } else if (this.customer_id !== null) {
      return `Pointofsale/CreateSale/Order/DeviceList/${this.customer_id}`;
    } else {
      return this.defaultUrl;
    }
  }

  createSaleInputBackendMapper() {
    let createSaleInput = {
      transaction_type: this.createSaleInput.transaction_type,
      transaction_status: this.createSaleInput.transaction_status,
      transaction_date: this.createSaleInput.transaction_date,
      sub_total_amount: Number(this.createSaleInput.sub_total_amount),
      Tax: this.createSaleInput.Tax,
      tax_amount: this.createSaleInput.tax_amount,
      tax_value: Number(this.createSaleInput.tax_value),
      is_tax_percentage: this.createSaleInput.is_tax_percentage,
      discount_amount: Number(this.createSaleInput.discount_amount),
      discount_value: Number(this.createSaleInput.discount_value),
      is_discount_percentage: this.createSaleInput.is_discount_percentage,
      total_amount: parseFloat(this.createSaleInput.total_amount.toString()),
      is_private: this.createSaleInput.is_private,
      additional_notes: this.createSaleInput.additional_notes,
      customer: this.customer_id,
      businessLocation: this.createSaleInput.BusinessLocation,
      is_apply_sale_tax: this.createSaleInput.is_apply_sale_tax,
      route_url: this.getRouteURL(),
      transactionSellLines: [],
      filterJson: JSON.stringify({
        customerFilter: this.createSaleService.customerFilter,
        deviceFilter: this.createSaleService.deviceFilter,
        productFilter: this.createSaleService.productFilter
      })
    };

    this.createSaleInput.saleCart.forEach(item => {
      if (item.is_device) {
        createSaleInput.transactionSellLines.push(this.deviceMapper(item));
      } else {
        createSaleInput.transactionSellLines.push(this.productMapper(item));
      }
    });
    return createSaleInput;
  }

  deviceMapper(item) {
    let saleCartItem = {
      sellLineType: SellLineEnumV4.RepairDevice,
      // category_type: '',
      // product_type: item.product_type,
      Product: item._id,
      // product_sku: '',
      // supplier_sku: '',
      quantity: 0,
      product_purchase_price: 0,
      product_sale_price: 0,
      is_discount_percentage: false,
      discount_amount: 0,
      discount_value: 0,
      sub_total_amount: Number(item.total_amount),
      total_amount: Number(item.total_amount),
      // customProduct: null,
      repairDeviceServices: [],
      Device: item._id,
      DeviceCheckIn: item.DeviceCheckIn,
      serial_number: "",
      stock_serial_number: item.stock_serial_number
        ? item.stock_serial_number
        : ""
    };
    item.deviceProducts.forEach(element => {
      let product = this.productMapper(element);
      if (
        product.sellLineType == SellLineEnumV4.Service &&
        element["serviceProduct"].length > 0
      ) {
        product["serviceProduct"] = this.productServiceMapper(element);
      }
      saleCartItem.repairDeviceServices.push(product);
    });
    return saleCartItem;
  }

  productServiceMapper(product) {
    let serviceProductList = [];

    product.serviceProduct.forEach(element => {
      let serviceProduct = {
        serviceProductType: element.category_type.category_name,
        serviceProductId: element._id,
        serviceProductQuantity: element.quantity
        //serviceProductCustomProduct: element.isCustomProduct ? element.CustomProduct : null
      };
      serviceProductList.push(serviceProduct);
    });

    return serviceProductList;
  }

  productMapper(item) {
    if (item.isCustomProduct) {
      item.average_cost = item.customProduct.cost_price;
      item.category_type = {
        category_name: SellLineEnumV4.CustomProduct,
        _id: null
      };
    }
    if (item.product_type == "giftCard") {
      item.average_cost = item.total_amount;
      item.sell_price_inc_tax = item.total_amount;
      item.category_type = {
        category_name: SellLineEnumV4.GiftCard,
        _id: null
      };
    }

    let saleCartItem = {
      sellLineType: item.category_type.category_name,
      Product: item._id,
      quantity: Number(item.quantity),
      product_purchase_price: item.average_cost
        ? parseFloat(item.average_cost)
        : 0,
      product_sale_price: Number(item.sell_price_inc_tax),
      is_discount_percentage: item.is_discount_percentage || false,
      discount_amount: Number(item.discount_amount),
      discount_value: Number(item.discount_value),
      sub_total_amount: Number(item.sub_total_amount),
      total_amount: Number(item.total_amount),
      serial_number: item.serial_number,
      stock_serial_number: item.stock_serial_number,
      category_type: item.category_type ? item.category_type._id : null
    };

    item.isCustomProduct
      ? (saleCartItem["customProduct"] = item.customProduct)
      : null;
    item.product_type == "giftCard"
      ? (saleCartItem["giftCard"] = item.giftCard)
      : null;

    return saleCartItem;
  }

  productWithMultipleSupplierMapper(item) {
    let saleCartItem = [];
    if (item.supplier_item)
      item.supplier_item.forEach(element => {
        if (element.quantity > 0) {
          let product = {
            is_device: false,
            product_type: item.product_type,
            sell_line_product_type: item.sell_line_product_type,
            Product: item._id,
            product_sku: item.sku ? item.sku : "",
            supplier_sku: element.supplier_sku,
            quantity: parseInt(element.quantity),
            product_purchase_price: item.average_cost,
            product_sale_price: Number(item.sell_price_inc_tax),
            is_discount_percentage: element.is_discount_percentage,
            discount_amount: Number(element.discount_amount),
            discount_value: Number(element.discount_value),
            sub_total_amount: Number(element.sub_total_amount),
            total_amount: Number(element.total_amount),
            customProduct: null,
            serial_number: item.serial_number, //to be added,
            Supplier: element.supplier_id,
            category_type: item.category_type._id
          };
          saleCartItem.push(product);
        }
      });
    return saleCartItem;
  }

  createSaleInputFrontEndMapper(input) {
    console.log("Sale cart Input", input);
    if (input && input.Customer) {
      this.customer = input.Customer;
    }

    let createSaleInput = {
      _id: input._id,
      transaction_date: input.transaction_date,
      transaction_type: input.transaction_type,
      transaction_status: input.transaction_status,
      sub_total_amount: input.sub_total_amount,
      Tax: input.Tax ? input.Tax._id : null,
      tax_amount: input.tax_amount,
      tax_value: input.tax_value,
      is_tax_percentage: input.is_tax_percentage,
      discount_amount: input.discount_amount,
      discount_value: input.discount_value,
      is_discount_percentage: input.is_discount_percentage,
      total_amount: input.total_amount,
      is_apply_sale_tax: input.is_apply_sale_tax,
      is_private: input.is_private,
      additional_notes: input.additional_notes,
      Customer: this.customer_id,
      BusinessLocation: this.locationId,
      route_url: input.route_url,
      filterJson: input.filterJson,
      saleCart: []
    };

    input.TransactionSellLines.forEach(product => {
      if (product.sellLineType == SellLineEnumV4.RepairDevice) {
        createSaleInput.saleCart.push(this.deviceInputFrontEndMapper(product));
      } else {
        createSaleInput.saleCart.push(this.productInputFrontEndMapper(product));
      }
    });
    console.log("out put create sale", createSaleInput);
    this.setCreateSaleInput(createSaleInput);
    this.taxesApplyOnCustomer()
  }

  deviceInputFrontEndMapper(input) {
    let device = {
      _id: input.Device._id,
      is_device: true,
      brand_model_name: input.Device.brand_model_name,
      product_type: ProductType.Product,
      device_color: input.Device.device_color,
      deviceBrand: input.Device.deviceBrand,
      deviceModel: input.Device.deviceModel,
      imei_ssn: input.Device.imei_ssn,
      Customer: this.customer,
      device_image: input.Device.device_image,
      device_keeping_unit: input.Device.device_keeping_unit,
      location_id: localStorage.getItem("location_id"),
      Checkin: input.DeviceCheckIns,
      total_amount: input.total_amount,
      DeviceCheckIn: input.DeviceCheckIns ? input.DeviceCheckIns._id : null,
      deviceProducts: [],
      key: uuidv4()
    };
    input.DeviceCheckIns
      ? (device["Checkin"]["DeviceImages"] = input.DeviceCheckIns.SourceFile)
      : null;
    input.repairDeviceServices.forEach(element => {
      device.deviceProducts.push(this.productInputFrontEndMapper(element));
    });
    return device;
  }

  productInputFrontEndMapper(product) {
    // item.quantity = item.total_quantity
    if (product["sellLineType"] == SellLineEnumV4.CustomProduct) {
      product["product_type"] = InputProductType.Custom;
      product["isCustomProduct"] = true;
      product["is_product"] = true;
      product["is_bundle_product"] = false;
      product["product_name"] = product["customProduct"].name;
      product["_id"] = product["customProduct"]._id;
      product.customProduct = {
        name: product.customProduct.name,
        quantity: product.customProduct.quantity,
        cost_price: product.customProduct.cost_price,
        selling_price: product.customProduct.selling_price,
        selling_price_include_tax: product.customProduct.sell_price_inc_tax,
        note: product.customProduct.note,
        is_taxable: product.customProduct.is_taxable,
        location_id: localStorage.getItem("location_id")
      };
    } else if (product.sellLineType == SellLineEnumV4.GiftCard) {
      product.product_type = "giftCard";
      product.giftCard = product.GiftCard;
    } else {
      product.product_type = SellLineProductType.Product;
      //copy project element to root
      for (var key of Object.keys(product.Product)) {
        product[key] = product.Product[key];
      }
    }
    product.is_device = false;
    product.sell_price_inc_tax = product.product_sale_price;
    product["isRetrivedProduct"] = true;
    product["key"] = uuidv4();

    if (
      product.sellLineType == SellLineEnumV4.Service &&
      product.serviceProduct
    ) {
      product.serviceProduct = this.serviceProdctFrontEndMapper(product);
    }

    this.assignProductType(product);

    return product;
  }

  serviceProdctFrontEndMapper(product) {
    let serviceProductList = [];

    product.serviceProduct.forEach(element => {
      let serviceProductObj = {};

      this.assignProductType(element.serviceProductId);
      serviceProductObj = element.serviceProductId;
      serviceProductObj["product_type"] = element.serviceProductType;
      serviceProductObj["quantity"] = element.serviceProductQuantity;
      element["key"] = uuidv4();

      serviceProductList.push(serviceProductObj);
    });
    return serviceProductList;
  }

  isValidMinimizerInput() {
    let isValid = false;
    if (this.customer != null || this.createSaleInput.saleCart.length !== 0) {
      isValid = true;
    } else {
      this.createSaleService.showToaster([
        "Select Customer or Product to minimize",
        "error"
      ]);
    }
    return isValid;
  }

  isValidateCreateSaleInput() {
    let isValid = true;

    if (this.createSaleInput.saleCart.length == 0) {
      this.createSaleService.showToaster(["Add product to cart", "error"]);
      isValid = false;
    } else {
      this.createSaleInput.saleCart.forEach(item => {
        item.isOpen = false;
       if (item.is_device && isValid) {
          if (!item.deviceProducts || item.deviceProducts.length == 0) {
            item.isOpen = true;
            this.createSaleService.showToaster([
              "Add service to device",
              "error"
            ]);
            isValid = false;
          } else if (item.DeviceCheckIn == "" || !item.DeviceCheckIn) {
            item.isOpen = true;
            this.createSaleService.showToaster([
              "Device Check-in required",
              "error"
            ]);
            isValid = false;
          } else if (item._id == "" || !item._id) {
            item.isOpen = true;
            this.createSaleService.showToaster([
              "Device Id is required",
              "error"
            ]);
            isValid = false;
          }

          if (!isValid) this.scrollToProductView(item.key);
        } else if (item.product_type == "giftCard" && isValid) {
          if (
            !item.giftCard ||
            item.giftCard.amount == 0 ||
            item.giftCard.amount == "" ||
            item.giftCard.to == "" ||
            item.giftCard.from == "" ||
            item.giftCard.subject == "" ||
            item.giftCard.send_gift_card == "" ||
            item.giftCard.email == ""
          ) {
            this.createSaleService.showToaster([
              "Provide value to gift card",
              "error"
            ]);
            item.isOpen = true;
            isValid = false;
          }
        } else if (
          item.category_type &&
          item.category_type.category_name == CategoryTypeEnum.Device &&
          isValid
        ) {
          this.isValidSerialNumber(item) ? "" : (isValid = false);
        } else if (
          item.product_type == InputProductType.Product &&
          item.quantity == 0
        ) {
          item.isOpen = true;
          isValid = false;
          this.createSaleService.showToaster([
            "Add quantity to " + item.product_name,
            "error"
          ]);
        }
      });
    }
    return isValid;
  }

  isValidSerialNumber(device) {
    let isValid = true;
    let deviceInCart = this.createSaleInput.saleCart.filter(product => {
      return (
        product.key !== device.key &&
        product.stock_serial_number === device.stock_serial_number
      );
    });
    if (deviceInCart.length > 0) {
      isValid = false;
      this.createSaleService.showToaster([
        "Same serial number with multiple devices",
        "error"
      ]);
    }
    if (!device.stock_serial_number) {
      this.createSaleService.showToaster([
        device.product_name + " add serial number",
        "error"
      ]);
      isValid = false;
    }

    isValid ? "" : this.scrollToProductView(device.key);
    return isValid;
  }

  onSaveOrder(transactionStatus, isQuote) {
    this.createSaleInput["transaction_status"] = transactionStatus;
    this.createSaleInput['transaction_date'] = new Date()
    let isValidInput = this.isValidateCreateSaleInput();
    if (isValidInput) {
      this.showLoadingScreen(true);
      this.createSaleGQLService
        .onCreateSale(
          this.createSaleInput["_id"],
          this.createSaleInputBackendMapper()
        )
        .subscribe(
          res => {
            this.showLoadingScreen(false);
            this.createSaleInput["_id"] = res.data.createSaleV4._id;
            if (!isQuote) {
              this.createSaleService.showToaster([
                "Order created successfully",
                "success"
              ]);
              this.router.navigateByUrl(
                `/Pointofsale/CreateSale/Checkout/${this.createSaleInput["_id"]}`
              );
              this.onDiscardOrder();
            } else {
              this.createSaleService.showToaster([
                "Quote created successfully",
                "success"
              ]);
            }
          },
          err => {
            this.showLoadingScreen(false);
            this.createSaleService.showToaster([err.message, "error"]);
            console.log("Create Sale error", err);
          }
        );
    }
  }

  createSaleMinimizer(transactionStatus) {
    this.createSaleInput["transaction_status"] = transactionStatus;
    this.showLoadingScreen(true);
    this.createSaleGQLService
      .onCreateSale(
        this.createSaleInput["_id"],
        this.createSaleInputBackendMapper()
      )
      .subscribe(
        res => {
          this.showLoadingScreen(false);
        },
        err => {
          this.showLoadingScreen(false);
          this.createSaleService.showToaster([err.message, "error"]);
          console.log("Create Sale error", err);
        }
      );
  }

  afterOrderSave() {
    let message = "";
    switch (this.createSaleInput["transaction_status"]) {
      case AllowedTransactionStatus.Quote: {
        message = "Quote created successfully";
        this.createSaleService.showToaster([message, "success"]);
        break;
      }
      case AllowedTransactionStatus.Order: {
        message = "Order created successfully";
        this.createSaleService.showToaster([message, "success"]);
        this.router.navigateByUrl(
          `/Pointofsale/CreateSale/Checkout${this.createSaleInput["_id"]}`
        );
        break;
      }
      case AllowedTransactionStatus.Minimize: {
        //this.router.navigateByUrl(`Pointofsale/CreateSale/Order`)
        //this.createSaleService.getCreateSaleMinimizerTab(false);
        //message = 'Order Minimized successfully'
        break;
      }
    }
  }

  getSelectedDeviceById(id) {
    return this.createSaleInput.saleCart.filter(
      dev => dev.is_device && dev._id == id
    );
  }

  getSelectedDeviceId() {
    let index = this.createSaleInput.saleCart.findIndex(
      item => item.is_device && item.isOpen == true
    );
    return index == -1 ? null : this.createSaleInput.saleCart[index]._id;
  }

  getCustomProductCount() {
    return this.createSaleInput.saleCart.filter(
      dev => !dev.is_device && dev.isCustomProduct
    ).length;
  }

  setCheckInData(data) {
    this.createSaleInput.saleCart[
      this.createSaleInput.saleCart.findIndex(item => item._id == data.Device)
    ]["Checkin"] = data;
    this.createSaleInput.saleCart[
      this.createSaleInput.saleCart.findIndex(item => item._id == data.Device)
    ]["DeviceCheckIn"] = data.checkinId;
    // this.createSaleInput.saleCart.filter(dev => dev.is_device && dev._id == data.Device);
    console.log("sale cart data is ", this.createSaleInput);
  }

  setCheckInImages(id, img) {
    this.createSaleInput.saleCart[
      this.createSaleInput.saleCart.findIndex(item => item._id == id)
    ]["Checkin"]["DeviceImages"] = img;
    console.log("sale cart updated", this.createSaleInput.saleCart);
  }
}
