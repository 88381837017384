import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AddEditComponent } from '../add-edit/add-edit.component'
import { RolesPermissionsGQLService } from 'src/app/services/role-permission/roles-permissions-gql.service'
import { GenericService } from 'src/app/services/stock/generic.service';
import { Router } from '@angular/router';
import { Role } from 'src/app/generated/graphql';

@Component({
  selector: 'role-listing',
  templateUrl: './role-listing.component.html',
  styleUrls: ['./role-listing.component.css']
})
export class RoleListingComponent implements OnInit {

  rolesList = []
  userAllowModules = []
  location_id = localStorage.getItem('location_id')
  bsModalRef: BsModalRef;
  loadingRoles = false
  event: EventEmitter<any> = new EventEmitter();
  modalRef: BsModalRef;
  selectedRolePermission = null
  selectedRole

  constructor(
    private modalService: BsModalService,
    private _rolesPermisionsGQL: RolesPermissionsGQLService,
    private genericService: GenericService,
    private router: Router
    ) { }

  ngOnInit() {
   this.getAllRoles()
   this.getLoggedInUserAllowModules()
  }

  getAllRoles(){
    this.loadingRoles = true
    this._rolesPermisionsGQL.getAllRoles(this.location_id)
    .subscribe((res)=>{
      this.loadingRoles = false
      this.rolesList = res.data.getRoles
      console.log('Role List', this.rolesList)
    },(err)=>{
      this.loadingRoles = false
      console.error(err.message)
    })
  }

  getLoggedInUserAllowModules(){
    this._rolesPermisionsGQL.getLoggedInUserAllowModules(this.location_id)
    .subscribe((res)=>{
      this.userAllowModules = res.data.getLoggedInUserAllowModules
    })
  }

  onAddEditRole(_id, data) {
    const initialState = { _id: _id, role_record: data, allowedModule: this.userAllowModules };
    this.bsModalRef = this.modalService.show(AddEditComponent, { class: 'modal-sm custModal wd600', backdrop: 'static', keyboard: false, initialState })
    this.bsModalRef.content.event.subscribe(res => {
      this.getAllRoles()
    });
  }

  openModal(tempalte, cls) {
    this.modalService.show(tempalte, { class: 'modal-sm ' + cls, backdrop: 'static', keyboard: false })
  }

  onDeleteRole(tempalte, cls){
    this.modalService.show(tempalte, { class: 'modal-sm ' + cls, backdrop: 'static', keyboard: false })
  }


  closeModel() {
    this.modalService.hide(1);
  }

  onSetRolePermission() {
    this.closeModel()
    this.router.navigateByUrl('/Permissions', { state: { role: this.selectedRolePermission } })
  }

  onDeleteRoleConfirmation(roleId,location_id = localStorage.getItem('location_id')){
    this.selectedRole
    this._rolesPermisionsGQL.deleteRole(roleId,location_id)
    .subscribe((res)=>{
      this.rolesList = this.rolesList.filter(roleObj=>roleObj.role._id !== roleId)
      this.closeModel()
    })
  }

}
