import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RolesPermissionsGQLService } from './roles-permissions-gql.service'

@Injectable({
  providedIn: 'root'
})
export class RolesPermissionsService {

  selectedModule: string = ''
  selectedFeature: string = ''
  selectedPermission: string = ''

  constructor(private _rolesPermissionGQL: RolesPermissionsGQLService,
    private router: Router) { }

  private _assignUserRolePermissions = [];
  private _assignUserRolePermissionsCopy = [];
  private _permissionsList = [];

  public get permissionsList() {
    return this._permissionsList;
  }
  public set permissionsList(value) {
    this._permissionsList = value;
  }

  public get assignUserRolePermissions() {
    return this._assignUserRolePermissions;
  }
  public set assignUserRolePermissions(value) {
    this._assignUserRolePermissions = value;
  }

  public get assignUserRolePermissionsCopy() {
    return this._assignUserRolePermissionsCopy;
  }
  public set assignUserRolePermissionsCopy(value) {
    this._assignUserRolePermissionsCopy = value;
  }

  getUserRolePermissions(userID, roleID, location_id = localStorage.getItem('location_id')) {
    this._rolesPermissionGQL.getUserRolePermissions(userID, roleID, location_id)
      .subscribe((res) => {
        this.remove_type(res.data.getUserRolePermissions.userRolePermissions)
        this.remove_type(res.data.getUserRolePermissions.loggedInUserPermissions)
        this.permissionsList = res.data.getUserRolePermissions.loggedInUserPermissions
        this.assignUserRolePermissions = res.data.getUserRolePermissions.userRolePermissions
        this.assignUserRolePermissionsCopy = res.data.getUserRolePermissions.userRolePermissions
      })
  }

  remove_type(array) {
    array.forEach(element => {
      delete element.__typename
    });
  }


  isModuleNotAllowed(module) {
    return this.assignUserRolePermissions.findIndex(permission => permission.module == module) === -1
  }

  isFeatureNotAllowed(module,feature) {
    return this.assignUserRolePermissions.findIndex(permission =>permission.module == module && permission.feature == feature) === -1
  }

  isActionNotAllowed(module,featureName, permissionName) {
    return this.assignUserRolePermissions.findIndex(permission =>permission.module == module && permission.permission == permissionName && permission.feature == featureName) === -1
  }

  onChangeActionPermission(featureName, permissionName, event) {
    if (event.target.checked) {
      this.assignUserRolePermissions = this.assignUserRolePermissions.filter(permission => permission.feature === featureName ? permission.permission === permissionName ? false : true : true)
    } else {
      this.assignUserRolePermissions = this.assignUserRolePermissions.concat(this.permissionsList.filter(permission => permission.feature == featureName && permission.permission == permissionName))
    }
    return this.assignUserRolePermissions
  }

  onChangeModulePermission(module, event) {
    //check mean lock this module
    if (event.target.checked) {
      this.assignUserRolePermissions = this.assignUserRolePermissions.filter(permission => permission.module !== module)
    } else {
      this.assignUserRolePermissions = this.assignUserRolePermissions.concat(this.permissionsList.filter(permission => permission.module == module))
    }
    return this.assignUserRolePermissions
  }

  onChangeFeaturePermission(module,feature, event) {
    if (event.target.checked) {
      this.assignUserRolePermissions = this.assignUserRolePermissions.filter((permission) => {
        if(permission.module == module && permission.feature == feature){
          return false
        }else{
          return true
        }
      })
    } else {
      this.assignUserRolePermissions = this.assignUserRolePermissions.concat(this.permissionsList.filter(permission => permission.module == module && permission.feature == feature))
    }
    return this.assignUserRolePermissions
  }

  onSavePermissions(userID, roleID) {
    let objWithAddRemoveItem = this.arrayCompareAddRemoveItem(this.assignUserRolePermissionsCopy, this._assignUserRolePermissions)
    this.addCustomUserRolePermissions(userID, roleID, objWithAddRemoveItem['add'], objWithAddRemoveItem['remove'])
  }

  addCustomUserRolePermissions(userID, roleID, addPermissions, removePermissions, location_id = localStorage.getItem('location_id')) {
    this._rolesPermissionGQL.addCustomUserRolePermissions(userID, roleID, location_id, addPermissions, removePermissions)
      .subscribe((res) => {
        this.router.navigate(['Pointofsale', 'Settings'])
      })
  }

  arrayCompareAddRemoveItem(oldArray, newArray) {
    var remove = oldArray.filter(o1 => !newArray.some(o2 => o1.permission === o2.permission && o1.feature === o2.feature && o1.module === o2.module));
    var add = newArray.filter(o1 => !oldArray.some(o2 => o1.permission === o2.permission && o1.feature == o2.feature && o1.module === o2.module));
    return { add: add, remove: remove }
  }

}
