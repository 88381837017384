import { Injectable } from '@angular/core';
import { WebsocketService } from "../../../services/websocket/websocket.service";
import { Observable, Subject } from "rxjs/Rx";

@Injectable({
  providedIn: 'root'
})
export class DongleSocketService {
  devices: Subject<any>;
  socket:any

  

  constructor(private wsService: WebsocketService) {
    this.devices = <Subject<any>>(
      wsService.connect().map((response: any): any => {
        return response;
      })
    );
  }
  
  getDevices() {
    this.socket.emit('show_devices', 'devices');
  }

  getDeviceData(devicePath){
    this.socket.emit('device_path',devicePath);
  }

}
