import { Component, DoCheck, OnInit } from '@angular/core';
import { AllowedTransactionStatus, MinimizerEnum } from 'src/app/generated/graphql';
import { MinimizerService } from 'src/app/services/minimizer/minimizer.service'
import { SaleCartService } from "src/app/services/create-sale/sale-cart.service"
import { Router } from '@angular/router';
import { ManagePoService } from 'src/app/services/orders/manage-po.service';
import { PurchaseOrderMinimizerService } from 'src/app/services/purchase-order/purchase-order-minimizer.service';
import { RepairRoomService } from 'src/app/services/repair-room/repair-room.service';
const Mousetrap = require("mousetrap");

@Component({
  selector: 'minimizer-list',
  templateUrl: './minimizer-list.component.html',
  styleUrls: ['./minimizer-list.component.css']
})
export class MinimizerListComponent implements OnInit, DoCheck{

  maxMinmizer = this._minimizer.maxMinmizer
  restoreRequestSent = false
  mouseTrapKeyNewTab = "ctrl+q"
  MinimizerEnum = MinimizerEnum
   
  public get activeIndex() {
    return this._minimizer.activeIndex;
  }

  public get minimizerList(){
    return this._minimizer.minimizerList
  }
  
  constructor(private _minimizer: MinimizerService,
    private minimizerServicePO: PurchaseOrderMinimizerService,
    private _saleCart: SaleCartService,
    private _repairRoom: RepairRoomService,
    private router: Router) { }
  
  ngDoCheck(): void {
    if(this._minimizer.minimizer_type == MinimizerEnum.RepairRoom &&  this._repairRoom.customer && this.router.url.includes("RepairRoom/detail")){
      if(this.minimizerList.length > 0 && !this.minimizerList[this.activeIndex].customer || this.minimizerList[this.activeIndex].customer._id !== this._repairRoom.customer._id)
        this._minimizer.minimizerList[this.activeIndex].customer = this._repairRoom.customer
    }
  }

  ngOnInit() {
    Mousetrap.bind('del', (e) => {
      this.deleteMinimizer(this._minimizer.minimizerList[this._minimizer.activeIndex], null, this._minimizer.activeIndex)
      return false;
    });
  }

  async onRestoreMinimizer(minizer, index) {
    if (this._minimizer.activeIndex == index)
      return
    if (this.restoreRequestSent)
      return

    if (minizer.minimizer_type == 'po') {
      if(!this._minimizer.loadPOMinimizer) {
        return
      }
      this.restoreRequestSent = true
      this.minimizerServicePO.poDetail['TransactionPurchaseLines'] = await this.minimizerServicePO.getPurchaseLineItems();
      this._minimizer.updatePOMinimizer()
      // this.managePoService.updateMinimizerDetail(true)
      localStorage.setItem(minizer.minimizer_type, index)
      let resp = await this._minimizer.restorePOMinimizer(minizer)
      if(resp) {
        this.restoreRequestSent = false
        this._minimizer.activeIndex = index
        return
      }
      this.restoreRequestSent = false
    }else if (this._minimizer.minimizer_type == MinimizerEnum.CreateSale) {
      if (this._saleCart.createSaleInput._id !== null) {
        this.restoreRequestSent = true
        this._minimizer.createSaleMinimizer()
          .subscribe((res) => {
            this._minimizer.activeIndex = index
            this.restoreRequestSent = false
            this._minimizer.restoreCreateSaleRecord(minizer)
            this._minimizer.getAllMinimizers(this._minimizer.minimizer_type, localStorage.getItem('location_id'), false)
          },(err)=>{
            this.restoreRequestSent = false
          })
      } else {
        this.restoreRequestSent = false
        this._minimizer.restoreCreateSaleRecord(minizer)
        this._minimizer.getAllMinimizers(this._minimizer.minimizer_type, localStorage.getItem('location_id'), false)
      }
    }else if(this._minimizer.minimizer_type == MinimizerEnum.RepairRoom){
      this._minimizer.activeIndex = index
      this._minimizer.onRestoreMinimizer(minizer)
    }
  }

  deleteMinimizer(minimizer, event, index) {
    event ? event.stopPropagation() : '';
    this._minimizer.deleteMinimizer(minimizer._id, minimizer.minimizer_type, localStorage.getItem('location_id'), index)
  }


  async createNewTab() {
    if(this._minimizer.minimizer_type == 'po') {
      this.minimizerServicePO.poDetail['TransactionPurchaseLines'] = await this.minimizerServicePO.getPurchaseLineItems();
      this._minimizer.updatePOMinimizer()
    }
    this._minimizer.createNewTab(this._minimizer.minimizer_type)
  }

  onSaveTabChanges(minimizer_type) {
    switch (minimizer_type) {
      case MinimizerEnum.CreateSale: {
        //this._saleCart.createSaleMinimizer(AllowedTransactionStatus.Minimize)
        break
      }
    }
  }
}
