import { Injectable, EventEmitter } from '@angular/core';
import { GenericUtility } from 'src/app/utilties/generic-utility';
import {
  CustomerNetTermGQL, CashRegistersOfLocationGQL, GetAllAccountTypeWiseGQL,
  GetAllAccountTypesGQL, CreateChartOfAccountByTypeGQL, GetAllChartOfAccountsGQL,
  CreateChartOfAccountGQL, DeleteChartOfAccountGQL, UpdateChartOfAccountGQL,
  GetAllStoreCoaGQL,
  UpdateUserPasswordGQL,
  TaxsGQL,
  GetTaxbyIdGQL,
  CreateTaxGQL,
  UpdateTaxGQL,
  DeleteTaxGQL,
  UniquePinCodeVerifyGQL
} from 'src/app/generated/graphql';
import { Observable, Subject } from 'rxjs';
import { LocalStoreService } from "src/app/modules/shared/service/local-store.service"

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  selectList: any = [];

  private fotterdata = new Subject<any>();
  fotterdata$ = this.fotterdata.asObservable();

  private headerData = new Subject<any>();
  headerData$ = this.headerData.asObservable();

  private showToasterSource = new Subject<any>();
  onShowToasted$ = this.showToasterSource.asObservable();
  public footerEmitter = new EventEmitter<any>();
  constructor(
    private _serviceCaller: GenericUtility,
    private customerNetTerm: CustomerNetTermGQL,
    private cashRegistersOfLocationGQL: CashRegistersOfLocationGQL,
    private getAllAccountTypeWiseGQL: GetAllAccountTypeWiseGQL,
    private getAllAccountTypesGQL: GetAllAccountTypesGQL,
    private createChartOfAccount: CreateChartOfAccountGQL,
    private getAllChartOfAccountsGQL: GetAllChartOfAccountsGQL,
    private currectAccount: DeleteChartOfAccountGQL,
    private updateChartOfAccountGQL: UpdateChartOfAccountGQL,
    private ls: LocalStoreService,
    private getAllStoreCoaGQL: GetAllStoreCoaGQL,
    private updateUserPasswordGQL: UpdateUserPasswordGQL,
    private taxsGQL: TaxsGQL,
    private getTaxByID: GetTaxbyIdGQL,
    private createTax: CreateTaxGQL,
    private updateTax: UpdateTaxGQL,
    private deleteTax: DeleteTaxGQL,
    private userPinCodeVerify: UniquePinCodeVerifyGQL
  ) { }

  /**
     * Creates a new instance of toaster in point of sale
     * pass two parameters in form of array first will be message to show and second one will be type of toaster
     * e.g ['this is toaster message', 'info']
     * @return Will show toaster on top
  */
   showToaster(valueArray: any) {
    this.showToasterSource.next(valueArray);
  }

  uploadImage(model_id, model_name, field_name, image) {
    let formData: FormData = new FormData();
    formData.append('model_id', model_id);
    formData.append('model_name', model_name);
    formData.append('field_name', field_name);
    formData.append('image', image, image.name);

    return this._serviceCaller.getPostCall('business/uploadImageForModel', formData)
  }

  async convertToImage(url, type = 'png', name = 'default') {
    return new Promise(
      (resolve) => {
        var image = (fetch(url)
          .then(function (res) { return res.arrayBuffer(); })
          .then(function (buf) { return new File([buf], name + '.' + type, { type: type }); })
        );
        resolve(image)
      }
    )
  }

   getFileNameWithExt(event) {

    if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
      return;
    }
  
    const name = event.target.files[0].name;
    const lastDot = name.lastIndexOf('.');
  
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    
    return {
      fileName: fileName,
      ext: ext
    }
    
  }

  toDataURL = url => fetch(url, {
    method: 'GET',
    headers: { 'Access-Control-Allow-Origin': '*' }
  })
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    }))

  public getcustomerNetTerm(customerId): any {
    let data: any
    this.customerNetTerm.watch({
      customerId: customerId
    }).valueChanges.subscribe(
      (res) => {
        console.log('filter by supplier res', res);
        data = res['data'].customerNetTerm
        return data
      }, (err) => {
        console.log('filter by supplier and Order err', err);
        return err
      }
    )
  }

  getAllCashRegisterarOfLocation() {
    return this.cashRegistersOfLocationGQL.watch({
      ID: localStorage.getItem('location_id')
    })
  }

  setFotterItem(valueArray: any) {
    this.fotterdata.next(valueArray);
  }

  setHeaderItem(valueArray: any) {
    this.headerData.next(valueArray);
  }

  userProfilePopup = false
  setUserProfilePopupValue(value) {
    this.userProfilePopup = value
  }

  notificationPopup = false
  setNotificationPopupValue(value) {
    this.notificationPopup = value
  }

  
  setChatPopup = false
  setChatPopupValue(value) {
    this.chatPopup = value
  }

  chatPopup = false
  chatPopupValue(value) {
    this.chatPopup = value
  }

  geChatPopupValue() {
    return this.setChatPopup
  }

  getchatPopupValue() {
    return this.chatPopup
  }

  setSelectList(dataArray){
     this.selectList.push(dataArray);
  }

  resetSelectList() {
    this.selectList = []
  }
  
  getSelectData() {
    return this.selectList
  }

  removeSelectData(id) {
    const index = this.selectList.findIndex(x => x._id == id);
    if(index > -1){
      this.selectList.splice(index, 1)
    }
  }

  getUserProfilePopupValue() {
    return this.userProfilePopup
  }

  getNotificationPopupValue() {
    return this.notificationPopup
  }

  getAllChartOfAccounts(data) {
    return this.getAllChartOfAccountsGQL.watch(data).valueChanges
  }

  getAllStoreCOA(location_id) {
    return this.getAllStoreCoaGQL.watch({
      location_id: location_id
    }).valueChanges
  }
  getAllAccount(search, type) {
    return this.getAllAccountTypeWiseGQL.watch({
      search: search,
      type: type
    }).valueChanges
  }

  getAllAccountTypes() {
    return this.getAllAccountTypesGQL.watch().valueChanges
  }

  createAccount(obj) {
    return this.createChartOfAccount.mutate({
      input: obj
    })
  }

  deleteChartOfAccount(account) {
    return this.currectAccount.mutate(account)
  }

  updateChartOfAccount(id, account) {
    return this.updateChartOfAccountGQL.mutate({
      _id: id,
      input: account
    })
  }

 

  changeUserPassword(data) {
    return this.updateUserPasswordGQL.mutate({
      id: localStorage.getItem('BusinessId'),
      input: data
    })
  }

  getAllTaxes(type = 'sale_tax') {
    return this.taxsGQL.watch({
      type: type
    }).valueChanges
  }

  getTaxById(id) {
    return this.getTaxByID.watch({
      id: id
    }).valueChanges
  }

  createTaxes(data) {
    return this.createTax.mutate({
      input: data
    })
  }

  updateTaxes(id, data) {
    return this.updateTax.mutate({
      id: id,
      input: data
    })
  }

  removeTax(id) {
    return this.deleteTax.mutate({
      id: id
    })
  }

  verifyClockInPin(pin, user) {
    return this.userPinCodeVerify.watch({
      pincode: pin,
      user_id: user
    }).valueChanges
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  

  

}
