import { Component, OnInit } from "@angular/core";
import { GetAllDeviceDiagnoseGQL } from "src/app/generated/graphql";
import { ChatService } from "src/app/services/chat-service/chat.service";

@Component({
  selector: "app-techbar-ai",
  templateUrl: "./techbar-ai.component.html",
  styleUrls: ["./techbar-ai.component.css"]
})
export class TechbarAiComponent implements OnInit {
  deviceLoader = false;
  devicesData = [];
  message = [];
  constructor(
    private getAllDeviceDiagnose: GetAllDeviceDiagnoseGQL,
    private chat: ChatService
  ) {}

  ngOnInit() {
    this.chat.messages.subscribe(msg => {
      // msg.text = msg.text.filter(devices => { return devices[2]});

      this.message = msg.text
      console.log(msg);
    });
    this.getDevicesRecord();
  }

  sendMessage() {
    this.chat.sendMsg("Test Message");
  }

  getDevicesRecord() {
    this.deviceLoader = true;
    this.getAllDeviceDiagnose.watch().valueChanges.subscribe(
      res => {
        this.deviceLoader = false;
        console.log(" res", res["data"].getAllDeviceDiagnose);
        this.devicesData = res["data"].getAllDeviceDiagnose;
      },
      err => {
        this.deviceLoader = false;
        console.log(" err", err);
      }
    );
  }

  isObjectEmpty(Obj) {
    for (var key in Obj) {
      if (Obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
}
