import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DongleRoutingModule } from './dongle-routing.module';
import { DongleComponent } from './dongle/dongle.component';
import { TreeDraggedElement, TreeModule } from '@circlon/angular-tree-component';


@NgModule({
  declarations: [DongleComponent],
  imports: [
    CommonModule,
    DongleRoutingModule,
    TreeModule
  ],
  providers:[TreeDraggedElement]
})
export class DongleModule { }
