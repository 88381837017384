import { Injectable } from '@angular/core';
import {
  GetDiscountsAndSearchGQL, SearchCampaignsGQL, CreateCampaignGQL,
  CreateCouponCodeGQL, SearchDiscountTagsGQL, EffectDiscountToCustomerGQL,
  SearchProductGQL, SearchCustomerTagsGQL, SearchBusinessLocationGQL, CreateDiscountGQL,
  DiscountByIdGQL, UpdateDiscountGQL, DiscountStatusChangedGQL, CampaignStatusChangedGQL
} from 'src/app/generated/graphql'

@Injectable({
  providedIn: 'root'
})
export class DiscountGQLService {

  constructor(private getDiscountsAndSearchGQL: GetDiscountsAndSearchGQL,
    private searchCampaignsGQL: SearchCampaignsGQL,
    private createCampaignGQL: CreateCampaignGQL,
    private createCouponCodeGQL: CreateCouponCodeGQL,
    private searchDiscountTagsGQL: SearchDiscountTagsGQL,
    private effectDiscountToCustomerGQL: EffectDiscountToCustomerGQL,
    private searchProductGQL: SearchProductGQL,
    private searchCustomerTagsGQL: SearchCustomerTagsGQL,
    private searchBusinessLocationGQL: SearchBusinessLocationGQL,
    private createDiscountGQL: CreateDiscountGQL,
    private discountByIdGQL: DiscountByIdGQL,
    private updateDiscountGQL: UpdateDiscountGQL,
    private discountStatusChangedGQL: DiscountStatusChangedGQL,
    private campaignStatusChangedGQL: CampaignStatusChangedGQL) { }

  getDiscountsAndSearch(searchDiscountInput) {
    return this.getDiscountsAndSearchGQL.watch({ input: searchDiscountInput }).valueChanges
  }

  searchCampaigns(search) {
    return this.searchCampaignsGQL.watch({
      search: search
    }).valueChanges
  }

  createCampaign(campaignInput) {
    return this.createCampaignGQL.mutate({
      input: campaignInput
    })
  }

  createCouponCode() {
    return this.createCouponCodeGQL.watch().valueChanges
  }

  searchDiscountTags(search) {
    return this.searchDiscountTagsGQL.watch({
      search: search
    }).valueChanges
  }

  effectDiscountToCustomer(progressBarInput) {
    return this.effectDiscountToCustomerGQL.watch({
      input: progressBarInput
    }).valueChanges
  }

  searchProduct(search, locationId) {
    return this.searchProductGQL.watch({
      locationId: locationId,
      search: search
    }).valueChanges
  }

  searchCustomerTags(search) {
    return this.searchCustomerTagsGQL.watch({
      search: search
    }).valueChanges
  }

  searchBusinessLocation(search) {
    return this.searchBusinessLocationGQL.watch({
      search: search
    }).valueChanges
  }

  createDiscount(input) {
    return this.createDiscountGQL.mutate({
      input: input
    })
  }

  getDiscountById(id) {
    return this.discountByIdGQL.watch({
      id: id
    }).valueChanges
  }
  
  updateDiscount(id,dicountInput){
  return this.updateDiscountGQL.mutate({
      id: id,
      input: dicountInput
    })
  }

  discountStatusChanged(campaignID, discountID, isActive){
    return this.discountStatusChangedGQL.mutate({
      campaign_id: campaignID,
      discount_id: discountID,
      status: isActive
    })
  }

  campaignStatusChanged(campaignID, isActive){
    return this.campaignStatusChangedGQL.mutate({
      campaign_id:campaignID,
      status: isActive
    })
  }


}
