import { Injectable } from '@angular/core';
import { BusinessLogoUploadGQL, CheckUserEmailGQL, CreateEmailOtpGQL, CreateOtpGQL, GetBusinessAdminGQL, OwnerPasswordUpdateGQL, UpdateBusinessAdminGQL, VerifyEmailOtpGQL, VerifyOtpGQL } from 'src/app/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(
    private getBusinessAdmin: GetBusinessAdminGQL,
    private createOtpGQL: CreateOtpGQL,
    private verifyOtpGQL: VerifyOtpGQL,
    private verifyEmailOtpGQL: VerifyEmailOtpGQL,
    private verifyEmail: CheckUserEmailGQL,
    private createEmailOtpGQL: CreateEmailOtpGQL,
    private ownerPasswordUpdate: OwnerPasswordUpdateGQL,
    private businessLogoUpload: BusinessLogoUploadGQL,
    private updateBusiness: UpdateBusinessAdminGQL

  ) { }

  getBusinessDetail() {
    return this.getBusinessAdmin.watch({
      _id: window.localStorage.getItem('BusinessId')
    }).valueChanges
  }

  sendOTP(data) {
    return this.createOtpGQL.mutate({
      input: data
    })
  }

  verifyOTP(data) {
    return this.verifyOtpGQL.mutate({
      input: data
    })
  }

  verifyEmailExist(email, id) {
    return this.verifyEmail.watch({
      email: email,
      userId: id
    }).valueChanges
  }

  sendEmailOTP(data) {
    return this.createEmailOtpGQL.mutate({
      input: data
    })
  }

  verifyEmailOTP(data) {
    return this.verifyEmailOtpGQL.mutate({
      input: data
    })
  }

  changeOwnerPassword(data) {
    return this.ownerPasswordUpdate.mutate({
      input: data
    })
  }

  updateBusinessLogo(data) {
    return this.businessLogoUpload.mutate(data)
  }

  updateBusinessInfo(data) {
    return this.updateBusiness.mutate(data,{
      context:{
        useMultipart: true
      }
    })
  }

}
