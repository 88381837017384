import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[PermissionsDisableActions]'
})
export class PermissionsDisableActionsDirective implements AfterViewInit {

  buttons = []
  anchors = []
  inputs = []
  italic = []
  isRolePermissionModule = false

  constructor(private elRef: ElementRef,
    private router: Router,
    private renderer: Renderer2) { }

  ngAfterViewInit() {
    const currentElement = this.elRef.nativeElement
    if (this.router.url.includes('Permissions')) {
      this.isRolePermissionModule = true
    } else {
      this.isRolePermissionModule = false
    }
    this.getAllElement(currentElement.children)
  }

  getAllElement(currentElement) {
    for (var i = 0; i < currentElement.length; i++) {
      currentElement[i].children.length > 0 ? this.getAllElement(currentElement[i].children) : ''
      this.elementEnableDisableAction(currentElement[i], this.isRolePermissionModule)
    }
  }



  elementEnableDisableAction(element, isDisable) {
    switch (element.tagName.toLowerCase( )) {
      case 'i': {
        isDisable ? element.classList.add("disabled") : element.classList.remove("disabled");
        break
      }
      case 'input': {
        isDisable && element.type !== 'checkbox' ? element.disabled = true : element.disabled = false
        break
      }
      case 'button': {
        isDisable ? element.disabled = true : element.disabled = false
        break
      }
      case 'a': {
        isDisable ? element.classList.add("disabled") : element.classList.remove("disabled");
        break
      }
    }
  }


}
