import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  private showToasterSource = new Subject<any>();
  onShowToasted$ = this.showToasterSource.asObservable();

  private sectionText = new Subject<any>();
  sectionText$ = this.sectionText.asObservable();

  private refreshSuplierList = new Subject<any>();
  resfreshList$ = this.refreshSuplierList.asObservable();

  private changeTab = new Subject<any>();
  changeTab$ = this.changeTab.asObservable();

  private refreshProductList = new Subject<any>();
  resfreshProductList$ = this.refreshProductList.asObservable();

  tabs = [
    {
      name: 'Orders',
      route: 'Orders',
      active: false,
      previousURLState: null
    },
    {
      name: 'Products',
      route: '/Pointofsale/Stock',
      active: false,
      previousURLState: null
    },
    {
      name: 'Services',
      route: 'Services',
      active: false,
      previousURLState: null
    },
    {
      name: 'Suppliers',
      route: 'Suppliers',
      active: false,
      previousURLState: null
    }
  ];

  newlyAddedProduct = [];

  constructor(
    private router: Router,
    private location: Location

  ) { }

  /**
     * Creates a new instance of toaster
     * pass two parameters in form of array first will be message to show and second one will be type of toaster
     * e.g ['this is toaster message', 'info']
     * @return Will show toaster on top of module stock module 
  */
  showToaster(valueArray: any) {
    this.showToasterSource.next(valueArray);
  }

  /**
    * change the text of tab
 */
  changeTabsText(text: string) {
    this.sectionText.next(text);
  }

  refreshSupplierListing(bool: boolean) {
    this.refreshSuplierList.next(bool)
  }

  refreshProductListing(data) {
    this.refreshProductList.next(data);
  }

  /**
     * Navigate between stock module only. If you want to navigate between the stock module you should use this function
     * value will be concated as Stock/'value'
     * @return navigate to specified url 
  */
  navigateURL(route) {
    this.router.navigateByUrl(`Pointofsale/Stock/${route})`)
  }

  /**
     * Navigate between PointOfSale module
     * value will be concated as Pointofsale/'value'
     * @return navigate to specified url 
  */
  navigateModule(route) {
    this.router.navigateByUrl(`Pointofsale/${route})`)
  }

  refreshTab(bool) {
    this.changeTab.next(bool)
  }

  sleep(time) {
    return new Promise( resolve => setTimeout(resolve, time) );
  }

  back() {
    this.location.back()
  }

}
