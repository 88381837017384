import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Account_Type } from 'src/app/generated/graphql';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-manage-accounts',
  templateUrl: './manage-accounts.component.html',
  styleUrls: ['./manage-accounts.component.css']
})
export class ManageAccountsComponent implements OnInit {

  @ViewChild('manageAccount', { static: true }) template: ElementRef;
  @Output() accountActionCompleted: EventEmitter<any> = new EventEmitter<any>();
  modalRef: BsModalRef;
  accountID = '';

  constructor(
    private formbulider: FormBuilder,
    private modalService: BsModalService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.getAllAccountType()
    this.getAllChartOfAccounts()
  }

  getAllChartOfAccounts() {
    let data = {
      limit: 100,
      skip: 0,
      search: '',
      status:'active'
    }
    this.commonService.getAllChartOfAccounts(data).subscribe(
      (res) => {
        this.allAcounts = res['data'].GetAllChartOfAccounts
      }, (err) => {
        console.log('Error getting Account List data', err);
      }
    )
  }

  allAccountTypes = []
  getAllAccountType() {
    this.commonService.getAllAccountTypes().subscribe(
      (res) => {
        this.allAccountTypes = res['data'].GetAllAccountTypes
      }, (err) => {
        console.log('err while loading acc types', err);
      }
    )
  }

  allAcounts = []
  getAccountDetails(val, type) {
    this.commonService.getAllAccount(val, type).subscribe(
      (res) => {
        this.allAcounts = []
        this.allAcounts = res['data'].GetAllAccountTypeWise
        console.log('accounts detail are', this.allAcounts);
      }, (err) => {
        console.log('err while loading accounts', err);
        this.allAcounts = []
      }
    )
  }

  addAcountForm: any
  initializeAddAccount() {
    this.getAllChartOfAccounts();
    this.selectedAccount = 'Please select any account type'
    this.parentAccount = 'Select Parent Account'
    this.accountID = ''
    this.addAccountSubmitted = false
    this.addAcountForm = this.formbulider.group({
      account_code: ['', [Validators.required]],
      Opening_balance: [],
      parent_account_Id: [],
      is_sub_account: [true],
      account_name: ['', [Validators.required]],
      account_type_Id: ['', [Validators.required]],
      description: [''],
      BusinessLocation: [localStorage.getItem('location_id')]
    });
    this.openModel()
  }

  get faddAccount() {
    return this.addAcountForm.controls
  }

  updateAccountForm(acc) {
    this.initializeAddAccount()
    this.addAcountForm.patchValue(acc)
    if (acc.parent_account_Id == null) {
      this.addAcountForm.controls.is_sub_account.setValue(false)
      this.addAcountForm.controls.parent_account_Id.setValue('')
    }
    else {
      this.parentAccount = acc.parent_account_Id.account_name
      this.addAcountForm.controls.parent_account_Id.setValue(acc.parent_account_Id._id)
    }
    this.selectedAccount = acc.account_type_Id.title
    this.addAcountForm.controls.account_type_Id.setValue(acc.account_type_Id._id)
    this.addAcountForm.controls.Opening_balance.setValue(acc.opening_balance)
    this.accountID = acc['_id']
    this.accountsTypeWise = this.allAcounts.filter(account => account.account_type_Id._id == acc.account_type_Id._id);
  }

  openModel() {
    this.modalRef = this.modalService.show(this.template, { class: 'modal-sm ' + 'custModal wd600', backdrop: 'static', keyboard: false });
  }

  accountsTypeWise = []
  selectParentAccount(acc) {
    this.addAcountForm.controls.parent_account_Id.setValue(acc._id)
    this.parentAccount = acc.account_name
  }

  subAccount() {
    if (this.addAcountForm.controls.is_sub_account.value) {
      this.addAcountForm.controls.parent_account_Id.setValue('')
      this.parentAccount = 'Select Parent Account'
      this.addAcountForm.controls['parent_account_Id'].setValidators(null)
      this.addAcountForm.controls['parent_account_Id'].updateValueAndValidity()
    }
    else {
      this.addAcountForm.controls['parent_account_Id'].setValidators([Validators.required])
      this.addAcountForm.controls['parent_account_Id'].updateValueAndValidity()
    }
    this.addAcountForm.controls.is_sub_account.setValue(!this.addAcountForm.controls.is_sub_account.value)
  }

  iSparentAccount = true
  parentAccount = 'Select Parent Account'
  selectParentAccountForProduct = 'Select Parent Account'
  incomeAccount = 'Please select income account'
  expenseAccount = 'Please select expense account'
  inventoryAccount = 'Please select inventory account'
  changeAccount(type, val, frm, id, controlName) {
    this[type] = val
    this[frm].controls[controlName].setValue(id)
  }

  selectedAccount = 'Please select any account type'
  selectAccountType(acc) {
    if (acc._id == this.addAcountForm.controls.account_type_Id.value) {
      return
    }
    this.addAcountForm.controls.parent_account_Id.setValue('')
    this.parentAccount = 'Select Parent Account'
    this.selectedAccount = acc.title
    this.addAcountForm.controls.account_type_Id.setValue(acc._id)
    this.accountsTypeWise = this.allAcounts.filter(account => account.account_type_Id._id == acc._id);
  }

  addAccountSubmitted = false
  addAddAccount() {
    if (this.addAcountForm.invalid) {
      this.showToaster('Please fill all the required fields.', 'error')
      this.addAccountSubmitted = true
      return
    }
    if (this.addAcountForm.controls.is_sub_account.value && ( this.addAcountForm.controls.parent_account_Id.value == '' || this.addAcountForm.controls.parent_account_Id.value == null)) {
      this.showToaster('Please select parent account', 'error')
      this.addAccountSubmitted = true
      return
    }
    let obj = this.addAcountForm.value
    if (!obj['is_sub_account']) {
      delete obj['parent_account_Id']
    }
    delete obj['is_sub_account']
    obj['Opening_balance'] = Number(obj['Opening_balance'])
    if (this.accountID != '') {
      this.updateChartOfAccount(obj)
      return
    }
    this.commonService.createAccount(obj).subscribe(
      (res) => {
        this.accountActionCompleted.emit('added')
        this.closeModel()
        this.addAccountSubmitted = false
      }, (err) => {
        this.showToaster(err.message, 'error')
        console.log('error while adding account', err);
      })
  }

  updateChartOfAccount(account) {
    this.commonService.updateChartOfAccount(this.accountID, account).subscribe(
      (res) => {
        this.accountActionCompleted.emit('updated')
        this.closeModel()
        this.addAccountSubmitted = false
      }, (err) => {
        this.showToaster('Something went wrong', 'error')
        console.log('error while adding account', err);
      }
    )
  }

  closeModel() {
    this.showToast = false
    this.modalRef.hide()
  }

  showToast = false
  hideToaster = true
  toasterMsg = ''
  toasterType = ''
  showToaster(message, status) {
    this.showToast = true
    this.hideToaster = false
    this.toasterMsg = message
    this.toasterType = status
  }

  closeToaster() {
    this.showToast = false
    this.hideToaster = true
  }


}
