import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CommonService } from 'src/app/services/common/common.service';
import { MustMatch } from '../../account/register-old/_helper/must-match.validator';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {

  modalRef: BsModalRef;
  @ViewChild('open_changePassword', { static: true }) template: ElementRef;
  showToast = false
  hideToaster = true
  toasterType = ''
  toasterMsg = ''

  constructor(
    private formbulider: FormBuilder,
    private modalService: BsModalService,
    private commonService: CommonService

  ) { }

  ngOnInit() {
  }

  passwordUpdateForm: any
  passwordUpdateSubmitted = false
  initializePasswordUpdateForm(userId) {
    this.passwordUpdateSubmitted = false
    this.passwordUpdateForm = this.formbulider.group({
      userId: [userId, [Validators.required]],
      current_password: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      newConfirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    }, {
      validator: MustMatch('password', 'newConfirmPassword')
    }
    );
    this.modalRef = this.modalService.show(this.template, { class: 'modal-sm ' + 'custModal wd500', backdrop: 'static', keyboard: false });
  }

  get fPasswordUpdateForm() {
    return this.passwordUpdateForm.controls;
  }

  updatePasswordUpdateFun() {
    if (this.passwordUpdateForm.invalid) {
      this.passwordUpdateSubmitted = true
      return;
    }
    let data = JSON.parse(JSON.stringify(this.passwordUpdateForm.value))
    delete data['newConfirmPassword']
    this.commonService.changeUserPassword(data).subscribe(
      (res) => {
        if (res['data'].updateUserPassword) {
          this.showToaster('Password updated successfully.', 'success')
          this.closeModel()
          return
        }
        this.showToaster('Something went wrong while updating password', 'error')
      }, (err) => {
        this.showToaster(err.graphQLErrors[0].message, 'error')
      }
    )
  }

  showToaster(message, status) {
    this.showToast = true
    this.hideToaster = false
    this.toasterMsg = message
    this.toasterType = status
  }

  closeToaster() {
    this.showToast = false
    this.hideToaster = true
  }

  closeModel() {
    this.modalService.hide(1);
  }

}
