import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { Papa } from 'ngx-papaparse';
import { Observable, Subject, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { CSVService } from 'src/app/services/csv.service';
import { PurchaseOrderMinimizerService } from 'src/app/services/purchase-order/purchase-order-minimizer.service';
import { StockService } from 'src/app/services/stock/stock.service';
import * as dummyCSVData from 'src/app/modules/shared/samplePoCSV.json'

@Component({
  selector: 'app-import-po',
  templateUrl: './import-po.component.html',
  styleUrls: ['./import-po.component.css']
})
export class ImportPoComponent implements OnInit {

  @Output() event: EventEmitter<any> = new EventEmitter();
  @Output() supplier: EventEmitter<any> = new EventEmitter();
  @Input() suppId: any;

  sampleUrl = '../../../../assets/ImportProductsSample.csv'
  importFileName = ''
  inputImport : boolean = true
  viewImport : boolean = true
  isInProgress = false;
  suppliers$: Observable<any>;
  supplierInput$ = new Subject<string>();
  minLengthTerm = 3;
  loadingSuppliers = false
  allSuppliers = []
  fileFieldsName: any
  fileList = []
  limit = 30
  skip = 0
  columsTomatch = ['Product Name', 'Supplier SKU', 'Unit Cost', 'QTY']
  fieldsSupplier = []
  columnToMapSupplier = []
  colSupplier = []
  newColName: any
  modalRef: any;
  importProductsDataArray = []
  selectedSupplier : 'asif'
  constructor(private modalService: BsModalService,private stockService: StockService, private papa : Papa, private csvService: CSVService, 
    private minimizerServicePO: PurchaseOrderMinimizerService,
    ) { }

  ngOnInit() {
    this.loadSuppliers(false)

    let supplier = this.minimizerServicePO.supplier == null ? null : this.minimizerServicePO.supplier['_id']
    this.loadSuppliers(this.minimizerServicePO.supplier == null ? null : this.minimizerServicePO.supplier)
    this.selectedSupplier = supplier

  }

  sampleCSV(){
      this.csvService.saveAsCSVFile(dummyCSVData.importPoSample, 'ImportPO');
}

  changedSupplier = null
  onSupplierChange(event) {
    this.changedSupplier = event;
    this.minimizerServicePO.supplier = event == null ? null : event
    this.minimizerServicePO.poDetail['Supplier'] = event == null ? null : event['_id'];
  }

  cancelImport() {
    this.modalService.hide(1)
  }

  back() {
    this.importFileName = ''
    this.fieldsSupplier = []
    this.modalRef.hide()
  }

  openModal(template: TemplateRef<any>, cls ) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm ' + cls, backdrop: 'static', keyboard: false });
  }

  trackByFn(item: any) {
    return item._id;
  }

  loadSuppliers(supplier) {
    this.suppliers$ = concat(
      of(supplier ? [...[],supplier] : []), // default items
      this.supplierInput$.pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => this.loadingSuppliers = true),
        switchMap(term => {
          return this.stockService.getAllSupplier(term).valueChanges.pipe(
            map(({ data }) => {
              this.allSuppliers = data.getAllSupplierRestock;
              return this.allSuppliers;
            }),
            catchError(() => of([])), // empty list on error
            tap(() => this.loadingSuppliers = false)
          )
        })
      )
    );
  }

  onFileChangedSupplier(event , supplier){
    this.importFileName = event[0].name
    this.papa.parse(event[0], {
      header: true,
      skipEmptyLines: true,
      complete: (result, file) => {
        this.fileFieldsName = result.meta.fields
        this.fileList = result.data;
        this.paginationList = this.fileList.slice(this.skip, this.limit);
        console.log("fileList ---->", this.fileList);
        for (let i = 0; i < this.fileFieldsName.length; i++) {
          let obj = {}
          for (let k = 0; k < this.columsTomatch.length; k++) {
            if (this.columsTomatch[k] == this.fileFieldsName[i]) {
              obj['name'] = this.fileFieldsName[i]
              obj['nameForShowData'] = this.fileFieldsName[i]
              obj['selectionBox'] = false
              obj['selectedBox'] = true
              obj['notMapped'] = false
              obj['skip'] = false
              this.fieldsSupplier.push(obj)
            }
          }
          if (this.isObjectEmpty(obj)) {
            obj['name'] = ''
            obj['csvName'] = this.fileFieldsName[i]
            obj['selectionBox'] = true
            obj['selectedBox'] = false
            obj['notMapped'] = true
            obj['skip'] = false
            this.fieldsSupplier.push(obj)
          }
        }
        this.initializeColumnsSupplier(result.meta.fields)
      }
    });
  }

  isObjectEmpty(Obj) {
    for (var key in Obj) {
      if (Obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  initializeColumnsSupplier(col) {
    this.columnToMapSupplier = []
    for (let i = 0; i < col.length; i++) {
      for (let k = 0; k < this.columsTomatch.length; k++) {
        if (col[i] === this.columsTomatch[k]) {
          this.columnToMapSupplier.push(
            {
              c_db: col[i],
              c_csv: this.columsTomatch[k]
            }
          )
          this.colSupplier[i] = this.columsTomatch[k]
        }
      }
      if (this.colSupplier[i] == undefined || this.colSupplier[i] == '') {
        this.colSupplier[i] = 'Make A selection'
      }
    }
  }

  editCol(i) {
    this.fieldsSupplier[i]['selectedBox'] = false
    this.fieldsSupplier[i]['selectionBox'] = true
  }
  skipEditedCol(i) {
    this.fieldsSupplier[i].selectionBox = false
    this.fieldsSupplier[i].selectedBox = true
  }

  skipSlideSupplier(index) {
        this.fieldsSupplier[index]['skip'] = true
        this.fieldsSupplier[index]['name'] = ''
        this.fieldsSupplier[index]['notMapped'] = true
        this.colSupplier[index] = 'Make A selection'
  }

  changeValueOfMapColumnsSupplier(type, i) {
    if (this.newColName == '') {
      this.fieldsSupplier[i].selectionBox = false
      this.fieldsSupplier[i].selectedBox = true
      return
    }
    this[type] = this.newColName
    this.colSupplier[i] = this.newColName
    this.fieldsSupplier[i].selectionBox = false
    this.fieldsSupplier[i].selectedBox = true
    this.fieldsSupplier[i]['skip'] = false
    this.fieldsSupplier[i]['name'] = this.newColName
    this.columnToMapSupplier.push(
      { c_db: this.newColName, c_csv: this.fieldsSupplier[i]['csvName'] }
    )
    this.newColName = ''
  }

  saveUnkownCol(i) {
    if (this.newColName == "") {
      this.showToaster( 'showFirstToast','Please select field first', 'error' )
      return
    }
    var index = this.fieldsSupplier.map(x => {
      return x.name;
    }).indexOf(this.newColName);
    if (index != -1) {
      this.showToaster('showFirstToast', 'This field is already selected', 'error' )
      return
    }
    this.fieldsSupplier[i].selectionBox = false
    this.fieldsSupplier[i].selectedBox = true
    this.fieldsSupplier[i]['skip'] = false
    this.fieldsSupplier[i]['notMapped'] = false
    this.fieldsSupplier[i]['name'] = this.newColName
    this.columnToMapSupplier.push(
      { c_db: this.newColName, c_csv: this.fieldsSupplier[i]['csvName'] }
    )
    this.newColName = ''
  }
 

  changeColItemName(value, i) {
    var index = this.fieldsSupplier.map(x => {
      return x.name;
    }).indexOf(value);
    if (index != -1) {
      this.showToaster( 'showFirstToast','This field is already selected', 'error')
      return
    }
    this.colSupplier[i] = value
    this.newColName = value
  }

  showFirstToast = false
  showSecondToast = false
  toasterMsg = 'no msg'
  toasterType = 'error'
  showToaster(toaster, msg, type) {
    this[toaster] = true
    if (toaster == 'showFirstToast') {
      this.showImportToaster = false
    }
    else {
      this.postVerifyToaster = false
    }
    this.toasterMsg = msg
    this.toasterType = type
  }

  showImportToaster = true
  closeImportToaster() {
    this.showFirstToast = false
    this.showImportToaster = true
  }

  postVerifyToaster = true
  closePostToaster() {
    this.showSecondToast = false
    this.postVerifyToaster = true
  }

  paginationList = []
  showMoreData() {
    this.skip = this.skip + 30
    this.limit = this.limit + 30
    this.paginationList.push(this.fileList.slice(this.skip, this.limit));
  }

  submit(){
    this.isInProgress = true;
    this.importProductsDataArray = []
    for (let i = 0; i < this.fileList.length; i++) {
      let product = {}
      for (let k = 0; k < this.columnToMapSupplier.length; k++) {
        product[this.columnToMapSupplier[k].c_db] = this.fileList[i][this.columnToMapSupplier[k].c_csv]
      }
      let prod = {}
      prod['product_name'] = product['Product Name'] == undefined ? "" : product['Product Name']
      prod['unit_cost'] = product['Unit Cost'] == undefined ? 0 : Number(product['Unit Cost'])
      prod['quantity'] = product['QTY'] == undefined ? 0 : Number(product['QTY'])
      prod['supplier_sku'] = product['Supplier SKU'] == undefined ? "" : product['Supplier SKU']
      prod['product_name'] = prod['product_name'].replace(/[|&;$%@"<>()+,]/g, "");
      this.importProductsDataArray.push(prod);
    }
    this.event.emit({data:this.importProductsDataArray, supplier: this.selectedSupplier});
    this.supplier.emit(this.selectedSupplier);
   this.back();
   this.cancelImport();

  }
  
}
