import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserClockOutBeforeLogOutGQL } from '../../generated/graphql'
import { RolesPermissionsGQLService } from "src/app/services/role-permission/roles-permissions-gql.service"
import {MODULE_PERMISSIONS_NAME, SYSTEM_FEATURE_NAME} from "./permissions-name"
import { Module } from 'module';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _allowedModules = []
  private _allowedModuleFeatures = []
  private _allowedFeatureActions = []
  private _activeModule = '';
  
  constructor(private userClockOutBeforeLogOutGQL: UserClockOutBeforeLogOutGQL,
    private router: Router,
    private _rolePermissionGQL: RolesPermissionsGQLService) { }


  public get allowedModules() {
    return this._allowedModules
  }

  public set allowedModules(modules) {
    this._allowedModules = modules
  }

  public set allowedModuleFeatures(features) {
    this._allowedModuleFeatures = features
  }

  public get allowedModuleFeatures() {
    return this._allowedModuleFeatures
  }

  public set allowedFeatureActions(actions) {
    this._allowedFeatureActions = actions
  }

  public get allowedFeatureActions() {
    return this._allowedFeatureActions
  }

  public get location_id() {
    return localStorage.getItem('location_id');
  }

  public get activeModule() {
    return this._activeModule;
  }
  public set activeModule(value) {
    this._activeModule = value;
  }


  public isModuleAllowed(moduleName) {
    return this.allowedModules && this.allowedModules.findIndex(module => module == moduleName) !== -1
  }

  public isFeatureAllowed(featureName) {
    let val = this.allowedModuleFeatures.findIndex(feature => feature === featureName) !== -1
    return val
  }

  public isActionAllowed(feature, action) {
    let val = this.allowedFeatureActions.findIndex(featureAction => featureAction.feature === feature && featureAction.permission === action) !== -1
    return val
  }

  getLoggedInUserModulePermissions(module, location_id = this.location_id) {
    this._rolePermissionGQL.getLoggedInUserModulePermissions(module, location_id)
      .subscribe((res) => {
        this.allowedModuleFeatures = res.data.getLoggedInUserModulePermissions.features
        this.allowedFeatureActions = res.data.getLoggedInUserModulePermissions.permissions
      })
  }

  getLoggedInUserModulePermissionsObservable(module, location_id = this.location_id) {
    return this._rolePermissionGQL.getLoggedInUserModulePermissions(module, location_id)
  }

  getLoggedInUserAllowModulesObservable(location_id = localStorage.getItem('location_id')) {
    return this._rolePermissionGQL.getLoggedInUserAllowModules(location_id)
  }

  getLoggedInUserAllowModules(location_id = localStorage.getItem('location_id')) {
    this._rolePermissionGQL.getLoggedInUserAllowModules(location_id)
      .subscribe((res) => {
        this.allowedModules = res.data.getLoggedInUserAllowModules
      }, (err) => {
        //this.getLoggedInUserAllowModules()
      })
  }

  logout() {
    localStorage.removeItem('StartModel');
    localStorage.removeItem('location_id');
    localStorage.removeItem('token');
    this.router.navigate(['/Login']);
  }


  userClockOutBeforeLogOut() {
    return this.userClockOutBeforeLogOutGQL.mutate({
      is_check_clockIn: false
    })
  }

  addCustomUserRolePermissions(userID, roleID, addPermissions, removePermissions, location_id = localStorage.getItem('location_id')) {
    return this._rolePermissionGQL.addCustomUserRolePermissions(userID, roleID, location_id, addPermissions, removePermissions)
  }


  isChangeLandingPage(menu){
    if(menu == MODULE_PERMISSIONS_NAME.system_menu){
      if(this.isFeatureAllowed(SYSTEM_FEATURE_NAME.store_management_tab)){
        this.router.navigate(['Pointofsale','Settings','store'])
      }else if(this.isFeatureAllowed(SYSTEM_FEATURE_NAME.user_tab)){
        this.router.navigate(['Pointofsale','Settings','user'])
      }else{
        this.router.navigate(['403'])
        return false
      }
    }
    return true
  }




}
