import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterComponent } from './toaster/toaster.component';
import { ProfilePhotoUploaderComponent } from './profile-photo-uploader/profile-photo-uploader.component';
import { PermissionCheckboxComponent } from './permission-checkbox/permission-checkbox.component';
import { SupplierBuyBack } from './Filters/supplierIsBuyBackPipe';
import { ImportProductSupplierComponent } from './import-product-supplier/import-product-supplier.component';
import { AccordionModule, BsDatepickerModule, BsDropdownModule, CarouselModule, TabsModule } from 'ngx-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ManageAccountsComponent } from './manage-accounts/manage-accounts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilitiesModule } from 'src/app/utilties/utilities.module';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { ManageUserComponent } from '../point-of-sale/system-settings/users/manage-user/manage-user.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormCreationComponent } from '../store-configurations/customers/form-creation/form-creation.component';



@NgModule({
  declarations: [ToasterComponent,
    ProfilePhotoUploaderComponent,
    PermissionCheckboxComponent,
    SupplierBuyBack,
    ImportProductSupplierComponent, ManageAccountsComponent, UpdatePasswordComponent,
    ManageUserComponent,
    FormCreationComponent],
  imports: [
    CommonModule,
    CarouselModule.forRoot(),
    InfiniteScrollModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    UtilitiesModule,
    NgSelectModule,
    NgxIntlTelInputModule,
    TabsModule,
    AccordionModule,
    BsDatepickerModule
  ],
  exports: [
    ToasterComponent,
    PermissionCheckboxComponent,
    SupplierBuyBack,
    ImportProductSupplierComponent,
    ManageAccountsComponent,
    UpdatePasswordComponent,
    ManageUserComponent,
    FormCreationComponent
  ],
  entryComponents: [
    ProfilePhotoUploaderComponent,
  ]
})
export class SharedModule { }
