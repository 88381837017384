import { Injectable } from '@angular/core';
import {
  GetRepairRoomTaskDetailGQL, GetRepairRoomTaskListGQL, GetRepairRoomTaskStatusGQL,
  TaskMarkAsDoneGQL, TaskServiceMarkAsDoneGQL, TaskDeviceMarkAsDoneGQL, GetAllTechnicianGQL,
  TechnicianAssignToDeviceGQL, TechnicianTimeLogGQL, RepairRoomOrderPartGQL, SetServiceItemTypeGQL,
  CreateTransactionAlertGQL, OrderPartSuppliersGQL, TaskEnum, DateFilterEnum, SetServiceProductTypeGQL, 
  GetReasonsGQL, RepairRoomRemoveServiceGQL, GetDeviceCheckInGQL,TechnicianStartTimeLogGQL, RemoveProductFromServiceGQL, DeleteRmaDeviceItemGQL
} from 'src/app/generated/graphql'
import { map, startWith, catchError } from 'rxjs/operators';
import { LOCATION_INITIALIZED } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RepairRoomGQLService {

  repairRoomFilters = {
    task: TaskEnum.All,
    dateFilter: DateFilterEnum.None,
    startDate: null,
    endDate: null,
    search: null,
    status: null
  }

  constructor(private getRepairRoomTaskDetailGQL: GetRepairRoomTaskDetailGQL,
    private getRepairRoomTaskListGQL: GetRepairRoomTaskListGQL,
    private getRepairRoomTaskStatusGQL: GetRepairRoomTaskStatusGQL,
    private taskMarkAsDone: TaskMarkAsDoneGQL,
    private taskServiceMarkAsDone: TaskServiceMarkAsDoneGQL,
    private taskDeviceMarkAsDone: TaskDeviceMarkAsDoneGQL,
    private getAllTechnician: GetAllTechnicianGQL,
    private technicianAssignToDevice: TechnicianAssignToDeviceGQL,
    private technicianTimeLog: TechnicianTimeLogGQL,
    private repairRoomOrderPart: RepairRoomOrderPartGQL,
    private setServiceItemType: SetServiceItemTypeGQL,
    private createTransactionAlert: CreateTransactionAlertGQL,
    private orderPartSuppliers: OrderPartSuppliersGQL,
    private setServiceProductTypeGQL: SetServiceProductTypeGQL,
    private getReasonsGQL: GetReasonsGQL,
    private repairRoomRemoveServiceGQL: RepairRoomRemoveServiceGQL,
    private getDeviceCheckInGQL: GetDeviceCheckInGQL,
    private technicianStartTimeLogGQL: TechnicianStartTimeLogGQL,
    private removeProductFromServiceGQL: RemoveProductFromServiceGQL,
    ) { }

  getRepairRoomTaskList(reapirRoomFilter, location_id, limit, skip) {
    return this.getRepairRoomTaskListGQL.watch({
      reapirRoomFilter: reapirRoomFilter,
      location_id: location_id,
      limit: limit,
      skip: skip,
    }).valueChanges
  }

  getReapirRoomFilter(){
    return this.repairRoomFilters
  }

  getRepairRoomTaskDetail(location_id, transactionID) {
    return this.getRepairRoomTaskDetailGQL.watch({
      transactionID: transactionID,
      location_id: location_id
    }).valueChanges
  }

  getRepairRoomTaskStatus() {
    return this.getRepairRoomTaskStatusGQL.watch().valueChanges
  }
  
  getAllTechnicianList(location_id) {
    return this.getAllTechnician.watch({ location_id }).valueChanges
  }

  markTaskAsDone(ids: [], location_id) {
    return this.taskMarkAsDone.mutate({
      transactionIDs: ids,
      location_id: location_id
    })
  }

  changeDeviceStatus(transactionID, deviceID, location_id) {
    return this.taskDeviceMarkAsDone.mutate({ transactionID, deviceID, location_id })
  }

  changeTaskServiceStatus(transactionID, deviceID, sellID, status, location_id) {
    return this.taskServiceMarkAsDone.mutate({ transactionID, deviceID, sellID, status, location_id })
  }

  assignTechnicianToDevice(transactionID, deviceID, technicianID, location_id) {
    return this.technicianAssignToDevice.mutate({ transactionID, deviceID, technicianID, location_id })
  }

  logTechnicianTime(transactionID, deviceID, serviceID, technicianID, location_id, log_time) {
    return this.technicianTimeLog.mutate({ transactionID, deviceID, serviceID, technicianID, location_id, log_time })
  }

  technicianStartTimeLog(transactionID, deviceID, serviceID, technicianID){
    return this.technicianStartTimeLogGQL.mutate({transactionID, deviceID, serviceID, technicianID})
  }

  orderPart(OrderPartInput) {
    return this.repairRoomOrderPart.mutate({ input: OrderPartInput })
  }

  setItemType(transactionID, serviceID, location_id, service_item_type) {
    return this.setServiceItemType.mutate({ transactionID, serviceID, location_id, service_item_type })
  }

  getReasons(type) {
    return this.getReasonsGQL.watch(
      {
        reason_type: type
      }
    ).valueChanges
  }

  setServiceProductType(id,transactionID,serviceID,productID,location_id, service_item_type, serviceReasons,supplierId, reason){
    return this.setServiceProductTypeGQL.mutate({
      id: id,
      transactionId:transactionID,
      serviceId: serviceID,
      productId: productID,
      locationId:location_id,
      serviceItemType:service_item_type,
      serviceReasons : serviceReasons,
      supplierId : supplierId,
      reason : reason
    })
  }

  transactionAlert(PurchaseAlertInput) {
    return this.createTransactionAlert.mutate({ input: PurchaseAlertInput })
  }

  getOrderPartSuppliers(location_id, product_id, search) {
    return this.orderPartSuppliers.watch({ location_id, product_id, search }).valueChanges
  }

  repairRoomRemoveService(transactionID,serviceID,locationID){
    return this.repairRoomRemoveServiceGQL.mutate({
      location_id:locationID,
      serviceID: serviceID,
      transactionID: transactionID
    })
  }

  getDeviceCheckIn(deviceID, transcationID,locationID){
    return this.getDeviceCheckInGQL.watch({
      deviceID:deviceID,
      transactionID: transcationID,
      locationID:locationID
    }).valueChanges
  }

  removeProductFromService(id,transcationID,serviceID,productID,location_id){
    return this.removeProductFromServiceGQL.mutate({
      _id:id,
      location_id:location_id,
      productID:productID,
      serviceID:serviceID,
      transactionID:transcationID
    })
  }




}
