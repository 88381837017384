import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketListingComponent } from './ticket-listing/ticket-listing.component';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { TicketRoutingModule } from './ticket-routing.module';
import { RouterModule } from '@angular/router';
import { AccordionModule, BsDatepickerModule, BsDropdownModule, CarouselModule, ModalModule, TabsModule, TooltipModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UtilitiesModule } from 'src/app/utilties/utilities.module';
import { SharedModule } from '../../shared/shared.module';
import { RefundByOrigianlPaymentMethodComponent } from './refund-by-origianl-payment-method/refund-by-origianl-payment-method.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [TicketListingComponent, TicketDetailComponent, RefundByOrigianlPaymentMethodComponent],
  imports: [
    CommonModule,
    TicketRoutingModule,
    RouterModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    FormsModule,
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TagInputModule,
    ReactiveFormsModule,
    CarouselModule.forRoot(),
    InfiniteScrollModule,
    TooltipModule.forRoot(),
    UtilitiesModule,
    SharedModule,
    NgSelectModule
  ],
  entryComponents: [
    RefundByOrigianlPaymentMethodComponent
  ]
})
export class TicketsModule { }
