import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPermission'
})
export class SearchPermissionPipe implements PipeTransform {

  transform(list: any, filterText: string): any {

    if (list.length == 0) {
      return list
    }

    if (list[0].hasOwnProperty('permission')) {
      return list.filter(item => { return item.permission.search(new RegExp(filterText, 'i')) > -1 })
    } else {
      return list.filter(item => { return item.search(new RegExp(filterText, 'i')) > -1 })
    }

   // return list ? list.filter(item => { return item.search(new RegExp(filterText, 'i')) > -1 }) : [];
  }

}
