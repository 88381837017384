import { Component, OnInit, TemplateRef } from '@angular/core';
import { AddEditUserComponent } from '../add-edit-user/add-edit-user.component'
import { SettingGqlService } from 'src/app/services/settings/setting-gql.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'user-listing',
  templateUrl: './user-listing.component.html',
  styleUrls: ['./user-listing.component.css']
})
export class UserListingComponent implements OnInit {

  userList = []
  loader = false
  userFilter = "all"
  locatin_id = localStorage.getItem('location_id')
  showUserDetail = false
  userForDetail: any
  bsModalRef: BsModalRef;
  loading = false

  constructor(private _settingGQL: SettingGqlService,
    private modalService: BsModalService,
    private router: Router) { }

  ngOnInit() {
    this.getAllUsers()
  }

  getAllUsers() {
    this.loading = true
    this._settingGQL.getAllBusinessUsers(this.userFilter, this.locatin_id, '', 30, 0)
      .subscribe(
        (res) => {
          this.loading = false
          this.userList = res.data.getAllBusinessUsers
          console.log('all users res--->', this.userList);
        }, (err) => {
          this.loading = false
          console.log('all users err', err);
        }
      )
  }

  updateFilters(type, value) {
    this[type] = value
    this.getAllUsers()
  }

  onShowUserDetail(user) {
    this.userForDetail = user
    this.showUserDetail = true
  }

  closeUserDetail() {
    this.showUserDetail = false
  }

  onAddEditUer(_id) {
    const initialState = { userId: _id };
    this.bsModalRef = this.modalService.show(AddEditUserComponent, { class: 'modal-sm box-add-supplier', backdrop: 'static', keyboard: false, initialState })
    this.bsModalRef.content.event.subscribe(res => {
      this.getAllUsers()
    });
  }

  openModal(template: TemplateRef<any>, cls) {
    this.modalService.show(template, { class: 'modal-sm ' + cls, backdrop: 'static', keyboard: false });
  }

  userToDelete: any
  removeUser() {
    this.closeModel()
    this._settingGQL.deleteBusinessUser(this.userToDelete['id'])
      .subscribe(
        (res) => {
          this.getAllUsers()
          // this.toaster.showSuccess('User removed successfully', 'User delete')
        }, (err) => {
          this.loading = false
        }
      )
  }

  closeModel() {
    this.modalService.hide(1)
  }

  selectedUserPermission
  onSetUserPermission() {
    this.closeModel()
    this.router.navigate(['Permissions'], { state: { user: this.selectedUserPermission } })
  }

  removeOwner(userList) {
    return userList.filter(user => user.isOwner == false)
  }

}
