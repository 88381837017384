import { Injectable } from "@angular/core";
import { io } from "socket.io-client";
import { Observable } from "rxjs/Observable";
import * as Rx from "rxjs/Rx";
import { EnvironmentUrl } from "../../../environments/environment-url";

@Injectable({
  providedIn: "root"
})
export class WebsocketService {
  private _socket;

  public get socket() {
    return this._socket;
  }
  public set socket(value) {
    this._socket = value;
  }

  constructor() { }

  connect(): Rx.Subject<MessageEvent> {
    // If you aren't familiar with environment variables then
    // you can hard code `environment.ws_url` as `http://localhost:5000`
    this.socket = io(EnvironmentUrl.getSocketURL,{
      transports: ["websocket", "polling"]
    });

    this.socket.on("connect_error", () => {
      // revert to classic upgrade
      this.socket.io.opts.transports = ["polling", "websocket"];
    });

    // We define our observable which will observe any incoming messages
    // from our socket.io server.
    let observable = new Observable(observer => {
        this.socket.on('devices', (data) => {
          console.log("Received message from Websocket Server")
          observer.next(data);
        })
        this.socket.on('device_data', (data) => {
          console.log("Received message from Websocket Server")
          observer.next(data);
        })
        return () => {
          this.socket.disconnect();
        }
    });

    // We define our Observer which will listen to messages
    // from our other components and send messages back to our
    // socket server whenever the `next()` method is called.
    let observer = {
        next: (data: Object) => {
            
        },
    };

    // we return our Rx.Subject which is a combination
    // of both an observer and observable.
    return Rx.Subject.create(observer, observable);
  }

}
