import { LOCATION_INITIALIZED } from '@angular/common';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import {
  GetAllBusinessUsersGQL, GetUserByIdGQL, GetCLockOfUserByIdGQL,
  GetUsersLogsActivityGQL, UserLogoUploadGQL, UserLogoDeleteGQL,
  StatusBusinessUserGQL, GetUserPinCodeByIdGQL, UniquePinCodeVerifyGQL,
  GeneratePinCodeGQL, UserPinCodeResendGQL, CreatePinCodeGQL, GetRolesGQL,
  GetCountriesGQL, CreateBusinessUserGQL, UpdateBusinessUserGQL, DeleteBusinessUserGQL,
  CreateUpdateDepartmentGQL, DeleteDepartmentGQL, GetDepartmentGQL, GetDepartmentsGQL,
  CashRegistersOfLocationGQL, DeleteCashRegisterGQL, GetCashDrawerLogsGQL, GetAllBusinessStoreAdminGQL,
  StatusBusinessStoreAdminGQL, BusinessLocationByIdGQL, CheckStoreNickNameGQL, GetCityStateTaxGQL,
  CreateEmailOtpGQL,
  CreateOtpGQL,
  VerifyOtpGQL,
  VerifyEmailOtpGQL,
  CreateBusinessStoreAdminGQL,
  StoreLogoUploadGQL,
  UpdateBusinessStoreAdminGQL,
  StoreLogoDeleteGQL,
  CreateCashRegisterGQL,
  GetCashRegisterByIdGQL,
  UpdateCashRegisterGQL,
  UserBusinessLocationsGQL,
  CreateEmployeeGQL,
  UpdateEmployeeGQL,
  GetRolesByStoreGQL,
  GetUserDetailGQL,
  AdminCreateUpdateEmployeeGQL,
  EmployeeUpdatedProfileGQL, ChangeCashRegisterStatusGQL, GetAllCashRegistersOfLocationGQL,
  // GetBusinessSalesReportsGQL,
  FilterEnum,
  GetBusinessSalesReportsGQL,
  GetPurchaseReportsGQL,
  GetStoreSalesReportsGQL,
  GetStorePurchaseReportsGQL,
  ActiveDepartmentGQL
} from 'src/app/generated/graphql'

@Injectable({
  providedIn: 'root'
})
export class SettingGqlService {

  constructor(
    private getAllBusinessUsersGQL: GetAllBusinessUsersGQL,
    private getUserByIdGQL: GetUserByIdGQL,
    private getCLockOfUserByIdGQL: GetCLockOfUserByIdGQL,
    private getUsersLogsActivityGQL: GetUsersLogsActivityGQL,
    private userLogoUploadGQL: UserLogoUploadGQL,
    private userLogoDeleteGQL: UserLogoDeleteGQL,
    private statusBusinessUserGQL: StatusBusinessUserGQL,
    private generatePinCodeGQL: GeneratePinCodeGQL,
    private getUserPinCodeByIdGQL: GetUserPinCodeByIdGQL,
    private uniquePinCodeVerifyGQL: UniquePinCodeVerifyGQL,
    private userPinCodeResendGQL: UserPinCodeResendGQL,
    private createPinCodeGQL: CreatePinCodeGQL,
    private getRolesGQL: GetRolesGQL,
    private getCountriesGQL: GetCountriesGQL,
    private createBusinessUserGQL: CreateBusinessUserGQL,
    private updateBusinessUserGQL: UpdateBusinessUserGQL,
    private deleteBusinessUserGQL: DeleteBusinessUserGQL,
    private createUpdateDepartmentGQL: CreateUpdateDepartmentGQL,
    private deleteDepartmentGQL: DeleteDepartmentGQL,
    private getDepartmentGQL: GetDepartmentGQL,
    private getDepartmentsGQL: GetDepartmentsGQL,
    private cashRegistersOfLocationGQL: CashRegistersOfLocationGQL,
    private getAllCashRegistersOfLocationGQL: GetAllCashRegistersOfLocationGQL,
    private deleteCashRegisterGQL: DeleteCashRegisterGQL,
    private getCashDrawerLogsGQL: GetCashDrawerLogsGQL,
    private getAllBusinessStoreAdminGQL: GetAllBusinessStoreAdminGQL,
    private statusBusinessStoreAdminGQL: StatusBusinessStoreAdminGQL,
    private businessLocationByIdGQL: BusinessLocationByIdGQL,
    private checkStoreNickNameGQL: CheckStoreNickNameGQL,
    private getCityStateTaxGQL: GetCityStateTaxGQL,
    private createEmailOtpGQL: CreateEmailOtpGQL,
    private createOtpGQL: CreateOtpGQL,
    private verifyOtpGQL: VerifyOtpGQL,
    private verifyEmailOtpGQL: VerifyEmailOtpGQL,
    private createBusinessStoreAdminGQL: CreateBusinessStoreAdminGQL,
    private storeLogoUploadGQL: StoreLogoUploadGQL,
    private updateBusinessStoreAdminGQL: UpdateBusinessStoreAdminGQL,
    private storeLogoDeleteGQL: StoreLogoDeleteGQL,
    private createCashRegisterGQL: CreateCashRegisterGQL,
    private getCashRegisterByIdGQL: GetCashRegisterByIdGQL,
    private updateCashRegisterGQL: UpdateCashRegisterGQL,
    private userBusinessLocationsGQL: UserBusinessLocationsGQL,
    private createEmployee: CreateEmployeeGQL,
    private updateEmployeeGQL: UpdateEmployeeGQL,
    private getRolesByStore: GetRolesByStoreGQL,
    private getUserDetail: GetUserDetailGQL,
    private adminCreateUpdateEmployee: AdminCreateUpdateEmployeeGQL,
    private employeeUpdatedProfile: EmployeeUpdatedProfileGQL,
    private changeCashRegisterStatusGQL: ChangeCashRegisterStatusGQL,
    private getBusinessSalesReportsGQL : GetBusinessSalesReportsGQL,
    private getPurchaseReportsGQL: GetPurchaseReportsGQL,
    private getStoreSalesReportsGQL:GetStoreSalesReportsGQL,
    private getStorePurchaseReportsGQL:GetStorePurchaseReportsGQL,
    private activeDepartmentGQL: ActiveDepartmentGQL
  ) { }


  getLogedInUser() {
    return this.getUserDetail.watch().valueChanges
  }

  getCashRegisterById(id) {
    return this.getCashRegisterByIdGQL.watch({
      id: id
    }).valueChanges
  }

  changeCashRegisterStatus(cashRegisterID, status, location_id) {
    return this.changeCashRegisterStatusGQL.mutate({
      cashRegisterID: cashRegisterID,
      location_id: location_id,
      status: status
    })
  }

  updateCashRegister(id, input) {
    return this.updateCashRegisterGQL.mutate({
      id: id,
      input: input
    })
  }

  createCashRegister(input) {
    return this.createCashRegisterGQL.mutate({
      input: input
    })
  }


  storeLogoUpload(store_Id: string, logo: string) {
    return this.storeLogoUploadGQL.mutate({
      logo: logo,
      store_id: store_Id
    })
  }

  storeLogoDelete(id) {
    return this.storeLogoDeleteGQL.mutate({
      store_id: id
    })
  }

  createBusinessStoreAdmin(input) {
    return this.createBusinessStoreAdminGQL.mutate({

      input: input,
    }, {
      context: {
        useMultipart: true
      }

    })
  }

  updateBusinessStoreAdmin(id, input) {
    return this.updateBusinessStoreAdminGQL.mutate({
      _id: id,
      input: input,
    }, {
      context: {
        useMultipart: true
      }
    })
  }

  userBusinessLocations() {
    return this.userBusinessLocationsGQL.watch().valueChanges
  }

  verifyEmailOtp(input) {
    return this.verifyEmailOtpGQL.mutate({
      input: input
    })
  }

  createOtp(input) {
    return this.createOtpGQL.mutate({
      input: input
    })
  }

  verifyOtp(input) {
    return this.verifyOtpGQL.mutate({
      input: input
    })
  }

  createEmailOtp(input) {
    return this.createEmailOtpGQL.mutate({
      input: input
    })
  }

  getCityStateTax(input) {
    return this.getCityStateTaxGQL.watch({
      input: input
    }).valueChanges
  }

  checkStoreNickName(store_nick_name) {
    return this.checkStoreNickNameGQL.watch({
      store_nick_name: store_nick_name
    }).valueChanges
  }


  businessLocationById(store_id) {
    return this.businessLocationByIdGQL.watch({
      businessLocationId: store_id
    }).valueChanges
  }

  getAllBusinessStoreAdmin(bussiness_id, filter, search = '') {
    return this.getAllBusinessStoreAdminGQL.watch({
      business_id: bussiness_id,
      filter: filter,
      limit: 30,
      skip: 0,
      search: search
    }).valueChanges
  }

  getAllBusinessStoreAdminWithFilter(filter) {
    return this.getAllBusinessStoreAdminGQL.watch({
      business_id: filter.business_id,
      filter: filter.filter,
      limit: filter.limit,
      skip: filter.skip,
      search: filter.search
    }).valueChanges
  }

  statusBusinessStoreAdmin(store_id, status) {
    return this.statusBusinessStoreAdminGQL.mutate({
      status: status,
      _id: store_id
    })
  }

  cashRegistersOfLocation(location_id: string) {
    return this.cashRegistersOfLocationGQL.watch({
      ID: location_id
    }).valueChanges
  }

  getAllCashRegistersOfLocation(location_id: string) {
    return this.getAllCashRegistersOfLocationGQL.watch({
      ID: location_id
    }).valueChanges
  }

  deleteCashRegister(register_id) {
    return this.deleteCashRegisterGQL.mutate({
      id: register_id
    })
  }

  getCashDrawerLogs(input) {
    return this.getCashDrawerLogsGQL.watch({
      input: input
    }).valueChanges
  }

  getAllBusinessUsers(filter, location_id, search, limit, skip) {
    return this.getAllBusinessUsersGQL.watch({
      filter: filter,
      store_id: location_id,
      search: search,
      limit: limit,
      skip: skip
    }).valueChanges
  }

  getUserById(id) {
    return this.getUserByIdGQL.watch({
      id: id
    }).valueChanges
  }

  updateEmployee(id, input) {
    return this.updateEmployeeGQL.mutate({
      _id: id,
      input: input
    }, {
      context: {
        useMultipart: true
      }
    })
  }

  addUpdateEmployee(id, data) {
    return this.adminCreateUpdateEmployee.mutate({
      _id: id,
      input: data
    }, {
      context: {
        useMultipart: true
      }
    })
  }

  getCLockOfUserById(id, fromDate, toDate, location_id,) {
    return this.getCLockOfUserByIdGQL.watch({
      location_id: location_id,
      userId: id,
      fromDate: fromDate,
      toDate: toDate
    }).valueChanges
  }

  getUsersLogsActivity(searchUserActivityLog) {
    return this.getUsersLogsActivityGQL.watch({
      input: searchUserActivityLog
    }).valueChanges
  }

  userLogoUpload(userId, logo) {
    return this.userLogoUploadGQL.mutate({
      logo: logo,
      user_id: userId
    },
      {
        context: {
          useMultipart: true
        }
      })
  }

  userLogoDelete(userId) {
    return this.userLogoDeleteGQL.mutate({
      user_id: userId
    })
  }

  statusBusinessUser(userId, status) {
    return this.statusBusinessUserGQL.mutate({
      _id: userId,
      status: status
    })
  }

  generatePinCode() {
    return this.generatePinCodeGQL.watch().valueChanges
  }

  getUserPinCodeById(userId) {
    return this.getUserPinCodeByIdGQL.watch({
      userId: userId
    }).valueChanges
  }

  uniquePinCodeVerify(pincode) {
    return this.uniquePinCodeVerifyGQL.watch({
      pincode: pincode
    }).valueChanges
  }

  userPinCodeResend(userId, method, password) {
    return this.userPinCodeResendGQL.mutate({
      userID: userId,
      method: method,
      password: password
    })
  }

  createPinCode(input) {
    return this.createPinCodeGQL.mutate({
      input: input
    })
  }

  getAllRoles(input) {
    return this.getRolesGQL.watch({
      roleFilter: input
    }).valueChanges
  }

  getLocationwiseRoles(id) {
    return this.getRolesByStore.watch({
      location_id: id
    }).valueChanges
  }

  getCountries() {
    return this.getCountriesGQL.watch().valueChanges
  }

  createBusinessUser(input, logo) {
    return this.createBusinessUserGQL.mutate({
      input: input,
      logo: logo
    })
  }

  createBusinessEmployee(logo, input) {
    return this.createEmployee.mutate({
      file: logo,
      input: input
    },
      {
        context: {
          useMultipart: true
        }
      })
  }

  updateBusinessUser(_id, input) {
    return this.updateBusinessUserGQL.mutate({
      _id: _id,
      input: input
    })
  }

  deleteBusinessUser(userId, archive = true) {
    return this.deleteBusinessUserGQL.mutate({
      _id: userId,
      archive: archive
    })
  }

  createUpdateDepartment(department_name, description, location_id, department_id = null) {
    return this.createUpdateDepartmentGQL.mutate({
      department_name: department_name,
      description: description,
      location_id: location_id,
      department_id: department_id
    })
  }

  deleteDepartment(department_id, location_id) {
    return this.deleteDepartmentGQL.mutate({
      department_id: department_id,
      location_id: location_id
    })
  }

  activeDepartment(department_id, location_id) {
    return this.activeDepartmentGQL.mutate({
      department_id: department_id,
      location_id: location_id
    })
  }

  getDepartment(department_id, location_id) {
    return this.getDepartmentGQL.watch({
      department_id: department_id,
      location_id: location_id
    }).valueChanges
  }

  getDepartments(input) {
    return this.getDepartmentsGQL.watch({
      input: input
    }).valueChanges
  }

  updateEmployeeProfileBit(id, bool) {
    return this.employeeUpdatedProfile.mutate({
      id: id,
      is_updated: bool
    })
  }

  getBusinessSalesReports(input){
    return this.getBusinessSalesReportsGQL.watch({
      input:input
    }).valueChanges
  }

  getBusinessPurchaseReport(input){
    return this.getPurchaseReportsGQL.watch({
      input:input
    }).valueChanges
  }

  getStoreSalesReports(input){
    return this.getStoreSalesReportsGQL.watch({
      input:input
    }).valueChanges
  }
  
  getStorePurchaseReport(input){
    return this.getStorePurchaseReportsGQL.watch({
      input:input
    }).valueChanges
  }

}
