import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AllowedOrdertStatus, AllowedTransactionStatus, AllowedTransactionType } from 'src/app/generated/graphql';
import { StockService } from '../stock/stock.service';

@Injectable({
  providedIn: 'root'
})
export class PoMinimizerService {

  minimizerID = ''
  poDetail: any

  /////////// Subjects /////////////////

  private resfreshShipment = new Subject<any>();
  resfreshShipment$ = this.resfreshShipment.asObservable();

  private resfreshSupplier = new Subject<any>();
  resfreshSupplier$ = this.resfreshSupplier.asObservable();

  constructor(
    private stockService: StockService,
  ) { }

  getOrderDetail(id) {
    this.stockService.getOrderByID(id)
      .valueChanges.subscribe(
        (res) => {
          this.minimizerID = id
          this.setPOdata(res['data'].getPurchaseOrderbyID)
        }, (err) => {
          console.log('err is ', err);
        }
      )
  }

  updatePODetail(key, value) {
    if (this.poDetail == undefined) { return }
    this.poDetail[key] = value
  }

  setMinimizerID(id) {
    this.minimizerID = id
  }

  getDefaultPOObject() {
    return {
      transaction_status: AllowedTransactionStatus.Minimize,
      transaction_type: AllowedTransactionType.Purchase,
      order_status: AllowedOrdertStatus.Ordered,
      transaction_date: new Date(),
      transaction_hours: '02',
      transaction_minutes: '00',
      transaction_am_pm: 'AM',
      discount_amount: 0,
      discount_value: 0,
      is_discount_percentage: true,
      total_amount: 0,
      sub_total_amount: 0,
      shipping_amount: 0,
      BusinessLocation: localStorage.getItem('location_id'),
      TransactionPurchaseLines: [],
      ShippingType: null,
      is_private: false,
      additional_notes: '',
      Tax: null,
      is_tax_percentage: true,
      tax_amount: 0,
      tax_value: 0,
      route_url: 'Pointofsale/Stock/Orders/PurchaseOrder'
    }
  }

  getPODetail() {
    return this.poDetail
  }

  getCurrentMinimizerId() {
    return this.minimizerID
  }

  setPOdata(po) {
    this.poDetail = {
      transaction_status: AllowedTransactionStatus.Minimize,
      transaction_type: AllowedTransactionType.Purchase,
      order_status: AllowedOrdertStatus.Ordered,
      transaction_date: po.transaction_date,
      transaction_hours: po.transaction_hours,
      transaction_minutes: po.transaction_minutes,
      transaction_am_pm: po.transaction_am_pm,
      discount_amount: po.discount_amount,
      discount_value: po.discount_value,
      is_discount_percentage: po.is_discount_percentage,
      total_amount: po.total_amount,
      sub_total_amount: po.sub_total_amount,
      shipping_amount: po.shipping_amount,
      BusinessLocation: localStorage.getItem('location_id'),
      Supplier: po.Supplier == null ? null : po.Supplier.supplier_company == 'Unknown Supplier' ? null : po.Supplier._id,
      ShippingType: po.ShippingType == null ? null : po.ShippingType._id,
      is_private: po.is_private,
      additional_notes: po.additional_notes,
      Tax: po.Tax == null ? null : po.Tax._id,
      is_tax_percentage: po.is_tax_percentage,
      tax_amount: po.tax_amount,
      tax_value: po.tax_value,
      filterJson: po.filterJson,
      route_url: po.route_url
    }
    if (po['ShippingType'] != null) {
      this.selectShipement(po['ShippingType']['_id'])
    }
    if (this.poDetail['Supplier'] != null) {
      this.selectSupplier(this.poDetail['Supplier'])
    }
  }

  selectShipement(ship) {
    this.resfreshShipment.next(ship)
  }

  selectSupplier(ship) {
    this.resfreshSupplier.next(ship)
  }

}
