import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { AllowedPaymentMethod } from "src/app/generated/graphql";
import { TicketsGQLService } from "src/app/services/order and invoice/ticketsGQLService";

@Component({
  selector: "app-refund-by-origianl-payment-method",
  templateUrl: "./refund-by-origianl-payment-method.component.html",
  styleUrls: ["./refund-by-origianl-payment-method.component.css"]
})
export class RefundByOrigianlPaymentMethodComponent implements OnInit {
  @Input() id: String;
  @Input() isRefundByAmount: boolean;
  @Input() refundInformation: any;
  @Input() transcation: any;
  

  @Output() event: EventEmitter<any> = new EventEmitter();
  form: FormGroup;

  loading: boolean = false;
  ticketDetail: any = [];
  paymentDetail: any = [];
  totalAmountInput = 0;
  totalAmountToRefund = 0;
  remainingRemaingAmountToInput: any = 0;

  constructor(
    private _ticketsGQL: TicketsGQLService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    if (this.isRefundByAmount) {
      this.initRefundByAmountForm();
      this.totalAmountToRefund = Number(
        this.refundInformation.amount.toFixed(2)
      );
    } else {
      this.initRefundByItemForm();
      this.calculateRefundAmount();
    }

    this.getTicketDetailByID();
    this.remainingRemaingAmountToInput = this.totalAmountToRefund
  }

  initRefundByAmountForm() {
    const validation = {
      transactionID: [this.id, Validators.compose([Validators.required])],
      reason: [
        this.refundInformation.reason,
        Validators.compose([Validators.required])
      ],
      note: [this.refundInformation.note],
      amount: [Number(this.refundInformation.amount.toFixed(2))],
      remainingAmountAdjust: [
        "",
        Validators.compose([Validators.max(this.transcation.remaining_amount)])
      ],
      location_id: [localStorage.getItem("location_id")],
      payment: this.fb.array([]),
      addToStoreCredit:[''],
    };
    this.form = this.fb.group(validation);
    this.totalAmountToRefund = Number(this.refundInformation.amount.toFixed(2));
  }

  initRefundByItemForm() {
    const validation = {
      transactionID: [this.id, Validators.compose([Validators.required])],
      location_id: [localStorage.getItem("location_id")],
      transactionReturnItems: this.fb.array([]),
      payment: this.fb.array([]),
      addToStoreCredit:[''],
      remainingAmountAdjust: [
        "",
        Validators.compose([Validators.max(this.transcation.remaining_amount)])
      ]
    };
    this.form = this.fb.group(validation);
    this.addTransactionReturnItems();
  }

  addTransactionReturnItems() {
    this.refundInformation.forEach(element => {
      (<FormArray>this.form.get("transactionReturnItems")).push(
        this.fb.group({
          TransactionSellID: [
            element.TransactionSellID,
            Validators.compose([])
          ],
          Product: [element.Product, Validators.compose([Validators.required])],
          sku_number: [element.sku_number],
          unit_price: [element.unit_price],
          return_qty: [element.return_qty],
          supplierId: [element.supplierId],
          reason: [element.reason],
          return_reason: [element.return_reason],
          stock_qty: [element.stock_qty],
          total: [element.total]
        })
      );
    });
  }

  calculateRefundAmount() {
    this.refundInformation &&
      this.refundInformation.forEach(product => {
        let product_waitage =
          (product.total / this.transcation.sub_total_amount) * 100;
        
        let discount_on_product =
          (product_waitage / 100) * this.transcation.discount_amount;
      
        let tax_on_product = this.transcation.is_apply_sale_tax && !this.transcation.is_tax_refund
          ? ((product.total - discount_on_product) *
              this.transcation.tax_value) /
            100
          : 0;

        let product_amount_to_refund =
          Number((product.total - discount_on_product + tax_on_product).toFixed(2));
          
        this.totalAmountToRefund += Number(product_amount_to_refund.toFixed(2));
      });
  }

  get f() {
    return this.form.controls;
  }

  get payments() {
    return this.form.get("payment") as FormArray;
  }

  addPaymentFromGroup() {
    this.paymentDetail.forEach(element => {
      element.amount = Number(element.amount.toFixed(2));
      (<FormArray>this.form.get("payment")).push(
        this.fb.group({
          _id: [element._id, Validators.compose([])],
          amount: [
            "",
            element.method == AllowedPaymentMethod.GiftCard
              ? Validators.compose([
                  Validators.max(element.amount),
                  Validators.min(element.amount)
                ])
              : Validators.compose([Validators.max(element.amount)])
          ]
        })
      );
    });
  }

  getTicketDetailByID() {
    this.loading = true;
    this._ticketsGQL.getTicketDetailByID(this.id).valueChanges.subscribe(
      res => {
        this.loading = false;
        if (res["data"].getTicketDetailById == null) {
          return;
        }
        this.ticketDetail = res["data"].getTicketDetailById;
        this.paymentDetail = res.data.getTicketDetailById.TransactionPayment;
        this.paymentDetail.length && this.paymentMethodName();
        this.paymentDetail.length && this.addPaymentFromGroup();
      },
      err => {
        this.loading = false;
        console.log("err while loading ticket detail", err);
      }
    );
  }

  get showAddToStoreCredit(){
    let show = false
    if(this.paymentDetail && this.paymentDetail.length > 0){
      if(this.paymentDetail.length == 1 && this.paymentDetail[0].method == AllowedPaymentMethod.NetTerm){
        show = false
      }else{
        show = true
      }
    }else{
      show = false
    }
    return show
  }

  paymentMethodName() {
    this.paymentDetail.forEach(element => {
      switch (element.method) {
        case AllowedPaymentMethod.Cash: {
          element.payment_menthod = "Cash";
          break;
        }
        case AllowedPaymentMethod.Cheque: {
          element.payment_menthod = "Cheque";
          break;
        }
        case AllowedPaymentMethod.DiscountCard: {
          element.payment_menthod = "Discount Coupon";
          break;
        }
        case AllowedPaymentMethod.PaypalInvoice: {
          element.payment_menthod = "Pay Pal Invoice";
          break;
        }
        case AllowedPaymentMethod.PaypalTransactionId: {
          element.payment_menthod = "Pay Pal";
          break;
        }
        case AllowedPaymentMethod.StoreCredit: {
          element.payment_menthod = "Store Credit";
          break;
        }
        case AllowedPaymentMethod.GiftCard: {
          element.payment_menthod = "Gift Card";
          break;
        }
        case AllowedPaymentMethod.Card: {
          element.payment_menthod = "Card";
          break;
        }
        case AllowedPaymentMethod.Stripe: {
          element.payment_menthod = "Card";
          break;
        }
        case AllowedPaymentMethod.NetTerm: {
          element.payment_menthod = "Net Term";
          break;
        }
      }
    });
  }

  calculateTotalRefundInput() {
    this.totalAmountInput = 0;
    let data = this.f.payment.value;
    data &&
      data.forEach(element => {
        this.totalAmountInput += Number(element.amount );   
      });
    this.totalAmountInput += Number(this.f.remainingAmountAdjust.value);
    this.totalAmountInput += Number(this.f.addToStoreCredit.value);
    this.remainingRemaingAmountToInput = parseFloat((this.totalAmountToRefund - this.totalAmountInput).toFixed(2));
   
  }

  submit() {
    //this.totalAmountInput = 0
    console.log(this.form.value);
    if (this.f.invalid) {
      return;
    }

    let data = this.removeEmpty(this.form.value);
    data.addToStoreCredit = Number(data.addToStoreCredit)
    data.remainingAmountAdjust = data.remainingAmountAdjust && Number(data.remainingAmountAdjust)
  
    data.payment = data.payment && data.payment.filter(payment => payment.amount);
    data.amount = data.amount && Number(data.amount);

    data.payment && data.payment.forEach(payment=>{payment.amount = Number(payment.amount)});

    this.calculateTotalRefundInput();

    data = this.removeEmpty(data)


    if (this.totalAmountInput !== this.totalAmountToRefund) {
      this.showToasterMessage(
        "error",
        "Add to refund not match with refund amount"
      );
      return;
    }
    

    if (this.isRefundByAmount) {
      this.refundInformation.refund_amount_to_customer ? this.refundAmountToCustomer(data) : this.refundByAmount(data)
    } else {this.refundByitem(data)};
  }

  refundByitem(data) {
    this._ticketsGQL.refundByItem(data).subscribe(
      res => {
        this.showToasterMessage("success", "Refund payment successfully");
        this.callBack();
        this.closeModel();
      },
      err => {
        this.showToasterMessage("error", err.message);
        console.log("err while refunding amount", err);
      }
    );
  }

  refundByAmount(data) {
    this._ticketsGQL.refundByAmount(data).subscribe(
      res => {
        this.showToasterMessage("success", "Refund payment successfully");
        this.callBack();
        this.closeModel();
      },
      err => {
        this.showToasterMessage("error", err.message);
        console.log("err while refunding amount", err);
      }
    );
  }

  refundAmountToCustomer(data){
    this._ticketsGQL.refundAmountToCustomer(data).subscribe(res =>{
      this.showToasterMessage("success", "Refund payment successfully");
      this.callBack();
      this.closeModel();
    }, err => {
      this.showToasterMessage("error", err.message);
      console.log("err while refunding amount", err);
    })
  }

  removeEmpty = obj => {
    Object.keys(obj).forEach(key => {
      if (obj[key] == null || obj[key] == "" || obj[key] == undefined) {
        delete obj[key];
      }
    });

    return obj;
  };

  isControlHasError(
    form: FormGroup,
    controlName: string,
    validationType: string
  ): boolean {
    const control = form.controls[controlName];
    if (!control) {
      return false;
    }

    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  callBack() {
    this.event.emit();
  }

  closeModel() {
    this.modalService.hide(1);
  }

  showToaster = true;
  toasterMsg = "";
  toasterType = "error";
  showToast = false;
  showToasterMessage(type, msg) {
    this.showToaster = false;
    this.toasterType = type;
    this.toasterMsg = msg;
    this.showToast = true;
  }

  closeToaster() {
    this.showToast = false;
    this.showToaster = true;
  }
}
